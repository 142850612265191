import React, { useState } from "react";

import Searchbar from "../../commonComponents/Searchbar/Searchbar";

import "../Inbox.css";
import { Modal, ModalTitle } from "react-bootstrap";
import ChatListing from "./ChatListing";

import { useMediaQuery } from "@mui/material";

const ChatList = (props) => {
  const [searchValue, setSearchValue] = useState("");
  let activeChat = window.location.href.split("/")[5];

  const mobileView = useMediaQuery("(max-width: 600px)");
  return (
    <div
      className="listContainer"
      style={{ paddingTop: mobileView ? "1rem" : "0" }}
    >
      <ModalTitle>
        <div id="listingHeader">
          <div>
            <Searchbar
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />
          </div>
        </div>
      </ModalTitle>

      <Modal.Body>
        <ul className="threadList">
          {props.chatList?.length > 0 ? (
            props.chatList
              .filter((list) =>
                String(list.conversation_name)
                  .replace(/\s+/g, "")
                  .toLowerCase()
                  .includes(
                    searchValue
                      .toLowerCase()
                      .replace(/\s+/g, "")
                      .replace(/,/g, "")
                  )
              )
              .map((chat) => (
                <ChatListing
                  setChatting={props.setChatting}
                  setChatID={props.setChatID}
                  chatId={props.chatId}
                  onlineUsers={props.onlineUsers}
                  chatList={chat}
                  notifications={props.notifications}
                  active={
                    activeChat
                      ? parseInt(activeChat) === chat.conversation_id
                      : false
                  }
                />
              ))
          ) : (
            <div>You currently have no any chat listed.</div>
          )}
        </ul>
      </Modal.Body>
    </div>
  );
};

export default ChatList;
