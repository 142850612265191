import { PDFViewer } from "@react-pdf/renderer";
import AgreementDocument from "./AgreementDocument";
import MainCard from "../../Layout/components/MainCard";

const AgreementViewer = () => {
  return (
    <MainCard
      style={{
        width: "50%",
        height: "90%",
        minWidth: "410px",
        backgroundColor: "#fff",
      }}
    >
      <PDFViewer style={{ width: "100%", height: "80vh" }}>
        <AgreementDocument />
      </PDFViewer>
    </MainCard>
  );
};

export default AgreementViewer;
