import { useEffect, useState } from "react";
import { useUserState } from "../../Context/UserContext";
import Calendar from "./CalendarComponents/Calendar";
import axios from "axios";
import MainCard from "../../Layout/components/MainCard";

const date_today = new Date();
date_today.setHours(0);

const CalendarPage = (props) => {
  const { user } = useUserState();
  const [isMobile, setIsMobile] = useState();

  const [JRs, setJRs] = useState([
    { id: "jr_skeleton_1" },
    { id: "jr_skeleton_2" },
    { id: "jr_skeleton_3" },
    { id: "jr_skeleton_4" },
    { id: "jr_skeleton_5" },
    { id: "jr_skeleton_6" },
  ]);

  const fetchAcceptedData = () => {
    axios.post("/jobRequest/getAcceptedJRdetails").then((res) => {
      const jobDetails = res.data.jobDetails;
      const newJRs = jobDetails.map((JR) => {
        const newJR = JR;
        newJR.job_due = new Date();
        return newJR;
      });
      setJRs(newJRs);
    });
  };

  useEffect(() => {
    fetchAcceptedData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 850) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <MainCard
      style={{ backgroundColor: "#fff" }}
      title="Calendar"
      sx={{ paddingBottom: "1rem" }}
    >
      <Calendar user={user} JRs={JRs} />
    </MainCard>
  );
};

export default CalendarPage;
