import { memo } from "react";
import styles from "./TotalsDisplay.module.css";

const TotalsDisplay = memo((props) => {
  return (
    <div className={styles.totalsDisplay}>
      <div>
        <h2>Time: {props.time} Days</h2>
      </div>
      <div>
        <h2>Jobs: {props.jrCount}</h2>
      </div>
      <div>
        <h2>Total: ${props.total.toFixed(2)}</h2>
      </div>
    </div>
  );
});

export default TotalsDisplay;
