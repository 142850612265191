import React, { useState, useEffect } from "react";

import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Alert
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";

const ReferralModal = (props) => {
  // Default share link
  const shareLink = "https://www.fixden.com/signup";
  const [errorMessage, setErrorMessage] = useState();

  const onSubmit = (values) => {
    const { email, name } = values;
    // console.log(email, name, shareLink);
    axios
      .post("/referTo", {
        email: email,
        name: name,
        shareLink: shareLink
      })
      .then((response) => {
        console.log(response.data.exists);
        const userExist = response.data.exists;
        try {
          if (userExist) {
            setErrorMessage("User with this email already exists");
          } else {
            props.displayReferSuccess();
            props.onCancel();
          }
        } catch (err) {
          console.error("Error submitting form:", err);
        }
      });
  };
  return (
    <Formik
      initialValues={{
        email: "",
        name: ""
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        name: Yup.string().required("Name is required")
      })}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        values
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="email-signup">Reciepient Name*</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="name"
                  type="name"
                  value={values.name}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Name"
                  inputProps={{}}
                />
                {touched.name && errors.name && (
                  <FormHelperText error id="helper-text-email-signup">
                    {errors.name}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="email-signup">
                  Reciepient Email Address*
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id="email-login"
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="email"
                  inputProps={{}}
                />
                {touched.email && errors.email && (
                  <FormHelperText error id="helper-text-email-signup">
                    {errors.email}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="share-link">Share Link</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="share-link"
                  type="text"
                  value={shareLink}
                  readOnly
                  disabled
                  placeholder={shareLink}
                  inputProps={{}}
                />
              </Stack>
            </Grid>
            <FormHelperText
              error
              id="helper-text-firstname-signup"
              style={{ marginLeft: 22 }}
            >
              {errorMessage}
            </FormHelperText>
            <Grid item xs={12}>
              <>
                <Button
                  disableElevation
                  size="small"
                  type="submit"
                  variant="contained"
                  style={{
                    backgroundColor: "#01552f",
                    float: "right",
                    marginLeft: 5
                  }}
                >
                  Send
                </Button>
                <Button
                  disableElevation
                  size="small"
                  onClick={props.onCancel}
                  variant="contained"
                  style={{
                    backgroundColor: "#01552f",
                    float: "right"
                  }}
                >
                  Cancel
                </Button>
              </>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ReferralModal;
