import React, { useState } from "react";
import axios from "axios";
// import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
// import { Switch } from "@mui/base";
// import { Stack } from "@mui/system";
// import { InputLabel } from "@mui/material";
import { Button, InputLabel, Stack, Switch, TextField } from "@mui/material";

const FeedbackForm = (props) => {
  const [problem, setProblem] = useState("");
  const [feedback, setFeedback] = useState("");
  const [feedbackError, setFeedbackError] = useState("");
  const [problemError, setProblemError] = useState("");
  const [problemOrFeedback, setProblemOrFeedback] = useState("0");

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate feedback input
    if (!feedback.trim() && problemOrFeedback === "0") {
      setFeedbackError("Feedback input is required");
      return;
    }

    if (!problem.trim() && problemOrFeedback === "1") {
      setProblemError("Problem input is required");
      return;
    }

    // Clear previous error message
    setFeedbackError("");

    axios.post("/feedback/sendFeedback", {
      problemOrFeedback,
      feedback: problemOrFeedback === "1" ? problem : feedback,
    });

    // Handle form submission logic here
    props.setFeedbackModal(false);
    console.log("Submitted:", { problem, feedback });
  };

  const handleSwitchChange = () => {
    if (problemOrFeedback === "1") {
      setProblemOrFeedback("0");
    } else {
      setProblemOrFeedback("1");
    }
  };

  return (
    <>
      <Stack
        direction={"row"}
        width={"100%"}
        height={"3rem"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <InputLabel
          style={{ cursor: "pointer", marginTop: "0.5rem" }}
          htmlFor="feedback"
        >
          Feedback
        </InputLabel>
        <Switch
          id="feedback"
          color="primary"
          onChange={() => {
            handleSwitchChange();
          }}
        />
        <InputLabel
          style={{ cursor: "pointer", marginTop: "0.5rem" }}
          htmlFor="feedback"
        >
          Problem
        </InputLabel>
      </Stack>

      {problemOrFeedback === "1" && (
        <TextField
          label="Problem"
          multiline
          rows={5}
          variant="outlined"
          fullWidth
          value={problem}
          onChange={(e) => setProblem(e.target.value)}
          inputProps={{ maxLength: 500 }}
          margin="normal"
          required={problemOrFeedback === "1"}
          error={!!problemError}
          helperText={problemError}
          placeholder="Please explain the problem and our team will address it right away..."
        />
      )}

      {problemOrFeedback === "0" && (
        <TextField
          label="Feedback"
          multiline
          rows={5}
          variant="outlined"
          fullWidth
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          inputProps={{ maxLength: 500 }}
          margin="normal"
          required={problemOrFeedback === "0"}
          error={!!feedbackError}
          helperText={feedbackError}
          placeholder="Please explain your thoughts on FixDen..."
        />
      )}
      <Stack width={"100%"}>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          style={{
            marginTop: "16px",
            width: "4rem",
            justifySelf: "flex-end",
            alignSelf: "flex-end",
          }}
        >
          Submit
        </Button>
      </Stack>
    </>
  );
};

export default FeedbackForm;
