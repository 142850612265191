import React from "react";
import styles from "../style.module.css";

const DatePicker = ({ required, value, onChange, onBlur, name, id }) => {
  if (value === "") value = "+1";
  return (
    <input
      required={required}
      placeholder="(888) 888 8888"
      title="Ten digits code"
      type="tel"
      defaultValue={"1"}
      className={styles.inputDefaults}
      name={name}
      id={id}
      value={value}
      onChange={(e) => {
        const input = e.target.value.replace(/\D/g, "").substring(1, 11); // First 11 digits of input only
        const areaCode = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 10);

        if (input.length > 6) {
          e.target.value = `+1-${areaCode}-${middle}-${last}`;
        } else if (input.length > 3) {
          e.target.value = `+1-${areaCode}-${middle}`;
        } else if (input.length > 0) {
          e.target.value = `+1-${areaCode}`;
        } else {
          e.target.value = "+1";
        }
        onChange(e);
      }}
      onBlur={onBlur}
    />
  );
};

export default DatePicker;
