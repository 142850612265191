import React, { useState } from "react";
import { Button, Grid, Link, Stack } from "@mui/material";

const FindHighlighted = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const highlighted = document.querySelectorAll(".highlight");

  const scrollToPrevious = () => {
    if (highlighted.length > 0) {
      setCurrentIndex(currentIndex - 1);
      let currentSpan = document.querySelector(
        `#highlighted${currentIndex - 1}`
      );
      currentSpan.classList.add("highlightFocus");
      let previousSpan = document.querySelector(`#highlighted${currentIndex}`);
      previousSpan.classList.remove("highlightFocus");
    }
  };

  const scrollToNext = () => {
    if (highlighted.length > 0) {
      setCurrentIndex(currentIndex + 1);
      let currentSpan = document.querySelector(
        `#highlighted${currentIndex + 1}`
      );
      currentSpan.classList.add("highlightFocus");
      let previousSpan = document.querySelector(`#highlighted${currentIndex}`);
      previousSpan.classList.remove("highlightFocus");
    }
  };

  return (
    <Grid container width={"fit-content"}>
      <Stack direction={"row"} alignItems={"center"}>
        <Link href={`#highlighted${currentIndex}`}>
          <Button
            onClick={() => {
              scrollToPrevious();
            }}
            disabled={currentIndex === 0}
            style={{
              marginLeft: "0",
              borderTopRightRadius: "0",
              borderBottomRightRadius: "0",
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
              minWidth: "1.5rem",
              maxWidth: "1.5rem",
              minHeight: "1.5rem",
              maxHeight: "1.5rem",
              border: "1px solid #dddddd",
              borderLeft: "0",
            }}
            variant="text"
            color="primary"
            size="small">
            {`<`}
          </Button>
        </Link>
        <Link href={`#highlighted${currentIndex}`}>
          <Button
            onClick={() => {
              scrollToNext();
            }}
            disabled={currentIndex === highlighted.length - 1}
            style={{
              marginLeft: "0",
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
              minWidth: "1.5rem",
              maxWidth: "1.5rem",
              minHeight: "1.5rem",
              maxHeight: "1.5rem",
              border: "1px solid #dddddd",
              borderLeft: "0",
            }}
            variant="text"
            color="primary"
            size="small">
            {`>`}
          </Button>
        </Link>
      </Stack>
    </Grid>
  );
};

export default FindHighlighted;
