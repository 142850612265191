import React, { useCallback, useEffect, useState } from "react";
import "../tile.css";
import {
  List,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  Alert,
  Button,
} from "@mui/material";
import "../../CSS/styles.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SpaIcon from "@mui/icons-material/Spa";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";

// icons
import access from "../../../../images/Icons/access&Security.png";
import applianceRepair from "../../../../images/Icons/applianceRepair.png";
import brickLayer from "../../../../images/Icons/brickLayer.png";
import buildingSupplies from "../../../../images/Icons/buildingSupplies.png";
import cabling from "../../../../images/Icons/cabling&Data.png";
import carpenter from "../../../../images/Icons/carpenter.png";
import electrical from "../../../../images/Icons/Electrical.png";
import environmental from "../../../../images/Icons/environmental.png";
import fireProtection from "../../../../images/Icons/fireProtection.png";
import flooring from "../../../../images/Icons/flooring.png";
import gasFitter from "../../../../images/Icons/gasFitter.png";
import generalRepair from "../../../../images/Icons/generalRepair.png";
import glassWholesale from "../../../../images/Icons/glassWholesale.png";
import heating from "../../../../images/Icons/heating.png";
import homeInspector from "../../../../images/Icons/homeInspector.png";
import hvac from "../../../../images/Icons/HVAC.png";
import landScaping from "../../../../images/Icons/landScaping.png";
import locksmith from "../../../../images/Icons/locksmith.png";
import miscellaneousSupplies from "../../../../images/Icons/miscellaneousSupplies.png";
import oilBurnerMechanic from "../../../../images/Icons/oilBurnerMechanic.png";
import other from "../../../../images/Icons/other.png";
import pestControl from "../../../../images/Icons/pestControl.png";
import plasterPaint from "../../../../images/Icons/plaster&PaintServices.png";
import plumbing from "../../../../images/Icons/plumbing.png";
import propaneServices from "../../../../images/Icons/propaneServices.png";
import propertyManagement from "../../../../images/Icons/propertyManagement.png";
import propertyRestoration from "../../../../images/Icons/propertyRestoration.png";
import refrigerationAC from "../../../../images/Icons/refrigeration&AC.png";
import roofing from "../../../../images/Icons/roofing.png";
import sheetMetalWork from "../../../../images/Icons/sheetMetalWork.png";
import snowClearing from "../../../../images/Icons/snowClearing.png";
import tileSetter from "../../../../images/Icons/tileSetter.png";
import ventilation from "../../../../images/Icons/ventilation.png";
import wasteManagement from "../../../../images/Icons/wasteManagement.png";
import windowsDoors from "../../../../images/Icons/windows&Doors.png";

import axios from "axios";

import ExpandedTilePhase1 from "./ExpandedTilePhase1";
import ExpandedTilePhase3 from "./ExpandedTilePhase3";
import ExpandedTilePhase4 from "./ExpandedTilePhase4";
import ExpandedTilePhase5 from "./ExpandedTilePhase5";
import ClientAvailability from "../Availability/ClientAvailability";

import moment from "moment-timezone";
import LoadScreen from "../../../../Components/LoadScreen";
import { useJRContext } from "../../../../Context/JobRequestContext";
import useOnce from "../../../../hooks/useOnce";
import { useTheme } from "@emotion/react";
import {
  CalendarTwoTone,
  DeleteOutlined,
  EditOutlined,
  MessageTwoTone,
} from "@ant-design/icons";
import { useUserState } from "../../../../Context/UserContext";
import JobRequest from "../JobRequest/JobRequest";
import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";
moment.tz.setDefault("Canada/Newfoundland");

const ClientExpandedTile = (props) => {
  let jrStatus = props.jrData.job_status;

  if (jrStatus === "Bidding") {
    console.log(props.jrData.job_description, "JRDATAAAAAAAAAAAAAAAAa");
    return props.jrData.job_description !== "" ? (
      <div style={{ width: "100%", cursor: "default" }}>
        <ExpandedTilePhase1
          openAgreement={props.setOpenAgreement}
          chargePercentages={props.chargePercentages}
          fromChat={props.fromChat}
          fetchData={props.fetchData}
          events={props.events}
          fetchJrData={props.fetchJrData}
          jrImages={props.jrImages}
          jrData={props.jrData}
          setOpenBid={props.setOpenBid}
          scopeDetails={props.scopeDetails}
          bidList={props.bidList}
          setAgreementOpen={props.setAgreementOpen}
          paymentMethod={props.paymentMethod}
          availabilities={props.availabilities}
        />
      </div>
    ) : (
      <LoadScreen />
    );
  } else if (jrStatus === "Scheduling") {
    return props.activityList.length > 0 ? (
      <div style={{ width: "100%", cursor: "default" }}>
        <ExpandedTilePhase1
          openAgreement={props.setOpenAgreement}
          chargePercentages={props.chargePercentages}
          fromChat={props.fromChat}
          fetchData={props.fetchData}
          bidList={props.bidList}
          events={props.events}
          fetchJrData={props.fetchJrData}
          jrImages={props.jrImages}
          jrData={props.jrData}
          setOpenBid={props.setOpenBid}
          scopeDetails={props.scopeDetails}
          vendorInvoiceDetails={props.vendorInvoiceDetails}
          setAgreementOpen={props.setAgreementOpen}
          paymentMethod={props.paymentMethod}
          availabilities={props.availabilities}
        />

        <ExpandedTilePhase3
          fetchData={props.fetchData}
          events={props.events}
          troubleshootingList={props.troubleshootingList}
          troubleshootingImages={props.troubleshootingImages}
          actionList={props.actionList}
          actionImages={props.actionImages}
          reworkDetails={props.reworkDetails}
          fetchJrData={props.fetchJrData}
          activityList={props.activityList}
          jrData={props.jrData}
        />
      </div>
    ) : (
      <LoadScreen />
    );
  } else if (
    jrStatus === "Reviewing" ||
    jrStatus === "Completing" ||
    jrStatus === "Invoicing"
  ) {
    return props.activityList.length > 0 ? (
      <div style={{ width: "100%", cursor: "default" }}>
        <ExpandedTilePhase1
          openAgreement={props.setOpenAgreement}
          chargePercentages={props.chargePercentages}
          fromChat={props.fromChat}
          fetchData={props.fetchData}
          bidList={props.bidList}
          fetchJrData={props.fetchJrData}
          activityList={props.activityList}
          jrImages={props.jrImages}
          jrData={props.jrData}
          setOpenBid={props.setOpenBid}
          scopeDetails={props.scopeDetails}
          setAgreementOpen={props.setAgreementOpen}
          paymentMethod={props.paymentMethod}
          availabilities={props.availabilities}
        />
        <ExpandedTilePhase3
          fetchData={props.fetchData}
          events={props.events}
          troubleshootingList={props.troubleshootingList}
          troubleshootingImages={props.troubleshootingImages}
          actionList={props.actionList}
          actionImages={props.actionImages}
          reworkDetails={props.reworkDetails}
          fetchJrData={props.fetchJrData}
          activityList={props.activityList}
          jrData={props.jrData}
          vendorInvoiceDetails={props.vendorInvoiceDetails}
        />
        <ExpandedTilePhase4
          fetchData={props.fetchData}
          invoice={props.invoice}
          invoiceDetails={props.invoiceDetails}
          fetchJrData={props.fetchJrData}
          activityList={props.activityList}
          jrData={props.jrData}
          clientReview={props.clientReview}
        />
      </div>
    ) : (
      <LoadScreen />
    );
  } else if (jrStatus === "Finalizing") {
    return props.activityList.length > 0 ? (
      <div style={{ width: "100%", cursor: "default" }}>
        <ExpandedTilePhase1
          openAgreement={props.setOpenAgreement}
          chargePercentages={props.chargePercentages}
          fromChat={props.fromChat}
          fetchData={props.fetchData}
          bidList={props.bidList}
          fetchJrData={props.fetchJrData}
          activityList={props.activityList}
          jrImages={props.jrImages}
          jrData={props.jrData}
          setOpenBid={props.setOpenBid}
          scopeDetails={props.scopeDetails}
          setAgreementOpen={props.setAgreementOpen}
          paymentMethod={props.paymentMethod}
          availabilities={props.availabilities}
        />
        <ExpandedTilePhase3
          fetchData={props.fetchData}
          events={props.events}
          troubleshootingList={props.troubleshootingList}
          troubleshootingImages={props.troubleshootingImages}
          actionList={props.actionList}
          actionImages={props.actionImages}
          reworkDetails={props.reworkDetails}
          fetchJrData={props.fetchJrData}
          activityList={props.activityList}
          jrData={props.jrData}
          vendorInvoiceDetails={props.vendorInvoiceDetails}
        />
        <ExpandedTilePhase4
          fetchData={props.fetchData}
          invoice={props.invoice}
          invoiceDetails={props.invoiceDetails}
          fetchJrData={props.fetchJrData}
          activityList={props.activityList}
          jrData={props.jrData}
          clientReview={props.clientReview}
          clientInvoiceDetails={props.clientInvoiceDetails}
          vendorInvoiceDetails={props.vendorInvoiceDetails}
        />
        <ExpandedTilePhase5
          invoice={props.invoice}
          invoiceDetails={props.invoiceDetails}
          clientInvoiceDetails={props.clientInvoiceDetails}
          vendorInvoiceDetails={props.vendorInvoiceDetails}
          fetchJrData={props.fetchJrData}
          activityList={props.activityList}
          jrData={props.jrData}
        />
      </div>
    ) : (
      <LoadScreen />
    );
  }
};

export function ClientTile(props) {
  let navigate = useNavigate();
  const { user } = useUserState();
  const { openJR, setOpenJR } = useJRContext();
  const request_time = new Date(props.jrData.job_request_time);
  const [clicked, setClicked] = useState(false);
  const [showAvailability, setShowAvailability] = useState(false);
  const [availibilities, setAvailibilities] = useState("");
  const [isExpanded, setIsExpanded] = useState(
    props.jrData.job_id === openJR?.job_id
  );
  const [color, setColor] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [submitAlert, setSubmitAlert] = useState(false);
  const [editJobModal, setEditJobModal] = useState(false);

  const [reworkDetails, setReworkDetails] = useState();
  const [activityList, setActivityList] = useState([]);
  const [clientReview, setClientReview] = useState([]);
  const [events, setEvents] = useState([]);
  const [troubleshootingList, setTroubleshootingList] = useState([]);
  const [troubleshootingImages, setTroubleshootingImages] = useState([]);
  const [actionList, setActionList] = useState([]);
  const [actionImages, setActionImages] = useState([]);
  const [jrImages, setJrImages] = useState([]);
  const [bidList, setBidList] = useState([]);
  const [scopeDetails, setScopeDetails] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [vendorInvoiceDetails, setVendorInvoiceDetails] = useState([]);
  const [clientInvoiceDetails, setClientInvoiceDetails] = useState([]);

  const [cancelJobModal, setCancelJobModal] = useState(false);

  const fetchData = useCallback(
    (page) => {
      const cachedData = sessionStorage.getItem(
        `cachedJobData#${props.jrData.job_id}`
      );

      if (cachedData) {
        if (typeof page !== "undefined") {
          const parsedData = JSON.parse(cachedData);

          console.log(parsedData.invoice.invoiceDetails, "bidDetails 3");

          setActivityList(parsedData.activities);
          setEvents(parsedData.events);
          setTroubleshootingList(parsedData.troubleshoots.troubleshootData);
          setTroubleshootingImages(parsedData.troubleshoots.resultImages);
          setActionList(parsedData.actions.actionData);
          setActionImages(parsedData.actions.resultImages);
          setClientReview(parsedData.reviews.clientReview);
          setJrImages(parsedData.jrImages);
          setReworkDetails(parsedData.reworks);
          setBidList(parsedData.bidDetails);
          setScopeDetails(parsedData.scopeDetails);
          setInvoice(parsedData.invoice.invoiceDetails);
          setInvoiceDetails(parsedData.invoiceDetails.extraDetails);
          setVendorInvoiceDetails(parsedData.vendorInvoiceDetails);
          setClientInvoiceDetails(parsedData.clientInvoiceDetails);
        } else {
          axios
            .post("/jobRequest/getExpandedClientDetails", {
              JobId: props.jrData.job_id,
              vendorId: props.jrData.assigned_vendor_id,
              clientId: props.jrData.requester_id,
            })
            .then((res) => {
              const activityData = res.data.activities;
              const events = res.data.events;
              const troubleshoots = res.data.troubleshoots;
              const actions = res.data.actions;
              const reviews = res.data.reviews;
              const jrImages = res.data.jrImages;
              const reworks = res.data.reworks;
              const bidDetails = res.data.bidDetails;
              const scopeDetails = res.data.scopeDetails;
              const invoice = res.data.invoiceDetails;
              const vendorInvoiceDetails = res.data.vendorInvoiceDetails;
              const clientInvoiceDetails = res.data.clientInvoiceDetails;
              console.log(bidDetails, "bidDetails 2");

              setActivityList(activityData);
              setEvents(events);
              setTroubleshootingList(troubleshoots.troubleshootData);
              setTroubleshootingImages(troubleshoots.resultImages);
              setActionList(actions.actionData);
              setActionImages(actions.resultImages);
              setClientReview(reviews.clientReview);
              setJrImages(jrImages);
              setReworkDetails(reworks);
              setBidList(bidDetails);
              setScopeDetails(scopeDetails);
              setInvoice(
                invoice.invoiceDetails.length > 0
                  ? invoice.invoiceDetails[0]
                  : invoice.invoiceDetails
              );
              setInvoiceDetails(invoice.extraDetails);
              setVendorInvoiceDetails(vendorInvoiceDetails);
              setClientInvoiceDetails(clientInvoiceDetails);

              const dataToCache = {
                activities: activityData,
                events: events,
                troubleshoots: troubleshoots,
                actions: actions,
                jrImages: jrImages,
                reworks: reworks,
                bidDetails: bidDetails,
                scopeDetails: scopeDetails,
                invoice: invoice,
                invoiceDetails: invoiceDetails,
                reviews: reviews,
                vendorInvoiceDetails: vendorInvoiceDetails,
                clientInvoiceDetails: clientInvoiceDetails,
              };
              sessionStorage.setItem(
                `cachedJobData#${props.jrData.job_id}`,
                JSON.stringify(dataToCache)
              );
            });
        }
      } else {
        axios
          .post("/jobRequest/getExpandedClientDetails", {
            JobId: props.jrData.job_id,
            vendorId: props.jrData.assigned_vendor_id,
            clientId: props.jrData.requester_id,
          })
          .then((res) => {
            const activityData = res.data.activities;
            const events = res.data.events;
            const troubleshoots = res.data.troubleshoots;
            const actions = res.data.actions;
            const reviews = res.data.reviews;
            const jrImages = res.data.jrImages;
            const reworks = res.data.reworks;
            const bidDetails = res.data.bidDetails;
            const scopeDetails = res.data.scopeDetails;
            const invoice = res.data.invoiceDetails;
            const vendorInvoiceDetails = res.data.vendorInvoiceDetails;
            const clientInvoiceDetails = res.data.clientInvoiceDetails;
            console.log(invoice, "bidDetails 1");

            setActivityList(activityData);
            setEvents(events);
            setTroubleshootingList(troubleshoots.troubleshootData);
            setTroubleshootingImages(troubleshoots.resultImages);
            setActionList(actions.actionData);
            setActionImages(actions.resultImages);
            setClientReview(reviews);
            setJrImages(jrImages);
            setReworkDetails(reworks);
            setBidList(bidDetails);
            setScopeDetails(scopeDetails);
            setInvoice(
              invoice.invoiceDetails.length > 0
                ? invoice.invoiceDetails[0]
                : invoice.invoiceDetails
            );
            setInvoiceDetails(invoice.extraDetails);
            setVendorInvoiceDetails(vendorInvoiceDetails);
            setClientInvoiceDetails(clientInvoiceDetails);

            const dataToCache = {
              activities: activityData,
              events: events,
              troubleshoots: troubleshoots,
              actions: actions,
              jrImages: jrImages,
              reworks: reworks,
              bidDetails: bidDetails,
              scopeDetails: scopeDetails,
              invoice: invoice,
              invoiceDetails: invoiceDetails,
              reviews: reviews,
              vendorInvoiceDetails: vendorInvoiceDetails,
              clientInvoiceDetails: clientInvoiceDetails,
            };
            sessionStorage.setItem(
              `cachedJobData#${props.jrData.job_id}`,
              JSON.stringify(dataToCache)
            );
          });
      }
    },
    [
      props.jrData.assigned_vendor_id,
      props.jrData.job_id,
      props.jrData.requester_id,
      invoiceDetails,
    ]
  );

  useOnce(() => {
    fetchData("TILE");
  });

  const displaySubmitAlert = () => {
    setSubmitAlert(true);
    setTimeout(() => setSubmitAlert(false), 4000);
  };

  console.log(props.jrData);
  const fetchPaymentMethod = () => {
    axios.get("/stripe/getPreferredPaymentMethod").then((res) => {
      if (res.data.paymentMethod !== null && res.data.ok) {
        setPaymentMethod(true);
      } else {
        setPaymentMethod(false);
      }
    });
  };
  useOnce(() => {
    fetchPaymentMethod();
  });
  console.log(paymentMethod);

  // time difference
  const passTime = (time) => {
    time = moment(time);
    let days = moment().diff(time, "days");
    if (days > 0) return days + "d";

    let hours = moment().diff(time, "hours");
    if (hours > 0) return hours + "h";

    let minutes = moment().diff(time, "minutes");
    return minutes + "m";
  };
  const openAvailability = () => {
    setShowAvailability(!showAvailability);
  };
  const getAvailability = useCallback(() => {
    axios.get("/schedule/get/retrieveavailability").then((response) => {
      if (response.data.Item) {
        let availabilities = response.data.Item.availability;
        let jrClientAvail = availabilities.filter(
          (avail) => avail.jobId === props.jrData.job_id
        );
        setAvailibilities(jrClientAvail);
      }
    });
  }, [props.jrData.job_id]);

  useEffect(() => {
    getAvailability();
  }, [getAvailability]);

  useEffect(() => {
    const shouldExpand = props.jrData.job_id === openJR?.job_id;
    setIsExpanded(shouldExpand);
    shouldExpand ? setColor("#dddddd") : setColor("#FDFDFD");
  }, [openJR, props.jrData.job_id]);

  const ref = React.useRef(null);
  const newChat = (vendorId, vendorName, bidId) => {
    axios
      .post("/communication/createConversation", {
        UserId: vendorId,
        JobId: props.jrData.job_id,
        VendorName: vendorName,
        BidChat: 1,
        BidId: bidId,
      })
      .then((res) => {
        navigate(`/dashboard/chat/${res.data.chatId}`);
      });
  };

  let tileIcon;
  switch (props.jrData.job_category) {
    case "Access & Security":
      tileIcon = access;
      break;
    case "Appliance Repair":
      tileIcon = applianceRepair;
      break;
    case "Brick Layer":
      tileIcon = brickLayer;
      break;
    case "Building Supplies":
      tileIcon = buildingSupplies;
      break;
    case "Cabling & Data":
      tileIcon = cabling;
      break;
    case "Carpenter":
      tileIcon = carpenter;
      break;
    case "Electrical":
      tileIcon = electrical;
      break;
    case "Environmental":
      tileIcon = environmental;
      break;
    case "Fire Protection":
      tileIcon = fireProtection;
      break;
    case "Flooring":
      tileIcon = flooring;
      break;
    case "Gas Fitter":
      tileIcon = gasFitter;
      break;
    case "General Repair":
      tileIcon = generalRepair;
      break;
    case "Glass Wholesale":
      tileIcon = glassWholesale;
      break;
    case "Heating":
      tileIcon = heating;
      break;
    case "Home Inspector":
      tileIcon = homeInspector;
      break;
    case "HVAC":
      tileIcon = hvac;
      break;
    case "Landscaping":
      tileIcon = landScaping;
      break;
    case "Locksmith":
      tileIcon = locksmith;
      break;
    case "Miscellaneous Supply":
      tileIcon = miscellaneousSupplies;
      break;
    case "Oil Burner Mechanic":
      tileIcon = oilBurnerMechanic;
      break;
    case "Pest Control":
      tileIcon = pestControl;
      break;
    case "Plaster & Paint Services":
      tileIcon = plasterPaint;
      break;
    case "Plumbing":
      tileIcon = plumbing;
      break;
    case "Propane Services":
      tileIcon = propaneServices;
      break;
    case "Property Management":
      tileIcon = propertyManagement;
      break;
    case "Property Restoration":
      tileIcon = propertyRestoration;
      break;
    case "Refrigeration & AC":
      tileIcon = refrigerationAC;
      break;
    case "Roofing":
      tileIcon = roofing;
      break;
    case "Sheet Metal Worker":
      tileIcon = sheetMetalWork;
      break;
    case "Snow Clearing":
      tileIcon = snowClearing;
      break;
    case "Tile Setter":
      tileIcon = tileSetter;
      break;
    case "Ventilation":
      tileIcon = ventilation;
      break;
    case "Waste Management":
      tileIcon = wasteManagement;
      break;
    case "Windows & Doors":
      tileIcon = windowsDoors;
      break;
    case "Other":
    default:
      tileIcon = other;
      break;
  }

  const unActivateJob = async (JobId) => {
    await axios
      .post("/jobRequest/deactivateJob", { jobId: JobId })
      .then((res) => {
        if (res.data.success) {
          props.fetchJrData();
          console.log("SUCCESS");
        } else {
          console.log("FAIL");
        }
      });
  };

  const theme = useTheme();

  const greaterThanSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const matches500 = useMediaQuery("(min-width: 600px)");
  const matches431 = useMediaQuery("(min-width: 431px)");

  return (
    <>
      {greaterThanSmall && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "100% !important" }}
        >
          <div
            style={{ backgroundColor: color }}
            ref={ref}
            className={`tile`}
            onClick={() => {
              const id = props.jrData.job_id;
              setClicked(true);
              if (openJR === null || openJR.job_id !== id) {
                if (typeof props.setMapFocus === "function") {
                  props.setMapFocus(props.jrData.location);
                }
                setOpenJR(props.jrData);
              } else {
                setOpenJR(null);
              }
            }}
          >
            <Grid item md={3} justifyContent="center" alignItems="center">
              {/* <Stack direction={"row"}> */}
              <Typography className="title">
                JR ID:{props.jrData.job_id}
                {/* <Stack direction="row" spacing={0.5} className="icon-size">
                    {props.jrData.assigned_vendor_id ? (
                      <>
                        {!props.fromChat && (

                          <MessageTwoTone
                            onClick={(e) => {
                              e.stopPropagation();
                              newChat(
                                props.jrData.assigned_vendor_id,
                                props.jrData.vendor_legal_name,
                                null
                              );
                            }}
                          />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Stack> */}
              </Typography>
              {/* </Stack> */}

              <img
                style={{ height: "40%" }}
                id="icon"
                src={tileIcon}
                alt="icon"
              />
              <Typography className="title">
                {`${passTime(request_time)} ago`}
              </Typography>
            </Grid>
            <Grid item md={5} justifyContent="center" alignItems="center">
              <List>
                <Stack direction={"row"} spacing={1}>
                  <Typography className="title">Category:</Typography>
                  <Typography
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                  >
                    {props.jrData.job_category}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography className="title">Title:</Typography>
                  <Typography
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                  >
                    {props.jrData.job_title}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography className="title">Address:</Typography>
                  <Typography
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                  >
                    {props.jrData.address}
                  </Typography>
                </Stack>
              </List>
            </Grid>
            <Grid item sm={4}>
              <Stack
                direction={"row-reverse"}
                spacing={1}
                alignItems={"center"}
              >
                <Stack direction={"column"} spacing={1}>
                  {props.jrData.assigned_vendor_id ? (
                    <>
                      {!props.fromChat && (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={(e) => {
                            e.stopPropagation();
                            newChat(
                              props.jrData.assigned_vendor_id,
                              props.jrData.vendor_legal_name,
                              null
                            );
                          }}
                          // style={{
                          //   position: "absolute",
                          //   top: "-0.75rem",
                          //   right: 0,
                          // }}
                        >
                          Chat
                        </Button>
                        // <MessageTwoTone
                        //   onClick={(e) => {
                        //     e.stopPropagation();
                        //     newChat(
                        //       props.jrData.assigned_vendor_id,
                        //       props.jrData.vendor_legal_name,
                        //       null
                        //     );
                        //   }}
                        // />
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {bidList.length === 0 ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditJobModal(true);
                      }}
                      // style={{ position: "absolute", top: "-3.75rem", right: 0 }}
                    >
                      Edit Details
                    </Button>
                  ) : (
                    ""
                  )}

                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => {
                      e.stopPropagation();
                      openAvailability(e);
                    }}
                    // style={{ position: "absolute", right: 0, top: 0 }}
                  >
                    Edit Availability
                  </Button>
                  {!props.jrData.assigned_vendor_id ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCancelJobModal(true);
                      }}
                      // style={{ position: "absolute", right: 0, top: 0 }}
                    >
                      Cancel Job
                    </Button>
                  ) : (
                    ""
                  )}
                </Stack>

                <Stack direction={"row"} spacing={1}>
                  <Typography className="title">Status:</Typography>
                  {props.jrData.is_done === 1 ? (
                    <>
                      <Typography
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                        style={{ color: "green" }}
                      >
                        Completed
                      </Typography>
                      <Typography
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                      >
                        (<em>{props.jrData.job_status}</em>)
                      </Typography>
                    </>
                  ) : props.jrData.assigned_vendor_id !== null ? (
                    <>
                      <Typography style={{ color: "#debd02" }}>
                        Ongoing
                      </Typography>
                      <Typography
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                      >
                        (<em>{props.jrData.job_status}</em>)
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography>Tender</Typography>
                      <Typography
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                      >
                        (<em>{props.jrData.job_status}</em>)
                      </Typography>
                    </>
                  )}
                </Stack>
              </Stack>
            </Grid>
            {/* <Grid item md={0.1} justifyContent="center" alignItems="center">
              {props.notification && !clicked && (
                <Typography color={"#00ff00"} variant={"h2"}>
                  !
                </Typography>
              )}
            </Grid> */}
          </div>
          {isExpanded && (
            <ClientExpandedTile
              chargePercentages={props.chargePercentages}
              fromChat={props.fromChat}
              jrDetails={props.jrDetails}
              fetchData={fetchData}
              fetchJrData={props.fetchJrData}
              events={events}
              jrImages={jrImages}
              setShowSubmitForm={props.setShowSubmitForm}
              jrData={props.jrData}
              openJR={openJR}
              setAgreementOpen={props.setAgreementOpen}
              paymentMethod={paymentMethod}
              availabilities={availibilities}
              scopeDetails={scopeDetails}
              bidList={bidList}
              vendorInvoiceDetails={vendorInvoiceDetails}
              troubleshootingList={troubleshootingList}
              troubleshootingImages={troubleshootingImages}
              actionList={actionList}
              actionImages={actionImages}
              reworkDetails={reworkDetails}
              activityList={activityList}
              invoice={invoice}
              clientReview={clientReview}
              clientInvoiceDetails={clientInvoiceDetails}
            />
          )}
        </Grid>
      )}

      {!greaterThanSmall && (
        <>
          <>
            {!matches431 && (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ padding: 0.5 }}
              >
                <div
                  style={{ backgroundColor: color, width: "100%" }}
                  ref={ref}
                  className={`tile`}
                  onClick={() => {
                    const id = props.jrData.job_id;
                    setClicked(true);
                    if (openJR === null || openJR.job_id !== id) {
                      if (typeof props.setMapFocus === "function") {
                        props.setMapFocus(props.jrData.location);
                      }
                      setOpenJR(props.jrData);
                    } else {
                      setOpenJR(null);
                    }
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Stack direction={"row"}>
                      <Grid container spacing={12}>
                        <Grid item xs={8}>
                          <Typography className="jobTitle">
                            {props.jrData.job_title}{" "}
                            <Typography sx={{ fontWeight: "bold" }}>
                              {`${passTime(request_time)} ago`}{" "}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography className="title">
                            JR ID:{props.jrData.job_id}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                    <List>
                      <Stack direction={"row"} spacing={1}>
                        <img
                          style={{
                            height: matches500 ? "40%" : "5%",
                            width: matches500 ? "40%" : "5%",
                          }}
                          id="icon"
                          src={tileIcon}
                          alt="icon"
                        />

                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                        >
                          {props.jrData.job_category}
                        </Typography>
                      </Stack>

                      <Stack direction={"row"} spacing={1}>
                        <LocationOnIcon
                          style={{
                            height: matches500 ? "40%" : "5%",
                            width: matches500 ? "40%" : "5%",
                          }}
                        />
                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                        >
                          {props.jrData.address}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={1}>
                        <SpaIcon
                          style={{
                            height: matches500 ? "40%" : "5%",
                            width: matches500 ? "40%" : "5%",
                          }}
                        />

                        {props.jrData.is_done === 1 ? (
                          <>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                              style={{ color: "green" }}
                            >
                              Completed
                            </Typography>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              (<em>{props.jrData.job_status}</em>)
                            </Typography>
                          </>
                        ) : props.jrData.assigned_vendor_id !== null ? (
                          <>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                              style={{ color: "#debd02" }}
                            >
                              Ongoing
                            </Typography>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              (<em>{props.jrData.job_status}</em>)
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              Tender
                            </Typography>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              (<em>{props.jrData.job_status}</em>)
                            </Typography>
                          </>
                        )}
                      </Stack>
                      <Stack direction={"row"} spacing={1}>
                        <EmojiPeopleIcon
                          style={{
                            height: matches500 ? "40%" : "5%",
                            width: matches500 ? "40%" : "5%",
                          }}
                        />
                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                        >
                          {props.jrData.assigned_vendor_id
                            ? `${props.jrData.vendor_legal_name}(Vendor Name)`
                            : "Waiting for bids from tradespeople"}
                        </Typography>
                      </Stack>
                    </List>
                  </Grid>
                  {matches431 ? (
                    <Stack
                      direction={"row-reverse"}
                      spacing={1}
                      alignItems={"center"}
                    >
                      <Stack direction={"column"} spacing={1}>
                        {props.jrData.assigned_vendor_id ? (
                          <>
                            {!props.fromChat && (
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  newChat(
                                    props.jrData.assigned_vendor_id,
                                    props.jrData.vendor_legal_name,
                                    null
                                  );
                                }}
                                // style={{
                                //   position: "absolute",
                                //   top: "-0.75rem",
                                //   right: 0,
                                // }}
                              >
                                Chat
                              </Button>
                              // <MessageTwoTone
                              //   onClick={(e) => {
                              //     e.stopPropagation();
                              //     newChat(
                              //       props.jrData.assigned_vendor_id,
                              //       props.jrData.vendor_legal_name,
                              //       null
                              //     );
                              //   }}
                              // />
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={(e) => {
                            e.stopPropagation();

                            setEditJobModal(true);
                          }}
                          // style={{ position: "absolute", top: "-3.75rem", right: 0 }}
                        >
                          Edit Details
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={(e) => {
                            e.stopPropagation();
                            openAvailability(e);
                          }}
                          // style={{ position: "absolute", right: 0, top: 0 }}
                        >
                          Edit Availability
                        </Button>
                      </Stack>
                    </Stack>
                  ) : (
                    <Stack
                      direction={"column"}
                      spacing={1}
                      marginRight={"1rem"}
                    >
                      <>
                        {bidList.length === 0 ? (
                          <EditOutlined
                            className="anticon-color"
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditJobModal(true);
                            }}
                          />
                        ) : (
                          ""
                        )}

                        <CalendarTwoTone
                          twoToneColor={"#017a46"}
                          className="anticon-color"
                          title="View Availability"
                          alt="View Availability"
                          onClick={(e) => {
                            e.stopPropagation();
                            openAvailability(e);
                          }}
                        />
                        {props.jrData.assigned_vendor_id
                          ? !props.fromChat && (
                              <MessageTwoTone
                                twoToneColor={"#017a46"}
                                className="anticon-color"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  newChat(
                                    props.jrData.assigned_vendor_id,
                                    props.jrData.vendor_legal_name,
                                    null
                                  );
                                }}
                              />
                            )
                          : ""}
                        {console.log(bidList)}
                        {bidList.length === 0 ? (
                          <DeleteOutlined
                            className="anticon-color"
                            onClick={(e) => {
                              e.stopPropagation();
                              setCancelJobModal(true);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    </Stack>
                  )}
                  {/* <Grid item xs={1} justifyContent="center" alignItems="center">
                    {props.notification && !clicked && (
                      <Typography color={"#00ff00"} variant={"h2"}>
                        !
                      </Typography>
                    )}
                  </Grid> */}
                </div>
                {isExpanded && (
                  <ClientExpandedTile
                    chargePercentages={props.chargePercentages}
                    fromChat={props.fromChat}
                    jrDetails={props.jrDetails}
                    fetchData={fetchData}
                    fetchJrData={props.fetchJrData}
                    events={events}
                    jrImages={jrImages}
                    setShowSubmitForm={props.setShowSubmitForm}
                    jrData={props.jrData}
                    openJR={openJR}
                    setAgreementOpen={props.setAgreementOpen}
                    paymentMethod={paymentMethod}
                    availabilities={availibilities}
                    scopeDetails={scopeDetails}
                    bidList={bidList}
                    vendorInvoiceDetails={vendorInvoiceDetails}
                    troubleshootingList={troubleshootingList}
                    troubleshootingImages={troubleshootingImages}
                    actionList={actionList}
                    actionImages={actionImages}
                    reworkDetails={reworkDetails}
                    activityList={activityList}
                    invoice={invoice}
                    clientReview={clientReview}
                    clientInvoiceDetails={clientInvoiceDetails}
                  />
                )}
              </Grid>
            )}
          </>
          <>
            {matches431 && (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <div
                  style={{ backgroundColor: color, width: "100%" }}
                  ref={ref}
                  className={`tile`}
                  onClick={() => {
                    const id = props.jrData.job_id;
                    setClicked(true);
                    if (openJR === null || openJR.job_id !== id) {
                      if (typeof props.setMapFocus === "function") {
                        props.setMapFocus(props.jrData.location);
                      }
                      setOpenJR(props.jrData);
                    } else {
                      setOpenJR(null);
                    }
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="center"
                    alignItems="center"
                    direction={"row"}
                  >
                    <Stack direction={"row"}>
                      <Typography className="title">ID:</Typography>
                      <Typography>{props.jrData.job_id}</Typography>
                    </Stack>

                    <img
                      style={{
                        height: matches500 ? "40%" : "5%",
                        width: matches500 ? "40%" : "5%",
                      }}
                      id="icon"
                      src={tileIcon}
                      alt="icon"
                    />
                    <Typography className="title">
                      {`${passTime(request_time)} ago`}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <List>
                      <Stack direction={"row"} spacing={1}>
                        <Typography className="title">Category:</Typography>
                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                        >
                          {props.jrData.job_category}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={1}>
                        <Typography className="title">Title:</Typography>
                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                        >
                          {props.jrData.job_title}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={1}>
                        <Typography className="title">Address:</Typography>
                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                        >
                          {props.jrData.address}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={1}>
                        <Typography className="title">Status:</Typography>
                        {props.jrData.is_done === 1 ? (
                          <>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                              style={{ color: "green" }}
                            >
                              Completed
                            </Typography>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              (<em>{props.jrData.job_status}</em>)
                            </Typography>
                          </>
                        ) : props.jrData.assigned_vendor_id !== null ? (
                          <>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                              style={{ color: "#debd02" }}
                            >
                              Ongoing
                            </Typography>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              (<em>{props.jrData.job_status}</em>)
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              Tender
                            </Typography>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              (<em>{props.jrData.job_status}</em>)
                            </Typography>
                          </>
                        )}
                      </Stack>
                    </List>
                  </Grid>
                  <Stack
                    direction={"row-reverse"}
                    spacing={1}
                    alignItems={"center"}
                  >
                    <Stack direction={"column"} spacing={1}>
                      {props.jrData.assigned_vendor_id ? (
                        <>
                          {!props.fromChat && (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={(e) => {
                                e.stopPropagation();
                                newChat(
                                  props.jrData.assigned_vendor_id,
                                  props.jrData.vendor_legal_name,
                                  null
                                );
                              }}
                              // style={{
                              //   position: "absolute",
                              //   top: "-0.75rem",
                              //   right: 0,
                              // }}
                            >
                              Chat
                            </Button>
                            // <MessageTwoTone
                            //   onClick={(e) => {
                            //     e.stopPropagation();
                            //     newChat(
                            //       props.jrData.assigned_vendor_id,
                            //       props.jrData.vendor_legal_name,
                            //       null
                            //     );
                            //   }}
                            // />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={(e) => {
                          e.stopPropagation();

                          setEditJobModal(true);
                        }}
                        // style={{ position: "absolute", top: "-3.75rem", right: 0 }}
                      >
                        Edit Details
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={(e) => {
                          e.stopPropagation();
                          openAvailability(e);
                        }}
                        // style={{ position: "absolute", right: 0, top: 0 }}
                      >
                        Edit Availability
                      </Button>
                    </Stack>
                  </Stack>
                  {/* <Grid item xs={1} justifyContent="center" alignItems="center">
                    {props.notification && !clicked && (
                      <Typography color={"#00ff00"} variant={"h2"}>
                        !
                      </Typography>
                    )}
                  </Grid> */}
                </div>
                {isExpanded && (
                  <ClientExpandedTile
                    chargePercentages={props.chargePercentages}
                    fromChat={props.fromChat}
                    jrDetails={props.jrDetails}
                    fetchData={fetchData}
                    fetchJrData={props.fetchJrData}
                    events={events}
                    jrImages={jrImages}
                    setShowSubmitForm={props.setShowSubmitForm}
                    jrData={props.jrData}
                    openJR={openJR}
                    setAgreementOpen={props.setAgreementOpen}
                    paymentMethod={paymentMethod}
                    availabilities={availibilities}
                    scopeDetails={scopeDetails}
                    bidList={bidList}
                    vendorInvoiceDetails={vendorInvoiceDetails}
                    troubleshootingList={troubleshootingList}
                    troubleshootingImages={troubleshootingImages}
                    actionList={actionList}
                    actionImages={actionImages}
                    reworkDetails={reworkDetails}
                    activityList={activityList}
                    invoice={invoice}
                    clientReview={clientReview}
                    clientInvoiceDetails={clientInvoiceDetails}
                  />
                )}
              </Grid>
            )}
          </>
        </>
      )}
      <Modal
        title={"Availability"}
        destroyOnClose={true}
        width="650px"
        open={cancelJobModal}
        onCancel={() => setCancelJobModal(false)}
        footer={false}
      >
        <Typography variant="h5">
          Do you wish to cancel Job #{props.jrData.job_id}
        </Typography>
        <Grid width={"100%"} flexDirection={"row"}>
          <Stack width={"100%"}>
            <Button
              size="sm"
              type="button"
              variant="contained"
              color="primary"
              onClick={() => {
                unActivateJob(props.jrData.job_id);
                setCancelJobModal(false);
              }}
              style={{
                justifySelf: "flex-end",
                alignSelf: "flex-end",
              }}
            >
              Cancel Job
            </Button>
          </Stack>
        </Grid>
      </Modal>
      <Modal
        title={"Availability"}
        destroyOnClose={true}
        width="650px"
        open={showAvailability}
        onCancel={openAvailability}
        footer={false}
      >
        <ClientAvailability
          availability={availibilities}
          jrData={props.jrData}
          fetchJrData={props.fetchJrData}
          openAvailability={openAvailability}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        title="Edit Job Description"
        width="700px"
        open={editJobModal}
        onCancel={() => setEditJobModal(false)}
        footer={false}
      >
        {editJobModal && (
          <JobRequest
            paymentMethod={true}
            displayRequestForm={editJobModal}
            onClose={() => setEditJobModal(false)}
            displaySubmitAlert={displaySubmitAlert}
            editForm={true}
            jrData={props.jrData}
            fetchJrData={props.fetchJrData}
            jrImages={jrImages}
            fetchData={fetchData}
            setJrImages={setJrImages}
          />
        )}
      </Modal>
      {submitAlert && (
        <Alert
          style={{
            zIndex: 10000,
            position: "absolute",
            top: "2rem",
            left: "calc(50vw - 8rem)",
            width: "16rem",
          }}
          severity="success"
        >
          Your job request has been updated.
        </Alert>
      )}
    </>
  );
}

ClientTile.defaultProps = {
  setOpenBid: false,
};
