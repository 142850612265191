import MainCard from "../../../../Layout/components/MainCard";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { ClientTile } from "./ClientTile";

import cstyles from "./ClientJR.module.css";
import { Alert, Button, Stack, useMediaQuery } from "@mui/material";
import JobRequest from "../JobRequest/JobRequest";
import LoadScreen from "../../../../Components/LoadScreen";
import { useJRContext } from "../../../../Context/JobRequestContext";
import useOnce from "../../../../hooks/useOnce";
import Agreement from "../../Agreement";
import { Modal } from "antd";
import ReferralModal from "../../Referrral/ReferralModal";

const ClientJR = (props) => {
  const [paymentMethod, setPaymentMethod] = useState(props.paymentMethod);
  const [openBid, setOpenBid] = useState(false);
  const [JRs, setJRs] = useState(null);
  const [currentJRs, setCurrentJRs] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [displayRequestForm, setDisplayRequestForm] = useState(false);
  const [referralModal, setReferrralmodal] = useState(false);
  const { openJR } = useJRContext();
  const [agreementOpen, setAgreementOpen] = useState(false);
  const [chargePercentages, setChargePercentages] = useState({
    clientPercentage: 0,
    vendorPercentage: 0,
  });

  const [submitAlert, setSubmitAlert] = useState(false);
  const [referAlert, setReferAlert] = useState(false);

  const displaySubmitAlert = () => {
    getJRData();
    setSubmitAlert(true);
    setTimeout(() => setSubmitAlert(false), 4000);
  };
  const displayReferSuccess = () => {
    getJRData();
    setReferAlert(true);
    setTimeout(() => setReferAlert(false), 4000);
  };

  const getNotifications = useCallback(() => {
    axios.get("/notifications/getJobs").then(({ data }) => {
      setNotifications(data.notifications);
    });
  }, []);

  useEffect(() => {
    getNotifications();
    const notificationTimer = setInterval(getNotifications, 60000);
    return clearInterval(notificationTimer);
  }, [getNotifications]);

  const fetchPaymentMethod = () => {
    axios.get("/stripe/getPreferredPaymentMethod").then((res) => {
      if (res.data.paymentMethod !== null && res.data.ok) {
        setPaymentMethod(true);
      } else {
        setPaymentMethod(false);
      }
    });
  };

  const fetchChargeData = useCallback(() => {
    axios.get("/finance/getChargePercentages").then((res) => {
      setChargePercentages(res.data);
    });
  }, []);

  const getJRData = useCallback(() => {
    axios.get("/jobRequest/getClientJRs").then((res) => {
      console.log(res.data);
      setJRs(res.data);
    });
  }, []);

  useOnce(() => {
    fetchChargeData();
    getJRData();
    fetchPaymentMethod();
  });

  useEffect(() => {
    setCurrentJRs(JRs);
  }, [JRs, openJR]);

  const mobileView = useMediaQuery("(max-width: 600px)");

  // const [footer, setFooter] = useState("");

  return JRs !== null ? (
    <>
      <MainCard title=" Your Job Requests" style={{ backgroundColor: "#fff" }}>
        {currentJRs?.length > 0 ? (
          <>
            {openJR === null && (
              <>
                <Button
                  onClick={() => setDisplayRequestForm(true)}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: mobileView ? 80 : 50,
                    marginBottom: "1rem",
                    width: mobileView ? "30%" : "10%",
                  }}
                >
                  Create Request
                </Button>
                <Button
                  onClick={() => setReferrralmodal(true)}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: mobileView ? 20 : 20,
                    marginBottom: "1rem",
                    width: mobileView ? "30%" : "10%",
                  }}
                >
                  Refer friend
                </Button>
              </>
            )}
            {currentJRs.map((jrData, i) => {
              return (
                <span key={`${jrData.job_id}`} className={cstyles.hover}>
                  <ClientTile
                    chargePercentages={chargePercentages}
                    fetchJrData={getJRData}
                    jrData={jrData}
                    notification={notifications.includes(jrData.job_id)}
                    setOpenBid={setOpenBid}
                    setAgreementOpen={setAgreementOpen}
                  />
                </span>
              );
            })}
          </>
        ) : (
          <Stack alignItems={"center"} width={"100%"}>
            <Button
              onClick={() => setDisplayRequestForm(true)}
              size="large"
              style={{
                width: "35vw",
                height: "fit-content",
                fontSize: "2rem",
              }}
              type="submit"
              variant="contained"
              color="primary"
            >
              Create your First Request!
            </Button>
          </Stack>
        )}
      </MainCard>
      {displayRequestForm && (
        <JobRequest
          paymentMethod={paymentMethod}
          displayRequestForm={displayRequestForm}
          onClose={() => setDisplayRequestForm(false)}
          displaySubmitAlert={displaySubmitAlert}
        />
      )}
      {openBid && (
        <MainCard
          height="fit-content"
          padding="0!important"
          style={{ backgroundColor: "#fff", padding: "0" }}
          className={cstyles.chatBox}
        >
          <div
            style={{
              height: "calc(100vh - 120px)",
              margin: "-20px",
              marginbottom: "-5px",
              marginTop: "-12px",
            }}
          >
            {currentJRs.map((jrData, i) => {
              return <span key={`${jrData.job_id}`} className="hover"></span>;
            })}
          </div>
        </MainCard>
      )}

      {submitAlert && (
        <Alert
          style={{
            zIndex: 10000,
            position: "absolute",
            top: "2rem",
            left: "calc(50vw - 8rem)",
            width: "16rem",
          }}
          severity="success"
        >
          Your job request has been submitted
        </Alert>
      )}
      {referAlert && (
        <Alert
          style={{
            zIndex: 10000,
            position: "absolute",
            top: "2rem",
            left: "calc(50vw - 8rem)",
            width: "16rem",
          }}
          severity="success"
        >
          Link has been sent. Thank you for referring.
        </Alert>
      )}
      <Modal
        destroyOnClose={true}
        width="1000px"
        title="Service Agreement"
        open={agreementOpen}
        onCancel={() => setAgreementOpen(false)}
        footer={false}
      >
        {console.log(openJR, "openJRRRRRRRRRRRRRRRRRRR")}
        {agreementOpen && (
          <Agreement
            hasAgreed={1}
            vendorLegalName={openJR.vendor_legal_name}
            clientName={openJR.clientName}
            workDescription={openJR.job_description}
            provinceName={openJR.province_name}
            jobId={openJR.job_id}
            closeAgreement={() => setAgreementOpen(false)}
          />
        )}
      </Modal>
      <Modal
        destroyOnClose={true}
        width="400px"
        title="Refer a friend"
        open={referralModal}
        onCancel={() => setReferrralmodal(false)}
        footer={false}
      >
        {referralModal && (
          <ReferralModal
            onCancel={() => setReferrralmodal(false)}
            displayReferSuccess={displayReferSuccess}
          />
        )}
      </Modal>
    </>
  ) : (
    <LoadScreen />
  );
};

export default ClientJR;
