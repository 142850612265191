import Link from "@mui/material/Link";
import { useUserState } from "../../../Context/UserContext";

const Logo = () => {
  const { user } = useUserState();
  // console.log(user);
  return (
    <Link
      href={
        user.vendorMode
          ? "/dashboard/vendorMode/bidding"
          : "/dashboard/clientjobrequest"
      }
    >
      <img
        src={require("../../../icons/FixDenLogo.png")}
        alt="FixDen"
        width="70"
      />
    </Link>
  );
};

export default Logo;
