import { useState, useEffect } from "react";
import axios from "axios";

const GetServices = () => {
  const [serviceList, setServiceList] = useState([]);
  useEffect(() => {
    axios.post("/services").then((res) => {
      setServiceList(res.data.services);
    });
  }, []);
  return serviceList;
};

export default GetServices;
