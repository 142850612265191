import PhoneInput from "../commonComponents/FormComponents/PhoneInput";

import axios from "axios";
import { message } from "antd";
import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Select,
  MenuItem,
  Typography,
  FormHelperText
} from "@mui/material";

import LoadingSpinner from "../../Layout/components/LoadingSpinner";

import { Formik } from "formik";
import * as Yup from "yup";

import AnimateButton from "../../Layout/components/@extended/AnimateButton";
import GetServices from "../commonComponents/API/GetServices";
import { useUserState } from "../../Context/UserContext";
import { useState } from "react";

function convertStringToList(inputString) {
  // Check if the input is a non-empty string
  if (typeof inputString !== "string" || inputString.trim() === "") {
    return [];
  }

  // Use the split() method to convert the string into an array
  // Trim any leading or trailing spaces from each value
  const valuesList = inputString.split(",").map((value) => value.trim());

  return valuesList;
}

const EditVendorProfile = (props) => {
  const [locationError, setLocationError] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const { user } = useUserState();
  const services = GetServices();

  const isAdmin = props.user.is_admin === 1;

  const vendorDetails = props.user.vendorDetails;
  const vendorServices = convertStringToList(vendorDetails.vendor_services);
  // console.log(vendorServices);

  const [institutionCode, setInstitutionCode] = useState(
    vendorDetails.institution_code
  );
  const [transitCode, setTransitCode] = useState(
    vendorDetails.bank_transit_code
  );
  const [accountNumber, setAccountNumber] = useState(
    vendorDetails.bank_account_number
  );
  console.log(vendorDetails);
  console.log("vendorProfile props", props);
  console.log(user, "USERJBHISHIHIHIHI");
  console.log(props.vendorDetails);

  const onSubmit = (values) => {
    setSubmitting(true);

    const updateObject = {
      BusinessNumber: values.businessNumber,
      HST: values.hstNumber,

      UserId: props.user.user_id,
      Email: props.vendorDetails.vendorDetails.company_email,
      vendorLegalName: values.vendorLegalName,
      vendorDbaName: values.vendorDbaName,
      vendorServices: `${values.vendorServices}`,
      userType: "vendor",
      EmpRole: user.empRole
    };
    axios
      .post("/updateCompany", updateObject)
      .then((res) => {
        if (res.data.ok) {
          props.setEditVendorModal(false);
          props.setShowSuccessModal(true); // Show the success modal

          setTimeout(() => {
            props.setShowSuccessModal(false); // Close the modal after 5 seconds
          }, 5000);
        }
      })
      .catch((err) => {
        console.error(err.message);
      })
      .finally(() => {
        setSubmitting(false);
        props.fetchVendorData();
      });
  };

  const initialValues = {
    phone: vendorDetails.phone_number,
    businessNumber: vendorDetails.business_number,
    hstNumber: vendorDetails.hst_registration_number,
    vendorLegalName: vendorDetails.vendor_legal_name,
    vendorDbaName: vendorDetails.vendor_dba_name,
    vendorServices: vendorServices
  };
  const validationSchema = {
    phone: Yup.string()
      .min(15, "phoneNumber is required")
      .max(20)
      .required("phoneNumber is required"),
    businessNumber: Yup.string()
      .max(255)
      .required("Business number is missing"),

    hstNumber: Yup.string()
      .max(255)
      .required("Hst registraion number is missing"),

    vendorLegalName: Yup.string()
      .max(255)
      .required("Vendor Legal Name is required"),
    vendorDbaName: Yup.string()
      .max(255)
      .required("Vendor dba Name is required"),
    vendorServices: Yup.string()
      .max(255)
      .required("Vendor Services is required")
  };

  return (
    <LoadingSpinner isActive={submitting}>
      <div id="update-user-form">
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape(validationSchema)}
          onSubmit={(e) => {
            console.log("submit 2");
            onSubmit(e);
          }}
        >
          {({
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            values
          }) => (
            <form noValidate action="#update-user-form" onSubmit={handleSubmit}>
              {console.log(errors, values, handleSubmit)}
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>Phone</InputLabel>
                    <PhoneInput
                      name="phone"
                      value={values.phone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.phone && errors.phone && (
                      <Typography noWrap variant="h8" color="#ff0000">
                        *{errors.phone}
                      </Typography>
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>Vendor Legal Name</InputLabel>
                    <OutlinedInput
                      id="vendorLegalName"
                      type="vendorLegalName"
                      value={values.vendorLegalName}
                      name="vendorLegalName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Vendor Legal Name"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>Vendor dba Name</InputLabel>
                    <OutlinedInput
                      id="vendorDbaName-signup"
                      type="vendorDbaName"
                      value={values.vendorDbaName}
                      name="vendorDbaName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Vendor dba Name"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <Stack>
                      <InputLabel>Provided Service</InputLabel>
                      <Select
                        style={{ marginTop: "0.5rem" }}
                        required
                        id="vendorServices"
                        name="vendorServices"
                        value={values.vendorServices}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiple
                      >
                        {services.map((service) => (
                          <MenuItem
                            value={service.service_name}
                            key={service.service_id}
                          >
                            {service.service_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>Business #</InputLabel>
                    <OutlinedInput
                      id="city-signup"
                      type="businessNumber"
                      value={values.businessNumber}
                      name="businessNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="business number"
                    />
                    {touched.businessNumber && errors.businessNumber && (
                      <FormHelperText error id="helper-text-city-signup">
                        {errors.businessNumber}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>HST Registration #</InputLabel>
                    <OutlinedInput
                      id="city-signup"
                      type="hst"
                      value={values.hstNumber}
                      name="hstNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="hst"
                    />
                    {touched.hstNumber && errors.hstNumber && (
                      <FormHelperText error id="helper-text-city-signup">
                        {errors.hstNumber}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>

                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}
                {locationError && (
                  <FormHelperText error id="helper-text-location-error">
                    Location not found check spelling
                  </FormHelperText>
                )}

                <Grid item xs={12}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      size="sm"
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ ml: 2, float: "right" }}
                    >
                      Update
                    </Button>
                    <Button
                      disableElevation
                      size="sm"
                      variant="outlined"
                      color="secondary"
                      onClick={() => props.setEditVendorModal(false)}
                      sx={{ ml: 2, float: "right" }}
                    >
                      Cancel
                    </Button>
                  </AnimateButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </LoadingSpinner>
  );
};

export default EditVendorProfile;
