import React from "react";

import { Tooltip, Image } from "antd";
import {
  FileTwoTone,
  FileTextTwoTone,
  FilePdfTwoTone,
  FileExcelTwoTone
} from "@ant-design/icons";

class AttachmentDisplay extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let renderData;
    switch (this.props.file.type.split("/")[0]) {
      case "image":
        renderData = (
          <img
            style={{ display: "block", height: "75px" }}
            src={this.props.file.url}
          />
        );
        break;
      case "text":
        renderData = (
          <FileTextTwoTone
            style={{
              position: "relative",
              top: "10px",
              display: "block",
              fontSize: "50px"
            }}
          />
        );
        break;
      default:
        switch (this.props.file.type.split("/")[1]) {
          case "pdf":
            renderData = (
              <FilePdfTwoTone
                twoToneColor="#fe4d97"
                style={{
                  position: "relative",
                  top: "10px",
                  display: "block",
                  fontSize: "50px"
                }}
              />
            );
            break;
          case "vnd.ms-excel":
            renderData = (
              <FileTextTwoTone
                twoToneColor="#6dd230"
                style={{
                  position: "relative",
                  top: "10px",
                  display: "block",
                  fontSize: "50px"
                }}
              />
            );
            break;
          case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            renderData = (
              <FileExcelTwoTone
                twoToneColor="#6dd230"
                style={{
                  position: "relative",
                  top: "10px",
                  display: "block",
                  fontSize: "50px"
                }}
              />
            );
            break;
          default:
            renderData = (
              <FileTwoTone
                style={{
                  position: "relative",
                  top: "10px",
                  display: "block",
                  fontSize: "50px"
                }}
              />
            );
            break;
        }
        break;
    }
    console.log(this.props.file);
    return (
      <Tooltip title={this.props.file.file_name}>
        <div
          onClick={() => {
            this.props.viewAttachment(this.props.file);
          }}
          style={{
            float: this.props.side,
            display: "inline",
            width: "75px",
            height: "75px",
            border: "1px solid grey",
            borderRadius: "5px",
            overflow: "hidden",
            backgroundColor: "white"
          }}
        >
          <div style={{ zIndex: 0, bottom: "22px" }}>{renderData}</div>
        </div>
      </Tooltip>
    );
  }
}

export default AttachmentDisplay;
