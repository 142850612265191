import { useMediaQuery, Button } from "@mui/material";
import "./propertyTable.css";
import { MinusOutlined, UserOutlined } from "@ant-design/icons";

const DelegateTable = (props) => {
  const mobileView = useMediaQuery("(max-width: 600px)");

  const getTableRows = (delegateProperty) => {
    let rowList = [];

    !mobileView
      ? rowList.push(
          <tr
            className="onHover"
            key={`delegateProperty${delegateProperty.property_id}`}
          >
            <td style={{ margin: "0", padding: "1rem" }}>
              {delegateProperty.delegated_property}
            </td>
            <td style={{ margin: "0", padding: "1rem" }}>
              {delegateProperty.property_type}
            </td>
            <td style={{ margin: "0", padding: "1rem" }}>
              {delegateProperty.total_units} Units
            </td>
          </tr>
        )
      : rowList.push(
          <tr
            className="onHover"
            key={`delegateProperty${delegateProperty.property_id}`}
          >
            <td style={{ margin: "0", padding: "1rem" }}>
              {delegateProperty.delegated_property}
            </td>
            <td style={{ margin: "0", padding: "1rem" }}>
              {delegateProperty.property_type}
            </td>
            <td style={{ margin: "0", padding: "1rem" }}>
              {delegateProperty.total_units} Unit(s)
            </td>
          </tr>
        );

    return rowList;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%"
        }}
      >
        <table className="propertyTable">
          <tbody className="propertyTbody">
            {getTableRows(props.delegateProperty)}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DelegateTable;
