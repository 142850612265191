import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Result } from "antd";
import axios from "axios";

import { Button, FormHelperText } from "@mui/material";

import AuthWrapper from "../../Authentication/auth-pages/AuthWrapper";

const AcceptDelegation = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  let id = window.location.href.split("/")[4];
  let token = window.location.href.split("/")[5];

  const onSubmit = () => {
    axios
      .post("/auth/acceptDelegation/" + id + "/" + token)
      .then((response) => {
        if (response.data.ok) {
          navigate("/delegationAccepted");
        } else setErrorMessage(response.data.error);
      })
      .catch((error) => {
        // Handle errors, including the "Token has already been used" message
        // console.error("Error:", error.response.data);
        // console.log("Response:", error.response);
        if (error.response.data === "Token has already been used") {
          // Display an error message to the user

          setErrorMessage("You already accepted this request");
        }
        if (error.response.data === "Invalid or expired token") {
          // Display an error message to the user

          setErrorMessage("Invalid or expired token");
        }
      });
  };

  return (
    <AuthWrapper>
      <Result
        className="flex-60 padding0"
        status="success"
        title={<h4>Please click Accept button to accept delegations.</h4>}
        extra={[
          <Button variant="contained" type="primary" onClick={onSubmit}>
            Accept
          </Button>
        ]}
      />
      <FormHelperText error id="helper-text-errorMessage-signup">
        {errorMessage}
      </FormHelperText>
    </AuthWrapper>
  );
};

export default AcceptDelegation;
