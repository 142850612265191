import styles from "./CSS/util.module.css";
import moment from "moment-timezone";
moment.tz.setDefault("Canada/Newfoundland");

export const jrCatagories = [
  "All",
  "Access & Security",
  "Appliance Repair",
  "Brick Layer",
  "Building Supplies",
  "Cabling & Data",
  "Carpenter",
  "Electrical",
  "Environmental",
  "Fire Protection",
  "Flooring",
  "Gas Fitter",
  "General Repair",
  "Glass Wholesale",
  "Heating",
  "Home Inspector",
  "HVAC",
  "Landscaping",
  "Locksmith",
  "Miscellaneous Supply",
  "Oil Burner Mechanic",
  "Pest Control",
  "Plaster & Paint Services",
  "Plumbing",
  "Propane Services",
  "Property Management",
  "Property Restoration",
  "Refrigeration & AC",
  "Roofing",
  "Sheet Metal Worker",
  "Snow Clearing",
  "Tile Setter",
  "Ventilation",
  "Waste Management",
  "Windows & Doors",
  "Other",
];

export const useQuery = () => {
  const result = {};
  new URLSearchParams(window.location.search).forEach((value, key) => {
    result[key] = value;
  });

  return result;
};

export const getMonth = (monthInt) => {
  let month;
  switch (monthInt) {
    case 0:
      month = "January";
      break;
    case 1:
      month = "February";
      break;
    case 2:
      month = "March";
      break;
    case 3:
      month = "April.";
      break;
    case 4:
      month = "May";
      break;
    case 5:
      month = "June";
      break;
    case 6:
      month = "July";
      break;
    case 7:
      month = "August";
      break;
    case 8:
      month = "September";
      break;
    case 9:
      month = "October";
      break;
    case 10:
      month = "November";
      break;
    case 11:
      month = "December";
      break;
    default:
      console.error("Error reading month default to january");
      month = "january";
  }
  return month;
};

export const JRSkeleton = (id) => {
  return (
    <div className={styles.jrSkeleton} key={id}>
      <div>
        <div className={styles.imgSkeleton}></div>
        <div className={styles.pSkeleton}></div>
      </div>
      <div>
        <div className={styles.pSkeleton}></div>
        <div className={styles.pSkeleton}></div>
        <div className={styles.pSkeleton}></div>
      </div>
      <div>
        <div className={styles.pSkeleton}></div>
        <div className={styles.pSkeleton}></div>
        <div className={styles.pSkeleton}></div>
      </div>
    </div>
  );
};

export const formatDate = (d) => `${moment(d).format("MMM Do YYYY, hh:mm a")}`;

const dateOffset = new Date().getTimezoneOffset();
export const localDate = (isoString) => {
  const date = new Date(isoString);
  date.setMinutes(date.getMinutes() - dateOffset);
  return date;
};

export const localToUTCDate = (isoString) => {
  const date = new Date(isoString);
  date.setMinutes(date.getMinutes() + dateOffset);
  return date;
};
