import { Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import MainCard from "../../Layout/components/MainCard";
import "../commonComponents/CSS/styles.css";
import FinanceTable from "./FinanceComponents/FinanceTable";
import FullTransaction from "./FinanceComponents/FullTransaction";
import TopBar from "./FinanceComponents/TopBar";
import TotalsDisplay from "./FinanceComponents/TotalsDisplay";
import axios from "axios";
import { useUserState } from "../../Context/UserContext";
import styles from "./index.module.css";
import { localDate } from "../commonComponents/util";

const FinancePage = () => {
  const { user } = useUserState();
  const currentDate = new Date();
  const [startDate, setStartDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0,
      23,
      59,
      59
    )
  );
  const [search, setSearch] = useState("");
  const [jrCount, setJRCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [openTransaction, setOpenTransaction] = useState(-1);
  const [transactionData, setTransactionData] = useState([]);
  const [transactionList, setTransactionList] = useState([]);

  const fetchFinanceDetails = useCallback(() => {
    axios.post("/finance/getFinanceDetails", { user: user }).then((res) => {
      console.log(res.data);
      const transactionDetails = res.data.financeDetails.map((details) => {
        details.date_finished = localDate(details.date_finished);
        details.location = [48.9429391, -57.9491593];
        return details;
      });

      setTransactionData(transactionDetails);
      setTransactionList(transactionDetails);
      console.log(transactionDetails, "detailsssssssssssssssssss");
    });
  }, []);

  useEffect(() => {
    fetchFinanceDetails();
  }, [fetchFinanceDetails]);

  useEffect(() => {
    const searchTransactions = () => {
      let rowList = transactionData;
      let total = 0;

      const searchTerms = search.toLocaleLowerCase().split(" ");

      rowList = rowList.filter((transaction) => {
        const date = new Date(transaction.date_finished);
        if (!(startDate <= date && endDate >= date)) return false;

        const transactionString = `${transaction.city} ${
          !user.vendorMode ? transaction.vendor_name : transaction.client_name
        } ${transaction.city} ${transaction.street} ${
          transaction.job_title
        }`.toLocaleLowerCase();

        if (search.trim() !== "") {
          for (let searchTerm of searchTerms) {
            if (!transactionString.includes(searchTerm)) {
              return false;
            }
          }
        }
        total += parseFloat(transaction.total_amount);
        return true;
      });

      setJRCount(rowList.length);
      setTotal(total);
      return rowList;
    };
    // console.log(
    //   searchTransactions(transactionData),
    //   "SEARCHTRANSACTIONSSSSSSSSSSSSSSSSSSS"
    // );
    setTransactionList(searchTransactions(transactionData));
  }, [search, startDate, endDate, transactionData, user.vendorMode]);

  return (
    <>
      <MainCard
        title="Finances"
        className={styles.mainCard}
        style={{ backgroundColor: "#fff" }}
      >
        <TopBar
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setSearch={setSearch}
        />
        <FinanceTable
          startDate={startDate}
          endDate={endDate}
          search={search}
          transactionData={transactionList}
          setJRCount={setJRCount}
          setTotal={setTotal}
          setOpenTransaction={setOpenTransaction}
        />
        <TotalsDisplay
          time={Math.floor((endDate - startDate) / (24 * 3600 * 1000)) + 1}
          jrCount={jrCount}
          total={total}
        />
        {console.log(transactionList[openTransaction])}
      </MainCard>
      <Modal
        destroyOnClose={true}
        width="700px"
        title={`Full Transaction Job ID: ${transactionList[openTransaction]?.job_id}`}
        open={openTransaction !== -1}
        onCancel={() => {
          setOpenTransaction(-1);
        }}
        footer={false}
      >
        {openTransaction !== -1 && (
          <FullTransaction
            tr={transactionList[openTransaction]}
            onClose={() => {
              setOpenTransaction(-1);
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default FinancePage;
