import { Grid, Stack, Button } from "@mui/material";
import { Modal } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import StarRating from "./StarRating";

const roundHalf = (num) => {
  return Math.round(num * 2) / 2;
};

function ClientReview(props) {
  const [comments, setComments] = useState();
  const [timelinessRating, setTimelinessRating] = useState();
  const [qualityRating, setQuantityRating] = useState();
  const [valueRating, setValueRating] = useState();
  const [cleanlinessRating, setCleanlinessRating] = useState();
  const [communicationRating, setCommunicationRating] = useState();
  const [clientReview, setClientReview] = useState(props.clientReview);
  const [vendorName, setVendorName] = useState(props.vendorName);

  const closeReview = () => {
    props.setShowReview(false);
  };

  const onChangeTimeliness = (e) => {
    setTimelinessRating(e.target.value);
  };
  const onChangeQuality = (e) => {
    setQuantityRating(e.target.value);
  };
  const onChangeValue = (e) => {
    setValueRating(e.target.value);
  };
  const onChangeCleanliness = (e) => {
    setCleanlinessRating(e.target.value);
  };
  const onChangeCommunication = (e) => {
    setCommunicationRating(e.target.value);
  };

  useEffect(() => {
    setClientReview(props.clientReview);
    setVendorName(props.vendorName);
  }, [props.clientReview, props.vendorName]);

  const onSubmitRating = (e) => {
    let percent =
      (parseFloat(communicationRating) +
        parseFloat(cleanlinessRating) +
        parseFloat(valueRating) +
        parseFloat(qualityRating) +
        parseFloat(timelinessRating)) /
      25;

    let overallScore = 5 * percent;

    let reviewObject = {
      jobId: props.jrData.job_id,
      communication: communicationRating,
      cleanliness: cleanlinessRating,
      value: valueRating,
      quality: qualityRating,
      timeliness: timelinessRating,
      aggregate: overallScore,
      comments: comments,
      activityDesc: "Review is given to Vendor.",
      userName: props.userName
    };

    axios.post("/review/newClientReview", reviewObject).then((res) => {
      props.fetchData();
      props.fetchJrData();
      closeReview();
    });
  };

  return (
    <div className="phase">
      <Modal
        destroyOnClose={true}
        width="400px"
        title="Leave Review"
        open={props.showReview}
        onCancel={() => props.setShowReview(false)}
        footer={false}
      >
        {props.showReview && (
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
              <h5>Please take the time to review your vendor:</h5>

              <label htmlFor="comments">Comments</label>
              <input
                onChange={(e) => setComments(e.target.value)}
                className="comments"
                type="text"
                id="comments"
              ></input>

              <h4>Timliness:</h4>
              <div className="wrapper">
                <input
                  onChange={onChangeTimeliness}
                  value="5"
                  type="radio"
                  id="timeliness-1-star"
                  name="timelinessRating"
                />
                <label htmlFor="timeliness-1-star">★</label>
                <input
                  onChange={onChangeTimeliness}
                  value="4"
                  type="radio"
                  id="timeliness-2-star"
                  name="timelinessRating"
                />
                <label htmlFor="timeliness-2-star">★</label>
                <input
                  onChange={onChangeTimeliness}
                  value="3"
                  type="radio"
                  id="timeliness-3-star"
                  name="timelinessRating"
                />
                <label htmlFor="timeliness-3-star">★</label>
                <input
                  onChange={onChangeTimeliness}
                  value="2"
                  type="radio"
                  id="timeliness-4-star"
                  name="timelinessRating"
                />
                <label htmlFor="timeliness-4-star">★</label>
                <input
                  onChange={onChangeTimeliness}
                  value="1"
                  type="radio"
                  id="timeliness-5-star"
                  name="timelinessRating"
                />
                <label htmlFor="timeliness-5-star">★</label>
              </div>

              <h4>Quality:</h4>
              <div className="wrapper">
                <input
                  onChange={onChangeQuality}
                  value="5"
                  type="radio"
                  id="quality-1-star"
                  name="qualityRating"
                />
                <label htmlFor="quality-1-star">★</label>
                <input
                  onChange={onChangeQuality}
                  value="4"
                  type="radio"
                  id="quality-2-star"
                  name="qualityRating"
                />
                <label htmlFor="quality-2-star">★</label>
                <input
                  onChange={onChangeQuality}
                  value="3"
                  type="radio"
                  id="quality-3-star"
                  name="qualityRating"
                />
                <label htmlFor="quality-3-star">★</label>
                <input
                  onChange={onChangeQuality}
                  value="2"
                  type="radio"
                  id="quality-4-star"
                  name="qualityRating"
                />
                <label htmlFor="quality-4-star">★</label>
                <input
                  onChange={onChangeQuality}
                  value="1"
                  type="radio"
                  id="quality-5-star"
                  name="qualityRating"
                />
                <label htmlFor="quality-5-star">★</label>
              </div>

              <h4>Value:</h4>
              <div className="wrapper">
                <input
                  onChange={onChangeValue}
                  value="5"
                  type="radio"
                  id="value-1-star"
                  name="valueRating"
                />
                <label htmlFor="value-1-star">★</label>
                <input
                  onChange={onChangeValue}
                  value="4"
                  type="radio"
                  id="value-2-star"
                  name="valueRating"
                />
                <label htmlFor="value-2-star">★</label>
                <input
                  onChange={onChangeValue}
                  value="3"
                  type="radio"
                  id="value-3-star"
                  name="valueRating"
                />
                <label htmlFor="value-3-star">★</label>
                <input
                  onChange={onChangeValue}
                  value="2"
                  type="radio"
                  id="value-4-star"
                  name="valueRating"
                />
                <label htmlFor="value-4-star">★</label>
                <input
                  onChange={onChangeValue}
                  value="1"
                  type="radio"
                  id="value-5-star"
                  name="valueRating"
                />
                <label htmlFor="value-5-star">★</label>
              </div>

              <h4>Cleanliness:</h4>
              <div className="wrapper">
                <input
                  onChange={onChangeCleanliness}
                  value="5"
                  type="radio"
                  id="cleanliness-1-star"
                  name="cleanlinessRating"
                />
                <label htmlFor="cleanliness-1-star">★</label>
                <input
                  onChange={onChangeCleanliness}
                  value="4"
                  type="radio"
                  id="cleanliness-2-star"
                  name="cleanlinessRating"
                />
                <label htmlFor="cleanliness-2-star">★</label>
                <input
                  onChange={onChangeCleanliness}
                  value="3"
                  type="radio"
                  id="cleanliness-3-star"
                  name="cleanlinessRating"
                />
                <label htmlFor="cleanliness-3-star">★</label>
                <input
                  onChange={onChangeCleanliness}
                  value="2"
                  type="radio"
                  id="cleanliness-4-star"
                  name="cleanlinessRating"
                />
                <label htmlFor="cleanliness-4-star">★</label>
                <input
                  onChange={onChangeCleanliness}
                  value="1"
                  type="radio"
                  id="cleanliness-5-star"
                  name="cleanlinessRating"
                />
                <label htmlFor="cleanliness-5-star">★</label>
              </div>

              <h4>Communication:</h4>
              <div className="wrapper">
                <input
                  onChange={onChangeCommunication}
                  value="5"
                  type="radio"
                  id="communication-1-star"
                  name="commmunicationRating"
                />
                <label htmlFor="communication-1-star">★</label>
                <input
                  onChange={onChangeCommunication}
                  value="4"
                  type="radio"
                  id="communication-2-star"
                  name="commmunicationRating"
                />
                <label htmlFor="communication-2-star">★</label>
                <input
                  onChange={onChangeCommunication}
                  value="3"
                  type="radio"
                  id="communication-3-star"
                  name="commmunicationRating"
                />
                <label htmlFor="communication-3-star">★</label>
                <input
                  onChange={onChangeCommunication}
                  value="2"
                  type="radio"
                  id="communication-4-star"
                  name="commmunicationRating"
                />
                <label htmlFor="communication-4-star">★</label>
                <input
                  onChange={onChangeCommunication}
                  value="1"
                  type="radio"
                  id="communication-5-star"
                  name="commmunicationRating"
                />
                <label htmlFor="communication-5-star">★</label>
              </div>

              <div>
                <Button
                  size="small"
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onSubmitRating();
                    closeReview();
                  }}
                  sx={{ ml: 1, float: "right" }}
                >
                  Submit Rating
                </Button>
                <Button
                  size="small"
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    closeReview();
                  }}
                  sx={{ ml: 1, float: "right" }}
                >
                  Cancel
                </Button>
              </div>
            </Stack>
          </Grid>
        )}
      </Modal>
    </div>
  );
}

export default ClientReview;
