import { Button } from "@mui/material";
import { useUserState } from "../../../Context/UserContext";
import styles from "./TopBar.module.css";
import { memo } from "react";

const searchIcon = require("../../../images/search.png");

const TopBar = memo((props) => {
  const { user } = useUserState();
  const downLoadTable = () => {
    let csv = [
      `Date, ${
        !user.vendorMode ? "Vendor" : "client"
      }, property, Description, Job Request, Amount`,
    ];
    const tableRowElements = document.querySelectorAll("table>tbody>tr");
    for (let row of tableRowElements) {
      let csvRow = [];
      row.childNodes.forEach((pEl) => {
        csvRow.push(pEl.innerText);
      });
      for (let col in csvRow) {
        csvRow[col] = '"' + csvRow[col] + '"';
      }
      csv.push(csvRow);
    }
    csv = csv.join("\n");

    const fileName = `Transaction_Data_${props.startDate}-${props.endDate}`;
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute("download", fileName);
    link.href = URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
    link.click();
  };

  const fixDate = (date) => {
    date.setDate(date.getDate() + 1);
    return date;
  };

  return (
    <div className={styles.topBar}>
      <div className={styles.searchContainer}>
        <div className={styles.searchBar}>
          <input
            type="text"
            onChange={({ target }) => props.setSearch(target.value)}
          />
          <img src={searchIcon} alt="" className={styles.searchIcon} />
        </div>
      </div>
      <div className={styles.dateRangeContainer}>
        <div className={styles.dateRange}>
          <input
            type="date"
            value={`${props.startDate.getFullYear()}-${
              props.startDate.getMonth() < 9 ? "0" : ""
            }${props.startDate.getMonth() + 1}-${
              props.startDate.getDate() < 10 ? "0" : ""
            }${props.startDate.getDate()}`}
            onChange={({ target }) => {
              let date = fixDate(new Date(target.value));
              date.setMinutes(0);
              date.setHours(0);
              props.setStartDate(date);
            }}
          />
          <p>→</p>
          <input
            type="date"
            value={`${props.endDate.getFullYear()}-${
              props.endDate.getMonth() < 9 ? "0" : ""
            }${props.endDate.getMonth() + 1}-${
              props.endDate.getDate() < 10 ? "0" : ""
            }${props.endDate.getDate()}`}
            onChange={({ target }) => {
              let date = fixDate(new Date(target.value));
              date.setMinutes(59);
              date.setHours(23);
              props.setEndDate(date);
            }}
          />
        </div>
      </div>
      <div className={styles.btnContainer}>
        <Button
          size="sm"
          variant="contained"
          color="primary"
          onClick={downLoadTable}
        >
          Export
        </Button>
      </div>
    </div>
  );
});

export default TopBar;
