// assets
import {
  DashboardOutlined,
  MessageOutlined,
  DollarCircleOutlined,
  ToolOutlined,
  GroupOutlined,
  UserOutlined,
  TeamOutlined,
  HomeOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  DashboardOutlined,
  MessageOutlined,
  DollarCircleOutlined,
  ToolOutlined,
  GroupOutlined,
  UserOutlined,
  TeamOutlined,
  HomeOutlined,
};

// ==============================|| MENU ITEMS - ADMIN ||============================== //

const adminMenu = {
  id: "group-admin",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "home",
      title: "Home",
      type: "item",
      url: "/admin/home",
      icon: icons.GroupOutlined,
      breadcrumbs: false,
    },
    {
      id: "client",
      title: "Client",
      type: "item",
      url: "/admin/client",
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
    },
    {
      id: "vendor",
      title: "Vendor",
      type: "item",
      url: "/admin/vendor",
      icon: icons.MessageOutlined,
      breadcrumbs: false,
    },
    {
      id: "finance",
      title: "Finance",
      type: "item",
      url: "/admin/finance",
      icon: icons.DollarCircleOutlined,
      breadcrumbs: false,
    },
  ],
};

export default adminMenu;
