import moment from "moment";
import { useUserState } from "../../../Context/UserContext";
import styles from "./FinanceTable.module.css";
import { memo } from "react";
import { useMediaQuery } from "@mui/material";

const FinanceTable = memo((props) => {
  const { user } = useUserState();
  const getTableRows = (transactionData) => {
    let rowList = [];
    for (let i in transactionData) {
      rowList.push(
        <tr
          key={transactionData[i].invoice_id}
          className={styles.onHover}
          onClick={() => props.setOpenTransaction(i)}
        >
          <td
            className={styles.cell}
            style={{ margin: "0", padding: "0.5rem" }}
          >
            {moment(transactionData[i].date_finished).format("MMM Do YYYY")}
          </td>
          <td style={{ margin: "0", padding: "0.5rem" }}>
            {!user.vendorMode
              ? `${transactionData[i].vendor_name}`
              : `${transactionData[i].client_name}`}
          </td>
          <td
            style={{
              margin: "0",
              padding: "0.5rem"
            }}
          >{`${transactionData[i].street} ${transactionData[i].city}`}</td>
          <td style={{ margin: "0", padding: "0.5rem" }}>
            {transactionData[i].job_title}
          </td>
          <td style={{ margin: "0", padding: "0.5rem" }}>
            {transactionData[i].job_id}
          </td>
          <td style={{ margin: "0", padding: "0.5rem" }}>
            $
            {user.vendorMode
              ? transactionData[i].total_payout.toFixed(2)
              : transactionData[i].total_amount.toFixed(2)}
          </td>
        </tr>
      );
    }
    return rowList;
  };

  const mobileView = useMediaQuery("(max-width: 600px)");

  return (
    // <Grid container>
    //   <Stack direction={"row"}></Stack>
    // </Grid>
    <div className={styles.financeTable} style={{ overflowX: "scroll" }}>
      <table className={styles.table}>
        <thead className={styles.thead}>
          <tr>
            <th
              style={{
                width: "5rem",
                textAlign: mobileView ? "center" : "left",
                paddingLeft: !mobileView ? "1rem" : "0"
              }}
            >
              Date
            </th>
            <th
              style={{
                width: "5rem",
                textAlign: mobileView ? "center" : "left",
                paddingLeft: !mobileView ? "1rem" : "0"
              }}
            >
              {!user.vendorMode ? "Vendor" : "Client"}
            </th>
            <th
              style={{
                width: "6rem",
                textAlign: mobileView ? "center" : "left",
                paddingLeft: !mobileView ? "1rem" : "0"
              }}
            >
              Property
            </th>
            <th
              style={{
                width: "15rem",
                textAlign: mobileView ? "center" : "left",
                paddingLeft: !mobileView ? "1rem" : "0"
              }}
            >
              Description
            </th>
            <th
              style={{
                width: "5rem",
                textAlign: mobileView ? "center" : "left",
                paddingLeft: !mobileView ? "1rem" : "0"
              }}
            >
              Job Request
            </th>
            <th
              style={{
                width: "6rem",
                textAlign: mobileView ? "center" : "left",
                paddingLeft: !mobileView ? "1rem" : "0"
              }}
            >
              Amount
            </th>
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {getTableRows(props.transactionData)}
        </tbody>
      </table>
    </div>
  );
});

export default FinanceTable;
