import {
  TextField,
  Grid,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

function PaymentForm() {
  const cardsLogo = ["mastercard", "visa", "paypal", "amazon"];
  return (
    <div>
      <Grid container spacing={3}>
        <Grid container item xs={12}>
          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="h6">Accepting Card</Typography>
            </Stack>
          </Grid>
          <Grid container item xs={12} sm={9} justify="space-between">
            {cardsLogo.map((e) => (
              <img
                key={e}
                src={require(`./cards/${e}.png`)}
                alt={e}
                width="50px"
                align="bottom"
                style={{ padding: "0 5px" }}
              />
            ))}
          </Grid>
        </Grid>

        <Grid item xs={6} md={6}>
          <TextField
            required
            id="cardName"
            name="cardName"
            label="Name on card"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            label="Credit Card Number"
            name="cardNumber"
            variant="outlined"
            required
            fullWidth
          />
        </Grid>

        <Grid item xs={6} sm={6}>
          <TextField
            label="Expiration Date(MM/YY)"
            name="expiry"
            variant="outlined"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            label="CVC"
            name="cvc"
            variant="outlined"
            required
            fullWidth
            helperText="Last three digits on signature strip"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default PaymentForm;
