import { useState } from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import AnimateButton from "../../Layout/components/@extended/AnimateButton";
import {
  strengthColor,
  strengthIndicator
} from "../../Layout/utils/password-strength";
import { useUserState } from "../../Context/UserContext";

import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ChangePassword = (props) => {
  let navigate = useNavigate();
  const { user } = useUserState();
  const [level, setLevel] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [showReset, setShowReset] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  const onSubmit = async (values) => {
    const { newPassword, oldPassword } = values;

    try {
      await axios
        .post("/changePassword", {
          id: user.id,
          OldPassword: oldPassword,
          password: newPassword
        })
        .then((res) => {
          if (res.data.ok) navigate("/dashboard/profile");
          else if (res.data.error === "WrongPass")
            setErrorMessage(
              "Invalid old password. Please try with correct password."
            );
          else if (res.data.error === "SAMEPASS")
            setErrorMessage(
              "New password cannot be the same as the old password. Please try again."
            );
          else setErrorMessage(res.data.error);
        });
    } catch (err) {}
  };

  return (
    <>
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmPassword: ""
        }}
        validationSchema={Yup.object().shape({
          oldPassword: Yup.string().max(255).required("Password is required"),

          newPassword: Yup.string().max(255).required("Password is required"),
          confirmPassword: Yup.string()
            .max(255)
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={3}
              style={{
                width: 350,
                height: 400,
                marginLeft: 10,
                marginTop: 20,
                marginRight: 30
              }}
            >
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password-signup">
                    Old Password
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="oldpassword-signup"
                    type={showPassword ? "text" : "password"}
                    value={values.oldPassword}
                    name="oldPassword"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? (
                            <EyeOutlined />
                          ) : (
                            <EyeInvisibleOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="******"
                    inputProps={{}}
                  />
                  {touched.oldPassword && errors.oldPassword && (
                    <FormHelperText error id="helper-text-newPassword-signup">
                      {errors.oldPassword}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password-signup">
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="newPassword-signup"
                    type={showPassword ? "text" : "password"}
                    value={values.newPassword}
                    name="newPassword"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      changePassword(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? (
                            <EyeOutlined />
                          ) : (
                            <EyeInvisibleOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="******"
                    inputProps={{}}
                  />
                  {touched.newPassword && errors.newPassword && (
                    <FormHelperText error id="helper-text-newPassword-signup">
                      {errors.newPassword}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password-signup">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="confirmPassword-signup"
                    type={showPassword ? "text" : "password"}
                    value={values.confirmPassword}
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      changePassword(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? (
                            <EyeOutlined />
                          ) : (
                            <EyeInvisibleOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="******"
                    inputProps={{}}
                  />
                  {touched.confirmPassword && errors.confirmPassword && (
                    <FormHelperText
                      error
                      id="helper-text-confirmPassword-signup"
                    >
                      {errors.confirmPassword}
                    </FormHelperText>
                  )}
                </Stack>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Box
                        sx={{
                          bgcolor: level?.color,
                          width: 85,
                          height: 8,
                          borderRadius: "7px"
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" fontSize="0.75rem">
                        {level?.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={
                      values.newPassword === values.confirmPassword
                        ? showReset
                        : !showReset
                    }
                    size="small"
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ float: "right" }}
                  >
                    Change Password
                  </Button>
                  <Button
                    disableElevation
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{ float: "right", marginRight: 5 }}
                    onClick={() => navigate("/dashboard/profile")}
                  >
                    cancel
                  </Button>
                </AnimateButton>
                <FormHelperText error id="helper-text-firstname-signup">
                  {errorMessage}
                </FormHelperText>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ChangePassword;
