import { Link } from "react-router-dom";

import { Result } from "antd";

import { Button } from "@mui/material";

import AuthWrapper from "../../Authentication/auth-pages/AuthWrapper";

const DelegationAccepted = () => {
  return (
    <>
      <AuthWrapper>
        <Result
          className="flex-60 padding0"
          status="success"
          title={
            <h4>
              Property delegation accepted successfully. Please login to see the
              changes.{" "}
            </h4>
          }
          extra={[
            <Button variant="contained" type="primary">
              <Link to="/login">Login</Link>
            </Button>
          ]}
        />
      </AuthWrapper>
    </>
  );
};

export default DelegationAccepted;
