import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import "./scope.css";
import { Button, Modal } from "antd";
import moment from "moment-timezone";
import { useUserState } from "../../../../Context/UserContext";
import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";
import ScopeTable from "./ScopeTable";
moment.tz.setDefault("Canada/Newfoundland");

const ScopeDetails = (props) => {
  const [scopeDetailsModal, setScopeDetailsModal] = useState(false);
  const [index, setIndex] = useState(0);
  const { user } = useUserState();
  const [acceptScopeModal, setAcceptScopeModal] = useState(false);
  const [chargePercentages, setChargePercentages] = useState({
    clientPercentage: 0,
    vendorPercentage: 0,
  });

  const [currentScope, setCurrentScope] = useState(props.scopeDetails[index]);

  useEffect(() => {
    setCurrentScope(props.scopeDetails[index]);
  }, [
    chargePercentages.clientPercentage,
    chargePercentages.vendorPercentage,
    index,
    props.scopeDetails,
    user.vendorMode,
  ]);

  useEffect(() => {
    axios.get("/finance/getChargePercentages").then((response) => {
      setChargePercentages(response.data);
    });
  }, []);

  const acceptScope = () => {
    axios
      .post("/scope/acceptScopeRework", {
        scopeID: props.scopeDetails[index].scope_id,
        jobID: props.jrData.job_id,
      })
      .then((res) => {
        setAcceptScopeModal(false);
        props.setOpenScopeModal(false);
        props.fetchData();
      });
  };

  const declineScope = () => {
    axios
      .post("scope/declineScopeRework", {
        scopeID: props.scopeDetails[index].scope_id,
      })
      .then((res) => {
        props.setOpenScopeModal(false);
        props.fetchData();
      });
  };

  const mobileView = useMediaQuery("(max-width: 500px)");

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {props.scopeDetails?.map((item, i) => (
            <Stack
              key={i}
              className="scopeHover"
              onClick={() => {
                setScopeDetailsModal(true);
                setIndex(i);
              }}
              width={"100%"}
              direction={mobileView ? "column" : "row"}
              spacing={1}
              border={"1px solid #dddddd"}
              borderRadius={"5px"}
              boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}
              justifyContent={"center"}
              alignItems={"center"}
              padding={"0.25rem"}
            >
              <Typography>{item.work_to_be_done}</Typography>
              <Typography>
                ${parseFloat(item.scope_amount).toFixed(2)}
              </Typography>
              <Typography>{item.notes}</Typography>
              {item.is_accepted === 1 && (
                <Typography style={{ color: "green" }}>Current</Typography>
              )}
              {item.is_closed === 1 && (
                <Typography
                  style={{
                    color: "orange",
                  }}
                >
                  Previous
                </Typography>
              )}
              {item.is_proposed === 1 && (
                <Typography
                  style={{
                    color: "yellow",
                  }}
                >
                  Proposed
                </Typography>
              )}
              {item.rejected === 1 && (
                <Typography style={{ color: "red" }}>Rejected</Typography>
              )}
            </Stack>
          ))}
        </Grid>
        {props.scopeDetails[props.scopeDetails.length - 1].rejected === 1 &&
          user.vendorMode && (
            <Grid item md={12}>
              <Typography variant="h6">
                The last Scope change you made has been{" "}
                <em style={{ color: "red" }}>rejected</em>. Please chat with the
                client and agree on a price for a new scope of work, or the{" "}
                <em style={{ color: "green" }}>current</em> scope of work will
                be used to create the invoice.
              </Typography>
            </Grid>
          )}
      </Grid>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Accept Scope Rework"
        open={acceptScopeModal}
        onCancel={() => {
          setAcceptScopeModal(false);
        }}
        footer={false}
      >
        {acceptScopeModal && (
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                I accept the rework of the <em>Scope</em> from{" "}
                {props.vendorData.vendor_legal_name} for{" "}
                {parseFloat(props.scopeDetails[index].scope_amount).toFixed(2)}.
                I agree to pay the charge as per the{" "}
                <em>Terms and Conditions</em>
              </Typography>
              <Grid item xs={12}>
                <AnimateButton>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    sx={{ ml: 2, float: "right" }}
                    onClick={() => {
                      acceptScope();
                    }}
                  >
                    Accept
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Modal>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Scope Details"
        open={scopeDetailsModal}
        onCancel={() => {
          setScopeDetailsModal(false);
        }}
        footer={false}
      >
        {scopeDetailsModal && (
          <Grid container spacing={1}>
            <Grid item xs={12} textAlign={"center"}>
              <Typography variant="h6">
                Scope of Work <em>(Edition #{index + 1})</em>
              </Typography>
              <Typography variant="h6">
                {moment(new Date(props.scopeDetails[index].scope_date)).format(
                  "MMM Do YYYY"
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} marginTop="1em">
              <ScopeTable
                lines={currentScope.scopeLines}
                amount={currentScope.scope_amount}
                chargePercentages={chargePercentages}
              />
              <hr style={{ marginTop: "1em" }} />
            </Grid>
            <Grid item xs={12}>
              {!user.vendorMode && props.scopeDetails[index].is_proposed === 1 && (
                <AnimateButton>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    sx={{ ml: 2, float: "right" }}
                    onClick={() => {
                      setAcceptScopeModal(true);
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    sx={{ ml: 2, float: "right" }}
                    onClick={() => {
                      declineScope();
                    }}
                  >
                    Decline
                  </Button>
                </AnimateButton>
              )}
            </Grid>
          </Grid>
        )}
      </Modal>
    </>
  );
};

export default ScopeDetails;
