import React from "react";
import BarCalendar from "../../../../Components/BarCalendar";

const ViewAvailability = (props) => {
  return (
    <>
      <BarCalendar
        availabilityArray={props.jrData.client_availability}
        viewing={true}
      />
    </>
  );
};

export default ViewAvailability;
