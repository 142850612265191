import "./App.css";
import axios from "axios";
import ThemeRoutes from "./Routes/routes.js";
import ThemeCustomization from "./Layout/themes";
import ScrollTop from "./Layout/components/ScrollTop";
import { UserProvider } from "./Context/UserContext";
import LocationTracker from "./Components/LocationTracker";
import { JRProvider } from "./Context/JobRequestContext";
import { useEffect } from "react";
// import Cookies from 'js-cookie';
// let session = Cookies.get('session');
// axios.defaults.headers.common['Authorization'] = session;

function App() {
  axios.defaults.baseURL =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT_ENDPOINT
      : process.env.REACT_APP_PRODUCTION_ENDPOINT;

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <UserProvider>
      <JRProvider>
        <LocationTracker>
          <ThemeCustomization>
            <ScrollTop>
              <ThemeRoutes />
            </ScrollTop>
          </ThemeCustomization>
        </LocationTracker>
      </JRProvider>
    </UserProvider>
  );
}

export default App;
