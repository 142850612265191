import { useEffect } from "react";
import styles from "./Container.module.css";

function Container(props) {
  const setIsMobile = props.setIsMobile;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 850) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setIsMobile]);

  return <div className={styles.contentBox}>{props.children}</div>;
}

Container.defaultProps = {
  setIsMobile: (bool)=>{return;}
}

export default Container;
