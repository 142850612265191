import React from 'react';
import { Tooltip } from 'antd';
import {
	CloseCircleTwoTone,
	FileTwoTone,
	FileTextTwoTone,
	FilePdfTwoTone,
	FileExcelTwoTone
} from '@ant-design/icons';
class AttachmentUploadDisplay extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let docLink = window.URL.createObjectURL(this.props.file);
		let renderData;
		switch (this.props.file.type.split('/')[0]) {
			case 'image':
				renderData = (
					<img style={{ display: 'block', height: '75px' }} src={docLink} />
				);
				break;
			case 'text':
				renderData = (
					<FileTextTwoTone
						style={{
							position: 'relative',
							top: '10px',
							display: 'block',
							fontSize: '50px'
						}}
					/>
				);
				break;
			default:
				switch (this.props.file.type.split('/')[1]) {
					case 'pdf':
						renderData = (
							<FilePdfTwoTone
								twoToneColor='#fe4d97'
								style={{
									position: 'relative',
									top: '10px',
									display: 'block',
									fontSize: '50px'
								}}
							/>
						);
						break;
					case 'vnd.ms-excel':
						renderData = (
							<FileTextTwoTone
								twoToneColor='#6dd230'
								style={{
									position: 'relative',
									top: '10px',
									display: 'block',
									fontSize: '50px'
								}}
							/>
						);
						break;
					case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
						renderData = (
							<FileExcelTwoTone
								twoToneColor='#6dd230'
								style={{
									position: 'relative',
									top: '10px',
									display: 'block',
									fontSize: '50px'
								}}
							/>
						);
						break;
					default:
						renderData = (
							<FileTwoTone
								style={{
									position: 'relative',
									top: '10px',
									display: 'block',
									fontSize: '50px'
								}}
							/>
						);
						break;
				}
				break;
		}
		return (
			<Tooltip title={this.props.file.name}>
				<div
					style={{
						float: 'left',
						display: 'inline',
						width: '75px',
						height: '75px',
						border: '1px solid grey',
						borderRadius: '5px',
						overflow: 'hidden',
						backgroundColor: 'white'
					}}
				>
					<div
						style={{
							position: 'relative',
							zIndex: 1,
							top: '1px',
							left: '55px'
						}}
					>
						<CloseCircleTwoTone
							onClick={() => {
								this.props.removeFile(this.props.fileIndex);
							}}
						/>
					</div>
					<div
						onClick={() => {
							this.props.file.url = docLink;
							this.props.viewAttachment(this.props.file);
						}}
						style={{ position: 'relative', zIndex: 0, bottom: '22px' }}
					>
						{renderData}
					</div>
				</div>
			</Tooltip>
		);
	}
}

export default AttachmentUploadDisplay;
