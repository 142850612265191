import axios from "axios";
import React, { useState } from "react";

import * as Yup from "yup";
import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  FormHelperText,
  Checkbox,
  Typography
} from "@mui/material";

import { Formik, Form } from "formik";

import DelegatePropertyTable from "./DelegatePropertyTable";
import { useUserState } from "../../../Context/UserContext";

const AddDelegate = (props) => {
  const { user } = useUserState();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [errorMessage, setErrorMessage] = useState();

  // Function to handle "Select All" checkbox change
  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    if (checked) {
      setSelectedProperties(
        props.propertyList.map((property) => property.property_id)
      );
    } else {
      setSelectedProperties([]);
    }
  };

  // Function to handle row click to toggle checkbox
  const handleRowClick = (property_id) => {
    if (selectedProperties.includes(property_id)) {
      setSelectedProperties((prevSelected) =>
        prevSelected.filter((id) => id !== property_id)
      );
    } else {
      setSelectedProperties((prevSelected) => [...prevSelected, property_id]);
    }
  };
  //   console.log(selectedProperties);

  const onSubmit = async (values) => {
    const { email, spendingLimit } = values;
    console.log(email, spendingLimit);
    if (selectedProperties.length === 0) {
      setErrorMessage("Please select property");
    } else {
      await axios.post("/auth/userExists", { email: email }).then((res) => {
        const userExist = res.data.exists;
        console.log(res.data.exists);
        if (!userExist) {
          setErrorMessage("User with this email does not exist");
        } else if (email === user.email) {
          setErrorMessage("You cannot delegate to yourself.");
        } else {
          try {
            axios
              .post("/property/delegateProperty", {
                email: email,
                PropertyIds: selectedProperties,
                SpendingLimit: spendingLimit,
                host: window.location.host
              })
              .then((res) => {
                props.fetchProperties();
                props.fetchOwnerDelegateProperties();
                props.displayDelegateSuccess();
                props.onClose();
              });
          } catch (err) {
            console.error(err);
          }
        }
      });
    }
  };
  console.log(props.propertyList);
  return (
    <>
      <Formik
        initialValues={{
          email: "",
          spendingLimit: ""
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          spendingLimit: Yup.number()
            .required("Spending limit is required")
            .max(2000, "Spending limit cannot exceed 2000")
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          values
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-signup">
                    Email address to be delegated*
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="email-login"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="email"
                    inputProps={{}}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText error id="helper-text-email-signup">
                      {errors.email}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="spendingLimit">
                    Spending Limit
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="spendingLimit"
                    type="number"
                    value={values.spendingLimit}
                    name="spendingLimit"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Max $2000"
                    inputProps={{}}
                  />
                  {touched.spendingLimit && errors.spendingLimit && (
                    <FormHelperText error id="helper-text-email-signup">
                      {errors.spendingLimit}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Typography style={{ textAlign: "right" }}>
                    Select All{" "}
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                style={{
                  width: "100%",
                  alignSelf: "center",
                  maxHeight: "200px",
                  overflowY: "auto"
                }}
              >
                {props.propertyList.map((property) => (
                  <DelegatePropertyTable
                    property={property}
                    setSelectedProperties={setSelectedProperties}
                    selectedProperties={selectedProperties}
                    handleRowClick={handleRowClick}
                  />
                ))}
              </Grid>
              <FormHelperText
                error
                id="helper-text-firstname-signup"
                style={{ marginLeft: 22 }}
              >
                {errorMessage}
              </FormHelperText>

              <Grid item xs={12}>
                <>
                  <Button
                    disableElevation
                    size="small"
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#01552f",
                      float: "right",
                      marginLeft: 5
                    }}
                  >
                    Send
                  </Button>
                  <Button
                    disableElevation
                    size="small"
                    onClick={props.onClose}
                    variant="contained"
                    style={{
                      backgroundColor: "#01552f",
                      float: "right"
                    }}
                  >
                    Cancel
                  </Button>
                </>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddDelegate;
