import { useState, useEffect } from 'react';
import axios from 'axios';

const GetPropertyType = () => {
	const [propertyType, setPropertyType] = useState([]);
	useEffect(() => {
		axios.get('/property/api/getPropertyType').then(res => {
			setPropertyType(res.data.propertyType);
		});
	}, []);
	return propertyType;
};

export default GetPropertyType;
