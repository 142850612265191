import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button } from "antd";
import BarCalendar from "../../../../Components/BarCalendar";

import axios from "axios";
const source = axios.CancelToken.source();

const EditClientAvailability = (props) => {
  const [taskArray, setTaskArray] = useState([]);
  const [lockOn, setLockOn] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const getAvailability = useCallback(() => {
    axios.get("/schedule/get/retrieveavailability").then((response) => {
      if (response.data.Item) {
        let availabilities = response.data.Item.availability;
        let jrClientAvail = availabilities.filter(
          (avail) => avail.jobId === props.jrData.job_id
        );
        setTaskArray(jrClientAvail);
        setShowCalendar(true);
      }
    });
  }, [props.jrData.job_id]);

  useEffect(() => {
    getAvailability();
  }, [getAvailability]);

  const createEvent = (event) => {
    if (source) source.cancel("Updated before finished");
    let newTaskArray;
    let recordExists = taskArray !== null;
    if (recordExists) {
      newTaskArray = [...taskArray];
      newTaskArray.push({
        startTime: event.startTime,
        endTime: event.endTime,
        startDate: event.startDate.valueOf(),
        jobId: props.jrData.job_id,
      });
    } else
      newTaskArray = [
        {
          startTime: event.startTime,
          endTime: event.endTime,
          startDate: event.startDate.valueOf(),
          jobId: props.jrData.job_id,
        },
      ];
    setTaskArray(newTaskArray);
    setShowCalendar(true);
  };
  const changeEvent = (newData) => {
    if (newData.operation !== "new") {
      if (source) source.cancel("Updated before finished");
      let newTaskArray = [...taskArray];
      if (newData.operation === "delete") {
        let deleteIndex = newTaskArray.findIndex((task) => {
          return newData.id === task.event_id;
        });
        newTaskArray.splice(deleteIndex, 1);
      }
      if (newData.operation === "edit") {
        let editIndex = newTaskArray.findIndex((task) => {
          return newData.id === task.event_id;
        });
        newTaskArray[editIndex].startTime = newData.data.startTime;
        newTaskArray[editIndex].endTime = newData.data.endTime;
        newTaskArray[editIndex].startDate = newData.data.startDate.valueOf();
        newTaskArray[editIndex].jobId = newData.data.jobId;
      }
      if (newData.operation === "merge") {
        let deleteIndex;
        for (const element of newData.delete_ids) {
          deleteIndex = newTaskArray.findIndex((task) => {
            return element === task.event_id;
          });
          newTaskArray.splice(deleteIndex, 1);
        }
        let editIndex = newTaskArray.findIndex((task) => {
          return newData.id === task.event_id;
        });
        newTaskArray[editIndex].startTime = newData.data.startTime;
        newTaskArray[editIndex].endTime = newData.data.endTime;
        newTaskArray[editIndex].startDate = newData.data.startDate.valueOf();
        newTaskArray[editIndex].jobId = newData.data.jobId;
      }

      setTaskArray(newTaskArray);
    }
  };
  //Submit schedule and send request to backend
  const submitSchedule = () => {
    let newTaskArray = [...taskArray];
    axios
      .post("/schedule/post/updateAvailability", {
        newAvailability: newTaskArray,
      })
      .then(() => {
        window.location.reload();
      });
  };
  return (
    <Modal
      title="Edit Availability"
      open={props.showModal}
      onCancel={() => props.onClose()}
      destroyOnClose={true}
      width={"1500px"}
      footer={[
        <Button key="modal_footer_btn1" onClick={() => props.onClose()}>
          Cancel
        </Button>,
        <Button
          key="modal_footer_btn2"
          type="button"
          color="primary"
          onClick={submitSchedule}
        >
          Submit
        </Button>,
      ]}
    >
      <BarCalendar />
    </Modal>
  );
};

export default EditClientAvailability;
