import React, { useState } from "react";
import BarCalendar from "../../../../Components/BarCalendar";
import { Button } from "@mui/material";
import EditClientAvailability from "./EditClientAvailability";
import axios from "axios";

const ClientAvailability = (props) => {
  const onSubmit = async (availabilityList) => {
    console.log(props.jrData);
    const JSONAvailabilityList = JSON.stringify(availabilityList);
    const response = await axios.post("/schedule/setClientAvailability", {
      availability: JSONAvailabilityList,
      jobId: props.jrData.job_id,
    });
    if (response.data.ok) {
      props.fetchJrData();
      props.openAvailability();
      console.log("FINISHED");
    } else console.log("NOTFINISHED");
  };
  return (
    <>
      <BarCalendar onSubmit={onSubmit} jrData={props.jrData} />
    </>
  );
};

export default ClientAvailability;
