import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement
} from "@stripe/react-stripe-js";
import { Button } from "@mui/material";
import AnimateButton from "../../Layout/components/@extended/AnimateButton";

const SaveCardForm = ({ onSuccess, successUrl }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: successUrl
      }
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      if (onSuccess) onSuccess();
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <AnimateButton>
        <Button
          size="sm"
          type="submit"
          variant="contained"
          color="primary"
          sx={{ ml: 2, float: "right", marginbottom: "1em" }}
          disabled={!stripe || !elements}
        >
          Submit
        </Button>
      </AnimateButton>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

SaveCardForm.defaultProps = {
  successUrl: window.location.origin + "/dashboard/profile"
};

export default SaveCardForm;
