import { useUserState } from "../../../../Context/UserContext";

/**
 * @typedef ScopeLine
 * @type {Object}
 * @property {number} id
 * @property {number} quantity
 * @property {number} amount
 * @property {string} type
 */

const round = (num) => Math.ceil(num * 100) / 100;

/**
 * creates a table showing the scope/bid
 * @param {{lines:ScopeLine[], amount:number, chargePercentages:Object}} props
 * @returns {React.JSX.Element}
 */
const ScopeTable = ({ lines, amount, chargePercentages, isInvoice }) => {
  console.log("ScopeTable", lines, amount, chargePercentages, isInvoice);
  const { user } = useUserState();
  const bidAmount = parseFloat(amount);
  let serviceFee;
  if (isInvoice) {
    const chargeLine = lines.find((line) => line.type === "Charge");
    console.log("chargeLine", chargeLine);
    serviceFee = chargeLine.amount;
    if (user.vendorMode) {
      serviceFee = serviceFee + chargeLine.tax;
    }
    serviceFee = round(serviceFee);
  } else {
    serviceFee = round(
      user.vendorMode
        ? (chargePercentages.vendorPercentage / 100) * bidAmount * 1.15
        : (chargePercentages.clientPercentage / 100) * bidAmount
    );
  }

  let tax = round(
    user.vendorMode ? bidAmount * 0.15 : (serviceFee + bidAmount) * 0.15
  );

  if (isInvoice) {
    tax = user.vendorMode ? bidAmount * 0.15 : (serviceFee + bidAmount) * 0.15;
  }

  return (
    <table width={"100%"}>
      <tbody width={"100%"}>
        <ScopeLines lines={lines} isInvoice={isInvoice} />
        {user.vendorMode ? (
          <>
            <tr>
              <td style={{ textAlign: "left", width: "min-content" }}>
                <strong
                  style={{
                    color: "black",
                    fontSize: "0.85rem",
                    fontWeight: "600",
                  }}
                >
                  HST collected
                </strong>
              </td>
              <td
                style={{
                  textAlign: "right",
                  width: "min-content",
                }}
              >
                {`$${tax.toFixed(2)}`}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", width: "min-content" }}>
                <strong
                  style={{
                    color: "black",
                    fontSize: "0.85rem",
                    fontWeight: "600",
                  }}
                >
                  Service Fee(with tax)
                </strong>
              </td>
              <td
                style={{
                  textAlign: "right",
                  width: "min-content",
                }}
              >
                {`$${serviceFee.toFixed(2)}`}
              </td>
            </tr>
          </>
        ) : (
          <>
            <tr>
              <td style={{ textAlign: "left", width: "min-content" }}>
                <strong
                  style={{
                    color: "black",
                    fontSize: "0.85rem",
                    fontWeight: "600",
                  }}
                >
                  Service Fee
                </strong>
              </td>
              <td
                style={{
                  textAlign: "right",
                  width: "min-content",
                }}
              >
                {`$${serviceFee.toFixed(2)}`}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", width: "min-content" }}>
                <strong
                  style={{
                    color: "black",
                    fontSize: "0.85rem",
                    fontWeight: "600",
                  }}
                >
                  HST
                </strong>
              </td>
              <td
                style={{
                  textAlign: "right",
                  width: "min-content",
                }}
              >
                {`$${tax.toFixed(2)}`}
              </td>
            </tr>
          </>
        )}

        <tr>
          <td style={{ textAlign: "left", width: "min-content" }}>
            <strong
              style={{
                color: "black",
                fontSize: "0.85rem",
                fontWeight: "600",
              }}
            >
              Total {user.vendorMode ? "payout" : null}
            </strong>
          </td>
          <td
            style={{
              borderTop: "2px solid black",
              textAlign: "right",
              width: "min-content",
            }}
          >
            {user.vendorMode
              ? `$${(bidAmount + tax - serviceFee).toFixed(2)}`
              : `$${(bidAmount + tax + serviceFee).toFixed(2)}`}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

ScopeTable.defaultProps = {
  chargePercentages: {
    vendorPercentage: 0,
    clientPercentage: 0,
  },
  isInvoice: false,
};

/**
 * displays lines for bid/scope display
 * @param {{lines:ScopeLines[]}} props
 * @returns {React.JSX.Element[]}
 */
const ScopeLines = ({ lines }) => {
  return lines?.filter((line) => line.type !== "Charge" && line.type !== "Tax")
    .map((line) => {
      console.log(line);
      const isMaterials = line.type === "Materials";
      if (isMaterials) {
        return (
          <tr key={`scopeLine_${line.id}`}>
            <td style={{ textAlign: "left", width: "min-content" }}>
              <strong
                style={{
                  color: "black",
                  fontSize: "0.85rem",
                  fontWeight: "600",
                }}
              >
                Material Cost
              </strong>
              {line.description && ( 
        <p style={{ marginBottom:  0.5 }}>
         ({line.description})
        </p>)}
            </td>
            <td style={{ textAlign: "right", width: "min-content" }}>
              {`$${parseInt(line.amount * line.quantity).toFixed(2)}`}
            </td>
          </tr>
        );
      } else {
        const isFlat = line.type.includes("Flat");
        const chargeType = isFlat ? "Flat Rate Charge" : "Labour Charge";
        return (
          <tr key={`scopeLine_${line.id}`}>
            <td style={{ textAlign: "left", width: "min-content" }}>
              <strong
                style={{
                  color: "black",
                  fontSize: "0.85rem",
                  fontWeight: "600",
                }}
              >
                {chargeType}
              </strong>
              {line.description && ( 
        <p style={{ marginBottom:  0.5 }}>
         ({line.description})
        </p>
      )}
            </td>
            <td style={{ textAlign: "right", width: "min-content" }}>
              {isFlat
                ? `$${parseInt(line.amount).toFixed(2)}`
                : `$${(parseInt(line.amount) * parseInt(line.quantity)).toFixed(
                    2
                  )}`}
            </td>
          </tr>
        );
      }
    });
};

export default ScopeTable;
