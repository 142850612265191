import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUserState, useUserDispatch } from "../Context/UserContext.js";
import Cookies from "js-cookie";
import MainLayout from "../Layout/MainLayout";
import axios from "axios";
import LoadScreen from "../Components/LoadScreen";

const Authenticator = (props) => {
  let { pathname } = useLocation();
  let navigate = useNavigate();
  let user = useUserState();
  let dispatch = useUserDispatch();
  const [page, setPage] = useState("Loading");
  let session = Cookies.get("session");
  axios.defaults.headers.common["Authorization"] = session;

  axios.interceptors.response.use(null, (error) => {
    if (error.response?.status === 401) {
      Cookies.set(
        "destination",
        JSON.stringify({
          location: pathname,
        }),
        { sameSite: "strict" }
      );
      navigate("/login");
    }
    return Promise.reject(error);
  });

  if (user !== null) {
    return <MainLayout userType={user.user.type} />;
  } else {
    //Session was previously intact - try refreshing the session.
    if (session !== undefined) {
      axios.post("/auth/refreshUser").then((response) => {
        //Session was found. Set user and reinitialize session in cookies
        if (response.data.ok) {
          dispatch({ state: { user: response.data.user }, type: "initialize" });
          Cookies.set(
            "session",
            JSON.stringify({
              session_id: response.data.session_id,
              type: response.data.user.type,
            })
          );
          setPage("private");
        } else {
          Cookies.set(
            "destination",
            JSON.stringify({
              location: pathname,
            }),
            { sameSite: "strict" }
          );
          Cookies.remove("session");
          setPage("not_authenticated");
        }
      });
    } else {
      Cookies.set(
        "destination",
        JSON.stringify({
          location: pathname,
        }),
        { sameSite: "strict" }
      );
      return navigate("/login");
    }
  }
  if (page === "Loading") return <LoadScreen />;
  else if (page === "private") {
    return <MainLayout userType={user.user.type} />;
  } else if (page === "not_authenticated") return navigate("/login");
};

export default Authenticator;
