import { Button, Grid, InputLabel, OutlinedInput, Stack } from "@mui/material";
import { DatePicker, Image, Modal, Typography } from "antd";
import axios from "axios";
import { useState } from "react";
import { useUserState } from "../../../../Context/UserContext";
import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";

import { EditOutlined } from "@ant-design/icons";

import moment from "moment-timezone";
import EditAction from "./EditAction";
moment.tz.setDefault("Canada/Newfoundland");

function AddAction(props) {
  const { user } = useUserState();
  const [text, setText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [showEditForm, setShowEditForm] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const [notEmployee, setNotEmployee] = useState("");

  const closetNotEmployee = () => {
    setNotEmployee(false);
  };

  const importFile = () => {
    let newArray;
    if (attachments !== undefined) {
      newArray = [...attachments];
    } else {
      newArray = [];
    }
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = () => {
      newArray.push(...input.files);
      setAttachments(2);
      setAttachments(newArray);
    };
    input.click();
  };

  const removeAttachment = (id) => {
    const newArray = [...attachments];
    newArray.splice(id, 1);
    if (newArray.length === 0) {
      setAttachments("");
    } else {
      setAttachments(0);
      setAttachments(newArray);
    }
  };

  const createAttachmentDisplay = (attachments) => {
    const attachmentList = [];
    for (let i in attachments) {
      attachmentList.push(
        <div
          onClick={() => removeAttachment(i)}
          key={attachments[i].name + attachments[i].size}
          className="attachment"
        >
          {attachments[i].type.includes("image") ? (
            <img
              className="testIMG"
              src={URL.createObjectURL(attachments[i])}
              alt=""
            />
          ) : (
            attachments[i].name
          )}
        </div>
      );
    }
    return (
      <>
        <div>
          <p>Attachments: </p>
        </div>
        {attachmentList}
      </>
    );
  };

  const handleEditAction = () => {
    user.vendorMode ? setShowEditForm(true) : setNotEmployee(true);
  };

  const handleSubmit = (e) => {
    const formObject = {
      JobId: props.jr.job_id,
      startDate: startDate,
      actionDesc: text,
      startTime: startTime,
      endTime: endTime,
      entryDate: new Date().toISOString(),
      activityDesc: `Created action "${text}"`,
      userName: `${user.first_name} ${user.last_name}`,
      eventID: props.eventId,
    };

    axios.post("/action/addActionEntry", formObject).then((response) => {
      let actionId = response.data.actionId;
      let JobId = props.jr.job_id;
      if (response.data.ok) {
        if (attachments.length > 0) {
          let form = new FormData();
          for (const element of attachments) {
            form.append("file", element);
          }
          form.append("action_id", actionId);
          form.append("job_id", JobId);

          axios.post("/action/addActionImages", form).then((res) => {
            props.setActionModal(false);
            props.fetchData();
          });
        } else {
          props.setActionModal(false);
          props.fetchData();
        }
      }
    });
  };

  return (
    <>
      <div>
        <br></br>
        <div className="buttonContainer">
          {props.actionDisplay && (
            <label className="categoryLabel" htmlFor="addButton">
              Actions
            </label>
          )}

          {props.jr.job_status === "Scheduling" &&
            user.empRole !== null &&
            !props.actionDisplay && (
              <Modal
                destroyOnClose={true}
                width="700px"
                title={"Add Action Entry"}
                open={props.actionModal}
                onCancel={() => props.setActionModal(false)}
                footer={false}
              >
                <form>
                  <Grid container spacing={1}>
                    <>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                          <InputLabel>Start Time</InputLabel>
                          <DatePicker
                            required
                            showTime={{
                              format: "HH:mm a",
                            }}
                            format={"YYYY-MM-DD HH:mm"}
                            label="Start Date"
                            onChange={(e, dateString) => {
                              setStartDate(dateString);
                              setStartTime(dateString);
                            }}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                          <InputLabel>End Time</InputLabel>
                          <DatePicker
                            required
                            showTime={{
                              format: "HH:mm a",
                            }}
                            format={"YYYY-MM-DD HH:mm"}
                            onChange={(e, dateString) => {
                              setEndTime(dateString);
                            }}
                          />
                        </Stack>
                      </Grid>
                    </>
                    <Grid item xs={12} md={12}>
                      <Stack spacing={1}>
                        <InputLabel>Action Description</InputLabel>
                        <OutlinedInput
                          required
                          id="ActionDesc"
                          name="actionDesc"
                          value={text}
                          onChange={(e) => setText(e.target.value)}
                          placeholder="Action Description"
                          multiline
                          rows={4}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <InputLabel>Pictures/Documents</InputLabel>
                        <Button
                          size="sm"
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={importFile}
                          id="file"
                          name="file"
                        >
                          Choose File
                        </Button>

                        {attachments !== undefined &&
                          attachments.length !== 0 && (
                            <div className="attachments">
                              {createAttachmentDisplay(attachments)}
                            </div>
                          )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Stack spacing={1}>
                        <AnimateButton>
                          <Button
                            size="sm"
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              handleSubmit();
                            }}
                            sx={{ ml: 2, float: "right" }}
                          >
                            Submit
                          </Button>

                          <Button
                            size="sm"
                            type="button"
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              props.setActionModal(false);
                            }}
                            sx={{ ml: 2, float: "right" }}
                          >
                            Cancel
                          </Button>
                        </AnimateButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              </Modal>
            )}
        </div>
        <div>
          {showEditForm && (
            <EditAction
              showEditForm={showEditForm}
              setShowEditForm={setShowEditForm}
              jobID={props.jr.job_id}
              fetchData={props.fetchData}
            />
          )}
          {console.log(props.actionList)}
          {props.actionDisplay && (
            <table className="table">
              <tbody>
                {props.actionList?.map((item, i) =>
                  item.is_terminated === 0 ? (
                    <tr
                      className="tableRowHover"
                      style={{
                        height: "1.7rem",
                      }}
                      key={item.action_id}
                    >
                      <td style={{ textAlign: "center" }} className="td">
                        {moment(new Date(item.start_date)).format(
                          "MMM Do YYYY"
                        )}
                        , {moment(new Date(item.start_time)).format("hh:mm a")}{" "}
                        - {moment(new Date(item.end_time)).format("h:mm a")}
                      </td>

                      <td style={{ textAlign: "center" }} className="td">
                        {item.action_description}
                      </td>
                      <td style={{ textAlign: "center" }} className="td">
                        {props.actionImages?.map((image, i) => {
                          if (item.action_id === image.action_id) {
                            return (
                              <Image key={i} width={30} src={image.image} />
                            );
                          }
                        })}
                      </td>

                      {props.jr.job_status === "Scheduling" &&
                      props.events[0].completed === 0 ? (
                        <td style={{ textAlign: "center" }}>
                          <Button
                            style={{
                              maxWidth: "0.1rem",
                              minWidth: "0.1rem",
                            }}
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              setText("");
                              setStartDate("");
                              setStartTime("");
                              setEndTime("");
                              handleEditAction();
                            }}
                          >
                            <EditOutlined />
                          </Button>
                        </td>
                      ) : (
                        <td style={{ textAlign: "center", color: "green" }}>
                          Completed
                        </td>
                      )}
                    </tr>
                  ) : (
                    ""
                  )
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Not Employee"
        open={notEmployee}
        onCancel={closetNotEmployee}
        footer={false}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography>
              You are not an employee. The employee who created this entry has
              to update it.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <AnimateButton>
              <Button
                size="sm"
                type="button"
                variant="contained"
                color="primary"
                onClick={closetNotEmployee}
                sx={{ ml: 2, float: "right" }}
              >
                Okay
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

export default AddAction;
