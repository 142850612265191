import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import DatePicker from "../../../Components/FormComponents/DatePicker";

const DeactivateEmployeeModal = (props) => {
  return (
    <Grid container alignItems={"center"} justifyContent={"center"}>
      <Grid item>
        <h5>
          Are you sure you want to deactivate{" "}
          <b>
            <i>
              {props.employeeList[props.currentEmployee].first_name}{" "}
              {props.employeeList[props.currentEmployee].last_name}
            </i>
          </b>
          ?
        </h5>
      </Grid>
      <Grid item>
        <InputLabel htmlFor="employeeDeactivationReason">
          Reason for deactivation:
        </InputLabel>
        <OutlinedInput
          id="employeeDeactivationReason"
          name="employeeDeactivationReason"
          type="text"
          onChange={(e) => {
            props.setDeactivationReason(e.target.value);
          }}
        />
      </Grid>
      <Grid item>
        <InputLabel htmlFor="lastDay">Last Day Worked:</InputLabel>
        <DatePicker
          required
          id="lastDay"
          type="date"
          name="lastDay"
          value={props.lastDay}
          onChange={(e, dateString) => {
            props.setLastDay(e.target.valueAsDate);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <AnimateButton>
          <Button
            color="error"
            variant="contained"
            onClick={props.confirmDeactivation}
            sx={{ ml: 2, float: "right" }}
          >
            Deactivate
          </Button>
          <Button
            size="sm"
            variant="outlined"
            color="secondary"
            onClick={() => props.setDeactivateEmployeeModal(false)}
            sx={{ ml: 2, float: "right" }}
          >
            Cancel
          </Button>
        </AnimateButton>
      </Grid>
    </Grid>
  );
};

export default DeactivateEmployeeModal;
