import { useMediaQuery, Button } from "@mui/material";
import "./propertyTable.css";
import { MinusOutlined } from "@ant-design/icons";

const PropertyTable = (props) => {
  const mobileView = useMediaQuery("(max-width: 600px)");
  const isDelegated = props.delegateProperties.some(
    (delegateProperty) =>
      delegateProperty.delegate_to_id === props.property.property_client_id
  );

  console.log(isDelegated);
  const getTableRows = (property) => {
    let rowList = [];

    !mobileView
      ? rowList.push(
          property.is_active && property.is_delegated === 0 && (
            <tr className="onHover" key={`property_${property.property_id}`}>
              <td style={{ margin: "0", padding: "1rem" }}>
                {`${property.street}, ${property.city}, ${property.province} ${property.postal_code}`}
              </td>
              <td style={{ margin: "0", padding: "1rem" }}>
                {property.property_type}
              </td>
              <td style={{ margin: "0", padding: "1rem" }}>
                {property.total_units} Units
              </td>

              <td style={{ margin: "0", padding: "1rem" }}>
                {property.is_active ? (
                  <td className="propertyActions">
                    <Button
                      size="small"
                      variant="contained"
                      className="deactivateProperty"
                      style={{ padding: "0.5", fontSize: "0.8rem" }}
                      onClick={(e) => {
                        props.onDeactivate();
                        e.stopPropagation();
                      }}
                    >
                      <MinusOutlined />{" "}
                    </Button>
                  </td>
                ) : (
                  ""
                )}
              </td>
            </tr>
          )
        )
      : rowList.push(
          property.is_active && property.is_delegated === 0 && (
            <tr className="onHover" key={`property_${property.property_id}`}>
              <td style={{ margin: "0", padding: "1rem" }}>
                {`${property.street}, ${property.city}, ${property.province}`}
              </td>
              <td style={{ margin: "0", padding: "1rem" }}>
                {property.property_type}
              </td>
              <td style={{ margin: "0", padding: "1rem" }}>
                {property.total_units} Unit(s)
              </td>
              <td style={{ margin: "0", padding: "1rem" }}>
                {property.is_active ? (
                  <td className="propertyActions">
                    <Button
                      size="small"
                      variant="contained"
                      className="deactivateProperty"
                      onClick={(e) => {
                        props.onDeactivate();
                        e.stopPropagation();
                      }}
                    >
                      <MinusOutlined />{" "}
                    </Button>
                  </td>
                ) : (
                  ""
                )}
              </td>
            </tr>
          )
        );

    return rowList;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%"
        }}
      >
        <table className="propertyTable">
          <tbody className="propertyTbody">
            {getTableRows(props.property)}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PropertyTable;
