import { Grid, Typography, useMediaQuery, Button } from "@mui/material";
import { DownloadOutlined, FullscreenOutlined } from "@ant-design/icons";
// import { usePDF } from "react-to-pdf";
import { useEffect, useRef, useState } from "react";
import { StyleSheet } from "@react-pdf/renderer";
import axios from "axios";
import { getMonth } from "../commonComponents/util";
import PDFToolBar from "./PDFToolBar";
import generatePDF from "react-to-pdf";
import ReactMarkdown from "react-markdown";
import termsFile from "./termsandconditions.md";

const TermsViewer = (props) => {
  const targetRef = useRef();
  const mobileView = useMediaQuery("(max-width: 600px");
  const [terms, setTerms] = useState("");

  useEffect(() => {
    // Assuming termsFile is a string with Markdown content
    fetch(termsFile)
      .then((response) => response.text())
      .then((data) => setTerms(data))
      .catch((error) => console.error("Error loading markdown file", error));
  }, []);

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    body: {
      padding: !mobileView
        ? props.signIn
          ? "0"
          : "35px 7.5rem 65px 7.5rem"
        : props.signIn
        ? "0 !important"
        : "3rem 0 0 0",
      paddingHorizontal: 35,
      backgroundColor: "white",
    },
    container: {
      margin: !mobileView
        ? props.signIn
          ? "0"
          : "1rem 7.5rem 25rem 7.5rem"
        : props.signIn
        ? "0"
        : "0.8rem 0 0 0",
      justifyContent: "center",
      padding: props.signIn ? "0 !important" : "0 0.15rem 0.15rem 0.15rem",
      backgroundColor: "white",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 14,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    text: {
      margin: 12,
      fontSize: 18,
      textAlign: "justify",
      fontFamily: "Times-Roman",
      marginbottom: 2,
    },
    title: {
      fontSize: 22,
      textAlign: "center",
      fontFamily: "Times-Roman",
      fontWeight: 800,
      marginbottom: 0.05,
    },
    heading: {
      margin: 12,
      fontSize: 20,
      textAlign: "justify",
      fontFamily: "Times-Roman",
      fontWeight: 600,
      marginbottom: 2,
    },
  });

  const [signDate, setSignDate] = useState();
  const [sign, setSign] = useState();
  useEffect(() => {
    !props.signIn &&
      axios.post("/getUserDetails").then((res) => {
        setSignDate(new Date(res.data.userData[0].created_date));
        setSign(res.data.userData[0].e_sign);
      });
  }, [props.signIn]);
  const [name, setName] = useState("CLIENT");
  useEffect(() => {
    !props.signIn &&
      axios.post("/getclientDetails").then((res) => {
        setName(
          res.data.clientData?.length === 0
            ? "CLIENT"
            : res.data.clientData[0].first_name +
                " " +
                res.data.clientData[0].last_name
        );
      });
  }, [props.signIn]);

  const date =
    typeof signDate !== "undefined" ? new Date(signDate) : new Date();

  return (
    // <ReactMarkdown children={terms} />
    <Grid
      container
      width={"fit-content"}
      justifyContent={"center"}
      style={styles.container}
    >
      <Grid item xs={12} textAlign={"right"}>
        <Button
          variant="text"
          style={{
            minWidth: "1.5rem",
            minHeight: "1.5rem",
            maxWidth: "1.5rem",
            maxHeight: "1.5rem",
            // border: "2px solid blue",
          }}
          onClick={() =>
            generatePDF(targetRef, { filename: "termsandconditions.pdf" })
          }
        >
          <DownloadOutlined />
        </Button>
      </Grid>

      <div id="termsandconditions" ref={targetRef} style={styles.body}>
        <Typography style={styles.title}>
          STEADY INNOVATION CORPORATION
        </Typography>
        <Typography style={styles.title}>TERMS AND CONDITIONS</Typography>
        <Typography style={styles.title}>(the “Terms”)</Typography>
        <Typography style={styles.title}>
          Effective Date:{" "}
          {`${date.getDate()} ${getMonth(
            date.getMonth()
          )} ${date.getFullYear()}`}
        </Typography>
        <Typography style={styles.text}>Between: {name}</Typography>
        <Typography style={styles.text}>
          (hereinafter called the "Client")
        </Typography>
        <Typography style={styles.text}>
          And: Steady Innovation Corporation
        </Typography>
        <Typography style={styles.text}>
          PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING OUR
          SERVICES.
        </Typography>
        <Typography style={styles.heading}>
          1. CONTRACTUAL RELATIONSHIP
        </Typography>
        <Typography style={styles.text}>
          These Terms and Conditions (the “Terms”) form a legal and binding
          agreement between you, the end user (“you” or “ your”), and Steady
          Innovation Corporation, a Newfoundland and Labrador (Canada)
          corporation operating under Steady Innovation, FixDen and other brands
          (“ FixDen”, “we”, “us”, or “our”), and governs your access or use of
          any of our websites, platforms, applications, products and services,
          including but not limited to www.FixDen.com, the FixDen platform and
          the FixDen application (referred to herein as the “ Services” and more
          fully defined below).
        </Typography>
        <Typography style={styles.text}>
          By accessing or using the Services, you agree to be legally bound by
          all of the terms and conditions of these Terms. If you do not agree to
          these Terms, you may not access or use the Services. These Terms
          expressly supersede prior agreements or arrangements with you. We may
          immediately terminate these Terms or any of the Services with respect
          to you, or generally cease or deny access to the Services or any
          portion thereof, at any time for any reason.
        </Typography>
        <Typography style={styles.text}>
          These Terms will govern any upgrades provided by FixDen that replace
          or supplement the original Services, unless such upgrade is
          accompanied by a new end user license agreement. Supplemental terms
          may apply to certain Services, such as policies for a particular
          event, program, activity or promotion, and such supplemental terms
          will be disclosed to you in a separate disclosure or in connection
          with the applicable Service(s). Supplemental terms are in addition to,
          and shall be deemed a part of, the Terms for the purposes of the
          applicable Service(s). Supplemental terms shall prevail over these
          Terms in the event of a conflict with respect to the applicable
          Services.
        </Typography>
        <Typography style={styles.text}>
          We may amend the Terms from time to time. Amendments will be effective
          upon our posting of such updated Terms at this location or in the
          amended policies or supplemental terms on the applicable Service. Your
          continued access or use of the Services after such posting confirms
          your consent to be bound by the Terms, as amended.
        </Typography>
        <Typography style={styles.text}>
          Our collection and use of personal information in connection with the
          Services is as provided in our Privacy Policy{" "}
          <a href="www.FixDen.com/privacypolicy.pdf"></a>. We may provide to a
          claims processor or an insurer any necessary information (including
          your contact information) if there is a complaint, dispute or
          conflict, which may include an incident involving you and a Third
          Party Provider and such information or data is necessary to resolve
          the complaint, dispute or conflict.
        </Typography>
        <Typography style={styles.heading}>2. SERVICES</Typography>
        <Typography style={styles.text}>
          The Services constitute a technology platform that enables users of
          our websites and mobile applications provided as part of the Services
          (each, an “Application”) to arrange and schedule home
          repair/renovation services with independent third party providers of
          such services, including but not limited to independent third party
          home repair and renovation service providers (“ Third Party
          Providers”). Unless otherwise agreed to by us in a separate written
          agreement with you, the Services are made available solely for your
          personal, noncommercial use.
        </Typography>
        <Typography style={styles.text}>
          YOU ACKNOWLEDGE THAT FIXDEN DOES NOT PROVIDE HOME REPAIR OR RENOVATION
          SERVICES OR FUNCTION AS A SERVICE PROVIDER AND THAT ALL SUCH HOME
          REPAIR AND RENOVATION AND PROPERTY MANAGEMENT SERVICES ARE PROVIDED BY
          INDEPENDENT THIRD PARTY CONTRACTORS WHO ARE NOT EMPLOYED BY FIXDEN OR
          ANY OF ITS AFFILIATES.
        </Typography>
        <Typography style={styles.heading}>License Grant</Typography>
        <Typography style={styles.text}>
          Subject to your compliance with these Terms, FixDen hereby grants you
          a limited, non-exclusive, non-sublicensable, revocable,
          non-transferable license to: (i) access and use the Applications on
          your personal device solely in connection with your use of the
          Services; and (ii) access and use any Content, information and related
          materials that may be made available through the Services, in each
          case solely for your personal, noncommercial use.
        </Typography>
        <Typography style={styles.text}>
          FixDen(and FixDen’s licensors) reserves all rights not expressly
          granted to you under these Terms.
        </Typography>
        <Typography style={styles.heading}>Restrictions</Typography>
        <Typography style={styles.text}>
          You may not, and shall not permit others, including but not limited to
          third parties, to directly or indirectly: (i) remove any copyright,
          trademark or other proprietary notices from any portion of the
          Services; (ii) reproduce, modify, prepare derivative works based upon,
          distribute, license, lease, sell, resell, transfer, publicly display,
          publicly perform, transmit, stream, broadcast or otherwise exploit the
          Services except as expressly permitted by FixDen; (iii) decompile,
          reverse engineer or disassemble the Services except as may be
          permitted by applicable law; (iv) link to, mirror or frame any portion
          of the Services; (v) cause or launch any programs or scripts for the
          purpose of scraping, indexing, surveying, or otherwise data mining any
          portion of the Services or unduly burdening or hindering the operation
          and/or functionality of any aspect of the Services; or (vi) attempt to
          gain unauthorized access to or impair any aspect of the Services or
          its related systems or networks.
        </Typography>
        <Typography style={styles.heading}>
          Provision of the Services
        </Typography>
        <Typography style={styles.text}>
          You acknowledge that portions of the Services may be made available
          under FixDen’s various brands. You also acknowledge that the Services
          may be made available under such brands or in connection with: (i)
          certain of FixDens subsidiaries and affiliates; or (ii) independent
          Third Party Providers, including home repair and renovation service
          providers.
        </Typography>
        <Typography style={styles.heading}>
          Third Party Services and Content
        </Typography>
        <Typography style={styles.text}>
          The Services may be made available or accessed in connection with
          third party services and content (including advertising) that FixDen
          does not control. You acknowledge that different terms of use and
          privacy policies may apply to your use of such third party services
          and content. FixDen does not endorse such third party services and
          content and in no event shall FixDen be responsible or liable for any
          products or services of such third party providers. Additionally,
          Apple Inc., Google, Inc., Microsoft Corporation or BlackBerry Limited
          and/or their applicable international subsidiaries and affiliates will
          be third-party beneficiaries to this contract if you access the
          Services using Applications developed for Apple iOS, Android,
          Microsoft Windows, or Blackberry-powered mobile devices, respectively.
          These third party beneficiaries are not parties to this contract and
          are not responsible for the provision or support of the Services in
          any manner. Your access to the Services using these devices is subject
          to terms set forth in the applicable third party beneficiary’s terms
          of service.
        </Typography>
        <Typography style={styles.heading}>Ownership</Typography>
        <Typography style={styles.text}>
          The Services and all rights therein are and shall remain FixDen’s
          property or the property of FixDen’s licensors. Neither these Terms
          nor your use of the Services convey or grant to you any rights: (i) in
          or related to the Services except for the limited license granted
          above; or (ii) to use or reference in any mannerFixDen’s company
          names, logos, product and service names, trademarks or services marks
          or those of FixDen’s licensors.
        </Typography>
        <Typography style={styles.heading}>3. USAGE RULES</Typography>
        <Typography style={styles.heading}>User Accounts</Typography>
        <Typography style={styles.text}>
          In order to use most aspects of the Services, you must register for
          and maintain an active personal user Services account (“Account”). You
          must be at least 18 years of age, or the age of legal majority in your
          jurisdiction (if different than 18), to obtain an Account. Account
          registration requires you to submit toFixDen certain personal
          information, such as your name, address, mobile phone number and age,
          as well as at least one valid payment method (either a credit card or
          accepted payment partner). You agree to maintain accurate, complete,
          and up-to-date information in your Account. Your failure to maintain
          accurate, complete, and up-to-date Account information, including
          having an invalid or expired payment method on file, may result in
          your inability to access and use the Services or FixDen’s termination
          of these Terms with you. You are responsible for all activity that
          occurs under your Account, and you agree to maintain the security and
          secrecy of your Account username and password at all times. Unless
          otherwise permitted by FixDen in writing, you may only possess one
          Account.
        </Typography>
        <Typography style={styles.heading}>
          User Requirements and Conduct
        </Typography>
        <Typography style={styles.text}>
          The Service is not available for use by persons under the age of 18.
          You may not authorize third parties to use your Account, and you may
          not allow persons under the age of 18 to receive property management
          or home repair or renovation services from Third Party Providers
          unless they are accompanied by you. You may not assign or otherwise
          transfer your Account to any other person or entity. You agree to
          comply with all applicable laws when using the Services and you may
          only use the Services for lawful purposes. You will not, in your use
          of the Services, cause nuisance, annoyance, inconvenience, or property
          damage, whether to the Third Party Provider or any other party. In
          certain instances you may be asked to provide proof of identity to
          access or use the Services, and you agree that you may be denied
          access to or use of the Services if you refuse to provide proof of
          identity.
        </Typography>
        <Typography style={styles.heading}>Text Messaging</Typography>
        <Typography style={styles.text}>
          By creating an Account, you agree that the Services may send you text
          (SMS) messages as part of the normal business operation of your use of
          the Services. You may opt-out of receiving text (SMS) messages from
          FixDen at any time by following the directions found at{" "}
          <a href="www.FixDen.com">www.FixDen.com</a>.
        </Typography>
        <Typography></Typography>
        <Typography style={styles.text}>
          You acknowledge that opting out of receiving text (SMS) messages may
          impact your use of the Services.
        </Typography>
        <Typography style={styles.heading}>Promotional Codes</Typography>
        <Typography style={styles.text}>
          FixDen may, in FixDen’s sole discretion, create promotional codes that
          may be redeemed for Account credit, or other features or benefits
          related to the Services and/or a Third Party Provider’s services,
          subject to any additional terms that FixDen establishes on a per
          promotional code basis (“Promo Codes”). You agree that Promo Codes:
          (i) must be used for the intended audience and purpose, and in a
          lawful manner; (ii) may not be duplicated, sold or transferred in any
          manner, or made available to the general public (whether posted to a
          public form or otherwise), unless expressly permitted by FixDen; (iii)
          may be disabled by FixDen at any time for any reason without liability
          to FixDen; (iv) may only be used pursuant to the specific terms that
          FixDen establishes for such Promo Code; (v) are not valid for cash;
          and (vi) may expire prior to your use. FixDen reserves the right to
          withhold or deduct credits or other features or benefits obtained
          through the use of Promo Codes by you or any other user in the event
          that FixDen determines or believes that the use or redemption of the
          Promo Code was in error, fraudulent, illegal, or in violation of the
          applicable Promo Code terms or these Terms.
        </Typography>
        <Typography style={styles.heading}>User Provided Content</Typography>
        <Typography style={styles.text}>
          FixDen may, in Licensor’s sole discretion, permit you from time to
          time to submit, upload, publish or otherwise make available to FixDen
          through the Services textual, audio, and/or visual content and
          information, including commentary and feedback related to the
          Services, initiation of support requests, and submission of entries
          for competitions and promotions (“User Content”). Any User Content
          provided by you remains your property. However, by providing User
          Content to FixDen, you grant FixDen a worldwide, perpetual,
          irrevocable, transferrable, royalty-free license, with the right to
          sublicense, to use, copy, modify, create derivative works of,
          distribute, publicly display, publicly perform, and otherwise exploit
          in any manner such User Content in all formats and distribution
          channels now known or hereafter devised (including in connection with
          the Services and FixDen’s business and on third-party sites and
          services), without further notice to or consent from you, and without
          the requirement of payment to you or any other person or entity.
        </Typography>
        <Typography style={styles.text}>
          You represent and warrant that: (i) you either are the sole and
          exclusive owner of all User Content or you have all rights, licenses,
          consents and releases necessary to grant FixDen the license to the
          User Content as set forth above; and (ii) neither the User Content nor
          your submission, uploading, publishing or otherwise making available
          of such User Content nor FixDen’s use of the User Content as permitted
          herein will infringe, misappropriate or violate a third party’s
          intellectual property or proprietary rights, or rights of publicity or
          privacy, or result in the violation of any applicable law or
          regulation.
        </Typography>
        <Typography style={styles.text}>
          You agree to not provide User Content that is defamatory, libelous,
          hateful, violent, obscene, pornographic, unlawful, or otherwise
          offensive, as determined by FixDen in its sole discretion, whether or
          not such material may be protected by law. FixDen may, but shall not
          be obligated to, review, monitor, or remove User Content, at FixDen’s
          sole discretion and at any time and for any reason, without notice to
          you.
        </Typography>
        <Typography style={styles.heading}>
          Network Access and Devices
        </Typography>
        <Typography style={styles.text}>
          You are responsible for obtaining the data network access necessary to
          use the Services. Your mobile network’s data and messaging rates and
          fees may apply if you access or use the Services from a
          wireless-enabled device and you shall be responsible for such rates
          and fees. You are responsible for acquiring and updating compatible
          hardware or devices necessary to access and use the Services and
          Applications and any updates thereto. FixDen does not guarantee that
          the Services, or any portion thereof, will function on any particular
          hardware or devices. In addition, the Services may be subject to
          malfunctions and delays inherent in the use of the Internet and
          electronic communications.
        </Typography>
        <Typography style={styles.heading}>PAYMENT</Typography>
        <Typography style={styles.text}>
          You understand that you may enter into agreements including, without
          limitation, services agreements directly with Third Party Providers (a
          “Third Party Agreement”) to which FixDen is not a party. You further
          understand that use of the Services may result in charges to you for
          the services or goods you receive from a Third Party Provider under a
          Third Party Agreement (“Charges”). After you have received services or
          goods obtained through your use of the Service pursuant to a Third
          Party Agreement, FixDen will facilitate your payment of the applicable
          Charges on behalf of the Third Party Provider as such Third Party
          Provider’s limited payment collection agent. Payment of the Charges in
          such manner shall be considered the same as payment made directly by
          you to the Third Party Provider. Charges will be inclusive of
          applicable taxes where required by law. Charges paid by you are final
          and non-refundable, unless otherwise determined by FixDen. You retain
          the right to request lower Charges from a Third Party Provider for
          services or goods received by you from such Third Party Provider at
          the time you receive such services or goods. FixDen will respond
          accordingly to any request from a Third Party Pr
        </Typography>
        <Typography style={styles.text}>
          All Charges are due immediately at the time of entering into a Service
          Agreement with a Third Party Service Provider and payment will be
          facilitated by FixDen to the Service Provider, less transaction fees
          due to Steady Innovation Corporation, using the preferred payment
          method designated in your Account, after which FixDen will send you a
          receipt by email. If your primary Account payment method is determined
          to be expired, invalid or otherwise not able to be charged, you agree
          that FixDen may, as the Third Party Provider’s limited payment
          collection agent, use a secondary payment method in your Account, if
          available.
        </Typography>
        <Typography style={styles.text}>
          As between you and FixDen, FixDen reserves the right to establish,
          remove and/or revise Charges for any or all services or goods obtained
          through the use of the Services at any time in FixDen’s sole
          discretion. Further, you acknowledge and agree that Charges applicable
          in certain geographical areas may increase substantially during times
          of high demand. FixDen will use reasonable efforts to inform you of
          Charges that may apply, provided that you will be responsible for
          Charges incurred under your Account regardless of your awareness of
          such Charges or the amounts thereof. FixDen may from time to time
          provide certain users with promotional offers and discounts that may
          result in different amounts charged for the same or similar services
          or goods obtained through the use of the Services, and you agree that
          such promotional offers and discounts, unless also made available to
          you, shall have no bearing on your use of the Services or the Charges
          applied to you. You may elect to cancel your request for services or
          goods from a Third Party Provider at any time prior to such Third
          Party Provider’s arrival or the commencement of the provision of
          service by that Third Party Provider, in which case you may be charged
          a cancellation fee.
        </Typography>
        <Typography style={styles.text}>
          This payment structure is intended to fully compensate the Third Party
          Provider for the services or goods provided. FixDen does not designate
          any portion of your payment as a tip or gratuity to the Third Party
          Provider. Any representation by FixDen (on FixDen’s website, in the
          Application, or in FixDen’s marketing materials) to the effect that
          tipping is “voluntary,” “not required,” and/or “included” in the
          payments you make for services or goods provided is not intended to
          suggest that FixDen provides any additional amounts, beyond those
          described above, to the Third Party Provider. You understand and agree
          that, while you are free to provide additional payment as a gratuity
          to any Third Party Provider who provides you with services or goods
          obtained through the Service, you are under no obligation to do so.
          Gratuities are voluntary. After you have received services or goods
          obtained through the Service, you will have the opportunity to rate
          your experience and leave additional feedback about your Third Party
          Provider.
        </Typography>
        <Typography style={styles.heading}>Repair or Cleaning Fees</Typography>{" "}
        <Typography style={styles.text}>
          Subject always to the terms of the Third Party Agreement, you shall be
          responsible for the cost of repair for damage to, or necessary
          cleaning of, homeowner or Third Party Provider property of any nature
          whatsoever, including without limitation real and personal property,
          resulting from use of the Services under your Account in excess of
          normal “wear and tear” damages and necessary cleaning (“Repair or
          Cleaning”). In the event that a Third Party Provider reports the need
          for Repair or Cleaning, and such Repair or Cleaning request is
          verified by FixDen in FixDen’s reasonable discretion, FixDen reserves
          the right to facilitate payment for the reasonable cost of such Repair
          or Cleaning on behalf of the Third Party Provider using your payment
          method designated in your Account. Such amounts will be transferred by
          FixDen to the applicable Third Party Provider and are non-refundable.
          For greater certainty, in the event that this provision relating to
          Repair or Cleaning fees conflicts with a Third Party Agreement, the
          terms of the Third Party Agreement shall prevail.
        </Typography>
        <Typography style={styles.heading}>
          5. LIMITATION OF LIABILITY, DISCLAIMERS AND INDEMNIFICATION
        </Typography>
        <Typography style={styles.heading}>DISCLAIMER</Typography>
        <Typography style={styles.text}>
          THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” FIXDEN DISCLAIMS
          ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, NOT
          EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT. IN ADDITION, FIXDEN MAKES NO REPRESENTATION,
          WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY,
          SUITABILITY OR AVAILABILITY OF THE SERVICES OR ANY SERVICES OR GOODS
          REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE SERVICES WILL
          BE UNINTERRUPTED OR ERROR-FREE. FIXDEN DOES NOT GUARANTEE THE QUALITY,
          SUITABILITY, SAFETY OR ABILITY OF THIRD PARTY PROVIDERS. YOU AGREE
          THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY
          SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH
          YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
        </Typography>
        <Typography></Typography>
        <Typography style={styles.heading}>LIMITATION OF LIABILITY</Typography>
        <Typography style={styles.text}>
          FIXDEN SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL,
          EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS,
          LOST DATA, PERSONAL INJURY OR PROPERTY DAMAGE RELATED TO, IN
          CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES,
          EVEN IF FIXDEN HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          FIXDEN SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES
          ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR
          INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR
          RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER, EVEN IF FIXDEN
          HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. FIXDEN SHALL NOT
          BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES
          BEYOND FIXDEN’S REASONABLE CONTROL. YOU ACKNOWLEDGE THAT THIRD PARTY
          PROVIDERS PROVIDING PROPERY MANAGEMENT OR HOME REPAIR AND RENOVATION
          SERVICES REQUESTED THROUGH THE SERVICES MAY NOT BE PROFESSIONALLY
          LICENSED OR PERMITTED. IN NO EVENT SHALL FIXDEN’S TOTAL LIABILITY TO
          YOU IN CONNECTION WITH THE SERVICES FOR ALL DAMAGES, LOSSES AND CAUSES
          OF ACTION EXCEED FIVE HUNDRED CANADIAN DOLLARS ($500).
        </Typography>
        <Typography style={styles.text}>
          FIXDEN’S SERVICES MAY BE USED BY YOU TO REQUEST AND ARRANGE HOME
          REPAIR AND RENOVATION SERVICES WITH THIRD PARTY PROVIDERS, BUT YOU
          AGREE THAT FIXDEN HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO
          ANY GOODS OR SERVICES PROVIDED TO YOU BY THIRD PARTY PROVIDERS OTHER
          THAN AS EXPRESSLY SET FORTH IN THESE TERMS.
        </Typography>
        <Typography style={styles.text}>
          {" "}
          THE LIMITATIONS AND DISCLAIMER IN THIS SECTION 5 DO NOT PURPORT TO
          LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE
          EXCLUDED UNDER APPLICABLE LAW.
        </Typography>{" "}
        <Typography style={styles.heading}>Indemnity</Typography>
        <Typography style={styles.text}>
          You agree to indemnify and hold FixDen and its officers, directors,
          employees and agents harmless from any and all claims, demands,
          losses, liabilities, and expenses (including attorneys’ fees) arising
          out of or in connection with: (i) your use of the Services or services
          or goods obtained through your use of the Services; (ii) your breach
          or violation of any of these Terms; (iii) FixDen’s use of your User
          Content; or (iv) your violation of the rights of any third party,
          including Third Party Providers.
        </Typography>
        <Typography style={styles.heading}>
          6. GOVERNING LAW AND ARBITRATION
        </Typography>
        <Typography style={styles.text}>
          Except as otherwise set forth in these Terms, these Terms shall be
          exclusively governed by and construed in accordance with the laws of
          the Province of Newfoundland and Labrador and the federal laws of
          Canada applicable therein, excluding its rules on conflicts of laws.
          The United Nations Convention on Contracts for the International Sale
          of Goods and the International Sale of Goods Act, RSNL 1990, c I-16
          shall not apply. Any dispute, conflict, claim or controversy arising
          out of or broadly in connection with or relating to the Services or
          these Terms, including those relating to its validity, its
          construction or its enforceability (any “Dispute”) shall be
          mandatorily submitted to binding arbitration pursuant to the
          Arbitration Act,RSNL 1990, c A-14.
        </Typography>
        <Typography style={styles.heading}>7. OTHER</Typography>
        <Typography style={styles.heading}>Copyright Infringement</Typography>
        <Typography style={styles.text}>
          FixDen respects the intellectual property of others. If you believe in
          good faith that any Content on the Services infringes upon your
          copyrights, please send the following to us at{" "}
          <a href="mailto: info@FixDen.com">info@FixDen.com</a>&nbsp;
        </Typography>
        <Typography style={styles.text}>
          {" "}
          (i) identification of the copyrighted work(s) claimed to have been
          infringed;
        </Typography>
        <Typography style={styles.text}>
          (ii) identification of the material that is claimed to be infringing
          or to be the subject of infringing activity and that is to be removed
          or access to which is to be disabled, and information reasonably
          sufficient to permit us to locate the material;
        </Typography>
        <Typography style={styles.text}>
          (iii) your mailing address, telephone number and email address;
        </Typography>
        <Typography style={styles.text}>
          (iv) a statement by you that you have a good faith belief that use of
          the material in the manner complained of is not authorized by the
          copyright owner, its agent, or the law;
        </Typography>{" "}
        <Typography style={styles.text}>
          (v) a statement by you, made under penalty of perjury, that the
          information in your notification is accurate, and that you are the
          copyright owner or are authorized to act on the copyright owner’s
          behalf; and
        </Typography>
        <Typography style={styles.text}>
          (vi) a physical or electronic signature of the copyright owner or the
          person authorized to act on behalf of the owner of the copyright
          interest.
        </Typography>
        <Typography style={styles.heading}>Notice</Typography>
        <Typography style={styles.text}>
          FixDen may give notice by means of a general notice on the Services,
          electronic mail to your email address in your Account, or by written
          communication sent to your address as set forth in your Account. You
          may give notice to FixDen by written communication to FixDen’s address
          at:
        </Typography>{" "}
        <Typography style={styles.title}>
          Steady Innovation Corporation
        </Typography>
        <Typography style={styles.title}>3-86 West Street</Typography>
        <Typography style={styles.title}>Corner Brook</Typography>
        <Typography style={styles.title}>
          Newfoundland & Labrador
        </Typography>{" "}
        <Typography style={styles.title}>Canada</Typography>
        <Typography style={styles.title}>A2H 2Z3</Typography>
        <Typography style={styles.heading}>General</Typography>
        <Typography style={styles.text}>
          You may not assign or transfer these Terms in whole or in part without
          FixDen’s prior written approval. You give your approval to FixDen for
          it to assign or transfer these Terms in whole or in part, including
          to: (i) a subsidiary or affiliate; (ii) an acquirer of FixDen’s
          equity, business or assets; or (iii) a successor by merger. No joint
          venture, partnership, employment or agency relationship exists between
          you, FixDen or any Third Party Provider as a result of the contract
          between you and FixDen or use of the Services.No failure or delay to
          exercise any rights under these Terms will operate as a waiver of such
          rights.
        </Typography>
        <Typography style={styles.text}>
          If any provision of these Terms is held to be illegal, invalid or
          unenforceable, in whole or in part, under any law, such provision or
          part thereof shall to that extent be deemed not to form part of these
          Terms but the legality, validity and enforceability of the other
          provisions in these Terms shall not be affected. In that event, the
          parties shall replace the illegal, invalid or unenforceable provision
          or part thereof with a provision or part thereof that is legal, valid
          and enforceable and that has, to the greatest extent possible, a
          similar effect as the illegal, invalid or unenforceable provision or
          part thereof, given the contents and purpose of these Terms. These
          Terms constitute the entire agreement and understanding of the parties
          with respect to its subject matter and replaces and supersedes all
          prior or contemporaneous agreements or undertakings regarding such
          subject matter. In these Terms, the words “including” and “include”
          mean “including, but not limited to.”
        </Typography>
        <Typography style={styles.signText}>{sign}</Typography>
        <Typography style={styles.text}>Client digital signature</Typography>
        {!props.signIn && (
          <Typography
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        )}
      </div>
    </Grid>
  );
};

export default TermsViewer;
