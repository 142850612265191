import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SaveCardForm from "./SaveCardForm";
import { useEffect, useState } from "react";
import axios from "axios";

// const stripePublicKey =
//   "pk_test_51N0NiJGAYHWSff8NcRXeDvgfztWRayxwvDvHgnegaOYxxQlKCJr9qUgs6EEJbJhk8N8iuqnU5UNJOv67PsFnGQYp00Fdy1AE7i";

// const stripePromise = loadStripe(stripePublicKey);

const NewPaymentMethod = ({ clientSecret, onSuccess, successUrl }) => {
  const [options, setOptions] = useState(false);
  const [stripePromise, setStripePromise] = useState(false);

  useEffect(() => {
    axios.get("/api/config").then((res) => {
      const publishableKey = res.data.publishableKey;
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    if (clientSecret) {
      setOptions({
        clientSecret: `${clientSecret}`,
        appearance: {
          /*...*/
        }
      });
    } else {
      axios.get("/stripe/secret/setupIntent").then(({ data }) => {
        setOptions({
          // passing the client secret obtained in step 3
          clientSecret: data.clientSecret,
          // Fully customizable with appearance API.
          appearance: {
            /*...*/
          }
        });
      });
    }
  }, [clientSecret]);
  return (
    <>
      {options !== false && stripePromise !== false && (
        <Elements stripe={stripePromise} options={options}>
          <SaveCardForm onSuccess={onSuccess} successUrl={successUrl} />
        </Elements>
      )}
    </>
  );
};

NewPaymentMethod.defaultProps = {
  clientSecret: false,
  onSuccess: false,
  successUrl: window.location.origin + "/dashboard/profile"
};

export default NewPaymentMethod;
