const Notification = (props) => {
  return (
    <div className="notifications">
      <div>
        {props.notifications.length === 0 ? null : (
          <span className="notification-count">
            <span>{props.notifications.length}</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default Notification;
