import { Grid, Stack, InputLabel, Button } from "@mui/material";
import { useUserState } from "../../../../Context/UserContext";
import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";
import ScopeTable from "../Scope/ScopeTable";

const BidDetails = ({
  bidDetails,
  acceptBid,
  rejectBid,
  bidIndex,
  setBidIndex,
  chargePercentages
  // openAgreement,
}) => {
  const { user } = useUserState();
  console.log(bidDetails);
  return (
    <Grid container spacing={1}>
      {bidDetails.is_accepted === 1 && bidDetails.is_closed === 0 && (
        <Grid item xs={12} md={12} margin={"0"}>
          <Stack direction={"row"} spacing={0}>
            <h2 style={{ fontWeight: "700" }}>
              {bidDetails.vendor_legal_name}
            </h2>
            <h2 style={{ fontWeight: "600", color: "Green" }}>
              &nbsp;(Bid Accepted)
            </h2>
          </Stack>
        </Grid>
      )}

      {bidDetails.is_accepted === 0 && bidDetails.is_closed === 1 && (
        <Grid item xs={12} md={12} margin={"0"}>
          <Stack direction={"row"} spacing={0}>
            <h2 style={{ fontWeight: "700" }}>
              {bidDetails.vendor_legal_name}
            </h2>
            <h2 style={{ fontWeight: "600", color: "Red" }}>(Bid Closed)</h2>
          </Stack>
        </Grid>
      )}

      <Grid item xs={12} marginTop="1em">
        <Stack spacing={1}>
          <InputLabel
            style={{
              color: "black",
              fontSize: "0.85rem",
              fontWeight: "600"
            }}
          >
            Work need to be done:
          </InputLabel>
          {bidDetails.work_to_be_done}
        </Stack>
        <hr style={{ marginTop: "1em" }} />
      </Grid>
      {!user.vendorMode ? (
        <Grid item md={12} xs={12}>
          <p
            style={{
              color: "black",
              fontSize: "0.85rem",
              fontWeight: "600"
            }}
          >
            Estimated Charges:
          </p>
          <hr />
        </Grid>
      ) : (
        ""
      )}

      <Grid item xs={12} marginTop="0.25em">
        <ScopeTable
          lines={bidDetails.bidLines}
          amount={bidDetails.bid_amount}
          chargePercentages={chargePercentages}
        />
        <hr style={{ marginTop: "1em" }} />
      </Grid>

      <Grid item xs={12} md={12}>
        <Stack spacing={1}>
          <InputLabel
            style={{
              color: "black",
              fontSize: "0.85rem",
              fontWeight: "600"
            }}
          >
            Extra Notes:
          </InputLabel>
          {bidDetails.notes}
        </Stack>
        {/* <hr /> */}
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "right",
          alignContent: "right"
        }}
      >
        {!user.vendorMode &&
          bidDetails.is_accepted === 0 &&
          bidDetails.is_closed === 0 && (
            <div
              style={{
                display: "flex",
                width: "max-content",
                flexDirection: "row",
                gap: "2rem"
              }}
            >
              <AnimateButton>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setBidIndex(bidIndex);
                    acceptBid();
                  }}
                  size="sm"
                  color="primary"
                  variant="contained"
                >
                  Accept
                </Button>
              </AnimateButton>
              <AnimateButton>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setBidIndex(bidIndex);
                    rejectBid();
                  }}
                  size="sm"
                  color="error"
                  variant="outlined"
                >
                  Reject
                </Button>
              </AnimateButton>
            </div>
          )}
      </Grid>
    </Grid>
  );
};

const emptyFunc = () => {
  return;
};

BidDetails.defaultProps = {
  // openAgreement: emptyFunc,
  acceptBid: emptyFunc,
  rejectBid: emptyFunc,
  onClose: emptyFunc,
  setBidIndex: (bidIndex) => {
    return;
  },
  chargePercentages: {
    clientPercentage: 0,
    vendorPercentage: 0
  }
};

export default BidDetails;
