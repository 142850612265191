import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import BarCalendar from "../../../../Components/BarCalendar";
import axios from "axios";
import { Button } from "@mui/material";
const source = axios.CancelToken.source();

const SetEmployeeAvailability = (props) => {
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const onSubmit = (availabilityList) => {
    console.log(availabilityList);
    console.log(props.currentEmployee);
    const JSONAvailabilityList = JSON.stringify(availabilityList);
    axios.post("/employee/updateEmployeeAvailability", {
      availabilityList: JSONAvailabilityList,
      employeeID: props.currentEmployee.employee_id,
    });
    console.log("submit!");
    props.fetchCurrentEmployeeData();
    setScheduleModalOpen(false);
  };

  const openScheduleModal = () => {
    setScheduleModalOpen(!scheduleModalOpen);
  };
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={openScheduleModal}
      >
        {props.adminOrManager ? "Set Admin Availability" : "Set Availability"}
      </Button>
      <Modal
        title="Schedule Availability"
        open={scheduleModalOpen}
        onCancel={() => setScheduleModalOpen(false)}
        destroyOnClose={true}
        width={"800px"}
        footer={false}
      >
        <BarCalendar
          availabilityArray={props.availabilityArray}
          onSubmit={onSubmit}
          jrData={props.jrData}
          editable={true}
        />
      </Modal>
    </>
  );
};

export default SetEmployeeAvailability;
