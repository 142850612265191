import { useUserState } from "../../../Context/UserContext";

import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import "./chat.css";
import { Avatar, Tooltip, Badge } from "antd";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";

const ChatListing = (props) => {
  const { user } = useUserState();
  const [chatOpen, setChatOpen] = useState(false);
  console.log(props.notifications);

  const thisUserNotifications = props.notifications?.filter((n) => {
    return n.conversation_id === props.chatList?.conversation_id;
  });
  console.log(props.notifications.length);
  console.log(thisUserNotifications);
  const updateMsgToRead = (chatId) => {
    axios
      .post("/communication/updateMsgToRead", {
        ChatId: chatId
      })
      .then(() => {});
  };

  const handleChat = (chatId) => {
    updateMsgToRead(chatId);
    setChatOpen(true);
  };

  const truncateText = (text) => {
    let shortText = text.substring(0, 20);
    if (text.length > 20) {
      shortText = shortText + ".....";
    }
    return shortText;
  };
  const getTime = (time) => {
    let dateTime = moment(new Date(time));
    let textTime = dateTime.format("h:mma");
    let textDate = dateTime.format("MMM D");
    let textDay = dateTime.format("ddd");
    let textYearWithDate = dateTime.format("MMM D, YYYY");
    let hours = moment().diff(dateTime, "hours");
    if (hours < 24) return textTime;
    let days = moment().diff(dateTime, "days");
    if (days < 7) return textDay;
    else if (days >= 7 && days < 365) return textDate;
    else return textYearWithDate;
  };

  let recentUserIndex = props.chatList.chattingPeople.findIndex(
    (chatPerson) => {
      return chatPerson.user_id === props.chatList.recent_msg_user_id;
    }
  );

  console.log(user.vendorMode);
  const mobileView = useMediaQuery("(max-width: 600px)");

  return (
    <li
      className="m-auto"
      onClick={() => {
        props.setChatID(props.chatList.conversation_id);
        handleChat(props.chatList.conversation_id);

        mobileView && props.setChatting(true);
      }}
    >
      <Link
        className="threadListing activethread"
        to={`/dashboard/chat/${props.chatList.conversation_id}`}
      >
        <div className="threadLeft">
          <Avatar.Group
            size="small"
            maxCount={1}
            maxStyle={{ backgroundColor: "#4d7cfe", color: "#ffffff" }}
          >
            {props.chatList.chattingPeople
              .filter((person) => person.user_id !== user.id)
              .map((person) => (
                <Tooltip title={person.name} key={person.user_id}>
                  <Badge
                    offset={["-5%", "85%"]}
                    style={{
                      width: "10px",
                      height: "10px",
                      boxShadow: "0 0 0 2px #fff",
                      backgroundColor: "rgb(0, 219, 0)"
                    }}
                    dot={props.onlineUsers?.some(
                      (user) => user.userId === person.user_id
                    )}
                  >
                    <Avatar
                      style={{ marginLeft: "0px", marginRight: "0px" }}
                      key={person.user_id}
                      src={person.imageurl}
                    />
                  </Badge>
                </Tooltip>
              ))}
          </Avatar.Group>
        </div>
        <div className="threadMain">
          <div className="caption threadName">
            {props.chatList.conversation_name.split(" ")[0]}
          </div>
          <div
            className={
              props.chatList.is_read === 0 &&
              props.chatList.recent_msg_user_id !== user.id
                ? "unreadTxt txtMsg b-text-sm"
                : "g-text-sm txtMsg"
            }
          >
            {props.chatList.message
              ? `${
                  recentUserIndex >= 0
                    ? props.chatList.recent_msg_user_id === user.id
                      ? "You"
                      : props.chatList.chattingPeople[
                          recentUserIndex
                        ].name.split(" ")[0]
                    : "Missing User"
                }: ${truncateText(props.chatList.message)}`
              : "No messages."}
          </div>
        </div>
        <div>
          <Badge
            count={
              thisUserNotifications?.length > 0
                ? thisUserNotifications?.length
                : ""
            }
            style={{ backgroundColor: "#6384EB" }}
          />
        </div>

        <div className="threadRight g-text-sm">
          {getTime(
            props.chatList.time
              ? props.chatList.time
              : props.chatList.create_date
          )}
        </div>
      </Link>
    </li>
  );
};

export default ChatListing;
