import { Link } from "react-router-dom";

import { Result } from "antd";

import { Button } from "@mui/material";

import AuthWrapper from "./AuthWrapper";

const VerificationPage = () => {
  return (
    <>
      <AuthWrapper>
        <Result
          className="flex-60 padding0"
          status="success"
          title={<h4>Your account is verified. You can Login now. </h4>}
          extra={[
            <Button variant="contained" type="primary">
              <Link to="/login">Login</Link>
            </Button>
          ]}
        />
      </AuthWrapper>
    </>
  );
};

export default VerificationPage;
