import React, { useState, useEffect, useCallback } from "react";
import "../../CSS/styles.css";
import axios from "axios";
import {
  List,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SpaIcon from "@mui/icons-material/Spa";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import {
  CalendarTwoTone,
  FilePdfTwoTone,
  MessageTwoTone,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

// icons
import access from "../../../../images/Icons/access&Security.png";
import applianceRepair from "../../../../images/Icons/applianceRepair.png";
import brickLayer from "../../../../images/Icons/brickLayer.png";
import buildingSupplies from "../../../../images/Icons/buildingSupplies.png";
import cabling from "../../../../images/Icons/cabling&Data.png";
import carpenter from "../../../../images/Icons/carpenter.png";
import electrical from "../../../../images/Icons/Electrical.png";
import environmental from "../../../../images/Icons/environmental.png";
import fireProtection from "../../../../images/Icons/fireProtection.png";
import flooring from "../../../../images/Icons/flooring.png";
import gasFitter from "../../../../images/Icons/gasFitter.png";
import generalRepair from "../../../../images/Icons/generalRepair.png";
import glassWholesale from "../../../../images/Icons/glassWholesale.png";
import heating from "../../../../images/Icons/heating.png";
import homeInspector from "../../../../images/Icons/homeInspector.png";
import hvac from "../../../../images/Icons/HVAC.png";
import landScaping from "../../../../images/Icons/landScaping.png";
import locksmith from "../../../../images/Icons/locksmith.png";
import miscellaneousSupplies from "../../../../images/Icons/miscellaneousSupplies.png";
import oilBurnerMechanic from "../../../../images/Icons/oilBurnerMechanic.png";
import other from "../../../../images/Icons/other.png";
import pestControl from "../../../../images/Icons/pestControl.png";
import plasterPaint from "../../../../images/Icons/plaster&PaintServices.png";
import plumbing from "../../../../images/Icons/plumbing.png";
import propaneServices from "../../../../images/Icons/propaneServices.png";
import propertyManagement from "../../../../images/Icons/propertyManagement.png";
import propertyRestoration from "../../../../images/Icons/propertyRestoration.png";
import refrigerationAC from "../../../../images/Icons/refrigeration&AC.png";
import roofing from "../../../../images/Icons/roofing.png";
import sheetMetalWork from "../../../../images/Icons/sheetMetalWork.png";
import snowClearing from "../../../../images/Icons/snowClearing.png";
import tileSetter from "../../../../images/Icons/tileSetter.png";
import ventilation from "../../../../images/Icons/ventilation.png";
import wasteManagement from "../../../../images/Icons/wasteManagement.png";
import windowsDoors from "../../../../images/Icons/windows&Doors.png";

import ExpandedTilePhase1 from "./ExpandedTilePhase1";
import ExpandedTilePhase2 from "./ExpandedTilePhase2";
import ExpandedTilePhase3 from "./ExpandedTilePhase3";

import moment from "moment-timezone";
import LoadScreen from "../../../../Components/LoadScreen";
import { useJRContext } from "../../../../Context/JobRequestContext";
import useOnce from "../../../../hooks/useOnce";
import { useTheme } from "@emotion/react";
import SubmitBidForm from "../../../MarketPage/MarketComponents/SubmitBidForm";
import { Modal } from "antd";
import ViewAvailability from "../Availability/ViewAvailability";
import { useUserState } from "../../../../Context/UserContext";

moment.tz.setDefault("Canada/Newfoundland");

function VendorExpandedTile(props) {
  const { user } = useUserState();
  const [reworkDetails, setReworkDetails] = useState();
  const [activityList, setActivityList] = useState([]);
  const [jrImages, setJrImages] = useState([]);
  const [vendorReview, setVendorReview] = useState([]);
  const [events, setEvents] = useState([]);
  const [troubleshootingImages, setTroubleshootingImages] = useState([]);
  const [troubleshootingList, setTroubleshootingList] = useState([]);
  const [actionImages, setActionImages] = useState([]);
  const [actionList, setActionList] = useState([]);
  const [bidDetails, setBidDetails] = useState(false);
  const [scopeDetails, setScopeDetails] = useState("");
  const [invoice, setInvoice] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [vendorInvoiceDetails, setVendorInvoiceDetails] = useState([]);
  const [clientInvoiceDetails, setClientInvoiceDetails] = useState([]);
  const { openJR } = useJRContext();

  const fetchData = useCallback(
    (page) => {
      // Check if data is already in the cache
      const cachedData = sessionStorage.getItem(
        `cachedJobData#${props.jrData.job_id}`
      );

      if (cachedData) {
        // If data is in the cache, parse and set the state
        if (typeof page !== "undefined") {
          console.log("PAGEUNDEFINED");
          const parsedData = JSON.parse(cachedData);

          setActivityList(parsedData.activities);
          setReworkDetails(parsedData.reworks);
          setVendorReview(parsedData.reviews);
          setEvents(parsedData.events);
          setTroubleshootingList(parsedData.troubleshootingList);
          setTroubleshootingImages(parsedData.troubleshootingImages);
          setActionList(parsedData.actionList);
          setActionImages(parsedData.actionImages);
          setJrImages(parsedData.jrImages);
          setBidDetails(parsedData.bidDetails);
          setScopeDetails(parsedData.scopeDetails);
          setInvoice(parsedData.invoice.invoiceDetails);
          setInvoiceDetails(parsedData.invoiceDetails.extraDetails);
          setVendorInvoiceDetails(parsedData.vendorInvoiceDetails);
          setClientInvoiceDetails(parsedData.clientInvoiceDetails);
        } else {
          console.log("PAGE DEFINED");
          axios
            .post("/jobRequest/getVendorExpandedDetails", {
              JobId: props.jrData.job_id,
              vendorId: props.jrData.assigned_vendor_id,
              clientId: props.jrData.requester_id,
            })
            .then((res) => {
              console.log(res.data.bidDetails, "BIDDETAILSSSSSHKHJHIHI");
              const activityData = res.data.activities;
              const reworks = res.data.reworks;
              const review = res.data.reviews;
              const events = res.data.events;
              const troubleshootingList = res.data.troubleshootList;
              const troubleshootingImages = res.data.troubleshootImages;
              const actions = res.data.actions;
              const actionImages = res.data.actionImages;
              const jrImages = res.data.jrImages;
              const bidDetails = res.data.bidDetails;
              const scopeDetails = res.data.scopeDetails;
              const invoice = res.data.invoiceDetails;
              const vendorInvoiceDetails = res.data.vendorInvoiceDetails;
              const clientInvoiceDetails = res.data.clientInvoiceDetails;

              // Set the state with the fetched data
              setActivityList(activityData);
              setReworkDetails(reworks);
              setVendorReview(review);
              setEvents(events);
              setTroubleshootingList(troubleshootingList);
              setTroubleshootingImages(troubleshootingImages);
              setActionList(actions);
              setActionImages(actionImages);
              setJrImages(jrImages);
              setBidDetails(bidDetails);
              setScopeDetails(scopeDetails);
              setInvoice(
                invoice.invoiceDetails.length > 0
                  ? invoice.invoiceDetails[0]
                  : invoice.invoiceDetails
              );
              setInvoiceDetails(invoice.extraDetails);
              setVendorInvoiceDetails(vendorInvoiceDetails);
              setClientInvoiceDetails(clientInvoiceDetails);

              // Cache the data in localStorage for future use
              const dataToCache = {
                activities: activityData,
                reworks: reworks,
                reviews: review,
                events: events,
                troubleshootingList: troubleshootingList,
                troubleshootingImages: troubleshootingImages,
                actionList: actions,
                actionImages: actionImages,
                jrImages: jrImages,
                bidDetails: bidDetails,
                scopeDetails: scopeDetails,
                invoice: invoice,
                invoiceDetails: invoiceDetails,
                vendorInvoiceDetails: vendorInvoiceDetails,
                clientInvoiceDetails: clientInvoiceDetails,
              };
              console.log(dataToCache, "CACHEEEEEEEEEEEEEEEEEEE");
              sessionStorage.setItem(
                `cachedJobData#${props.jrData.job_id}`,
                JSON.stringify(dataToCache)
              );
            });
        }
      } else {
        // If data is not in the cache, make a server call
        console.log(props.jrData, "JRDATAAAAAAAAAAAAAAAA");
        axios
          .post("/jobRequest/getVendorExpandedDetails", {
            JobId: props.jrData.job_id,
            vendorId: props.jrData.assigned_vendor_id,
            clientId: props.jrData.requester_id,
          })
          .then((res) => {
            console.log(res.data.bidDetails, "BIDDETAILSSSSSHKHJHIHI");
            const activityData = res.data.activities;
            const reworks = res.data.reworks;
            const review = res.data.reviews;
            const events = res.data.events;
            const troubleshootingList = res.data.troubleshootList;
            const troubleshootingImages = res.data.troubleshootImages;
            const actions = res.data.actions;
            const actionImages = res.data.actionImages;
            const jrImages = res.data.jrImages;
            const bidDetails = res.data.bidDetails;
            const scopeDetails = res.data.scopeDetails;
            const invoice = res.data.invoiceDetails;
            console.log("Vendor Tile invoice", invoice);
            const vendorInvoiceDetails = res.data.vendorInvoiceDetails;
            const clientInvoiceDetails = res.data.clientInvoiceDetails;

            // Set the state with the fetched data
            setActivityList(activityData);
            setReworkDetails(reworks);
            setVendorReview(review);
            setEvents(events);
            setTroubleshootingList(troubleshootingList);
            setTroubleshootingImages(troubleshootingImages);
            setActionList(actions);
            setActionImages(actionImages);
            setJrImages(jrImages);
            setBidDetails(bidDetails);
            setScopeDetails(scopeDetails);
            setInvoice(
              invoice.invoiceDetails.length > 0
                ? invoice.invoiceDetails[0]
                : invoice.invoiceDetails
            );
            setInvoiceDetails(invoice.extraDetails);
            setVendorInvoiceDetails(vendorInvoiceDetails);
            setClientInvoiceDetails(clientInvoiceDetails);

            // Cache the data in localStorage for future use
            const dataToCache = {
              activities: activityData,
              reworks: reworks,
              reviews: review,
              events: events,
              troubleshootingList: troubleshootingList,
              troubleshootingImages: troubleshootingImages,
              actionList: actions,
              actionImages: actionImages,
              jrImages: jrImages,
              bidDetails: bidDetails,
              scopeDetails: scopeDetails,
              invoice: invoice,
              invoiceDetails: invoiceDetails,
              vendorInvoiceDetails: vendorInvoiceDetails,
              clientInvoiceDetails: clientInvoiceDetails,
            };
            console.log(dataToCache, "CACHEEEEEEEEEEEEEEEEEEE");
            sessionStorage.setItem(
              `cachedJobData#${props.jrData.job_id}`,
              JSON.stringify(dataToCache)
            );
          });
      }
    },
    [props.jrData]
  );

  useOnce(() => {
    fetchData("TILE");
  });
  console.log(scopeDetails);
  let jrStatus = props.jrData.job_status;

  if (jrStatus === "Bidding") {
    console.log(activityList, "activitylistgmjkdbvhjdfbi");
    return props.jrData.job_description !== "" ? (
      <>
        <ExpandedTilePhase1
          fromChat={props.fromChat}
          bidDetails={bidDetails}
          scopeDetails={scopeDetails}
          setScopeDetails={setScopeDetails}
          activityList={activityList}
          fetchJrData={props.fetchJrData}
          jrImages={jrImages}
          setShowSubmitForm={props.setShowSubmitForm}
          jrData={props.jrData}
          fetchData={fetchData}
          chargePercentages={props.chargePercentages}
          setAgreementOpen={props.setAgreementOpen}
        />
        <Modal
          destroyOnClose={true}
          width={user.vetted === 1 ? "700px" : "400px"}
          title={user.vetted === 1 ? "Submitting Bid" : "Vetting Pending"}
          open={props.showSubmitForm}
          onCancel={() => props.setShowSubmitForm(false)}
          footer={false}
        >
          {props.showSubmitForm &&
            (user.vetted === 1 ? (
              <SubmitBidForm
                chargePercentages={props.chargePercentages}
                openJR={openJR}
                currentJRs={props.currentJRs}
                fetchJrData={props.fetchJrData}
                fetchData={fetchData}
                id={openJR.job_id}
                clientID={openJR.client_id}
                onClose={() => props.setShowSubmitForm(false)}
              />
            ) : (
              "Vendor Vetting in progress. Please hold Off on Bidding."
            ))}
        </Modal>
      </>
    ) : (
      <LoadScreen />
    );
  } else if (
    jrStatus === "Scheduling" ||
    jrStatus === "Completing" ||
    jrStatus === "Reviewing"
  ) {
    return activityList?.length > 0 ? (
      <>
        <ExpandedTilePhase1
          fromChat={props.fromChat}
          bidDetails={bidDetails}
          scopeDetails={scopeDetails}
          setScopeDetails={setScopeDetails}
          activityList={activityList}
          fetchJrData={props.fetchJrData}
          jrImages={jrImages}
          setShowSubmitForm={props.setShowSubmitForm}
          jrData={props.jrData}
          fetchData={fetchData}
          chargePercentages={props.chargePercentages}
          setAgreementOpen={props.setAgreementOpen}
        />
        <ExpandedTilePhase2
          fetchData={fetchData}
          scopeDetails={scopeDetails}
          setScopeDetails={setScopeDetails}
          jrDetails={props.jrDetails}
          activityList={activityList}
          reworkDetails={reworkDetails}
          vendorReview={vendorReview}
          fetchJrData={props.fetchJrData}
          setShowSubmitForm={props.setShowSubmitForm}
          jrData={props.jrData}
          events={events}
          troubleshootingImages={troubleshootingImages}
          troubleshootingList={troubleshootingList}
          actionList={actionList}
          actionImages={actionImages}
          invoiceDetails={invoiceDetails}
          invoice={invoice}
          vendorInvoiceDetails={vendorInvoiceDetails}
          clientInvoiceDetails={clientInvoiceDetails}
        />
      </>
    ) : (
      <LoadScreen />
    );
  } else if (jrStatus === "Invoicing" || jrStatus === "Finalizing") {
    return activityList.length > 0 ? (
      <>
        <ExpandedTilePhase1
          fromChat={props.fromChat}
          bidDetails={bidDetails}
          scopeDetails={scopeDetails}
          setScopeDetails={setScopeDetails}
          activityList={activityList}
          fetchJrData={props.fetchJrData}
          jrImages={jrImages}
          setShowSubmitForm={props.setShowSubmitForm}
          jrData={props.jrData}
          fetchData={fetchData}
          setAgreementOpen={props.setAgreementOpen}
        />
        <ExpandedTilePhase2
          fetchData={fetchData}
          scopeDetails={scopeDetails}
          setScopeDetails={setScopeDetails}
          reworkDetails={reworkDetails}
          activityList={activityList}
          fetchJrData={props.fetchJrData}
          setShowSubmitForm={props.setShowSubmitForm}
          jrData={props.jrData}
          vendorReview={vendorReview}
          events={events}
          troubleshootingImages={troubleshootingImages}
          troubleshootingList={troubleshootingList}
          actionList={actionList}
          actionImages={actionImages}
          invoiceDetails={invoiceDetails}
          invoice={invoice}
          vendorInvoiceDetails={vendorInvoiceDetails}
          clientInvoiceDetails={clientInvoiceDetails}
        />
        <ExpandedTilePhase3
          fetchData={fetchData}
          invoice={invoice}
          invoiceDetails={invoiceDetails}
          activityList={activityList}
          fetchJrData={props.fetchJrData}
          setShowSubmitForm={props.setShowSubmitForm}
          jrData={props.jrData}
          vendorInvoiceDetails={vendorInvoiceDetails}
          clientInvoiceDetails={clientInvoiceDetails}
        />
      </>
    ) : (
      <LoadScreen />
    );
  }
}

export function VendorTile(props) {
  let navigate = useNavigate();
  const user = useUserState();
  const request_time = new Date(props.jrData.job_request_time);
  const { openJR, setOpenJR } = useJRContext();
  const [clicked, setClicked] = useState(false);
  const [color, setColor] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [showSubmitForm, setShowSubmitForm] = useState(false);
  const [showAvailability, setShowAvailability] = useState(false);
  const [availabilities, setAvailabilities] = useState([]);

  // time difference
  const passTime = (time) => {
    time = moment(time);
    let days = moment().diff(time, "days");
    if (days > 0) return days + "d";

    let hours = moment().diff(time, "hours");
    if (hours > 0) return hours + "h";

    let minutes = moment().diff(time, "minutes");
    return minutes + "m";
  };

  const ref = React.useRef(null);

  useEffect(() => {
    const shouldExpand = props.jrData.job_id === openJR?.job_id;
    setIsExpanded(shouldExpand);
    shouldExpand ? setColor("#dddddd") : setColor("white");
  }, [openJR, props.jrData.job_id]);

  const openAvailability = (e) => {
    e.stopPropagation();
    setShowAvailability(!showAvailability);
  };
  useEffect(() => {
    // getAvailability();
  }, []);

  const newChat = (e) => {
    e.stopPropagation(); // Stop event propagation
    console.log(props.jrData);
    axios
      .post("/communication/createConversation", {
        UserId: props.jrData.client_id,
        JobId: props.jrData.job_id,
        ClientName: props.jrData.clientName,
      })
      .then((res) => {
        navigate(`/dashboard/chat/${res.data.chatId}`);
      });
  };

  let tileIcon;
  switch (props.jrData.job_category) {
    case "Access & Security":
      tileIcon = access;
      break;
    case "Appliance Repair":
      tileIcon = applianceRepair;
      break;
    case "Brick Layer":
      tileIcon = brickLayer;
      break;
    case "Building Supplies":
      tileIcon = buildingSupplies;
      break;
    case "Cabling & Data":
      tileIcon = cabling;
      break;
    case "Carpenter":
      tileIcon = carpenter;
      break;
    case "Electrical":
      tileIcon = electrical;
      break;
    case "Environmental":
      tileIcon = environmental;
      break;
    case "Fire Protection":
      tileIcon = fireProtection;
      break;
    case "Flooring":
      tileIcon = flooring;
      break;
    case "Gas Fitter":
      tileIcon = gasFitter;
      break;
    case "General Repair":
      tileIcon = generalRepair;
      break;
    case "Glass Wholesale":
      tileIcon = glassWholesale;
      break;
    case "Heating":
      tileIcon = heating;
      break;
    case "Home Inspector":
      tileIcon = homeInspector;
      break;
    case "HVAC":
      tileIcon = hvac;
      break;
    case "Landscaping":
      tileIcon = landScaping;
      break;
    case "Locksmith":
      tileIcon = locksmith;
      break;
    case "Miscellaneous Supply":
      tileIcon = miscellaneousSupplies;
      break;
    case "Oil Burner Mechanic":
      tileIcon = oilBurnerMechanic;
      break;
    case "Pest Control":
      tileIcon = pestControl;
      break;
    case "Plaster & Paint Services":
      tileIcon = plasterPaint;
      break;
    case "Plumbing":
      tileIcon = plumbing;
      break;
    case "Propane Services":
      tileIcon = propaneServices;
      break;
    case "Property Management":
      tileIcon = propertyManagement;
      break;
    case "Property Restoration":
      tileIcon = propertyRestoration;
      break;
    case "Refrigeration & AC":
      tileIcon = refrigerationAC;
      break;
    case "Roofing":
      tileIcon = roofing;
      break;
    case "Sheet Metal Worker":
      tileIcon = sheetMetalWork;
      break;
    case "Snow Clearing":
      tileIcon = snowClearing;
      break;
    case "Tile Setter":
      tileIcon = tileSetter;
      break;
    case "Ventilation":
      tileIcon = ventilation;
      break;
    case "Waste Management":
      tileIcon = wasteManagement;
      break;
    case "Windows & Doors":
      tileIcon = windowsDoors;
      break;
    case "Other":
    default:
      tileIcon = other;
      break;
  }
  const theme = useTheme();
  const address = props.jrData.address
    ? props.jrData.address.split(", ")
    : `${props.jrData.street}, ${props.jrData.city}, ${props.jrData.province} ${props.jrData.postal_code}`;
  const street = props.jrData.address
    ? address[0].split(" ")
    : props.jrData.street.split(" ");
  const city = props.jrData.address ? address[1] : props.jrData.city;
  const province = props.jrData.address
    ? address[2].split(" ")[0]
    : props.jrData.province;
  street.shift();
  const areaAddress = street.join(" ") + ", " + city + ", " + province;

  const greaterThanSmall = useMediaQuery(theme.breakpoints.up("lg"));
  const matches500 = useMediaQuery("(min-width: 600px)");
  const matches431 = useMediaQuery("(min-width: 431px)");

  return (
    <>
      {greaterThanSmall && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <div
            style={{ backgroundColor: color }}
            ref={ref}
            className={`tile`}
            onClick={() => {
              const id = props.jrData.job_id;
              setClicked(true);
              if (openJR === null || openJR.job_id !== id) {
                if (typeof props.setMapFocus === "function") {
                  props.setMapFocus(props.jrData.location);
                }
                setOpenJR(props.jrData);
              } else {
                setOpenJR(null);
              }
            }}
          >
            <Grid item md={3} justifyContent="center" alignItems="center">
              <Typography className="title">
                JR ID: {props.jrData.job_id}
              </Typography>

              <img
                style={{ height: "40%" }}
                id="icon"
                src={tileIcon}
                alt="icon"
              />
              <Typography className="title">
                {`${passTime(request_time)} ago`}
              </Typography>
            </Grid>
            <Grid item md={5} justifyContent="center" alignItems="center">
              <List>
                <Stack direction={"row"} spacing={1}>
                  <Typography className="title">Category:</Typography>
                  <Typography
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                  >
                    {props.jrData.job_category}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography className="title">Title:</Typography>
                  <Typography
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                  >
                    {props.jrData.job_title}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography className="title">Address:</Typography>
                  <Typography
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                  >
                    {props.jrData.assigned_vendor_id
                      ? props.jrData.address
                      : areaAddress}
                  </Typography>
                </Stack>
              </List>
            </Grid>
            <Grid item md={4} justifyContent="center" alignItems="center">
              <Stack
                direction={"row-reverse"}
                spacing={1}
                alignItems={"center"}
              >
                <Stack direction={"column"} spacing={1}>
                  {!props.fromChat && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation();
                        newChat(e);
                      }}
                    >
                      Chat
                    </Button>
                  )}

                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => openAvailability(e)}
                  >
                    View Availability
                  </Button>
                </Stack>
                <Stack>
                  <Stack direction={"row"} spacing={1}>
                    <Typography className="title">Status:</Typography>
                    {props.jrData.is_done === 1 ? (
                      <>
                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                          style={{ color: "green" }}
                        >
                          Completed
                        </Typography>
                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                        >
                          (<em>{props.jrData.job_status}</em>)
                        </Typography>
                      </>
                    ) : props.jrData.assigned_vendor_id !== null ? (
                      <>
                        <Typography style={{ color: "#debd02" }}>
                          Ongoing
                        </Typography>
                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                        >
                          (<em>{props.jrData.job_status}</em>)
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography>Tender</Typography>
                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                        >
                          (<em>{props.jrData.job_status}</em>)
                        </Typography>
                      </>
                    )}
                  </Stack>
                  <Stack direction={"row"} spacing={1}>
                    <Typography className="title">Requester:</Typography>
                    <Typography
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {props.jrData.address
                        ? props.jrData.clientName.split(" ")[0]
                        : props.jrData.client_name.split(" ")[0]}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            <Grid item md={0.1} justifyContent="center" alignItems="center">
              {props.notification && !clicked && (
                <Typography color={"#00ff00"} variant={"h2"}>
                  !
                </Typography>
              )}
            </Grid>
          </div>
          {isExpanded && (
            <VendorExpandedTile
              fromChat={props.fromChat}
              chargePercentages={props.chargePercentages}
              jrDetails={props.jrDetails}
              fetchJrData={props.fetchJrData}
              setShowSubmitForm={setShowSubmitForm}
              showSubmitForm={showSubmitForm}
              currentJRs={props.currentJRs}
              jrData={props.jrData}
              openJR={openJR}
              setAgreementOpen={props.setAgreementOpen}
            />
          )}
        </Grid>
      )}

      {!greaterThanSmall && (
        <>
          <>
            {!matches431 && (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ padding: 0.5 }}
              >
                <div
                  style={{ backgroundColor: color, width: "100%" }}
                  ref={ref}
                  className={`tile`}
                  onClick={() => {
                    const id = props.jrData.job_id;
                    setClicked(true);
                    if (openJR === null || openJR.job_id !== id) {
                      if (typeof props.setMapFocus === "function") {
                        props.setMapFocus(props.jrData.location);
                      }
                      setOpenJR(props.jrData);
                    } else {
                      setOpenJR(null);
                    }
                  }}
                >
                  <Grid item xs={10}>
                    <Stack direction={"row"}>
                      <Grid container spacing={12}>
                        <Grid item xs={8}>
                          <Typography className="jobTitle">
                            {props.jrData.job_title}{" "}
                            <Typography sx={{ fontWeight: "bold" }}>
                              {`${passTime(request_time)} ago`}{" "}
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                    <List>
                      <Stack direction={"row"} spacing={1}>
                        <img
                          style={{
                            height: matches500 ? "40%" : "5%",
                            width: matches500 ? "40%" : "5%",
                          }}
                          id="icon"
                          src={tileIcon}
                          alt="icon"
                        />

                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                        >
                          {props.jrData.job_category}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={1}>
                        <LocationOnIcon
                          style={{
                            height: matches500 ? "40%" : "5%",
                            width: matches500 ? "40%" : "5%",
                          }}
                        />
                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                        >
                          {props.jrData.assigned_vendor_id
                            ? props.jrData.address
                            : areaAddress}
                        </Typography>
                      </Stack>

                      <Stack direction={"row"} spacing={1}>
                        <SpaIcon
                          style={{
                            height: matches500 ? "40%" : "5%",
                            width: matches500 ? "40%" : "5%",
                          }}
                        />

                        {props.jrData.is_done === 1 ? (
                          <>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                              style={{ color: "green" }}
                            >
                              Completed
                            </Typography>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              (<em>{props.jrData.job_status}</em>)
                            </Typography>
                          </>
                        ) : props.jrData.assigned_vendor_id !== null ? (
                          <>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                              style={{ color: "#debd02" }}
                            >
                              Ongoing
                            </Typography>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              (<em>{props.jrData.job_status}</em>)
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              Tender
                            </Typography>
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              (<em>{props.jrData.job_status}</em>)
                            </Typography>
                          </>
                        )}
                      </Stack>
                      <Stack direction={"row"} spacing={1}>
                        <EmojiPeopleIcon
                          style={{
                            height: matches500 ? "40%" : "5%",
                            width: matches500 ? "40%" : "5%",
                          }}
                        />
                        <Typography className="title">Requester:</Typography>
                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                        >
                          {props.jrData.address
                            ? props.jrData.clientName.split(" ")[0]
                            : props.jrData.client_name.split(" ")[0]}
                        </Typography>
                      </Stack>
                    </List>
                  </Grid>
                  <Grid item xs={2}>
                    <Stack
                      direction={"column"}
                      spacing={1}
                      alignItems={"center"}
                    >
                      <Typography className="title">
                        JR ID:{props.jrData.job_id}
                      </Typography>
                      <Stack
                        direction={"column"}
                        spacing={1}
                        marginRight={"1rem"}
                      >
                        <>
                          <CalendarTwoTone
                            twoToneColor={"#017a46"}
                            className="anticon-color"
                            title="View Availability"
                            alt="View Availability"
                            onClick={(e) => {
                              e.stopPropagation();
                              openAvailability(e);
                            }}
                          />
                          {!props.fromChat && (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={(e) => {
                                e.stopPropagation();
                                newChat(e);
                              }}
                            >
                              Chat
                            </Button>
                          )}
                        </>
                      </Stack>
                    </Stack>
                  </Grid>
                </div>
                {isExpanded && (
                  <VendorExpandedTile
                    fromChat={props.fromChat}
                    chargePercentages={props.chargePercentages}
                    jrDetails={props.jrDetails}
                    fetchJrData={props.fetchJrData}
                    setShowSubmitForm={setShowSubmitForm}
                    showSubmitForm={showSubmitForm}
                    currentJRs={props.currentJRs}
                    jrData={props.jrData}
                    openJR={openJR}
                    setAgreementOpen={props.setAgreementOpen}
                  />
                )}
              </Grid>
            )}
          </>
          <>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <div
                style={{ backgroundColor: color }}
                ref={ref}
                className={`tile`}
                onClick={() => {
                  const id = props.jrData.job_id;
                  setClicked(true);
                  if (openJR === null || openJR.job_id !== id) {
                    if (typeof props.setMapFocus === "function") {
                      props.setMapFocus(props.jrData.location);
                    }
                    setOpenJR(props.jrData);
                  } else {
                    setOpenJR(null);
                  }
                }}
              >
                {matches431 && (
                  <>
                    <Grid
                      item
                      md={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography className="title">
                        JR ID: {props.jrData.job_id}
                      </Typography>

                      <img
                        style={{ height: "40%" }}
                        id="icon"
                        src={tileIcon}
                        alt="icon"
                      />
                      <Typography className="title">
                        {`${passTime(request_time)} ago`}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={5}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <List>
                        <Stack direction={"row"} spacing={1}>
                          <Typography className="title">Category:</Typography>
                          <Typography
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            whiteSpace={"nowrap"}
                          >
                            {props.jrData.job_category}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                          <Typography className="title">Title:</Typography>
                          <Typography
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            whiteSpace={"nowrap"}
                          >
                            {props.jrData.job_title}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                          <Typography className="title">Address:</Typography>
                          <Typography
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            whiteSpace={"nowrap"}
                          >
                            {props.jrData.assigned_vendor_id
                              ? props.jrData.address
                              : areaAddress}
                          </Typography>
                        </Stack>
                      </List>
                    </Grid>
                    <Stack
                      direction={"row-reverse"}
                      spacing={1}
                      alignItems={"center"}
                    >
                      <Stack direction={"column"} spacing={1}>
                        {!props.fromChat && (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={(e) => {
                              e.stopPropagation();
                              newChat(e);
                            }}
                          >
                            Chat
                          </Button>
                        )}
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={(e) => openAvailability(e)}
                        >
                          View Availability
                        </Button>
                      </Stack>
                    </Stack>
                  </>
                )}
              </div>
            </Grid>
          </>
        </>
      )}
      <Modal
        title={"Client Availability"}
        destroyOnClose={true}
        width="650px"
        open={showAvailability}
        onCancel={openAvailability}
        footer={false}
      >
        <ViewAvailability jrData={props.jrData} availability={availabilities} />
      </Modal>
    </>
  );
}

VendorTile.defaultProps = {
  overdue: false,
  is_market: false,
};
