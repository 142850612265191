import axios from "axios";
import * as Yup from "yup";
import {
  Button,
  Grid,
  Select,
  InputLabel,
  OutlinedInput,
  Stack,
  MenuItem,
  Typography,
} from "@mui/material";

import GetProvinceList from "../../Authentication/auth-forms/GetProvinceList";
import { Formik } from "formik";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import DatePicker from "../../../Components/FormComponents/DatePicker";
import PhoneInput from "../../../Components/FormComponents/PhoneInput";
import { useState } from "react";

const roles = ["Manager", "Supervisor", "Worker"];

const EmployeeForm = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const submitEmployee = ({
    firstName,
    lastName,
    email,
    gender,
    phone,
    dateOfBirth,
    role,
    streetAddress,
    city,
    postalCode,
    province,
  }) => {
    setSubmitting(true);
    const employee = {
      FirstName: firstName,
      LastName: lastName,
      email: email,
      Role: role,
      PhoneNumber: phone,
      DOB: new Date(dateOfBirth).toISOString(),
      Gender: gender,
      Street: streetAddress,
      City: city,
      Province: province,
      PostalCode: postalCode,
      employeeUserId: props.employeeUserId,
      host: window.location.host,
    };

    let endpoint;
    if (props.isNew) {
      employee.is_active = 1;
      endpoint = "/employee/auth/addEmployee";
    } else {
      endpoint = "/employee/updateEmployeeDetails";
    }

    axios
      .post(endpoint, employee)
      .then((res) => {
        props.onClose();
        props.fetchData();
      })
      .catch((err) => {
        if (err.response.status === 409) {
          setEmailExists(true);
        } else {
          setEmailExists(false);
          console.error(err);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{
        firstName: props.firstName,
        lastName: props.lastName,
        email: props.email,
        gender: props.gender,
        phone: props.phoneNumber,
        dateOfBirth: props.dob,
        role: props.role,
        streetAddress: props.streetAddress,
        city: props.city,
        postalCode: props.postalCode,
        province: props.province,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .max(255, "Too Long")
          .required("First name is required"),
        lastName: Yup.string().max(255).required("Last name is required"),
        email: Yup.string().email().max(255).required("email is required"),
        gender: Yup.string()
          .matches(/[MFO]/)
          .max(1)
          .required("gender is required"),
        phone: Yup.string()
          .min(15, "phoneNumber is required")
          .max(255)
          .required("phoneNumber is required"),
        dateOfBirth: Yup.string()
          .max(255)
          .required("Date of birth is required"),
        role: Yup.string().max(255).required("Role is required"),
        streetAddress: Yup.string()
          .max(255)
          .required("Street address is required"),
        city: Yup.string().max(255).required("City is required"),
        postalCode: Yup.string().max(255).required("Postal code is required"),
        province: Yup.string().max(255).required("Province is required"),
      })}
      onSubmit={submitEmployee}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        values,
        isSubmitting,
        isValidating,
      }) => (
        <form style={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel>First Name</InputLabel>
                <OutlinedInput
                  required
                  name="firstName"
                  id="firstName"
                  type="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="First Name"
                />
                {touched.firstName && errors.firstName && (
                  <Typography noWrap variant="h8" color="#ff0000">
                    *{errors.firstName}
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel>Last Name</InputLabel>
                <OutlinedInput
                  required
                  name="lastName"
                  id="lastName"
                  type="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Last Name"
                />
                {touched.lastName && errors.lastName && (
                  <Typography noWrap variant="h8" color="#ff0000">
                    *{errors.lastName}
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel>Email</InputLabel>
                <OutlinedInput
                  required
                  name="email"
                  id="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="email"
                />
                {touched.email && (
                  <Typography noWrap variant="h8" color="#ff0000">
                    {errors.email && `*${errors.email}`}
                    {emailExists && "*Account with this email already exists"}
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel>Gender</InputLabel>
                <Select
                  required
                  name="gender"
                  id="gender"
                  value={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value={"SG"} hidden>
                    Select Gender
                  </MenuItem>
                  <MenuItem value={"M"}>Male</MenuItem>
                  <MenuItem value={"F"}>Female</MenuItem>
                  <MenuItem value={"O"}>Other</MenuItem>
                </Select>
                {touched.gender && errors.gender && (
                  <Typography noWrap variant="h8" color="#ff0000">
                    *{errors.gender}
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel>Phone</InputLabel>
                <PhoneInput
                  name="phone"
                  value={values.phone}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.phone && errors.phone && (
                  <Typography noWrap variant="h8" color="#ff0000">
                    *{errors.phone}
                  </Typography>
                )}
              </Stack>
            </Grid>

            {props.isNew && (
              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel>Date of Birth</InputLabel>
                  <DatePicker
                    required
                    type="date"
                    name="dateOfBirth"
                    value={values.dateOfBirth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.dateOfBirth && errors.dateOfBirth && (
                    <Typography noWrap variant="h8" color="#ff0000">
                      *{errors.dateOfBirth}
                    </Typography>
                  )}
                </Stack>
              </Grid>
            )}

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel>Role</InputLabel>
                <Select
                  id="role"
                  name="role"
                  value={values.role}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value={"SR"} hidden>
                    Select Role
                  </MenuItem>
                  {roles.map((role) => (
                    <MenuItem value={role} key={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
                {touched.role && errors.role && (
                  <Typography noWrap variant="h8" color="#ff0000">
                    *{errors.role}
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel>Street Address</InputLabel>
                <OutlinedInput
                  required
                  name="streetAddress"
                  id="streetAddress"
                  value={values.streetAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Street Address"
                />
                {touched.streetAddress && errors.streetAddress && (
                  <Typography noWrap variant="h8" color="#ff0000">
                    *{errors.streetAddress}
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel>City</InputLabel>
                <OutlinedInput
                  required
                  name="city"
                  id="city"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="City"
                />
                {touched.city && errors.city && (
                  <Typography noWrap variant="h8" color="#ff0000">
                    *{errors.city}
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel>Postal Code</InputLabel>
                <OutlinedInput
                  required
                  name="postalCode"
                  id="postalCode"
                  value={values.postalCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Postal Code"
                />
                {touched.postalCode && errors.postalCode && (
                  <Typography noWrap variant="h8" color="#ff0000">
                    *{errors.postalCode}
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel>Province</InputLabel>
                <Select
                  required
                  name="province"
                  id="province"
                  value={values.province}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value={"SP"} hidden>
                    Select Province
                  </MenuItem>
                  {GetProvinceList().map((provinces) => (
                    <MenuItem
                      value={provinces.province_shortform}
                      key={provinces.province_shortform}
                    >
                      {provinces.province_name}
                    </MenuItem>
                  ))}
                </Select>
                {touched.province && errors.province && (
                  <Typography noWrap variant="h8" color="#ff0000">
                    *{errors.province}
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <AnimateButton>
                <Button
                  size="sm"
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{ ml: 2, float: "right" }}
                  disabled={submitting || isValidating}
                >
                  {props.isNew ? "Add" : "Update"}
                </Button>
              </AnimateButton>
              <AnimateButton>
                <Button
                  size="sm"
                  variant="outlined"
                  color="secondary"
                  onClick={props.onClose}
                  sx={{ ml: 2, float: "right" }}
                >
                  Cancel
                </Button>
              </AnimateButton>
              {(submitting || isValidating) && (
                <Typography>Submitting</Typography>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

EmployeeForm.defaultProps = {
  firstName: "",
  lastName: "",
  email: "",
  gender: "SG",
  role: "SR",
  phoneNumber: "",
  dob: "2000-00-00",
  city: "",
  province: "SP",
  postalCode: "",
  streetAddress: "",
};

export default EmployeeForm;
