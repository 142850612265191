import { Result } from "antd";
import Link from "@mui/material/Link";

import AuthWrapper from "../auth-pages/AuthWrapper";

const SignUpSuccess = () => {
  return (
    <>
      <AuthWrapper>
        <Result
          className="flex-60 padding0"
          status="success"
          title={
            <h4>
              Your Account is created. Please verify your email to{" "}
              <Link
                color="#4c5ee5"
                href={
                  process.env.NODE_ENV === "development"
                    ? "http://localhost:3001/login"
                    : "https://www.fixden.com/login"
                }
              >
                login
              </Link>{" "}
            </h4>
          }
        />
      </AuthWrapper>
    </>
  );
};

export default SignUpSuccess;
