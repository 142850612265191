import React, { useState } from "react";
import { Modal } from "antd";

const Video = ({ src }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [playing, setPlaying] = useState(false);

  const togglePlayback = () => {
    setPlaying(!playing);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setPlaying(false); // Pause video when modal is closed
    setModalVisible(false);
  };

  return (
    <>
      <div
        onClick={togglePlayback}
        style={{
          position: "relative",
          width: 100, // Adjust the width as needed
          height: 100, // Adjust the height as needed
          cursor: "pointer"
        }}
      >
        {/* Video thumbnail with play button overlay */}
        <video
          src={src}
          controls={false}
          onClick={togglePlayback}
          style={{ width: "100%", height: "100%" }}
        />
        {!playing && (
          <svg
            viewBox="0 0 64 64"
            width="64"
            height="64"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <polygon points="25 18 25 46 45 32 25 18"></polygon>
          </svg>
        )}
      </div>
      <Modal
        visible={modalVisible}
        onCancel={handleModalCancel}
        footer={null}
        destroyOnClose
      >
        <video
          src={src}
          controls
          autoPlay={playing}
          onEnded={() => setPlaying(false)}
          style={{ width: "100%", height: "auto" }}
        />
      </Modal>
    </>
  );
};

export default Video;
