import {
  CloudUploadOutlined,
  EditOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import { Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";

const AdminEditProfile = (props) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          direction={"row"}
          spacing={3}
        >
          <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={1}
          >
            <IconButton
              size="large"
              color="primary"
              onClick={props.editModalUser}
            >
              <EditOutlined title="Edit profile" style={{ color: "blue" }} />
            </IconButton>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography noWrap variant="h6">
                User Details
              </Typography>
            </Stack>
          </Stack>

          <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={1}
          >
            <IconButton
              size="large"
              color="primary"
              onClick={props.changeProfilePic}
            >
              <CloudUploadOutlined
                title="Change/upload pic"
                style={{ color: "blue" }}
              />
            </IconButton>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography noWrap variant="h6">
                Profile Picture
              </Typography>
            </Stack>
          </Stack>

          <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={1}
          >
            <IconButton
              size="large"
              color="primary"
              onClick={props.changePassword}
            >
              <KeyOutlined title="Change password" style={{ color: "blue" }} />
            </IconButton>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography noWrap variant="h6">
                Password
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} marginTop={"1rem"}>
        <AnimateButton>
          <Button
            size="small"
            type="button"
            variant="contained"
            color="primary"
            onClick={() => props.setAdminProfileEditModal(false)}
            sx={{ ml: 1, float: "right" }}
          >
            Done
          </Button>
        </AnimateButton>
      </Grid>
    </Grid>
  );
};

export default AdminEditProfile;
