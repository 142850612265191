import { Box } from "@mui/material";

// ==============================|| AUTH BLUR BACK SVG ||============================== //

const AuthBackground = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        filter: "blur(18px)",
        zIndex: -1,
        bottom: 0,
      }}></Box>
  );
};

export default AuthBackground;
