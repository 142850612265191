import React from 'react';
import { Modal, ModalTitle } from 'react-bootstrap';
import { FileTwoTone, FileExcelTwoTone } from '@ant-design/icons';
import LoadScreen from '../../../Components/LoadScreen';

import axios from 'axios';
class AttachmentFullDisplay extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			url: this.props.file.url
		};
	}

	componentDidMount() {
		if (
			(this.props.file.type.split('/')[0] !== 'text' &&
				this.props.file.type.split('/')[1] !== 'vnd.ms-excel') ||
			this.props.file.url.indexOf('blob:') === 0
		) {
			this.setState({ isLoading: false });
		} else {
			axios
				.post('/communication/getAttachmentBlob', {
					url: this.props.file.url
				})
				.then((response, err) => {
					if (err) throw err;
					this.setState({
						isLoading: false,
						url: window.URL.createObjectURL(new Blob([response.data.blobData]))
					});
				});
		}
	}

	render() {
		let renderData;
		if (!this.state.isLoading) {
			switch (this.props.file.type.split('/')[0]) {
				case 'image':
					renderData = <img src={this.state.url} />;
					break;
				case 'text':
					renderData = (
						<object
							height='400'
							type='text/plain'
							data={this.state.url}
						></object>
					);
					break;
				default:
					switch (this.props.file.type.split('/')[1]) {
						case 'pdf':
							renderData = (
								<object
									height='400'
									type='application/pdf'
									data={this.state.url}
								></object>
							);
							break;
						case 'vnd.ms-excel':
							renderData = (
								<object
									height='400'
									type='text/plain'
									data={this.state.url}
								></object>
							);
							break;
						case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
							renderData = (
								<FileExcelTwoTone
									twoToneColor='#6dd230'
									style={{
										paddingTop: '200px',
										paddingBottom: '200px',
										fontSize: '200px'
									}}
								/>
							);
							break;
						default:
							renderData = (
								<FileTwoTone
									style={{
										paddingTop: '200px',
										paddingBottom: '200px',
										fontSize: '200px'
									}}
								/>
							);
							break;
					}
					break;
			}
		} else renderData = <LoadScreen />;
		return (
			<>
				<Modal.Header closeButton>{this.props.file.file_name}</Modal.Header>
				{renderData}
				<Modal.Footer>
					<a href={this.props.file.url} download>
						Download
					</a>
				</Modal.Footer>
			</>
		);
	}
}

export default AttachmentFullDisplay;
