import { Grid, MenuItem, Select, Stack } from "@mui/material";
import axios from "axios";
import { memo, useEffect, useState } from "react";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";

import { Typography ,Modal } from "antd";
import '../../commonComponents/JRComponents/tile.css'
import { useNavigate } from "react-router";
import { PlusCircleTwoTone } from "@ant-design/icons";
import NewPaymentMethod from "../../NewPaymentMethod";

const PaymentMethodSelector = memo(() => {
  const navigate = useNavigate();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [preferredMethod, setPreferredMethod] = useState("default");
  const [updated, setUpdated] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [noMethods, setNoMethods] = useState(false);
  const [cardModal, setCardModal] = useState(false)

  useEffect(() => {
    axios
      .get("/stripe/getPaymentMethods")
      .then(({ data: { paymentMethods, preferredMethod } }) => {
        if (paymentMethods.length === 0) {
          setNoMethods(true);
        } else {
          setPaymentMethods(paymentMethods);
          setPreferredMethod(preferredMethod);
        }
      })
      .catch((e) => {
        setNoMethods(true);
      });
  }, []);

  return (
    <>
    <Grid>
      <Stack direction={"row"} spacing={1} style={{justifyContent: "center" }}>
      <PaymentOutlinedIcon style={{
                      height: "5%",
                      width: "5%",
                      marginTop:2.5
                    }} />
      {noMethods ? (
       <Typography style={{marginTop: 2}}>No payment methods</Typography> 
     
      ) : (
        <>
          <Select
            name="payment method"
            value={preferredMethod}
            disabled={updating}
            onChange={({ target: { value }, target }) => {
              setUpdating(true);
              setUpdated(false);
              axios
                .post("stripe/setPreferredPaymentMethod", {
                  paymentMethodID: value,
                })
                .then(() => {
                  setUpdating(false);
                  setUpdated(true);
                });
              setPreferredMethod(value);
            }}
          >
            <MenuItem value={"default"} hidden></MenuItem>
            {paymentMethods.map((method) => (
              <MenuItem value={method.id} key={method.id}>
                {`${method.type} ending in: ${method.card.last4}`}
              </MenuItem>
            ))}
          </Select>
          {updated && (
            <p style={{ color: "green" }}>preferred payment method updated</p>
          )}
        </>
       
      )}
                      <PlusCircleTwoTone  onClick={(e) => {
                    setCardModal(true)
                  }}/>
      </Stack>
      
      
            
    </Grid>
    
    <Modal
          title="Add Payment Details"
          open={cardModal}
          onCancel={()=>setCardModal(false)}
          footer={false}
          bodyStyle={{ overflowY: "auto", maxHeight: "calc(90vh - 200px)" }}
        >
          <NewPaymentMethod
            
          />
        </Modal>
    </>
  );
});

export default PaymentMethodSelector;
