import { useMediaQuery, Checkbox } from "@mui/material";
import "./propertyTable.css";

const DelegatePropertyTable = (props) => {
  const mobileView = useMediaQuery("(max-width: 600px)");
  console.log(props.property);

  //   // Function to handle individual checkbox change
  //   const handleCheckboxChange = (event, property_id) => {
  //     const checked = event.target.checked;
  //     props.setSelectedProperties((prevSelected) => {
  //       if (checked) {
  //         return [...prevSelected, property_id];
  //       } else {
  //         return prevSelected.filter((id) => id !== property_id);
  //       }
  //     });
  //   };

  const getTableRows = (property) => {
    let rowList = [];

    !mobileView
      ? rowList.push(
          <tr
            className="onHover"
            key={`property_${property.property_id}`}
            onClick={() => props.handleRowClick(property.property_id)}
          >
            <td
              style={{
                margin: "0",
                padding: "1rem"
              }}
            >
              <div>
                {`${property.street}, ${property.city}, ${property.province}`}
              </div>
              <div>{property.postal_code}</div>
            </td>

            {property.is_active ? (
              <td className="propertyActions" align="left">
                {property.is_active === 1 ? (
                  <Checkbox
                    size="small"
                    checked={props.selectedProperties.includes(
                      property.property_id
                    )}
                    // onChange={(e) =>
                    //   handleCheckboxChange(e, property.property_id)
                    // }
                  />
                ) : (
                  ""
                )}
              </td>
            ) : (
              ""
            )}
          </tr>
        )
      : rowList.push(
          <tr
            className="onHover"
            key={`property_${property.property_id}`}
            onClick={() => props.handleRowClick(property.property_id)}
          >
            <td style={{ margin: "0", padding: "1rem" }}>
              <div>
                {`${property.street}, ${property.city}, ${property.province}`}
              </div>
              <div>{property.postal_code}</div>
            </td>

            {property.is_active === 1 ? (
              <Checkbox
                size="small"
                checked={props.selectedProperties.includes(
                  property.property_id
                )}
                // onChange={(e) => handleCheckboxChange(e, property.property_id)}
              />
            ) : (
              ""
            )}
          </tr>
        );

    return rowList;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "left",
          width: "100%"
        }}
      >
        <table className="propertyTable">
          <tbody className="propertyTbody">
            {getTableRows(props.property)}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DelegatePropertyTable;
