import PropTypes from "prop-types";

// material-ui
import {
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project import
import NavItem from "./NavItem";
import { useUserState } from "../../../../../Context/UserContext";
import { ToolOutlined } from "@ant-design/icons";

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item, setOpen, handleVendorMode }) => {
  const { user } = useUserState();
  const matches = useMediaQuery("(min-width: 560px)");
  const matchesXs = useMediaQuery("(max-width: 900px)");

  const navCollapse = item.children
    ?.filter((menuItem) => {
      return !(menuItem.id === "bidding" && !user.vendorMode);
    })
    .map((menuItem) => {
      switch (menuItem.type) {
        case "collapse":
          return (
            <Typography
              key={menuItem.id}
              variant="caption"
              color="error"
              sx={{ p: 2.5 }}
            >
              collapse - only available in paid version
            </Typography>
          );
        case "item":
          return (
            <NavItem
              setOpen={setOpen}
              key={menuItem.id}
              item={menuItem}
              level={1}
            />
          );
        default:
          return (
            <Typography
              key={menuItem.id}
              variant="h6"
              color="error"
              align="center"
            >
              Fix - Group Collapse or Items
            </Typography>
          );
      }
    });

  return (
    <List sx={{ py: 0, zIndex: 0 }}>
      <Stack
        // style={{ backgroundColor: "red" }}
        direction={matches ? "row" : "column"}
      >
        {navCollapse}
        {matchesXs && (
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"center"}
            marginLeft={matches ? "0.5rem" : ""}
          >
            <InputLabel
              style={{
                cursor: "pointer",
                marginTop: "0.5rem",
                color: "#fff",
              }}
              htmlFor="vendorMode"
            >
              {user.vendorMode ? "Client Mode" : "Vendor Mode"}
            </InputLabel>
            <Switch
              id="vendorMode"
              color="primary"
              checked={user.vendorMode}
              onChange={() => {
                handleVendorMode();
                setOpen(false);
              }}
            />
          </Stack>
        )}

        {/* <ListItemButton
          disabled={item.disabled}
          onClick={() => {
            handleVendorMode();
          }}
        >
          <ListItemIcon style={{ color: "#fcf2e7" }}>
            {ToolOutlined}
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="h6">Vendor Mode</Typography>}
          />
        </ListItemButton> */}
      </Stack>
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
