import styles from "./EmployeeTile.module.css";
import { EditOutlined, MinusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Grid, Button } from "@mui/material";
import Avatar from "antd/lib/avatar/avatar";
import { Modal } from "antd";
import { useUserState } from "../../../Context/UserContext";

const EmployeeTile = ({ employee, onEdit, onDeactivate }) => {
  const {
    first_name,
    last_name,
    role,
    profile_pic,
    city,
    email,
    phone_number,
    province,
    street,
    postal_code
  } = employee;
  const [isExpanded, setIsExpanded] = useState(false);

  const closeModal = () => {
    setIsExpanded(false);
  };

  const { user } = useUserState();

  return (
    <>
      <div
        key={`${first_name} ${role}`}
        className={`${styles.employee} ${
          employee.is_active === 1 ? "" : styles.gray
        }`}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <div style={{ paddingLeft: "0.25rem", paddingRight: "0.25rem" }}>
          {profile_pic === "avatar_default.png" ? (
            <Avatar size={60}>{first_name[0]}</Avatar>
          ) : (
            <Avatar size={60} src={profile_pic} />
          )}
        </div>

        <div className={styles.name}>
          <p>
            {employee.employee_user_id === user.id
              ? "You"
              : `${first_name} ${last_name}`}
          </p>
          <p>{role}</p>
        </div>
        <div className={styles.employeeActions}>
          <Button
            size="small"
            variant="contained"
            className={styles.editEmployee}
            onClick={(e) => {
              onEdit();
              e.stopPropagation();
            }}
          >
            <EditOutlined />{" "}
          </Button>
          {employee.is_active === 1 ? (
            <Button
              size="small"
              variant="contained"
              className={styles.deactivateEmployee}
              onClick={(e) => {
                onDeactivate();
                e.stopPropagation();
              }}
            >
              <MinusOutlined />{" "}
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        width="450px"
        title="Employee Details"
        open={isExpanded}
        onCancel={closeModal}
        footer={false}
      >
        {isExpanded && (
          <div className={styles.expanded}>
            <Grid item style={{ alignSelf: "center" }}>
              {profile_pic === "avatar_default.png" ? (
                <Avatar style={{ alignSelf: "center" }} size={145}>
                  {first_name[0]}
                </Avatar>
              ) : (
                <Avatar size={145} src={profile_pic} />
              )}
            </Grid>
            <Grid item xs={4} style={{ textAlign: "left" }}>
              <h2 style={{ margin: "0" }}>
                {first_name} {last_name}
              </h2>
              <h5 style={{ margin: "0" }}>{role}</h5>
              <p style={{ margin: "0" }}>{email}</p>
              <p style={{ margin: "0" }}>{phone_number}</p>
              <p style={{ margin: "0" }}>{street}</p>
              <p style={{ margin: "0" }}>
                {city} {province} {postal_code}
              </p>
            </Grid>
          </div>
        )}
      </Modal>
    </>
  );
};

export default EmployeeTile;
