import { useState, useEffect } from "react";
import { Button, Grid, Stack, Alert } from "@mui/material";
import { Image, Typography } from "antd";

import ExpandedTilePhase2 from "./ExpandedTilePhase2";

import LoadScreen from "../../../../Components/LoadScreen";

import Video from "../../API/Video";
import AddImageModal from "./AddImageModal";

const ExpandedTilePhase1 = (props) => {
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [imageSubmitAlert, setImageSubmitAlert] = useState(false);

  useEffect(() => {
    if (imageSubmitAlert) {
      const timeoutId = setTimeout(() => {
        setImageSubmitAlert(false);
      }, 5000); // Adjust the timeout duration (in milliseconds) as needed

      return () => clearTimeout(timeoutId); // Clear timeout when component unmounts or alert is hidden
    }
  }, [imageSubmitAlert]);

  return props.availibilities !== "" ? (
    <>
      <Grid container spacing={1} width={"100%"} margin={"1rem 0 0 0"}>
        <Stack spacing={1} direction={"column"} width={"100%"}>
          <Grid item sm={12} md={8}>
            <Stack spacing={1}>
              <Stack direction={"row"} spacing={1}>
                <Typography className="title">Problem Description</Typography>
              </Stack>
              <Typography
                style={{
                  width: "350px",
                  overflowWrap: "break-word",
                  wordWrap: "break-word"
                }}
              >
                {props.jrData.job_description}
              </Typography>

              <Typography className="title">
                Images / Documents
                <Button
                  variant="contained"
                  type="primary"
                  style={{ marginLeft: 10 }}
                  onClick={() => setShowImageUploadModal(true)}
                >
                  Add
                </Button>
              </Typography>

              <Stack spacing={1} direction={"row"}>
                {props.jrImages.map((media, i) => {
                  // Extract file extension from the source URL

                  const fileExtension = media.imageurl
                    .split(".")
                    .pop()
                    .toLowerCase();

                  // Check if it's an image based on the file extension
                  const isImage = ["jpg", "jpeg", "png", "gif"].includes(
                    fileExtension
                  );

                  if (isImage) {
                    return (
                      <Image
                        key={media.jobrequest_images_id}
                        width={80}
                        src={media.image}
                      />
                    );
                  } else {
                    return (
                      <Video
                        key={media.jobrequest_images_id}
                        src={media.image}
                        // poster={media.image} // Assuming you have a thumbnail URL for each video
                        width={80}
                      />
                    );
                  }
                })}
              </Stack>
            </Stack>
          </Grid>
          {props.bidList?.length > 0 ? (
            <Grid item sm={12} md={4}>
              <Stack justifyContent={"center"} alignItems={"flex-end"}>
                <ExpandedTilePhase2
                  openAgreement={props.openAgreement}
                  chargePercentages={props.chargePercentages}
                  fromChat={props.fromChat}
                  fetchData={props.fetchData}
                  scopeDetails={props.scopeDetails}
                  bidDetails={props.bidList}
                  fetchJrData={props.fetchJrData}
                  activityList={props.activityList}
                  jrData={props.jrData}
                  setOpenBid={props.setOpenBid}
                  setAgreementOpen={props.setAgreementOpen}
                />
              </Stack>
            </Grid>
          ) : (
            ""
          )}
        </Stack>
        {showImageUploadModal && (
          <AddImageModal
            onClose={() => setShowImageUploadModal(false)}
            showImageUploadModal={showImageUploadModal}
            setImageSubmitAlert={setImageSubmitAlert}
            jrData={props.jrData}
            fetchData={props.fetchData}
            fetchJrData={props.fetchJrData}
          />
        )}
        {imageSubmitAlert && (
          <Alert
            style={{
              zIndex: 10000,
              position: "absolute",
              top: "2rem",
              left: "calc(50vw - 8rem)",
              width: "16rem"
            }}
            severity="success"
          >
            Your images has been added.
          </Alert>
        )}
      </Grid>
    </>
  ) : (
    <LoadScreen />
  );
};

export default ExpandedTilePhase1;
