import axios from "axios";
import { useState, useEffect } from "react";

const GetProvinceList = () => {
  const [provinces, setProvinces] = useState([]);
  useEffect(() => {
    axios.get("/api/getProvinces").then((res) => {
      setProvinces(res.data.provinces);
    });
  }, []);
  return provinces;
};

export default GetProvinceList;
