import { useState, useEffect } from "react";
import axios from "axios";
import { Avatar, Input, Tooltip, Badge } from "antd";
import {
  PaperClipOutlined,
  PlusCircleTwoTone,
  SendOutlined
} from "@ant-design/icons";
import { Button, useMediaQuery } from "@mui/material";

const MessageField = (props) => {
  const [message, setMessage] = useState("");

  const checkForOnlyWhite = () => {
    let checkText = message;
    checkText = checkText.replace(/^\s\s*/, "").replace(/\s+$/, "");
    return checkText === "";
  };

  const handleTyping = () => {
    if (message !== "")
      props.socket.emit(
        "typing",

        `${props.conversation.sender_name} is typing`
      );
    else props.socket.emit("typing", "");
  };

  const mobileView = useMediaQuery("(max-width: 900px");
  const handleKeyPress = (event) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
      event.preventDefault();
      let isOnlyWhite = checkForOnlyWhite();
      if (props.uploadFiles.length > 0 || !isOnlyWhite) {
        if (props.uploadFiles.length > 0) {
          props.sendMessageWithAttach(isOnlyWhite ? null : message);
          setMessage("");
          //   props.socket.emit("typing", "");
        } else if (message.length > 0) {
          props.sendMessage(message);
          setMessage("");
          //   props.socket.emit("typing", "");
        }
      }
    }
  };

  return (
    <div id="messageTextArea">
      <input
        multiple
        type="file"
        ref={props.fileUpload}
        style={{ display: "none" }}
        onChange={(event) => {
          event.stopPropagation();
          event.preventDefault();
          props.setFiles(event.target.files);
        }}
      />

      <>
        <Tooltip title="Attach Files">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              props.fileUpload.current.click();
            }}
            type="text"
            style={{ maxWidth: "fit-content", minWidth: "fit-content" }}
          >
            <PaperClipOutlined />
          </Button>
        </Tooltip>
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 6 }}
          placeholder="Aa"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          //   onKeyUp={handleTyping}
          id="commTextArea"
          onKeyDown={handleKeyPress}
        />
        <Button
          // disabled={props.messageSending}
          className="ml-2"
          id="commSendMessage"
          variant="contained"
          color="primary"
          size="small"
          style={{
            minWidth: mobileView ? "fit-content" : "",
            maxWidth: mobileView ? "fit-content" : ""
          }}
          onClick={() => {
            let isOnlyWhite = checkForOnlyWhite();
            if (props.uploadFiles.length > 0 || !isOnlyWhite) {
              if (props.uploadFiles.length > 0) {
                props.sendMessageWithAttach(isOnlyWhite ? null : message);
                setMessage("");
                // props.socket.emit("typing", "");
              } else if (message.length > 0) {
                props.sendMessage(message);
                setMessage("");
                // props.socket.emit("typing", "");
              }
            }
          }}
        >
          {mobileView ? <SendOutlined /> : "Send"}
        </Button>
      </>
    </div>
  );
};

export default MessageField;
