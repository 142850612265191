import { React, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { useUserDispatch } from "../../../Context/UserContext";

// material-ui
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  FormControl,
  Box
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";

import AnimateButton from "../../../Layout/components/@extended/AnimateButton";

import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import LoadScreen from "../../../Components/LoadScreen";
import { Modal } from "antd";
import TermsAgreement from "../auth-pages/TermsAgreement";
import {
  strengthColor,
  strengthIndicator
} from "../../../Layout/utils/password-strength";

const ResetPassword = ({ userId, oldPassword }) => {
  let navigate = useNavigate();

  const [level, setLevel] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  const onSubmit = async (values) => {
    const { newPassword } = values;

    try {
      await axios
        .post("/auth/resetPassword", {
          userId: userId,
          newPassword: newPassword,
          oldPassword: oldPassword
        })
        .then((res) => {
          if (res.data.ok) navigate("/resetSuccessful");
          else if (res.data.error === "SAMEPASS")
            setErrorMessage(
              "New password cannot be the same as the old password. Please try again."
            );
          else setErrorMessage(res.data.error);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {}
  };

  return (
    <Formik
      initialValues={{
        newPassword: "",
        confirmPassword: ""
      }}
      validationSchema={Yup.object().shape({
        newPassword: Yup.string().max(255).required("Password is required"),
        confirmPassword: Yup.string()
          .max(255)
          .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      })}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="password-signup">New Password</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="newPassword-signup"
                  type={showPassword ? "text" : "password"}
                  value={values.newPassword}
                  name="newPassword"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    changePassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="******"
                  inputProps={{}}
                />
                {touched.newPassword && errors.newPassword && (
                  <FormHelperText error id="helper-text-newPassword-signup">
                    {errors.newPassword}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="password-signup">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id="confirmPassword-signup"
                  type={showPassword ? "text" : "password"}
                  value={values.confirmPassword}
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    changePassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="******"
                  inputProps={{}}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <FormHelperText error id="helper-text-confirmPassword-signup">
                    {errors.confirmPassword}
                  </FormHelperText>
                )}
              </Stack>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Box
                      sx={{
                        bgcolor: level?.color,
                        width: 85,
                        height: 8,
                        borderRadius: "7px"
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" fontSize="0.75rem">
                      {level?.label}
                    </Typography>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <AnimateButton>
                <Button
                  disableElevation
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Reset Password
                </Button>
              </AnimateButton>
              <FormHelperText error id="helper-text-firstname-signup">
                {errorMessage}
              </FormHelperText>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

const AuthLogin = () => {
  const navigate = useNavigate();
  const userDispatch = useUserDispatch();

  const [loginData, setLoginData] = useState(false);
  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [screen, setScreen] = useState("Form");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [isOpenAgreement, setIsOpenAgreement] = useState(false);
  const [isOpenPasswordChange, setIsOpenPasswordChange] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   * @param {{user:Object, session_id: string}} loginData
   * @param {string | undefined} url
   */
  const login = (loginData, url) => {
    userDispatch({
      state: { user: loginData.user },
      type: "initialize"
    });

    Cookies.set(
      "session",
      JSON.stringify({
        session_id: loginData.session_id,
        type: loginData.user.type
      })
    );

    if (!loginData.verifyAccount) {
      //Account not verified
      setErrorMessage("Please verify your account to login.");
    } else if (typeof url === "string") {
      navigate(url);
    }
  };
  useEffect(() => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";

    setRememberMe(rememberMe);
  }, []);

  const handleCheckboxChange = (event, setFieldValue) => {
    const { checked } = event.target;
    setRememberMe(checked);
    // Store checkbox state in localStorage
    if (checked) {
      localStorage.setItem("rememberMe", "true");
    } else {
      localStorage.removeItem("rememberMe");
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
  };

  const onSubmit = async (values) => {
    const { email, password } = values;

    if (rememberMe && email !== "") {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }

    try {
      await axios
        .post("/auth/login", {
          email: email,
          password: password
        })
        .then((response) => {
          if (response.data.ok && !response.data.accountTerminated) {
            //email & password correct
            if (response.data.user.acceptedTerms) {
              login(response.data, "/dashboard/clientjobrequest");
            } else {
              setLoginData(response.data);
              setPassword(password);
              setIsOpenAgreement(true);
            }
          } else {
            setErrorMessage("This account is no longer active. ");
          }
        });
    } catch (err) {
      if (err.response) {
        setErrorMessage(err.response.data.message);
      }
    }
  };

  if (screen === "Form") {
    return (
      <>
        <Formik
          initialValues={{
            email: localStorage.getItem("email") || "",
            password: localStorage.getItem("password") || ""
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            password: Yup.string().max(255).required("Password is required")
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,

            values,
            handleChange,
            handleSubmit,
            handleBlur
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email-login">
                        Email Address
                      </InputLabel>
                      <OutlinedInput
                        id="email-login"
                        type="email"
                        value={values.email}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter email address"
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                      />
                      {touched.email && errors.email && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-email-login"
                        >
                          {errors.email}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="password-login">Password</InputLabel>
                      <OutlinedInput
                        fullWidth
                        id="password-login"
                        type={showPassword ? "text" : "password"}
                        error={Boolean(touched.password && errors.password)}
                        value={values.password}
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="Enter password"
                      />
                      {touched.password && errors.password && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-password-login"
                        >
                          {errors.password}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sx={{ mt: -1 }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="stay_signed_in_ckbox"
                            checked={rememberMe}
                            color="primary"
                            size="small"
                            onChange={(e) => handleCheckboxChange(e)}
                          />
                        }
                        label={
                          <Typography variant="h6">Keep me sign in</Typography>
                        }
                      />

                      <Link
                        id="forget_password_link"
                        variant="h6"
                        component={RouterLink}
                        to="/recoverPassword"
                        color="text.primary"
                      >
                        Forgot Password?
                      </Link>
                    </Stack>
                  </Grid>
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText id="submission_error_message" error>
                        {errors.submit}
                      </FormHelperText>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <AnimateButton>
                      <Button
                        disableElevation
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{ backgroundColor: "#01552f" }}
                      >
                        Login
                      </Button>
                    </AnimateButton>
                    <FormHelperText error id="error_login">
                      {errorMessage}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
        <Modal
          width="700px"
          open={isOpenAgreement}
          footer={null}
          onCancel={() => {
            setIsOpenAgreement(false);
          }}
          bodyStyle={{ overflowY: "auto", maxHeight: "calc(80vh - 200px)" }}
        >
          {isOpenAgreement && (
            <TermsAgreement
              onAgree={() => {
                login(loginData);
                setIsOpenPasswordChange(true);
              }}
              userData={loginData}
            />
          )}
        </Modal>
        <Modal
          width="700px"
          open={isOpenPasswordChange}
          footer={null}
          onCancel={() => {
            if (loginData !== false) {
              login(loginData);
            } else {
              setIsOpenAgreement(false);
            }
          }}
          bodyStyle={{ overflowY: "auto", maxHeight: "calc(80vh - 200px)" }}
        >
          {isOpenPasswordChange && (
            <ResetPassword userId={loginData.user.id} oldPassword={password} />
          )}
        </Modal>
      </>
    );
  }

  if (screen === "overview") {
    return navigate("/dashboard");
  } else {
    return <LoadScreen />;
  }
};

export default AuthLogin;
