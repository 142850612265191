import { createContext, useContext, useMemo, useState } from "react";

const JobRequestContext = createContext(null);

const JRProvider = ({ children }) => {
  const [openJR, setOpenJR] = useState(null);
  const value = useMemo(() => {
    return { openJR, setOpenJR };
  }, [openJR, setOpenJR]);
  return (
    <JobRequestContext.Provider value={value}>
      {children}
    </JobRequestContext.Provider>
  );
};

function useJRContext() {
  const context = useContext(JobRequestContext);
  if (context === null) {
    throw new Error("useJRContext must be used within a JRProvider");
  }
  return context;
}

export { JRProvider, useJRContext };
