import { Grid, Stack, Button } from "@mui/material";
import { Modal } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useUserState } from "../../../../Context/UserContext";
import StarRating from "./StarRating";

const roundHalf = (num) => {
  return Math.round(num * 2) / 2;
};

function VendorReview(props) {
  const [comments, setComments] = useState();
  const [safenessRating, setSafenessRating] = useState();
  const [organizationRating, setOrganizationRating] = useState();
  const [cleanlinessRating, setCleanlinessRating] = useState();
  const [communicationRating, setCommunicationRating] = useState();
  const [vendorReview, setVendorReview] = useState(props.vendorReview);
  const [clientName, setClientName] = useState(props.clientName);
  const { user } = useUserState();

  useEffect(() => {
    setVendorReview(props.vendorReview);
    setClientName(props.clientName);
  }, [props.vendorReview, props.clientName]);

  const closeReview = () => {
    props.setShowReview(false);
  };

  const onChangeSafeness = (e) => {
    setSafenessRating(e.target.value);
  };
  const onChangeOrganization = (e) => {
    setOrganizationRating(e.target.value);
  };
  const onChangeCleanliness = (e) => {
    setCleanlinessRating(e.target.value);
  };
  const onChangeCommunication = (e) => {
    setCommunicationRating(e.target.value);
  };

  const onSubmitRating = (e) => {
    let percent =
      (parseFloat(communicationRating) +
        parseFloat(cleanlinessRating) +
        parseFloat(organizationRating) +
        parseFloat(safenessRating)) /
      20;

    let overallScore = 5 * percent;

    let reviewObject = {
      jobId: props.jrData.job_id,
      communication: communicationRating,
      cleanliness: cleanlinessRating,
      organization: organizationRating,
      safeness: safenessRating,
      aggregate: overallScore,
      comments: comments,

      activityDesc: `Vendor reviewed client.`,
      userName: `${user.first_name} ${user.last_name}`
    };

    axios.post("/review/newVendorReview", reviewObject).then((res) => {
      props.fetchData();
      // props.fetchReviewData();
      // props.fetchActivityData();
      closeReview();
    });
  };

  return (
    <div className="phase">
      <Modal
        destroyOnClose={true}
        width="400px"
        title="Leave Review"
        open={props.showReview}
        onCancel={() => props.setShowReview(false)}
        footer={false}
      >
        {props.showReview && (
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
              <h5>Please take the time to review the client:</h5>
              <label htmlFor="comments">Comments</label>
              <input
                onChange={(e) => setComments(e.target.value)}
                className="comments"
                type="text"
                id="comments"
              ></input>
              <h4>Job Site Safeness:</h4>
              <div className="wrapper">
                <input
                  onChange={onChangeSafeness}
                  value="5"
                  type="radio"
                  id="safeness-1-star"
                  name="safenessRating"
                />
                <label htmlFor="safeness-1-star">★</label>
                <input
                  onChange={onChangeSafeness}
                  value="4"
                  type="radio"
                  id="safeness-2-star"
                  name="safenessRating"
                />
                <label htmlFor="safeness-2-star">★</label>
                <input
                  onChange={onChangeSafeness}
                  value="3"
                  type="radio"
                  id="safeness-3-star"
                  name="safenessRating"
                />
                <label htmlFor="safeness-3-star">★</label>
                <input
                  onChange={onChangeSafeness}
                  value="2"
                  type="radio"
                  id="safeness-4-star"
                  name="safenessRating"
                />
                <label htmlFor="safeness-4-star">★</label>
                <input
                  onChange={onChangeSafeness}
                  value="1"
                  type="radio"
                  id="safeness-5-star"
                  name="safenessRating"
                />
                <label htmlFor="safeness-5-star">★</label>
              </div>

              <h4>Job Site Organization:</h4>
              <div className="wrapper">
                <input
                  onChange={onChangeOrganization}
                  value="5"
                  type="radio"
                  id="organization-1-star"
                  name="organizationRating"
                />
                <label htmlFor="organization-1-star">★</label>
                <input
                  onChange={onChangeOrganization}
                  value="4"
                  type="radio"
                  id="organization-2-star"
                  name="organizationRating"
                />
                <label htmlFor="organization-2-star">★</label>
                <input
                  onChange={onChangeOrganization}
                  value="3"
                  type="radio"
                  id="organization-3-star"
                  name="organizationRating"
                />
                <label htmlFor="organization-3-star">★</label>
                <input
                  onChange={onChangeOrganization}
                  value="2"
                  type="radio"
                  id="organization-4-star"
                  name="organizationRating"
                />
                <label htmlFor="organization-4-star">★</label>
                <input
                  onChange={onChangeOrganization}
                  value="1"
                  type="radio"
                  id="organization-5-star"
                  name="organizationRating"
                />
                <label htmlFor="organization-5-star">★</label>
              </div>

              <h4>Job Site Cleanliness:</h4>
              <div className="wrapper">
                <input
                  onChange={onChangeCleanliness}
                  value="5"
                  type="radio"
                  id="cleanliness-1-star"
                  name="cleanlinessRating"
                />
                <label htmlFor="cleanliness-1-star">★</label>
                <input
                  onChange={onChangeCleanliness}
                  value="4"
                  type="radio"
                  id="cleanliness-2-star"
                  name="cleanlinessRating"
                />
                <label htmlFor="cleanliness-2-star">★</label>
                <input
                  onChange={onChangeCleanliness}
                  value="3"
                  type="radio"
                  id="cleanliness-3-star"
                  name="cleanlinessRating"
                />
                <label htmlFor="cleanliness-3-star">★</label>
                <input
                  onChange={onChangeCleanliness}
                  value="2"
                  type="radio"
                  id="cleanliness-4-star"
                  name="cleanlinessRating"
                />
                <label htmlFor="cleanliness-4-star">★</label>
                <input
                  onChange={onChangeCleanliness}
                  value="1"
                  type="radio"
                  id="cleanliness-5-star"
                  name="cleanlinessRating"
                />
                <label htmlFor="cleanliness-5-star">★</label>
              </div>

              <h4>Communication:</h4>
              <div className="wrapper">
                <input
                  onChange={onChangeCommunication}
                  value="5"
                  type="radio"
                  id="communication-1-star"
                  name="commmunicationRating"
                />
                <label htmlFor="communication-1-star">★</label>
                <input
                  onChange={onChangeCommunication}
                  value="4"
                  type="radio"
                  id="communication-2-star"
                  name="commmunicationRating"
                />
                <label htmlFor="communication-2-star">★</label>
                <input
                  onChange={onChangeCommunication}
                  value="3"
                  type="radio"
                  id="communication-3-star"
                  name="commmunicationRating"
                />
                <label htmlFor="communication-3-star">★</label>
                <input
                  onChange={onChangeCommunication}
                  value="2"
                  type="radio"
                  id="communication-4-star"
                  name="commmunicationRating"
                />
                <label htmlFor="communication-4-star">★</label>
                <input
                  onChange={onChangeCommunication}
                  value="1"
                  type="radio"
                  id="communication-5-star"
                  name="commmunicationRating"
                />
                <label htmlFor="communication-5-star">★</label>
              </div>

              <div>
                <Button
                  size="small"
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onSubmitRating();
                    closeReview();
                  }}
                  sx={{ ml: 1, float: "right" }}
                >
                  Submit Rating
                </Button>
                <Button
                  size="small"
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    closeReview();
                  }}
                  sx={{ ml: 1, float: "right" }}
                >
                  Cancel
                </Button>
              </div>
            </Stack>
          </Grid>
        )}
      </Modal>
    </div>
  );
}

export default VendorReview;
