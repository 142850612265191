import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal, Checkbox } from "antd";
import axios from "axios";
import * as Yup from "yup";
import {
  Button,
  Divider,
  Grid,
  Link,
  InputLabel,
  OutlinedInput,
  Stack,
  Select,
  Typography,
  MenuItem
} from "@mui/material";
import { Formik } from "formik";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import AuthWrapper from "../auth-pages/AuthWrapper";
import TermsAndConditions from "../../PDF/TermsViewer";
import PrivacyPolicy from "../../PDF/PrivacyViewer";
import NewPaymentMethod from "../../NewPaymentMethod";
import LoadingSpinner from "../../../Layout/components/LoadingSpinner";

const CompanyIntakeDetails = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);
  const [activeCreateAccount, setActiveCreateAccount] = useState(false);
  const [termsIsRead, setTermsIsRead] = useState(false);
  const [policyIsRead, setPolicyIsRead] = useState(false);
  const [termsReadError, setTermsReadError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [creditCardModal, setCreditCardModal] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);
  const [clientID, setClientID] = useState();
  const [locationNotFound, setLocationNotFound] = useState(false);

  const onFinish = () => {
    navigate("/accountcreated");
  };

  const openTermsAndConditions = () => {
    setIsOpenTerms(true);
    setTermsIsRead(true);
  };
  const closeTermsAndConditions = () => {
    setIsOpenTerms(false);
  };

  const openPrivatePolicy = () => {
    setPolicyIsRead(true);
    setIsOpenPolicy(true);
  };
  const closePrivatePolicy = () => {
    setIsOpenPolicy(false);
  };

  const services = [
    "Access & Security",
    "Appliance Repair",
    "Brick Layer",
    "Building Supplies",
    "Cabling and Data",
    "Carpenter",
    "Electrical",
    "Environmental",
    "Fire Protection",
    "Flooring",
    "Gas Fitter",
    "General Repair",
    "Glass Wholesale",
    "Heating",
    "Home Inspector",
    "Hot Tub Service",
    "HVAC",
    "Landscaping",
    "Lock Smith",
    "Miscellaneaous Supply",
    "Oil Burner Mechanic",
    "Paint Supply",
    "Pest Control",
    "Plaster & Paint Service",
    "Plumbing Supply",
    "Propane Service",
    "Property Management",
    "Property Restoration",
    "Refrigeration & AC",
    "Roofing",
    "Sheet Metal Worker",
    "Snow Clearing",
    "Tile Setter",
    "Ventilation",
    "Waste Management",
    "Windows & Doors",
    "Other"
  ];

  const onSubmit = async (values) => {
    console.log(values, location.state);
    setSubmitting(true);
    try {
      console.log(`(${values.service})`);
      await axios
        .post("/auth/register", {
          Client: 1,
          email: values.companyEmail,
          AdminEmail: location.state.email,
          password: location.state.password,
          Vendor: 1,
          // Employee: 1,
          DOB: new Date(location.state.DOB),
          // Gender: location.state.Gender,
          host: window.location.host,
          Terms: `${values.companyLegalName}2023T&C`,
          Policy: `${values.companyLegalName}2023PP`,
          FirstName: location.state.FirstName,
          LastName: location.state.LastName,
          CompanyLegalName: values.companyLegalName,
          companyDbaName: values.companyDbaName,
          PhoneNumber: location.state.PhoneNumber,
          BusinessNumber: values.businessNumber,
          HSTRegistrationNumber: values.taxID,
          Services: `${values.service}`,
          Street: location.state.Street,
          City: location.state.City,
          Province: location.state.Province,
          PostalCode: location.state.PostalCode,
          // Esign: location.state.Esign,
          vendorMode: true
          // InstitutionCode: values.institutionCode,
          // TransitCode: values.transitCode,
          // AccountNumber: values.accountNumber,
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.ok) {
            //email & password correct
            setLocationNotFound(false);
            setClientSecret(res.data.clientSecret);
            setClientID(res.data.clientID);
            // setCreditCardModal(true);
            setSubmitted(true);
            onFinish();
          } else if (res.data.notFound) {
            setLocationNotFound(true);
          }
        });
    } catch (err) {
      console.error(err.message);
      setSubmitting(false);
    }
  };

  return (
    <LoadingSpinner isActive={submitting}>
      <AuthWrapper>
        <Formik
          initialValues={{
            companyEmail: "",
            companyLegalName: "",
            companyDbaName: "",
            businessNumber: "",
            taxID: "",
            service: []
            // institutionCode: "",
            // transitCode: "",
            // accountNumber: "",
          }}
          validationSchema={Yup.object().shape({
            companyLegalName: Yup.string()
              .max(255)
              .required("First Name is required"),
            companyDbaName: Yup.string()
              .max(255)
              .required("First Name is required"),
            companyEmail: Yup.string()
              .email()
              .max(255)
              .required("email is required"),
            businessNumber: Yup.string()
              .max(255)
              .required("First Name is required"),
            taxID: Yup.string().max(255).required("First Name is required"),
            service: Yup.string().max(255).required("Service is required")
            // institutionCode: Yup.string()
            //   .max(3)
            //   .required("Institution code is required"),
            // transitCode: Yup.string()
            //   .max(5)
            //   .required("Transit code is required"),
            // accountNumber: Yup.string()
            //   .max(7)
            //   .required("Account number is required"),
          })}
          onSubmit={onSubmit}
        >
          {({ handleChange, handleBlur, handleSubmit, values }) => (
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="companyLegalName-signup">
                      Company Legal Name*
                    </InputLabel>
                    <OutlinedInput
                      required
                      id="companyLegalName-signup"
                      type="companyLegalName"
                      value={values.companyLegalName}
                      name="companyLegalName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Legal Name"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="companyDbaName-signup">
                      Company DBA Name*
                    </InputLabel>
                    <OutlinedInput
                      required
                      id="companyDbaName-signup"
                      type="companyDbaName"
                      value={values.companyDbaName}
                      name="companyDbaName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="DBA Name"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="businessNumber-signup">
                      Business Number
                    </InputLabel>
                    <OutlinedInput
                      required
                      id="businessNumber-signup"
                      type="businessNumber"
                      value={values.businessNumber}
                      name="businessNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Business Number"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="taxID-signup">
                      HST Registration #
                    </InputLabel>
                    <OutlinedInput
                      required
                      id="taxID-signup"
                      type="taxID"
                      value={values.taxID}
                      name="taxID"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Tax ID"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <Stack>
                      <InputLabel>Provided Service</InputLabel>
                      <Select
                        required
                        id="service"
                        name="service"
                        value={values.service}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiple
                      >
                        {services.map((service, i) => (
                          <MenuItem value={service} key={`${service}_service`}>
                            {service}
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>Email</InputLabel>
                    <OutlinedInput
                      required
                      name="companyEmail"
                      id="companyEmail"
                      type="email"
                      value={values.companyEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Company Email"
                    />
                  </Stack>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>Bank institution code</InputLabel>
                    <OutlinedInput
                      required
                      name="institutionCode"
                      id="institutionCode"
                      type="text"
                      value={values.institutionCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Institution Code"
                    />
                  </Stack>
                </Grid> */}
                {/* <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>Branch transit code</InputLabel>
                    <OutlinedInput
                      required
                      name="transitCode"
                      id="transitCode"
                      type="text"
                      value={values.transitCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Branch transit code"
                    />
                  </Stack>
                </Grid> */}
                {/* <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>Account number</InputLabel>
                    <OutlinedInput
                      required
                      name="accountNumber"
                      id="accountNumber"
                      type="text"
                      value={values.accountNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Account number"
                    />
                  </Stack>
                </Grid> */}

                <Grid item xs={12}>
                  <Typography variant="body2">
                    <Checkbox
                      checked={activeCreateAccount}
                      onChange={(e) => {
                        if (termsIsRead && policyIsRead) {
                          setActiveCreateAccount(e.target.checked);
                          setTermsReadError(false);
                        } else {
                          setTermsReadError(true);
                        }
                      }}
                    />
                    &nbsp; I have read and agreed to &nbsp;
                    <Link
                      color={"#188bff"}
                      variant="subtitle2"
                      onClick={() => openTermsAndConditions()}
                    >
                      Terms of Service
                    </Link>
                    &nbsp; and &nbsp;
                    <Link
                      color={"#188bff"}
                      variant="subtitle2"
                      onClick={() => openPrivatePolicy()}
                    >
                      Privacy Policy
                    </Link>
                  </Typography>
                  <Typography color={"#ff0000"}>
                    {termsReadError &&
                      "*You must read the terms and conditions & privacy policy"}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      disabled={!activeCreateAccount}
                      fullWidth
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                      }}
                      size="large"
                      type="button"
                      variant="contained"
                      color="primary"
                    >
                      Create Account
                    </Button>
                  </AnimateButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>

        {/* <Modal
          title="Add Payment Details"
          open={creditCardModal}
          onCancel={onFinish}
          footer={false}
          bodyStyle={{ overflowY: "auto", maxHeight: "calc(90vh - 200px)" }}
        >
          <NewPaymentMethod
            onSuccess={onFinish}
            clientSecret={clientSecret}
            successUrl={window.location.origin + "/accountcreated"}
          />
        </Modal> */}

        {/* Open Terms and Conditions */}
        <Modal
          title="Terms And Conditions"
          width="700px"
          open={isOpenTerms}
          onCancel={closeTermsAndConditions}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={closeTermsAndConditions}
            >
              Okay
            </Button>
          ]}
          bodyStyle={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 250px)"
          }}
        >
          {isOpenTerms && (
            <TermsAndConditions
              signIn={true}
              openPolicy={() => {
                closeTermsAndConditions();
                openPrivatePolicy();
              }}
            />
          )}
        </Modal>

        {/* Open Private Policy */}
        <Modal
          title="Privacy Policy"
          width="700px"
          open={isOpenPolicy}
          onCancel={closePrivatePolicy}
          footer={[
            <Button key="submit" type="primary" onClick={closePrivatePolicy}>
              Okay
            </Button>
          ]}
          bodyStyle={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 250px)"
          }}
        >
          {isOpenPolicy && <PrivacyPolicy signIn={true} />}
        </Modal>
      </AuthWrapper>
    </LoadingSpinner>
  );
};

export default CompanyIntakeDetails;
