import moment from "moment";

import { Grid, Typography, useMediaQuery } from "@mui/material";
import ScopeTable from "../Scope/ScopeTable";

import { useUserState } from "../../../../Context/UserContext";

/**
 * @typedef ScopeLine
 * @type {Object}
 * @property {number} id
 * @property {number} quantity
 * @property {number} amount
 * @property {string} type
 * @property {number} tax
 */

/**
 * creates a table showing the scope/bid
 * @param {{invoice:{invoiceLines:ScopeLine[], id:number, amount:number, date:string, subtotal:number, total:number, tax:number}, chargePercentages:Object}} props
 * @returns {React.JSX.Element}
 */

const InvoiceTable = ({ invoice, chargePercentages }) => {
  console.log("InvoiceTable", invoice, chargePercentages);
  const { user } = useUserState();

  return (
    <table width={"100%"} style={{ marginTop: "1rem" }}>
      <tbody width={"100%"}>
        <InvoiceLines lines={invoice?.invoiceLines} />

        <tr>
          <td style={{ textAlign: "left", width: "min-content" }}>
            <strong
              style={{
                color: "black",
                fontSize: "0.85rem",
                fontWeight: "600"
              }}
            >
              Total {user.vendorMode ? "payout" : null}
            </strong>
          </td>
          <td
            style={{
              borderTop: "2px solid black",
              textAlign: "right",
              width: "min-content"
            }}
          >
            {`$${
              user.vendorMode
                ? invoice?.total_payout
                : invoice?.total_received_amount
            }`}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

ScopeTable.defaultProps = {
  chargePercentages: {
    vendorPercentage: 0,
    clientPercentage: 0
  },
  isInvoice: false
};

/**
 * displays lines for bid/scope display
 * @param {{lines:ScopeLines[]}} props
 * @returns {React.JSX.Element[]}
 */
const InvoiceLines = ({ lines }) => {
  const { user } = useUserState();
  return lines?.map((line) => {
    console.log(line);
    const isMaterials = line.type === "Materials";
    const isTax = line.type === "Tax";
    const isCharge = line.type === "Charge";
    if (isMaterials) {
      return (
        <tr key={`scopeLine_${line.id}`}>
          <td style={{ textAlign: "left", width: "min-content" }}>
            <strong
              style={{
                color: "black",
                fontSize: "0.85rem",
                fontWeight: "600"
              }}
            >
              Material Cost
            </strong>
            {line.work_description && (
              <p style={{ margin: 0 }}>({line.work_description})</p>
            )}
          </td>
          <td style={{ textAlign: "right", width: "min-content" }}>
            {`$${parseInt(line.amount * line.quantity).toFixed(2)}`}
          </td>
        </tr>
      );
    } else if (isTax) {
      return (
        <tr key={`scopeLine_${line.id}`}>
          <td style={{ textAlign: "left", width: "min-content" }}>
            <strong
              style={{
                color: "black",
                fontSize: "0.85rem",
                fontWeight: "600"
              }}
            >
              HST{user.vendorMode ? " collected" : null}
            </strong>
          </td>
          <td
            style={{
              textAlign: "right",
              width: "min-content"
            }}
          >
            {`$${line.amount.toFixed(2)}`}
          </td>
        </tr>
      );
    } else if (isCharge) {
      return (
        <tr key={`scopeLine_${line.id}`}>
          <td style={{ textAlign: "left", width: "min-content" }}>
            <strong
              style={{
                color: "black",
                fontSize: "0.85rem",
                fontWeight: "600"
              }}
            >
              Service Fee{user.vendorMode ? "(with tax)" : null}
            </strong>
          </td>
          <td
            style={{
              textAlign: "right",
              width: "min-content"
            }}
          >
            {console.log("service fee line", line)}
            {`$${
              user.vendorMode
                ? (line.amount + line.tax).toFixed(2)
                : line.amount.toFixed(2)
            }`}
          </td>
        </tr>
      );
    } else {
      const isFlat = line.type.includes("Flat");
      const chargeType = isFlat ? "Flat Rate Charge" : "Labour Charge";
      return (
        <tr key={`scopeLine_${line.id}`}>
          <td style={{ textAlign: "left", width: "min-content" }}>
            <strong
              style={{
                color: "black",
                fontSize: "0.85rem",
                fontWeight: "600"
              }}
            >
              {chargeType}
            </strong>
            {line.work_description && (
              <p style={{ margin: 0 }}>({line.work_description})</p>
            )}
          </td>
          <td style={{ textAlign: "right", width: "min-content" }}>
            {isFlat
              ? `$${parseInt(line.amount).toFixed(2)}`
              : `$${parseInt(line.amount).toFixed(2)}`}
          </td>
        </tr>
      );
    }
  });
};

const Invoice = (props) => {
  const mobileView = useMediaQuery("(max-width: 600px)");
  console.log(props.invoice);
  return (
    <Grid className="invoiceDetails" container spacing={1}>
      <Grid item md={12}>
        <div style={{ width: mobileView ? 350 : "" }}>
          <div
            className="invoiceDiv"
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div>
              <Typography>
                <b>Invoice Id: </b>
                {props.invoice?.id}
              </Typography>
            </div>
            <div>
              <Typography>
                <b>Invoice Date:</b>{" "}
                {moment(props.invoice?.date).format("MMM Do YYYY")}
              </Typography>
            </div>
          </div>

          <div className="invoiceDiv">
            <Typography style={{ marginTop: "1rem" }}>
              {props.vendorInvoiceDetails.vendor_legal_name}
            </Typography>
            <Typography>
              {props.vendorInvoiceDetails.street}{" "}
              {props.vendorInvoiceDetails.postal_code}
            </Typography>
            <Typography>
              {props.vendorInvoiceDetails.city},{" "}
              {props.vendorInvoiceDetails.province} Canada
            </Typography>
            <Typography>{props.vendorInvoiceDetails.phone_number}</Typography>
          </div>
          <Typography style={{ marginTop: "1rem" }}>
            <b>Bill To:</b>
          </Typography>
          <div className="invoiceDiv">
            <Typography>
              {props.clientInvoiceDetails.first_name}{" "}
              {props.clientInvoiceDetails.last_name}
            </Typography>
            <Typography>
              {props.clientInvoiceDetails.street}{" "}
              {props.clientInvoiceDetails.postal_code}
            </Typography>
            <Typography>
              {props.clientInvoiceDetails.city},{" "}
              {props.clientInvoiceDetails.province} Canada
            </Typography>
            <Typography>{props.clientInvoiceDetails.phone_number}</Typography>
          </div>

          <InvoiceTable
            invoice={props.invoice}
            chargePercentages={props.chargePercentages}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        marginTop={"0.5rem"}
        textAlign={"left"}
        width={"60%"}
        height={"100%"}
        border={"1px solid #dddddd"}
      >
        <Typography variant="h5">Notes:</Typography>
        <Typography variant="h6">{props.invoice?.invoice_notes}</Typography>
      </Grid>
    </Grid>
  );
};

export default Invoice;
