import { Grid } from "@mui/material";
import { Modal } from "antd";
import { useState } from "react";
import CreateInvoice from "../Invoice/CreateInvoice";

import moment from "moment-timezone";
import { useUserState } from "../../../../Context/UserContext";
import Invoice from "../Invoice/Invoice";
moment.tz.setDefault("Canada/Newfoundland");

function ExpandedTilePhase3(props) {
  const [displayInvoice, setDisplayInvoice] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const { user } = useUserState();

  const closeInvoice = () => {
    setDisplayInvoice(false);
  };

  return (
    <div className="phase">
      {props.jrData.job_status === "Invoicing" &&
        user.empRole === ("Admin" || "Manager") && (
          <button
            onClick={(e) => {
              setDisplayInvoice(true);
            }}
            id="review-button"
            className="styledButton"
          >
            Create Invoice
          </button>
        )}
      <Modal
        destroyOnClose={true}
        width="700px"
        title="View Invoice"
        open={showInvoice}
        onCancel={() => setShowInvoice(false)}
        footer={false}
      >
        <Invoice
          clientInvoiceDetails={props.clientInvoiceDetails[0]}
          vendorInvoiceDetails={props.vendorInvoiceDetails[0]}
          invoice={props.invoice}
          invoiceDetails={props.invoiceDetails}
          jrData={props.jrData}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        width="700px"
        open={displayInvoice}
        onCancel={() => setDisplayInvoice(false)}
        footer={false}
      >
        {displayInvoice && (
          <CreateInvoice
            fetchData={props.fetchData}
            clientInvoiceDetails={props.clientInvoiceDetails[0]}
            vendorInvoiceDetails={props.vendorInvoiceDetails[0]}
            activityList={props.activityList}
            jrData={props.jrData}
            onClose={closeInvoice}
          />
        )}
      </Modal>
    </div>
  );
}

export default ExpandedTilePhase3;
