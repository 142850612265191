import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker, Image, Modal, TimePicker, Typography } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useUserState } from "../../../../Context/UserContext";
import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import moment from "moment-timezone";
import EditTroubleshooting from "./EditTroubleshooting";
moment.tz.setDefault("Canada/Newfoundland");

function AddTroubleshooting(props) {
  const { user } = useUserState();
  const [jobTime, setJobTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [file, setFile] = useState("");
  const [showTextbox, setShowTextbox] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [text, setText] = useState("");

  const [currentId, setCurrentId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [currentTroubleshootId, setCurrentTroubleshootId] = useState();

  const [notEmployee, setNotEmployee] = useState("");

  const closetNotEmployee = () => {
    setNotEmployee(false);
  };

  const importFile = () => {
    let newArray;
    if (attachments !== undefined) {
      newArray = [...attachments];
    } else {
      newArray = [];
    }
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = () => {
      newArray.push(...input.files);
      setAttachments(2);
      setAttachments(newArray);
    };
    input.click();
  };

  const removeAttachment = (id) => {
    const newArray = [...attachments];
    newArray.splice(id, 1);
    if (newArray.length === 0) {
      setAttachments("");
    } else {
      setAttachments(0);
      setAttachments(newArray);
      setFile("");
      setFile(newArray);
    }
  };

  const createAttachmentDisplay = (attachments) => {
    const attachmentList = [];
    for (let i in attachments) {
      attachmentList.push(
        <div
          onClick={() => removeAttachment(i)}
          key={attachments[i].name + attachments[i].size}
          className="attachment"
        >
          {attachments[i].type.includes("image") ? (
            <img
              className="testIMG"
              src={URL.createObjectURL(attachments[i])}
              alt=""
            />
          ) : (
            attachments[i].name
          )}
        </div>
      );
    }
    return (
      <>
        <div>
          <p>Attachments: </p>
        </div>
        {attachmentList}
      </>
    );
  };

  const handleEditTroubleshoot = () => {
    user.vendorMode ? setShowEditForm(true) : setNotEmployee(true);
  };

  const handleDeleteTroubleshoot = () => {
    setShowDeleteModal(true);
  };

  const handleSubmit = (e) => {
    console.log(typeof jobTime);
    console.log(jobTime);
    const formObject = {
      JobId: props.jr.job_id,
      startDate: startDate,
      startTime: new Date(jobTime).toISOString(),
      troubleshootDesc: text,
      entryDate: new Date(),
      activityDesc: `Created troubleshooting entry "${text}"`,
      userName: `${user.first_name} ${user.last_name}`,
      eventID: props.eventId,
    };

    axios
      .post("/troubleshoot/addTroubleshootEntry", formObject)
      .then((response) => {
        let troubleshootId = response.data.troubleshootId;
        setCurrentTroubleshootId(troubleshootId);
        let JobId = props.jr.job_id;
        if (response.data.ok) {
          if (attachments.length > 0) {
            let form = new FormData();

            for (const element of attachments) {
              form.append("file", element);
            }
            form.append("troubleshoot_id", troubleshootId);
            form.append("job_id", JobId);

            axios
              .post("/troubleshoot/addTroubleshootingImages", form)
              .then((res) => {
                props.setTroubleShootingModal(false);
                props.fetchData();
                // props.fetchTroubleshootData();
                // props.fetchActivityData();
              });
          } else {
            props.setTroubleShootingModal(false);
            props.fetchData();
            // props.fetchTroubleshootData();
            // props.fetchActivityData();
            // props.fetchEventData();
          }
        }
      });
  };

  return (
    <>
      <div>
        <br></br>
        <div className="buttonContainer">
          {props.troubleshootDisplay && (
            <label className="categoryLabel" htmlFor="addButton">
              Troubleshooting
            </label>
          )}

          {props.jr.job_status === "Scheduling" &&
            user.empRole !== null &&
            !props.troubleshootDisplay && (
              <Modal
                destroyOnClose={true}
                width="700px"
                title={"Add Troubleshooting Entry"}
                open={props.troubleshootingModal}
                onCancel={() => props.setTroubleShootingModal(false)}
                footer={false}
              >
                <form>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <InputLabel>Start Date</InputLabel>
                        <DatePicker
                          required
                          label="Start Time"
                          name="startTime"
                          showTime={{
                            format: "HH:mm a",
                          }}
                          format={"YYYY-MM-DD HH:mm"}
                          onChange={(e, dateString) => {
                            setStartDate(dateString);
                            setJobTime(dateString);
                          }}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Stack spacing={1}>
                        <InputLabel>Description</InputLabel>
                        <OutlinedInput
                          required
                          id="troubleshootingDescription"
                          name="troubleshootingDescription"
                          value={text}
                          onChange={(e) => setText(e.target.value)}
                          placeholder="Troubleshooting Description"
                          multiline
                          rows={4}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <InputLabel>Pictures/Documents</InputLabel>
                        <Button
                          size="sm"
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={importFile}
                          id="file"
                          name="file"
                        >
                          Choose File
                        </Button>

                        {attachments !== undefined &&
                          attachments.length !== 0 && (
                            <div className="attachments">
                              {createAttachmentDisplay(attachments)}
                            </div>
                          )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Stack spacing={1}>
                        <AnimateButton>
                          <Button
                            size="sm"
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              handleSubmit();
                            }}
                            sx={{ ml: 2, float: "right" }}
                          >
                            Submit
                          </Button>

                          <Button
                            size="sm"
                            type="button"
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              props.setTroubleShootingModal(false);
                            }}
                            sx={{ ml: 2, float: "right" }}
                          >
                            Cancel
                          </Button>
                        </AnimateButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              </Modal>
            )}
        </div>
        <div>
          {showEditForm && (
            <EditTroubleshooting
              showEditForm={showEditForm}
              setShowEditForm={setShowEditForm}
              jobID={props.jr.job_id}
              // fetchTroubleshootData={props.fetchTroubleshootData}
              // fetchActivityData={props.fetchActivityData}
              fetchData={props.fetchData}
              // fetchEventData={props.fetchEventData}
            />
          )}

          {props.troubleshootDisplay && (
            <table className="table">
              <tbody>
                {props.troubleshootingList?.map((item, i) =>
                  item.is_terminated === 0 ? (
                    <tr
                      className="tableRowHover"
                      style={{
                        height: "1.7rem",
                      }}
                      key={item.troubleshoot_id}
                    >
                      {console.log(item.start_date)}
                      <td style={{ textAlign: "center" }} className="td">
                        {moment(new Date(item.start_time)).format(
                          "MMM Do YYYY, h:mm a"
                        )}
                      </td>
                      <td style={{ textAlign: "center" }} className="td">
                        {item.troubleshoot_description}
                      </td>
                      <td style={{ textAlign: "center" }} className="td">
                        {props.troubleshootingImages?.map((image, i) => {
                          if (item.troubleshoot_id === image.troubleshoot_id) {
                            return (
                              <Image key={i} width={30} src={image.image} />
                            );
                          }
                        })}
                      </td>
                      {props.jr.job_status === "Scheduling" &&
                      props.events[0].completed === 0 ? (
                        <td style={{ textAlign: "center" }}>
                          <Button
                            style={{
                              maxWidth: "0.1rem",
                              minWidth: "0.1rem",
                            }}
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              setJobTime("");
                              setStartDate("");
                              setCurrentId(i);
                              handleEditTroubleshoot();
                            }}
                          >
                            <EditOutlined />
                          </Button>
                        </td>
                      ) : (
                        <td style={{ textAlign: "center", color: "green" }}>
                          Completed
                        </td>
                      )}
                    </tr>
                  ) : (
                    ""
                  )
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Not Employee"
        open={notEmployee}
        onCancel={closetNotEmployee}
        footer={false}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography>
              You are not an employee. The employee who created this entry has
              to update it.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <AnimateButton>
              <Button
                size="sm"
                type="button"
                variant="contained"
                color="primary"
                onClick={closetNotEmployee}
                sx={{ ml: 2, float: "right" }}
              >
                Okay
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

export default AddTroubleshooting;
