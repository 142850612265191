import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TermsAndConditions from "../../PDF/TermsViewer";
import PrivacyPolicy from "../../PDF/PrivacyViewer";
import { Modal, Checkbox } from "antd";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import GetProvinceList from "./GetProvinceList";

import {
  Box,
  Button,
  FormControl,
  Grid,
  Link,
  InputLabel,
  OutlinedInput,
  Stack,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
  Divider
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";

import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import AuthWrapper from "../auth-pages/AuthWrapper";
import LoadingSpinner from "../../../Layout/components/LoadingSpinner";
import DatePicker from "../../../Components/FormComponents/DatePicker";
import PhoneInput from "../../../Components/FormComponents/PhoneInput";
import axios from "axios";

const ClientIntakeDetails = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);
  const [policyIsRead, setPolicyIsRead] = useState(false);
  const [termsReadError, setTermsReadError] = useState(false);
  const [termsIsRead, setTermsIsRead] = useState(false);

  const [locationNotFound, setLocationNotFound] = useState(false);

  const [activeCreateAccount, setActiveCreateAccount] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const openTermsAndConditions = () => {
    setIsOpenTerms(true);
    setTermsIsRead(true);
  };

  const closeTermsAndConditions = () => {
    setIsOpenTerms(false);
  };
  const openPrivatePolicy = () => {
    setPolicyIsRead(true);
    setIsOpenPolicy(true);
  };
  const closePrivatePolicy = () => {
    setIsOpenPolicy(false);
  };

  const sign = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  const onSubmit = (values) => {
    setSubmitting(true);
    const {
      firstname,
      lastname,
      dob,
      // gender,
      streetAddress,
      city,
      province,
      postalCode,
      phone
    } = values;

    let Esignature = sign(15);

    console.log(dob);
    axios
      .post("/auth/checkLocation", {
        Street: values.streetAddress,
        City: values.city,
        Province: values.province
      })
      .then((response) => {
        if (!response.data.found) {
          setLocationNotFound(true);
        } else {
          setLocationNotFound(false);
          navigate("/signup/vendorDecision", {
            state: {
              email: state.email,
              password: state.password,
              Terms: `${firstname}-${lastname}2023T&C`,
              Policy: `${firstname}-${lastname}2023PP`,
              host: window.location.host,
              Client: state.isClient,
              FirstName: firstname,
              LastName: lastname,
              PhoneNumber: phone,
              DOB: `${dob}`,
              // Gender: gender,
              Street: streetAddress,
              City: city,
              Province: province,
              PostalCode: postalCode,
              Esign: Esignature
            }
          });
        }
        setSubmitting(false);
      });
  };

  return (
    <LoadingSpinner isActive={submitting}>
      <AuthWrapper>
        <Formik
          initialValues={{
            firstname: "",
            lastname: "",
            phone: "",
            dob: "",
            // gender: "",
            streetAddress: "",
            city: "",
            province: "",
            postalCode: "",
            submit: null
          }}
          validationSchema={Yup.object().shape({
            firstname: Yup.string().max(255).required("First Name is required"),
            lastname: Yup.string().max(255).required("Last Name is required"),

            phone: Yup.string()
              .min(17, "Phone number must be at least 10 characters")
              .max(255)
              .required("Phone number is required"),
            dob: Yup.date().nullable().required("Birth Date is required"),

            streetAddress: Yup.string()
              .max(255)
              .required("Street Address is missing"),
            city: Yup.string().max(255).required("City is missing"),
            province: Yup.string().max(255).required("Province is missing")
            // postalCode: Yup.string().max(255).required("Postal code is missing")
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            values,
            setValues
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <Divider
                      sx={{
                        color: "#46505A",
                        fontSize: 34,
                        fontWeight: "medium"
                      }}
                    >
                      Client Details
                    </Divider>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>First Name*</InputLabel>
                    <OutlinedInput
                      id="firstname-signup"
                      type="firstname"
                      value={values.firstname}
                      name="firstname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="First Name"
                    />
                    {touched.firstname && errors.firstname && (
                      <FormHelperText error id="helper-text-firstname-signup">
                        {errors.firstname}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>Last Name*</InputLabel>
                    <OutlinedInput
                      id="lastname-signup"
                      type="lastname"
                      value={values.lastname}
                      name="lastname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Last Name"
                    />
                    {touched.lastname && errors.lastname && (
                      <FormHelperText error id="helper-text-lastname-signup">
                        {errors.lastname}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack>
                    <InputLabel>Phone</InputLabel>
                    <PhoneInput
                      name="phone"
                      value={values.phone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Stack>
                  {touched.phone && errors.phone && (
                    <FormHelperText error id="helper-text-lastname-signup">
                      {errors.phone}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>Date of Birth</InputLabel>
                    <DatePicker
                      required
                      name="dob"
                      value={values.dob}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {touched.dob && errors.dob && (
                      <FormHelperText error id="helper-text-dob-signup">
                        {errors.dob}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>Gender</InputLabel>
                    <Select
                      id="gender-signup"
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                    >
                      <MenuItem value={"M"}>Male</MenuItem>
                      <MenuItem value={"F"}>Female</MenuItem>
                      <MenuItem value={"O"}>Other</MenuItem>
                    </Select>
                  </Stack>
                </Grid> */}

                <Grid item xs={12} md={12}>
                  {" "}
                  <GooglePlacesAutocomplete
                    className={"css-1hb7zxy-IndicatorsContainer"}
                    apiKey={process.env.GOOGLE_API_KEYS}
                    autocompletionRequest={{
                      componentRestrictions: { country: "ca" }, // Restrict to Canada (CA)
                      types: ["address"]
                    }}
                    selectProps={{
                      name: "streetAddress",
                      value: values.streetAddress,
                      onChange: (selectedOption) => {
                        console.log(selectedOption);

                        const addressComponents =
                          selectedOption.label.split(", ");
                        const street = addressComponents[0];
                        const city = addressComponents[1];
                        const province = addressComponents[2];

                        // Update city and province fields in Formik values
                        setValues({
                          ...values,
                          streetAddress: street,
                          city: city,
                          province: province
                        });
                      },
                      placeholder: "Find address here"
                    }}
                  />
                  <style>
                    {`
          .css-1hb7zxy-IndicatorsContainer {
            display: none; // Hide dropdown icon
          }
        `}
                  </style>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    {console.log(values.streetAddress)}
                    <InputLabel>Street Address</InputLabel>
                    <OutlinedInput
                      id="streetAddress-signup"
                      type="streetAddress"
                      value={values.streetAddress}
                      name="streetAddress"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Street Address"
                    />

                    {touched.streetAddress && errors.streetAddress && (
                      <FormHelperText
                        error
                        id="helper-text-streetAddress-signup"
                      >
                        {errors.streetAddress}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>City</InputLabel>
                    {console.log(values.city)}
                    <OutlinedInput
                      id="city-signup"
                      type="city"
                      value={values.city}
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="city"
                    />
                    {touched.city && errors.city && (
                      <FormHelperText error id="helper-text-city-signup">
                        {errors.city}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>Province</InputLabel>
                    <Select
                      id="province-signup"
                      value={values.province}
                      name="province"
                      defaultValue={"Select Province"}
                      onChange={handleChange}
                    >
                      {GetProvinceList().map((provinces) => (
                        <MenuItem
                          value={provinces.province_shortform}
                          key={provinces.province_shortform}
                        >
                          {provinces.province_name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.province && errors.province && (
                      <FormHelperText error id="helper-text-firstname-signup">
                        {errors.province}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel>Postal Code</InputLabel>
                    <OutlinedInput
                      id="postalCode-signup"
                      type="postalCode"
                      value={values.postalCode}
                      name="postalCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Postal Code"
                    />
                    {touched.postalCode && errors.postalCode && (
                      <FormHelperText error id="helper-text-postalCode-signup">
                        {errors.postalCode}
                      </FormHelperText>
                    )}
                  </Stack>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Box
                          sx={{
                            width: 85,
                            height: 8,
                            borderRadius: "7px"
                          }}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    <Checkbox
                      checked={activeCreateAccount}
                      onChange={(e) => {
                        if (termsIsRead && policyIsRead) {
                          setActiveCreateAccount(e.target.checked);
                          setTermsReadError(false);
                        } else {
                          setTermsReadError(true);
                        }
                      }}
                    />
                    &nbsp; I have read and agreed to &nbsp;
                    <Link
                      color={"#188bff"}
                      variant="subtitle2"
                      onClick={() => openTermsAndConditions()}
                    >
                      Terms of Service
                    </Link>
                    &nbsp; and &nbsp;
                    <Link
                      color={"#188bff"}
                      variant="subtitle2"
                      onClick={() => openPrivatePolicy()}
                    >
                      Privacy Policy
                    </Link>
                  </Typography>
                  <Typography color={"#ff0000"}>
                    {termsReadError &&
                      "*You must read the terms and conditions & privacy policy"}
                  </Typography>
                </Grid>
                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}

                {locationNotFound && (
                  <Grid item xs={12}>
                    <Typography style={{ color: "#FF0000" }}>
                      *Location not found please check spelling
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      disabled={!activeCreateAccount || submitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Create Account
                    </Button>
                  </AnimateButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>

        {/*  Open Terms and Conditions */}
        <Modal
          title="Terms And Conditions"
          width="700px"
          open={isOpenTerms}
          onCancel={closeTermsAndConditions}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={closeTermsAndConditions}
            >
              Ok
            </Button>
          ]}
          bodyStyle={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 250px)"
          }}
        >
          {isOpenTerms ? (
            <TermsAndConditions
              signIn={true}
              openPolicy={() => {
                closeTermsAndConditions();
                openPrivatePolicy();
              }}
            />
          ) : (
            ""
          )}
        </Modal>

        {/*  Open Privacy Policy */}
        <Modal
          title="Privacy Policy"
          width="700px"
          maxHeight="98vh"
          open={isOpenPolicy}
          onCancel={closePrivatePolicy}
          footer={[
            <Button key="submit" type="primary" onClick={closePrivatePolicy}>
              Ok
            </Button>
          ]}
          bodyStyle={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 250px)"
          }}
        >
          {isOpenPolicy ? <PrivacyPolicy signIn={true} /> : ""}
        </Modal>
      </AuthWrapper>
    </LoadingSpinner>
  );
};

export default ClientIntakeDetails;
