import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4"
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey"
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    marginbottom: 2
  },
  title: {
    // margin: 12,
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",

    marginbottom: 0.05
  },
  heading: {
    margin: 12,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    marginbottom: 2
  }
});
const AgreementDocument = () => {
  return (
    <Document>
      <Page size="A4" style={styles.body} wrap>
        <Text style={styles.title}>GENERAL SERVICES AGREEMENT</Text>
        <Text style={styles.text}>
          This Agreement made at [Insert City], in the Province of [Insert
          Province], this [insert day] day of [insert month], [insert year] (the
          “Effective Date”).
        </Text>
        <Text style={styles.text}>
          Between: [INSERT client], a body corporate incorporated pursuant to
          the laws of the Province of [Insert Province] OR an individual
          resident in the Province of [Insert Province]
        </Text>
        <Text style={styles.text}>(hereinafter called the "Client")</Text>
        <Text style={styles.text}>
          And: [INSERT CONTRACTOR], a body corporate incorporated pursuant to
          the laws of the Province of [Insert Province] OR an individual
          resident in the Province of [Insert Province] [select corporation or
          individual]
        </Text>
        <Text style={styles.heading}>WHEREAS:</Text>
        <Text style={styles.text}>
          A. The Client requires, and the Contractor has agreed to provide, the
          services described in Schedule ‘A’ - Scope of Work.
        </Text>
        <Text style={styles.text}>
          B. In exchange for performance of the Services, the Client has agreed
          to pay the Contractor the price(s) set out Schedule ‘B’ –
          Compensation.
        </Text>
        <Text style={styles.text}>
          IN CONSIDERATION of the rents and the cove¬nants and agree¬ments
          hereinafter contained (the receipt and sufficiency of which is hereby
          acknowledged) the parties agree as follows:
        </Text>
        <Text style={styles.heading}>1. Definitions</Text>
        <Text style={styles.text}>
          (d) We may contact you to survey you with respect to your use and
          views on our products or to send you promotional material. If you do
          not wish to receive such communications, you may opt out anytime by
          updating your account settings or by emailing{" "}
          {/* TODO: Use real email */}
          <a href="">info@fixden.com.</a>e applicable third party beneficiary’s
          terms of service.
        </Text>
        <Text style={styles.text}>1.1 In this Agreement:</Text>
        <Text style={styles.text}>
          (a) “Agreement” means this Agreement and all Schedules attached
          hereto.
        </Text>
        <Text style={styles.text}>
          (b) “Change” means an addition, reduction, or other change to the
          Scope of Work and/or Compensation.
        </Text>
        <Text style={styles.text}>
          (c) “Claim” means, without limitation, all losses, damages, costs,
          disbursements, penalties, fines, claims, demands, actions, causes of
          action, proceedings, lawsuits, liens, encumbrances, statutory
          obligations, liabilities, judgments, settlements, decrees,
          assessments, arbitration awards, adjudications, litigation expenses,
          unpaid taxes of any kind, costs of investigations and fees (including
          legal fees on a solicitor-client basis), together with any interest
          applicable thereto, whether ascertained or not ascertained, liquid or
          illiquid, contingent or non-contingent.
        </Text>
        <Text style={styles.text}>
          (d) “Compensation” means the pricing terms and conditions outlined in
          Schedule ‘B’ – Compensation.
        </Text>
        <Text style={styles.text}>
          (e) “Confidential Information” means all information, including
          personal information, and documentation acquired directly or
          indirectly, from any source, in writing, orally, or through
          observation, and includes all documents, financial data, trade
          secrets, assets, operations, activities, prospects, technical
          information, processes, drawings, plans, sketches, software,
          photographs, development plans, or other documents prepared by or
          received from the Client or any of its affiliates, representatives, or
          agents, and any other commercial, financial or technical information
          related to the Agreement or the Services, but it does not include
          information that (i) at the time of disclosure was in the public
          domain, or (ii) following its disclosure hereunder, was acquired by
          the Contractor without any confidentiality obligation.
        </Text>
        <Text style={styles.text}>
          (f) “Defect” has its meaning set out in section 6.1.
        </Text>
        <Text style={styles.text}>
          Service Providers - We may contract with third party service providers
          to assist us in performing business-related functions such as
          information processing, conducting security and background checks,
          fulfilling customer orders, delivering products to you, managing and
          enhancing customer data, providing customer service, assessing your
          interest in our products and services, and conducting customer
          research or satisfaction surveys. To the extent a service provider has
          access to your personal information for such a purpose, we will ensure
          that they are obligated to use your personal information only for such
          purpose and that they are obligated to protect your information to
          standards at least as protective as those set out in this Privacy
          Policy.
        </Text>
        <Text style={styles.text}>
          (g) “Gross Negligence” means any act or failure to act which was in
          serious reckless disregard to avoidable, foreseeable and harmful
          consequences that such Person knew, or should have known, such act or
          failure to act would have on the property, rights, safety, health, or
          life of another Person.
        </Text>
        <Text style={styles.text}>
          (h) “Laws” means all applicable common law and federal, state,
          provincial, territorial, municipal, local and foreign statutes, codes,
          ordinances, decrees, rules, regulations, by-laws, judicial or arbitral
          or administrative or ministerial or departmental or regulatory
          judgments, orders, decisions, rulings or awards, policies and
          guidelines having the force of law and “Law” means any of the
          foregoing;
        </Text>
        <Text style={styles.text}>
          (i) “Party” means either the Client or the Contractor and “Parties”
          means both the Client and the Contractor.
        </Text>
        <Text style={styles.text}>
          (j) “Person” means any individual, sole proprietorship, partnership,
          firm, entity, unincorporated association, unincorporated syndicate,
          unincorporated organization, trust, body corporate, governmental
          authority, and where the context requires, any of the foregoing when
          they are acting as trustee.
        </Text>
        <Text style={styles.heading}>4. Anonymized and Aggregated Data</Text>{" "}
        <Text style={styles.text}>
          (k) “Scope of Work” means the work set out in Schedule ‘A’ – Scope of
          Work.
        </Text>
        <Text style={styles.text}>
          (l) “Services” means, without limitation, all actions, labour,
          equipment, materials, efforts, quality control, quality assurance,
          inspection, training, supervision, procurement, transportation,
          administration, products, and services required to complete the Scope
          of Work.
        </Text>
        <Text style={styles.text}>
          (m) “Term” has its meaning set out in section 2.{" "}
        </Text>
        <Text style={styles.text}>
          (n) “Warranty Charges” has its meaning sect out in section 7.2.
        </Text>
        <Text style={styles.text}>
          (o) “Willful Misconduct” means any act or failure to act which was
          intended to harm the property, rights, safety, health or life of
          another Person.
        </Text>
        <Text style={styles.heading}>2. Term</Text>
        <Text style={styles.text}>
          This Agreement will start on [Insert Start Date] and end [on Insert
          End Date OR when the Services are complete] (the “Term”) unless
          terminated earlier in accordance with the terms herein.
        </Text>
        <Text style={styles.heading}>3. Pricing and Payment </Text>
        <Text style={styles.text}>
          The Contractor shall be paid in accordance with the Compensation
          listed in Schedule ‘B’ – Compensation.
        </Text>
        <Text style={styles.heading}> 4. Standard of Care </Text>
        <Text style={styles.text}>
          4.1 The Contractor shall provide the Services in a skillful and
          workmanlike manner, which is consistent with the level of expertise,
          care, skill and diligence demonstrated by experienced and reputable
          services providers performing services of a similar nature.
        </Text>
        <Text style={styles.text}>
          4.2 The Contractor shall at all times act diligently in the
          performance of the Services under this Agreement. The Contractor shall
          comply with all Laws applicable to the Services.
        </Text>
        <Text style={styles.text}>
          4.3 The Contractor agrees that any information given or representation
          made to the Client and its representatives in connection with the
          Services is, to the best of its knowledge, accurate, current and is
          not misleading or deceptive in any respect, and as at the date of the
          Agreement, no conflict of interest exists and it will ensure none will
          arise in the performance of the Services.
        </Text>
        <Text style={styles.heading}>
          5. Assignment of manufacturers’ warranties
        </Text>
        <Text style={styles.text}>
          The Client shall benefit from all legal and manufacturer’s warranties
          available in connection with the goods, equipment, materials,
          machinery, tools and consumables incorporated or used in the Services
          and the Contractor shall ensure for the assignment of all such
          warranties to the Client on the same terms in which these warranties
          have been provided to the Contractor. The Contractor shall cooperate
          with the Client in the enforcement of the assignment and the
          warranties against the manufacturers.
        </Text>
        <Text style={styles.heading}>6. Defects and Charges</Text>
        <Text style={styles.text}>
          6.1 Upon the Client’s request, the Contractor shall expeditiously
          re-perform, at the Contractor’s own expense (and at no additional cost
          to the Client) any parts of the Services which are in breach of
          section 4 (herein “Defects”) that are directly attributable to the
          Contractor until its correction.
        </Text>
        <Text style={styles.text}>
          6.2 Subject to section 6.3, the Client may charge to the Contractor
          the additional costs incurred by the Client over and above the pricing
          set out in the Compensation to:
        </Text>
        <Text style={styles.text}>(a) correct all Defects;</Text>
        <Text style={styles.text}>
          (b) remedy delays caused by the Contractor; and
        </Text>
        <Text style={styles.text}>
          (c) reconstruct and/or repair any structure, building, equipment etc.
          that was adversely affected by the Defects,(the “Warranty Charges”).
        </Text>
        <Text style={styles.text}>
          6.3 Prior to applying Warranty Charges for the correction of Defects,
          the Contractor shall be given a reasonable opportunity to re-perform
          its Services pursuant to section 6.1.
        </Text>
        <Text style={styles.text}>
          6.4 The Client may set-off Warranty Charges against any sums owed to
          the Contractor. For this purpose, the Client may withhold any funds
          payable to the Contractor while the Warranty Charges are still
          outstanding.
        </Text>
        <Text style={styles.text}>
          6.5 The Contractor shall continue performing the Services irrespective
          of any disputes related to Warranty Charges.
        </Text>
        <Text style={styles.text}>
          6.6 The Contractor shall correct any Defects during the Term and for a
          period of 90 days after the expiration of the Term or termination of
          the Agreement. Acceptance of the Services by the Client shall not
          relieve the Contractor from its obligation to correct Defective
          Services.
        </Text>
        <Text style={styles.heading}>7. Title to the Services</Text>
        <Text style={styles.text}>
          The materials and products used for performance of the Services shall
          become the property of the Client when and to the extent payments are
          made for same by the Client.
        </Text>
        <Text style={styles.heading}>8. Changes to the Services</Text>
        <Text style={styles.text}>
          8.1 At any time, the Client or the Contractor may propose a Change.
          The proposing party shall submit the request for Change in writing to
          the other party detailing any adjustments required to the Agreement,
          including the Schedules.
        </Text>
        <Text style={styles.text}>
          8.2 If both parties mutually agree to the proposed Change, the parties
          shall agree in writing to the details of the Change and such Change
          will be deemed effective the date the written agreement detailing the
          Change is signed.
        </Text>
        <Text style={styles.heading}>
          9. Insurance Requirement of the Contractor
        </Text>
        <Text style={styles.text}>
          9.1 Prior to commencing the Services and at all times throughout the
          Term, the Contractor shall, at is sole expense, maintain all insurance
          that a reasonably prudent contractor engaging in the Services would
          maintain.
        </Text>
        <Text style={styles.text}>
          9.2 The Contractor shall provide the Client with a copy of such
          insurance policies from time to time as requested by the Client.
        </Text>
        <Text style={styles.heading}>10. Confidential Information</Text>
        <Text style={styles.text}>
          10.1 Except as otherwise permitted by this Agreement, any Confidential
          Information which the Contracting Party now has or which may come into
          its possession in the course of this Agreement, including any
          information which is marked “CONFIDENTIAL”, will be kept confidential
          and will not, without the Client’s prior written consent, be disclosed
          by the Contractor in any manner whatsoever, in whole or in part, and
          will not be used for any purpose other than to provide Services to the
          Client. The Contractor may reveal or permit access to the Confidential
          Information only to its employees, consultants and subcontractors who
          need to know the Confidential Information, who must be advised of the
          confidential nature of the Confidential Information, who are directed
          by the Contractor to hold the Confidential Information in confidence
          and who agree to be bound by and to act in accordance with the terms
          and conditions of this Agreement. The Contractor shall take all
          necessary precautions or measures to prevent improper access to, or
          use or disclosure of, the Confidential Information by its employees,
          consultants and subcontractors and shall be jointly and severally
          responsible for any breach of this Agreement by any of its employees,
          consultants and subcontractors.
        </Text>
        <Text style={styles.text}>
          10.2 The Contractor warrants that the Contractor has made no use or
          disclosure of any Confidential Information prior to the date of the
          Contractor’s signing of this Agreement, except as permitted by this
          Agreement.
        </Text>
        <Text style={styles.text}>
          10.3 If the Contractor becomes legally compelled or is required by any
          governmental authority having appropriate jurisdiction to disclose any
          of the Confidential Information, the Contractor shall, if permitted
          under Law, promptly provide the Client with notice (including copies
          of the governmental authority's requirements) so that the Client may
          seek a protective order or other appropriate remedy and/or waive
          compliance with the provisions of this Agreement. The Contractor shall
          cooperate with the Client to obtain a protective order or other
          remedy. If a protective order or other remedy is not available, then
          the Contractor shall only disclose that portion of the Confidential
          Information which is legally required to be disclosed and use all
          reasonable efforts to obtain undertakings that the Confidential
          Information so disclosed will be treated in confidence by the
          recipients. The Contractor shall provide the Client, in advance of any
          disclosure to such governmental authority, the Confidential
          Information intended to be disclosed.
        </Text>
        <Text style={styles.text}>
          10.4 Upon termination or expiration of this Agreement, the Contractor
          shall promptly, and in any event, within ten (10) days after such
          request from the Client, return or destroy all copies of the
          Confidential Information and other material if it contains any
          Confidential Information, and delete all Confidential Information from
          all computer systems and databases. Any Confidential Information that
          is not returned or destroyed by the Contractor remains subject to the
          confidentiality obligations under this Agreement.
        </Text>
        <Text style={styles.heading}>11. Health and Safety</Text>
        <Text style={styles.text}>
          The Contractor shall perform the Services in a safe manner and at all
          times shall comply with applicable occupational health and safety
          Laws, and any health and safety policies, rules or directions provided
          by the Client.
        </Text>
        <Text style={styles.heading}>
          12. Indemnity from Third Party Claims
        </Text>
        <Text style={styles.text}>
          The Contractor shall at all times indemnify, defend, and save the
          Client and the Client’s affiliates, directors, officers, employees and
          agents harmless against all liabilities resulting from claims by third
          parties against the Client, the Client’s affiliates, directors,
          officers, employees or agents to the extent that such claims have been
          caused by the negligence, Gross Negligence, Willful Misconduct, breach
          of contract and/or material violation of any Law by the Contractor.
        </Text>
        <Text style={styles.heading}>13. LIMITATION OF LIABILITY</Text>
        <Text style={styles.text}>
          13.1 The Contractor hereby waives any Claim in connection with this
          Agreement against the Client, its affiliates, directors, officers
          employees or agents.
        </Text>
        <Text style={styles.text}>
          13.2 The liability of either party in connection with this Agreement
          is limited to a sum equal to 100% of the total Price contained in the
          Compensation.
        </Text>
        <Text style={styles.text}>
          13.3 Neither party shall be liable to pay the other party for any
          indirect, consequential, aggravated or special damages.
        </Text>
        <Text style={styles.text}>
          13.4 Notwithstanding sections 13.1, 13.2, and 13.3, no limitation of
          liability shall apply to:
        </Text>
        <Text style={styles.text}>
          (a) claims by the Client for contribution and/or indemnity against the
          Contractor in accordance with section 12; and
        </Text>
        <Text style={styles.text}>
          (b) claims by the Client against the Contractor resulting from the
          Contractor’s Gross Negligence, Willful Misconduct, or breach of any
          applicable Law.
        </Text>
        <Text style={styles.text}>
          10.4 Upon termination or expiration of this Agreement, the Contractor
          shall promptly, and in any event, within ten (10) days after such
          request from the Client, return or destroy all copies of the
          Confidential Information and other material if it contains any
          Confidential Information, and delete all Confidential Information from
          all computer systems and databases. Any Confidential Information that
          is not returned or destroyed by the Contractor remains subject to the
          confidentiality obligations under this Agreement.
        </Text>
        <Text style={styles.heading}>14. Liens and Claims</Text>
        <Text style={styles.text}>
          14.1 Contractor shall defend, protect, release, indemnify and hold
          Client harmless from and against, and shall keep Client's property,
          the Services and the site the Services are performed, thereon free and
          clear of all liens, charges, claims, assessments, fines and levies
          suffered, created, or committed by Contractor.
        </Text>
        <Text style={styles.text}>
          14.2 Contractor shall bear the cost of vacating and discharging all
          liens. If Contractor either does not promptly satisfy or commence
          reasonable efforts to vacate and discharge such lien(s) (or, where
          permitted, fails to provide a bond in lieu thereof), Client shall have
          the right, at its option, after two (2) days prior written
          notification to Contractor, to pay or vacate and discharge such
          lien(s) and Contractor shall, within two (2) days of request by
          Client, reimburse Client for all reasonable out of pocket costs
          actually incurred by Client to vacate and discharge such lien(s),
          including administrative costs, bond premiums reasonable attorneys'
          fees and other expenses.
        </Text>
        <Text style={styles.heading}>15. Suspension of Services</Text>
        <Text style={styles.text}>
          15.1 The Client may suspend the Services at any time for any reason
          (or no reason at all) upon provision of notice to the Contractor, with
          such notice having immediate effect.
        </Text>
        <Text style={styles.text}>
          15.2 At the end of the suspension period, the parties may negotiate in
          good faith any equitable adjustments to the Scope of Work and/or the
          Compensation in order to account for the time the Services were
          suspended.
        </Text>
        <Text style={styles.heading}>16. Termination</Text>
        <Text style={styles.text}>
          16.1 Termination without Cause. The Client may terminate this
          Agreement without cause for any reason (or not reason at all) upon 15
          days written notice to the Contractor.{" "}
        </Text>
        <Text style={styles.text}>
          16.2 Effect of Termination Without Cause. Should the Client terminate
          this Agreement in accordance with section 16.1, the sole Claim of the
          Contractor against the Client shall be for all undisputed invoices for
          Services actually provided by the Contractor up to the effective date
          of termination.{" "}
        </Text>
        <Text style={styles.text}>
          16.3 Termination for Cause by Client. The following events shall
          constitute a material breach of this Agreement by the Contractor and
          shall entitle the Client to terminate this Agreement upon notice,
          effective immediately:
        </Text>
        <Text style={styles.text}>
          (a) Willful Misconduct of the Contractor;
        </Text>
        <Text style={styles.text}>(b) Gross Negligence of the Contractor;</Text>
        <Text style={styles.text}>
          (c) the Contractor failing to comply with health and safety Laws or
          Client policies or directions;{" "}
        </Text>
        <Text style={styles.text}>
          (d) the Contractor going into receivership, insolvency or bankruptcy;
        </Text>
        <Text style={styles.text}>
          (e) the Contractor assigning any right or obligation of this Agreement
          without the prior written consent of the Client; and
        </Text>
        <Text style={styles.text}>
          (f) the Contractor breaching any obligation under this Agreement.
        </Text>
        <Text style={styles.text}>
          16.4 Termination for Cause by Contractor. The Contractor may terminate
          this Agreement upon notice to the Client if the Client goes
          receivership, insolvency or bankruptcy, or the Client breaches an
          obligation under this Agreement.{" "}
        </Text>
        <Text style={styles.text}>
          16.5 Effect of Termination for Cause. In the event this Agreement is
          terminated in accordance with section 16.3, in addition to any other
          legal remedy or recourse at Law available to the Client, the Client
          shall be entitled to:
        </Text>
        <Text style={styles.text}>
          (a) withhold any monies otherwise due to the Contractor to cover the
          costs of any disputes arising from or in connection with the
          termination; and{" "}
        </Text>
        <Text style={styles.text}>
          (b) complete the Services or have a third Person complete the Services
          and charge to the Contractor any additional costs in connection to the
          completion of the Services that are over and above the Price set out
          in the Compensation.{" "}
        </Text>
        <Text style={styles.text}>
          16.6 Termination with cause by either Party shall not prejudice the
          rights that either Party may have under this Agreement and under Law.
        </Text>
        <Text style={styles.heading}>17. Independent Contractor Status </Text>
        <Text style={styles.text}>
          17.1 The Contractor is and shall remain an independent contractor and
          not an employee of the Client. The Contractor shall retain independent
          professional status throughout this Agreement and will use its own
          discretion in performing the tasks assigned.
        </Text>
        <Text style={styles.text}>
          17.2 This Agreement does not constitute the Parties as partners, joint
          venturers or agents of each other and no Party may so represent itself
          in this manner.
        </Text>
        <Text style={styles.heading}>18. Assignment </Text>
        <Text style={styles.text}>
          Neither Party may assign this Agreement or subcontract its obligations
          under this Agreement without the express consent of the other Party.
          However, upon notice to the Contractor, the Client may assign this
          Agreement to any affiliated Client or Person without the prior express
          consent of the Contractor. This Agreement ensures to the benefit of
          the Parties and it is binding upon the Parties' heirs, executors,
          successors and assigns.
        </Text>
        <Text style={styles.heading}>19. Notices</Text>
        <Text style={styles.text}>
          19.1 All notices in connection with this Agreement shall be in
          writing.{" "}
        </Text>
        <Text style={styles.text}>
          19.2 Notices may be transmitted to e-mail or courier. Each party shall
          designate, from time to time, a representative for the purposes of
          providing notice to one another.{" "}
        </Text>
        <Text style={styles.heading}>20. Other Provisions </Text>
        <Text style={styles.text}>
          20.1 The parties shall use reasonable efforts to meet any time
          requirements in the performance of the parties’ respective.
        </Text>
        <Text style={styles.text}>
          20.2 Failure or delay in exercising any right, power or privilege in
          respect of this Agreement will not be presumed to operate as a waiver,
          and a single or partial exercise of any right, power or privilege will
          not be presumed to preclude any subsequent or further exercise, of
          that right, power or privilege or the exercise of any other right,
          power or privilege.
        </Text>
        <Text style={styles.text}>
          20.3 The parties shall, with reasonable diligence, do all such things
          and provide all such reasonable assurances as may be required to
          consummate the transactions contemplated by this Agreement, and each
          party shall provide such further documents or instruments required by
          any other party as may be reasonably necessary or desirable to effect
          the purpose of this Agreement and carry out its provisions, whether
          before or after the date of this Agreement.
        </Text>
        <Text style={styles.text}>
          20.4 All currency references are to Canadian Dollars unless otherwise
          specified.
        </Text>
        <Text style={styles.text}>
          20.5 Unless the context otherwise requires, words importing the
          singular include the plural and vice versa and words importing one
          gender include all genders. If, in any jurisdiction, any provision of
          the Agreement or its application to any Party or circumstance is
          restricted, prohibited or unenforceable, the provision is, as to that
          jurisdiction, ineffective only to the extent of the restriction,
          prohibition or unenforceability without invalidating the remaining
          provisions of the Agreement and without affecting the validity or
          enforceability of such provision in any other jurisdiction, and
          without affecting its application to other Parties or circumstances.
        </Text>
        <Text style={styles.text}>
          20.6 Sections 5, 6, 7, 10, 12, 13, 16.5, 16.6, and 20.6 shall survive
          termination or expiration of this Agreement.{" "}
        </Text>
        <Text style={styles.text}>
          20.7 This Agreement shall be governed by the laws of the Province of
          [Insert Province].
        </Text>
        <Text style={styles.text}>
          20.8 This Agreement may be executed in two or more counterparts, each
          of which together shall be deemed an original, but all of which
          together shall constitute one and the same instrument. In the event
          that the signature is delivered by fax transmission or by email
          delivery of a scanned data file (Adobe PDF or otherwise), such
          signature shall create a valid and binding obligation on the party
          executing the Agreement with the same force and effect as if the fax
          or scanned data file (Adobe PDF or otherwise) were an original
          thereof.
        </Text>
        <Text style={styles.text}>
          By the Client: [INSERT CLIENT IF CORPORATION]{" "}
        </Text>
        <Text style={styles.text}> [INSERT CLIENT IF PERSON] </Text>
        <Text style={styles.text}>
          By the Contractor:[INSERT CONTRACTOR IF CORPORATION]{" "}
        </Text>
        <Text style={styles.text}>[INSERT CONTRACTOR IF PERSON]</Text>
        <Text style={styles.title}>SCHEDULE ‘B’ – COMPENSATION</Text>
        <Text style={styles.heading}>1. Price </Text>
        <Text style={styles.text}>[Insert Details] (the “Price”)</Text>
        <Text style={styles.heading}>2. Invoicing </Text>
        <Text style={styles.text}>
          2.1 All invoices must be sent to the Client within sixty (30) days
          after the provision of the Services to which the invoice makes
          reference, otherwise payment of the invoices may be significantly
          delayed, and delayed payment by the Client under these circumstances
          shall not be considered a breach of the Client’s obligations
          hereunder.
        </Text>
        <Text style={styles.text}>
          2.2 The Contractor also waives its right to the payment of any
          invoices that have been received for the first time by the Client more
          than six months after the provision of the Services to which such
          invoice refers.
        </Text>
        <Text style={styles.text}>
          2.3 Each invoice shall be submitted with proper backup documentation
          to evidence to the satisfaction of the Client that all Services that
          are the subject of the invoice have been performed. The date of the
          proforma invoice shall never precede the date that the invoice and all
          backup documentation was submitted.
        </Text>
        <Text style={styles.text}>
          2.4 All invoices shall include such information as required for the
          Client to claim available input tax credits for GST/HST.
        </Text>
        <Text style={styles.heading}>3. Taxes</Text>
        <Text style={styles.text}>
          3.1 “Taxes” means any and all taxes, levies, imposts, duties, customs
          duties, stamp duties, fees, withholdings, assessments, deductions or
          charges whatsoever, imposed, assessed, levied or collected by any
          governmental authority, together with interest, fines and penalties
          including occupational, excise, unemployment, ownership, sales, gross
          receipts, income taxes, payroll taxes, employer contributions (both
          statutory and otherwise) and workers' compensation payments and
          contributions but does not include value-added tax.
        </Text>
        <Text style={styles.text}>
          3.2 Other than GST/HST, QST or PST applicable, the Price is inclusive
          of all Taxes for which the Contractor is responsible under applicable
          laws. In addition, the Client shall pay the GST/HST, QST or PST, as
          applicable, to the Contractor provided that the Contractor is
          registered for GST/HST, QST or PST remittance.
        </Text>
        <Text style={styles.text}>
          3.3 The Contractor shall indemnify, save and hold harmless the Client
          against any assessment by tax authorities in respect of GST/HST, QST
          or PST relative to the Services under this Agreement which was not
          timely remitted by the Contractor to the appropriate tax authorities.
        </Text>
        <Text style={styles.text}>
          3.4 In addition, if the Contractor is a not resident or becomes a
          non-resident of Canada during the Term of the Agreement, the
          Contractor shall ensure that each invoice issued to the Client
          identifies that portion of the Services performed in Canada and the
          value of that portion, in which event the Client is authorized to
          withhold from any monies payable to the Contractor in respect of that
          invoice, such amounts as the Client may be obliged to withhold under
          applicable tax legislation in respect of the Services performed in
          Canada, and to remit the same to the applicable tax authorities in
          Canada. The Contractor shall be responsible for filing the appropriate
          paperwork with the tax authorities for the applicable refunds or
          reimbursements, if applicable, as the case may be.
        </Text>
        <Text style={styles.heading}>
          4. Representations of the Contracting Party upon each Invoice
        </Text>
        <Text style={styles.text}>
          4.1 By submitting an invoice the Contractor represents and warrants to
          the Client (and the Contractor acknowledges that it is a condition
          precedent to payment) that:
        </Text>
        <Text style={styles.text}>
          (a) the Contractor has completed the portions of the Services that are
          the subject of the invoice;
        </Text>
        <Text style={styles.text}>
          (b) the Contractor is not aware of any Defects in the Services that
          are the subject of the invoice;{" "}
        </Text>
        <Text style={styles.text}>
          (c) all remuneration and amounts due and payable by the Contractor to
          any of its employees, subcontractors, agents, and suppliers have been
          paid, except for any applicable holdback amounts required by Law; and{" "}
        </Text>
        <Text style={styles.text}>
          (d) the Contractor is not aware of any Claim against the Client not
          previously made aware to the Client.{" "}
        </Text>
        <Text style={styles.heading}>5. Holdback</Text>
        <Text style={styles.text}>
          5.1 The Client shall make all holdbacks on the Price as required by
          Law.{" "}
        </Text>
        <Text style={styles.heading}>6. Set-Off</Text>
        <Text style={styles.text}>
          6.1 In the event the Contractor is in any way indebted to the Client
          under this Agreement or for any other reason, the Client shall have
          the right to set-off such debt against the Price.{" "}
        </Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default AgreementDocument;
