import { useCallback, useEffect, useState } from "react";

import "../commonComponents/CSS/styles.css";

import axios from "axios";
import JobDisplay from "./InboxComponents/JobDisplay";
import { io } from "socket.io-client";

import { useUserState } from "../../Context/UserContext";

import Messenger from "./InboxComponents/Messenger";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";

import "./Inbox.css";
import ChatList from "./InboxComponents/ChatList";

import { useMediaQuery } from "@mui/material";

const { Sider, Content } = Layout;
const InboxPage = () => {
  const { user } = useUserState();

  const [chatting, setChatting] = useState(false);
  const [jobView, setJobView] = useState(false);

  const [chatList, setChatList] = useState(null);

  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);

  const [unreadMsg, setUnreadMsg] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState([]);

  const [notifications, setNotifications] = useState([]);

  const [chatID, setChatID] = useState();
  let locationSplit = window.location.href.split("/");
  let selected_chat = locationSplit.length > 5 ? locationSplit[5] : null;

  useEffect(() => {
    setChatID(locationSplit.length > 5 ? locationSplit[5] : null);
    // console.log(locationSplit);
    if (!window.location.href.includes(chatID)) {
      setChatID(selected_chat);
    }
  }, [selected_chat]);
  useEffect(() => {
    getChatList();
  }, [newMessage]);

  useEffect(() => {
    axios
      .post("/communication/getUnreadMessage", {
        // receiverId: receipientId
      })
      .then((res) => {
        console.log(res.data.unreadMsgList);
        setUnreadMsg(res.data.unreadMsgList);
        setNotifications(res.data.unreadMsgList);
      });
  }, [newMessage]);

  //get web socket connection
  useEffect(() => {
    const newSocket = io("http://localhost:5000");
    console.log(newSocket);
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [user]);

  // add online users
  useEffect(() => {
    if (socket === null) return;

    socket.emit("addNewUser", user.id);
    socket.on("getOnlineUsers", (res) => {
      setOnlineUsers(res);
    });

    return () => {
      socket.off("getOnlineUsers");
    };
  }, [socket]);

  //send message
  useEffect(() => {
    if (socket === null) return;
    console.log(newMessage);
    const receipientDetails = chatList?.filter(
      (list) => list.conversation_id === newMessage.conversation_id
    );

    const receipientUserDetails = receipientDetails.map((list) =>
      list.chattingPeople.find((people) => people.user_id !== user.id)
    );

    const receipientId = receipientUserDetails[0].user_id;

    console.log(receipientId);
    socket.emit("sendMessage", {
      ...newMessage,
      receipientId,
      receipientUserDetails,
      receipientDetails
    });
  }, [newMessage]);

  // receive message
  useEffect(() => {
    // console.log(chatList);
    if (socket === null) return;
    // const receipientDetails = chatList?.filter(
    // 	list => list.conversation_id === newMessage.conversation_id
    // );

    socket.on("getMessage", (res) => {
      const receipientDetails = chatList?.filter(
        (list) => list.conversation_id === parseInt(res.conversation_id)
      );

      if (receipientDetails[0].conversation_id !== res.conversation_id) {
        return console.log("pppppppppppppppp");
      }

      if (parseInt(chatID) === parseInt(res.conversation_id)) {
        setMessages((prev) => [...prev, res]);
        updateMsgToRead(res.message_id);
      }
      // updateMsgToRead()

      getChatList();
    });
    // console.log(messages);
    socket.on("getNotification", (res) => {
      console.log(res);

      if (res.is_read === 0) setNotifications((prev) => [res, ...prev]);
    });

    return () => {
      socket.off("getMessage");
      socket.off("getNotification");
    };
  }, [socket, chatList, messages, newMessage]);

  const getChatList = () => {
    axios.get("/communication/getChatList").then((response) => {
      // console.log(response.data.chatList);
      setChatList(response.data.chatList);
    });
  };

  const updateMsgToRead = (msgId) => {
    axios
      .post("/communication/updateMsgToReadInMessenger", {
        MsgId: msgId
      })
      .then(() => {});
  };

  const mobileView = useMediaQuery("(max-width: 600px)");
  console.log(chatting);
  console.log(user.vendorMode);

  return !mobileView ? (
    <Layout className="commmunicationLayout communicationDesktop">
      <Sider width={300} id="commSiderLeft" style={{ overflow: "auto" }}>
        <ChatList
          socket={socket}
          setChatID={setChatID}
          chatList={chatList}
          getChatList={getChatList}
          chatId={chatID}
          onlineUsers={onlineUsers}
          notifications={notifications}
        />
      </Sider>
      <Content id="communicationContent">
        {selected_chat !== null ? (
          <Messenger
            setJobView={setJobView}
            socket={socket}
            chatId={selected_chat}
            getChatList={getChatList}
            setNewMessage={setNewMessage}
            messages={messages}
            setMessages={setMessages}
            newMessage={newMessage}
          />
        ) : (
          <NoMessage />
        )}
      </Content>
      <Content id="allJobRequest">
        <JobDisplay jobView={jobView} chatId={selected_chat} />
      </Content>
    </Layout>
  ) : (
    <>
      {selected_chat !== null ? (
        !jobView ? (
          <Messenger
            setJobView={setJobView}
            setChatting={setChatting}
            className="mr-2 m-auto"
            chatId={selected_chat}
            getChatList={getChatList}
            setNewMessage={setNewMessage}
            messages={messages}
            setMessages={setMessages}
            newMessage={newMessage}
          />
        ) : (
          <JobDisplay setJobView={setJobView} chatId={selected_chat} />
        )
      ) : (
        !chatting && (
          <ChatList
            setChatting={setChatting}
            setChatID={setChatID}
            chatList={chatList}
            getChatList={getChatList}
            chatId={chatID}
            onlineUsers={onlineUsers}
            notifications={notifications}
          />
        )
      )}
    </>
  );
};
/* Displays when no thread is specified in the main body. Only used in desktop view */
const NoMessage = () => {
  return (
    <div className="noSelectedImg text-center">
      <img
        src={require("../../images/emptyThread.png")}
        alt={"noMessageSelected"}
      />
      <div className="g-text-lg">
        Select a thread to the left to open the messenger.
      </div>
    </div>
  );
};

export default InboxPage;
