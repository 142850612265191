import styles from "./MapDisplay.module.css";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import { useCallback, useEffect, useState } from "react";
import { getMonth } from "../../commonComponents/util";
import { useJRContext } from "../../../Context/JobRequestContext";

const BlackIcon = new L.icon({
  iconUrl: require("../../../images/mapIcon.png"),
  iconSize: [40, 45],
  iconAnchor: [20, 45],
});
const BlueIcon = new L.icon({
  iconUrl: require("../../../images/blueMapIcon.png"),
  iconSize: [40, 45],
  iconAnchor: [20, 45],
});
const RedIcon = new L.icon({
  iconUrl: require("../../../images/redMapIcon.png"),
  iconSize: [40, 45],
  iconAnchor: [20, 45],
});
const empIconCanvas = (() => {
  let canvas = document.createElement("canvas");
  canvas.width = 40;
  canvas.height = 50;
  let cont = canvas.getContext("2d");

  // draw line
  cont.beginPath();
  cont.moveTo(20, 30);
  cont.lineTo(20, 50);
  cont.strokeStyle = "black";
  cont.lineWidth = 1;
  cont.stroke();
  cont.closePath();

  // add circle
  cont.beginPath();
  cont.arc(20, 16, 15, 0, 2 * Math.PI);
  cont.stroke();
  cont.closePath();
  return canvas;
})();

const ChangeView = ({ center }) => {
  const map = useMap();
  map.setView(center);
  return null;
};

const Routing = (props) => {
  return null;
  const map = useMap();

  useEffect(() => {
    if (!map) return;
    const routingControl = L.Routing.control({
      waypoints: [
        L.latLng(props.JRLocation[0], props.JRLocation[1]),
        L.latLng(props.empLocation[0], props.empLocation[1]),
      ],
      routeWhileDragging: false,
      DraggableWaypoints: false,
      addWaypoints: false,
      lineOptions: {
        styles: [
          {
            color: "#1890ff",
            opacity: 1,
            weight: 5,
          },
        ],
      },
      createMarker: function (i, start, n) {
        return null;
      },
    }).addTo(map);
    return () => map.removeControl(routingControl);
  }, [map, props.JRLocation, props.empLocation]);

  return null;
};

const MapIcon = (props) => {
  const map = useMap();
  return (
    <Marker
      autoPan={false}
      autoPanOnFocus={false}
      id={props.className}
      position={props.location}
      icon={props.icon}
      eventHandlers={{
        click: ({ target }) => {
          props.click();
          props.setCurrentIcon(target);
          setTimeout(() => target.openPopup(), 10);
        },
        mouseover: ({ target }) => {
          const center = map.getCenter();
          props.setFocus(center);
          props.setCurrentIcon(target);
          target.openPopup();
        },
      }}
    >
      <Popup autoPan={false} autoPanOnFocus={false} className={styles.popup}>
        {props.popup}
      </Popup>
    </Marker>
  );
};

const MapDisplay = (props) => {
  const [routingToggle, setRoutingToggle] = useState(false);
  const [iconList, setIconList] = useState();
  const { setOpenJR } = useJRContext();

  const {
    setFocus,
    setCurrentEmployee,
    setCurrentIcon,
    mapEmployees,
    toggleRouting,
  } = props;

  const changeFocus = useCallback(
    (jr) => {
      setFocus(jr.location);
      setOpenJR(jr);
    },
    [setFocus, setOpenJR]
  );

  const createJRIcons = useCallback(
    (newIconList) => {
      const today = new Date();
      const JRs = props.currentJRs;
      for (let i in JRs) {
        const key = `JR_${JRs[i].job_id}_icon`;
        let icon;
        const dateDif = parseInt(
          (JRs[i].job_due - today) / (1000 * 60 * 60 * 24)
        );
        if (props.isMarket) {
          icon = BlackIcon;
        } else if (dateDif < 0) {
          icon = RedIcon;
        } else if (dateDif === 0) {
          icon = BlueIcon;
        } else {
          icon = BlackIcon;
        }

        newIconList.push(
          <span key={key}>
            <MapIcon
              setCurrentIcon={props.setCurrentIcon}
              className={key}
              location={JRs[i].location}
              icon={icon}
              setFocus={props.setFocus}
              click={() => {
                changeFocus(JRs[i]);
              }}
              popup={
                <>
                  <div className={styles.popupTitle}>
                    <h2>{JRs[i].clientName.split(" ")[0]}</h2>
                    {!props.isMarket && (
                      <p>
                        {`${getMonth(JRs[i].job_due.getMonth())} ${
                          JRs[i].job_due.getDate() < 10 ? "0" : ""
                        }${JRs[i].job_due.getDate()}, ${JRs[
                          i
                        ].job_due.getHours()}:${
                          JRs[i].job_due.getMinutes() < 10 ? "0" : ""
                        }${JRs[i].job_due.getMinutes()}`}
                      </p>
                    )}
                  </div>
                  <p>Category: {JRs[i].job_category}</p>
                  <p className={styles.popupDesc}>{JRs[i].job_description}</p>
                </>
              }
            />
          </span>
        );
      }
    },
    [
      changeFocus,
      props.currentJRs,
      props.isMarket,
      props.setCurrentIcon,
      props.setFocus,
    ]
  );

  const createEmpIcons = useCallback(
    (newIconList) => {
      const employeesList = mapEmployees;
      for (let i in employeesList) {
        const employee = employeesList[i];
        console.log(employee);
        if (employeesList[i].visible) {
          // create icon using canvas
          const profilePic = employee.profile_pic;
          let canvas = empIconCanvas;
          let cont = canvas.getContext("2d");

          // add profile pic
          cont.beginPath();
          cont.arc(20, 16, 15, 0, 2 * Math.PI);
          cont.closePath();
          cont.clip();
          cont.drawImage(profilePic, 0, 0, 40, 40);
          const dataURL = canvas.toDataURL();

          const empIcon = new L.icon({
            iconUrl: dataURL,
            iconSize: [40, 50],
            iconAnchor: [20, 50],
          });

          const key = `Employee_${employee.employee_id}_icon`;
          newIconList.push(
            <span key={key}>
              <MapIcon
                location={[employee.lat, employee.lon]}
                icon={empIcon}
                setFocus={setFocus}
                click={() => {
                  if (routingToggle) {
                    toggleRouting();
                  }
                  setCurrentEmployee(employee);
                  setFocus([employee.lat, employee.lon]);
                }}
                setCurrentIcon={setCurrentIcon}
                popup={employee.name}
              />
            </span>
          );
        }
      }
    },
    [
      setCurrentEmployee,
      setFocus,
      setCurrentIcon,
      mapEmployees,
      toggleRouting,
      routingToggle,
    ]
  );

  const createMapIcons = useCallback(async () => {
    const newIconList = [];
    if (
      !(
        typeof props.currentJRs[0] === "undefined" ||
        typeof props.currentJRs[0].job_id === "undefined"
      )
    ) {
      createJRIcons(newIconList);
    }
    if (!props.isMarket) {
      await createEmpIcons(newIconList);
    }
    setIconList(newIconList);
  }, [props, createJRIcons, createEmpIcons]);

  useEffect(() => {
    createMapIcons();
  }, [
    props.currentJRs,
    props.openJR,
    props.filterCategory,
    props.startDate,
    props.endDate,
    props.mapEmployees,
    props.mapRefresh,
    createMapIcons,
  ]);

  return (
    <div className={styles.mapDisplay}>
      <MapContainer
        className={styles.map}
        center={props.focus}
        zoom={props.zoom}
        scrollWheelZoom={true}
        autoPan={false}
      >
        <ChangeView center={props.focus} zoom={props.zoom} />
        {routingToggle && !props.isMarket && (
          <Routing
            JRLocation={props.openJR.location}
            empLocation={[props.currentEmployee.lat, props.currentEmployee.lon]}
          />
        )}
        <TileLayer
          autoPan={false}
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {iconList}
      </MapContainer>
    </div>
  );
};

export default MapDisplay;
