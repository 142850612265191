import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Modal } from "antd";
import axios from "axios";
import { useState } from "react";
import { useUserState } from "../../../../Context/UserContext";
import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";
import Invoice from "../Invoice/Invoice";
import ClientReview from "../Review/ClientReview";
import StarRating from "../Review/StarRating";

function ExpandedTilePhase4(props) {
  const { user } = useUserState();
  const [displayCompletedWorkModal, setDisplayCompletedWorkModal] = useState();
  const [reworkModal, setReworkModal] = useState(false);
  const [reworkDesc, setReworkDesc] = useState();
  const [showInvoice, setShowInvoice] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [workAccepted, setWorkAccepted] = useState(false);

  const submitReworkRequest = () => {
    const reworkData = {
      JobId: props.jrData.job_id,
      vendorId: props.jrData.assigned_vendor_id,
      reworkDesc: reworkDesc,
      activityDesc: `Requested rework "${reworkDesc}"`,
      userName: `${user.first_name} ${user.last_name}`
    };

    axios.post("/rework/newReworkRequest", reworkData).then((res) => {
      // props.fetchActivityData();
      props.fetchData();
      props.fetchJrData();
    });
  };

  const updateJobStatus = () => {
    const activityObject = {
      JobId: props.jrData.job_id,
      activityDesc: `Workdone has been confirmed `,
      userName: `${user.first_name} ${user.last_name}`
    };
    axios.post("/jobRequest/updateToFinalizing", activityObject).then((res) => {
      props.fetchData();
      props.fetchJrData();
    });
  };

  const mobileView = useMediaQuery("(max-width: 600px)");
  return (
    <>
      <div className="phase">
        {props.jrData.job_status === "Reviewing" ? (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography style={{ marginLeft: "25px" }}>
                The vendor has completed the work. Please review work done by
                vendor. If the work has been done click "Accept", otherwise
                click "Rework".
              </Typography>
              <Stack
                direction={mobileView ? "column" : "row"}
                spacing={1}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Button
                  onClick={() => {
                    setDisplayCompletedWorkModal(true);
                  }}
                  size="small"
                  type="button"
                  variant="contained"
                  color="primary"
                  style={{ minWidth: "25%", maxWidth: "50%" }}
                  // style={{ marginLeft: !mobileView ? "50px" : "0" }}
                >
                  Accept
                </Button>
                <Button
                  onClick={() => {
                    setReworkModal(true);
                  }}
                  size="small"
                  type="button"
                  variant="contained"
                  color="primary"
                  style={{ minWidth: "25%", maxWidth: "50%" }}
                  // style={{ marginLeft: !mobileView ? "25px" : "0" }}
                >
                  Rework
                </Button>
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <Typography style={{ marginTop: "1rem" }}>
            Your job request is completed and your payment is done.
            <Button
              size="sm"
              color="primary"
              onClick={() => setShowInvoice(true)}
              variant="contained"
              type="button"
              style={{ marginLeft: "30px" }}
            >
              View Invoice
            </Button>
            {props.jrData.client_review === 0 ? (
              <Button
                size="sm"
                color="primary"
                onClick={() => setShowReview(true)}
                variant="contained"
                type="button"
                style={{ marginLeft: "30px" }}
              >
                Leave Review
              </Button>
            ) : (
              <Stack>
                <Typography variant="h4">Review Summary</Typography>
                {props.clientReview?.clientReview?.map((review) => {
                  return (
                    <>
                      <StarRating rating={review.aggregate} />
                      <Typography>
                        <b>Comments</b>: {review.comments}
                      </Typography>
                    </>
                  );
                })}
              </Stack>
            )}
          </Typography>
        )}
      </div>
      {props.clientInvoiceDetails !== null &&
        props.vendorInvoiceDetails !== null && (
          <Modal
            title="Invoice"
            open={showInvoice}
            onCancel={() => setShowInvoice(false)}
            footer={false}
          >
            <Invoice
              invoice={props.invoice}
              invoiceDetails={props.invoiceDetails}
              clientInvoiceDetails={props.clientInvoiceDetails[0]}
              vendorInvoiceDetails={props.vendorInvoiceDetails[0]}
              jrData={props.jrData}
            />
          </Modal>
        )}
      {showReview && (
        <ClientReview
          fetchJrData={props.fetchJrData}
          fetchData={props.fetchData}
          jrData={props.jrData}
          clientReview={props.clientReview}
          showReview={showReview}
          setShowReview={setShowReview}
          userName={`${user.first_name} ${user.last_name}`}
        />
      )}

      <Modal
        destroyOnClose={true}
        width="700px"
        title="Request Rework"
        open={reworkModal}
        onCancel={() => setReworkModal(false)}
        footer={false}
      >
        {reworkModal && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <h4>
                Please give details as to why you're requesting more work done
              </h4>
            </Grid>
            <Grid item={12}>
              <Stack spacing={1}>
                <InputLabel>What needs to be done?</InputLabel>
                <OutlinedInput
                  required
                  id="reworkDesc"
                  type="reworkDesc"
                  onChange={(e) => setReworkDesc(e.target.value)}
                  placeholder="Rework Description"
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <AnimateButton>
                <Button
                  size="sm"
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    submitReworkRequest();
                    setReworkModal(false);
                  }}
                  sx={{ ml: 2, float: "right" }}
                >
                  Confirm
                </Button>
                <Button
                  size="sm"
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={() => setReworkModal(false)}
                  sx={{ ml: 2, float: "right" }}
                >
                  Cancel
                </Button>
              </AnimateButton>
            </Grid>
          </Grid>
        )}
      </Modal>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Accept Work"
        open={displayCompletedWorkModal}
        onCancel={() => setDisplayCompletedWorkModal(false)}
        footer={false}
      >
        {displayCompletedWorkModal && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              I agree that the work has been done accordingly to the Job
              Request.
            </Grid>

            <Grid item xs={12} md={12}>
              <Stack spacing={1}>
                <AnimateButton>
                  <Button
                    size="sm"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      updateJobStatus();
                      setDisplayCompletedWorkModal(false);
                      setShowReview(true);
                      setWorkAccepted(true);
                    }}
                    sx={{ ml: 2, float: "right" }}
                  >
                    Accept
                  </Button>
                  <Button
                    size="sm"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setDisplayCompletedWorkModal(false);
                    }}
                    sx={{ ml: 2, float: "right" }}
                  >
                    Decline
                  </Button>
                </AnimateButton>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Modal>
    </>
  );
}

ExpandedTilePhase4.defaultProps = {
  clientInvoiceDetails: null,
  vendorInvoiceDetails: null
};

export default ExpandedTilePhase4;
