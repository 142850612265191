import { useState, useEffect, useCallback } from "react";
import {
  List,
  ListItem,
  Grid,
  Stack,
  Button,
  Alert,
  useMediaQuery
} from "@mui/material";
import { Avatar, Modal, Typography } from "antd";
import { useUserState } from "../../Context/UserContext.js";
import EditUserProfile from "../EditForms/EditUserProfile";
import {
  PlusCircleTwoTone,
  EditTwoTone,
  CameraTwoTone,
  DownOutlined,
  DownCircleTwoTone,
  UpCircleTwoTone
} from "@ant-design/icons";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

import MainCard from "../../Layout/components/MainCard";

import ChangeProfilePicture from "../EditForms/ChangeProfilePicture.js";
import "./profile.css";
import PropertyTable from "../PropertiesPage/PropertyComponents/PropertyTable.js";
import axios from "axios";

import AddProperty from "../PropertiesPage/PropertyComponents/AddProperty.js";
import VendorProfile from "./VendorProfile";

import PaymentMethodSelector from "./components/PaymentMethodSelector.jsx";
import AddDelegate from "../PropertiesPage/PropertyComponents/AddDelegate.js";
import DelegateList from "../PropertiesPage/PropertyComponents/DelegateList.js";
import DelegateTable from "../PropertiesPage/PropertyComponents/DelegateTable.js";

const UserProfile = () => {
  const userDetails = useUserState();
  const { user } = userDetails;
  const [editModal, setEditModal] = useState(false);

  const [changeImageModal, setChangeImageModal] = useState(false);
  const [displayAddProperty, setDisplayAddProperty] = useState(false);
  const [displayAddDelegate, setDisplayAddDelegate] = useState(false);

  const [properties, setProperties] = useState([]);
  const [propertyList, setPropertyList] = useState(properties);
  const [delegateProperties, setDelegateProperties] = useState([]);
  const [ownerDelegateProperties, setOwnerDelegateProperties] = useState([]);
  const [showDelegatedProperties, setShowDelegatedProperties] = useState(false);
  const [showServiceAddress, setShowServiceAddress] = useState(false);
  const [showOwnerDelegateProperties, setShowOwnerDelegateProperties] =
    useState(false);

  const [vendorDetails, setVendorDetails] = useState("");
  const [clientDetails, setClientDetails] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState(false);

  const [currentProperty, setCurrentProperty] = useState(-1);
  const [deactivePropertyModal, setDeactivePropertyModal] = useState(false);
  const [deactiveDelegationModal, setDeactiveDelegationModal] = useState(false);

  const [userFirstName, setUserFirstName] = useState(user.first_name);
  const [userLastName, setUserLastName] = useState(user.last_name);
  const [userEmail, setUserEmail] = useState(user.email);
  const [userPhoneNum, setUserPhoneNum] = useState(user.phone_number);
  const [userStreet, setUserStreet] = useState(user.street);
  const [userCity, setUserCity] = useState(user.city);
  const [userProvince, setUserProvince] = useState(user.province);
  const [userPostal, setUserPostal] = useState(user.postal_code);
  const [delegateAlert, setDelegateAlert] = useState(false);

  const displayDelegateSuccess = () => {
    setDelegateAlert(true);
    setTimeout(() => setDelegateAlert(false), 4000);
  };

  const handleSetProperties = (newProperties) => {
    setProperties(newProperties);
    const rowList = newProperties.filter(
      (property) => property.is_active === 1 && property.is_delegated === 0
    );
    setPropertyList(rowList);
  };

  const fetchVendorData = () => {
    axios.post("/jobRequest/getVendorDetails").then(({ data }) => {
      console.log(data, "DATATATATASGFJAGFYUAGYUFG");
      setVendorDetails(data);
    });
  };

  const fetchDelegateProperties = () => {
    axios.get("/property/getDelegatedPropertyDetails").then(({ data }) => {
      console.log(data.delegateProperties);
      setDelegateProperties(data.delegateProperties);
    });
  };

  const fetchOwnerDelegateProperties = () => {
    axios.get("/property/getOwnerDelegatedPropertyDetails").then(({ data }) => {
      console.log(data.ownerDelegatedProperties);
      setOwnerDelegateProperties(data.ownerDelegatedProperties);
    });
  };

  const fetchClientData = useCallback(() => {
    axios.get("/jobRequest/getClientDetails").then(({ data }) => {
      handleSetProperties(data.properties);
      setClientDetails(data.clientDetails);
    });
  }, []);

  const fetchEmployeeData = () => {
    axios.get("/jobRequest/getEmployeeDetails").then(({ data }) => {
      setEmployeeDetails(data.employeeDetails);
    });
  };

  const fetchProperties = () => {
    axios.get("/property/getPropertyDetails").then((res) => {
      const propertyDetails = res.data.property;
      handleSetProperties(propertyDetails);
    });
  };
  const deleteProperty = () => {
    axios.post("/property/deactivateProperty", {
      PropertyId: propertyList[currentProperty].property_id
    });
    fetchProperties();
    fetchClientData();
    setDeactivePropertyModal(false);
  };

  useEffect(() => {
    const fetchProfileData = () => {
      fetchEmployeeData();
      fetchVendorData();
    };
    fetchDelegateProperties();
    fetchOwnerDelegateProperties();
    if (!user.vendorMode) {
      fetchClientData();
    } else if (user.empRole !== ("Admin" || "Manager")) {
      fetchVendorData();
    } else {
      fetchProfileData();
    }
  }, [fetchClientData, user.vendorMode, user.empRole]);

  const editModalUser = () => {
    setEditModal(!editModal);
  };
  const closeEditModalUser = () => {
    setEditModal(false);
  };

  const changeProfilePic = () => {
    setChangeImageModal(!changeImageModal);
  };
  const closeChangeProfilePic = () => {
    setChangeImageModal(false);
  };

  const onOwnerDelegateBar = () => {
    setShowOwnerDelegateProperties(!showOwnerDelegateProperties);
  };

  const onServiceAddressBar = () => {
    setShowServiceAddress(!showServiceAddress);
  };

  const onDelegateBar = () => {
    setShowDelegatedProperties(!showDelegatedProperties);
  };

  const removeDelegation = () => {
    axios
      .post("/property/deactivateOwnerDelegatedPropertyDetails", {
        PropertyId: ownerDelegateProperties[currentProperty].property_id
      })
      .then(() => {
        fetchOwnerDelegateProperties();
        fetchProperties();
        setDeactiveDelegationModal(false);
      });
  };

  const closeAddProperty = () => {
    setDisplayAddProperty(false);
  };
  const mobileView = useMediaQuery("(max-width: 600px)");

  return (
    <>
      {!user.vendorMode ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh"
          }}
        >
          <MainCard
            title="Profile"
            style={{
              width: "600px",
              height: "fit-content",
              minWidth: "5vw",
              maxWidth: "75vw",
              backgroundColor: "#fff",
              // marginLeft: 50,
              display: "flex",
              flexDirection: "column", // Align children vertically
              justifyContent: "center", // Center vertically
              alignItems: "center" // Center horizontally
            }}
          >
            <List>
              <Grid
                item
                xs={4}
                style={{
                  marginBottom: "0.5em",
                  alignContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center" // Center Avatar horizontally
                }}
              >
                {user.profile_pic === "avatar_default.png" ? (
                  <Avatar size={145}>{user.first_name[0]}</Avatar>
                ) : (
                  <Avatar size={145} src={user.profile_pic_url} />
                )}
                <CameraTwoTone
                  className="icon-size"
                  style={{
                    position: "absolute",
                    right: mobileView ? "80px" : "185px",
                    top: "25%",
                    transform: "translateY(-50%)",
                    WebkitTransform: "translateY(-50%)", // WebKit prefix
                    MozTransform: "translateY(-50%)", // Mozilla prefix
                    msTransform: "translateY(-50%)", // Microsoft prefix
                    OTransform: "translateY(-50%)" // Opera prefix
                  }}
                  onClick={changeProfilePic}
                />
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  marginBottom: "0.5em",

                  display: "flex",
                  flexDirection: "column",

                  fontWeight: "bold"
                }}
              >
                <Stack
                  direction={"row"}
                  spacing={1}
                  style={{ justifyContent: "center" }}
                >
                  <Typography>
                    {userFirstName} {userLastName}
                  </Typography>
                  <EditTwoTone
                    style={{
                      height: "15%",
                      width: "15%",
                      marginTop: 2.5
                    }}
                    onClick={editModalUser}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  marginBottom: "0.5em",

                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Stack>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ justifyContent: "center" }}
                  >
                    <EmailOutlinedIcon
                      style={{
                        height: "5%",
                        width: "5%",
                        marginTop: 2.5
                      }}
                    />
                    <Typography style={{ marginTop: 2 }}>
                      {userEmail}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ justifyContent: "center" }}
                  >
                    <PhoneOutlinedIcon
                      style={{
                        height: "5%",
                        width: "5%",
                        marginTop: 2.5
                      }}
                    />
                    <Typography style={{ marginTop: 2 }}>
                      {userPhoneNum}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ justifyContent: "center" }}
                  >
                    <HomeOutlinedIcon
                      style={{
                        height: "5%",
                        width: "5%",
                        marginTop: 2.5
                      }}
                    />
                    <Typography style={{ marginTop: 2 }}>
                      {userStreet}
                      <Typography style={{ marginTop: 2 }}>
                        {userCity}, {userProvince} {userPostal}
                      </Typography>
                    </Typography>
                  </Stack>

                  <PaymentMethodSelector />
                </Stack>
              </Grid>
            </List>
          </MainCard>
          <MainCard
            style={{
              width: "600px",
              height: "fit-content",
              minWidth: "5vw",
              maxWidth: "75vw",
              backgroundColor: "#fff",

              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <ListItem style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <Grid
                  container
                  className="serviceAddresses"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%"
                  }}
                >
                  <Grid item xs={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        // alignItems: "center",
                        justifyContent: "flex-start",
                        cursor: "pointer"
                      }}
                      onClick={onOwnerDelegateBar}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          textAlign: "left"
                        }}
                      >
                        Delegates
                      </Typography>
                      <div style={{ marginLeft: 2 }}>
                        {!showOwnerDelegateProperties ? (
                          <DownCircleTwoTone />
                        ) : (
                          <UpCircleTwoTone />
                        )}
                      </div>
                      <div style={{ marginLeft: 2 }}>
                        <PlusCircleTwoTone
                          style={{ marginLeft: 2 }}
                          id="addDelegateButton"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDisplayAddDelegate(true);
                          }}
                          alt="addDelegateButton"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {showOwnerDelegateProperties && (
                    <Grid item style={{ width: "100%", alignSelf: "center" }}>
                      {ownerDelegateProperties.length > 0 ? (
                        ownerDelegateProperties.map((delegateProperty, i) => (
                          <DelegateList
                            delegateProperty={delegateProperty}
                            onDeactivate={() => {
                              setCurrentProperty(i);
                              setDeactiveDelegationModal(true);
                            }}
                          />
                        ))
                      ) : (
                        <Typography>
                          You have not delegated your any property.
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </div>
            </ListItem>
          </MainCard>
          <MainCard
            style={{
              width: "600px",
              height: "fit-content",
              minWidth: "5vw",
              maxWidth: "75vw",
              backgroundColor: "#fff",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer"
            }}
          >
            <ListItem style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <Grid
                  container
                  className="serviceAddresses"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%"
                  }}
                >
                  <Grid item xs={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start"
                      }}
                      onClick={onServiceAddressBar}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        Service Address
                      </Typography>
                      <div style={{ marginLeft: 2 }}>
                        {!showServiceAddress ? (
                          <DownCircleTwoTone />
                        ) : (
                          <UpCircleTwoTone />
                        )}
                      </div>
                      <div style={{ marginLeft: 2 }}>
                        <PlusCircleTwoTone
                          style={{ marginLeft: 2 }}
                          id="addServiceAddressButton"
                          onClick={() => setDisplayAddProperty(true)}
                          alt="addServiceAddressButton"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {showServiceAddress && (
                    <Grid item style={{ width: "100%", alignSelf: "center" }}>
                      {propertyList.length > 0 ? (
                        propertyList.map((property, i) => (
                          <PropertyTable
                            // handleEdit={handleEdit}
                            delegateProperties={delegateProperties}
                            closeAddProperty={closeAddProperty}
                            property={property}
                            onDeactivate={() => {
                              setCurrentProperty(i);
                              setDeactivePropertyModal(true);
                            }}
                          />
                        ))
                      ) : (
                        <Typography>
                          You have delegated all your properties.
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </div>
            </ListItem>
          </MainCard>
          <MainCard
            style={{
              width: "600px",
              height: "fit-content",
              minWidth: "5vw",
              maxWidth: "75vw",
              backgroundColor: "#fff",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer"
            }}
          >
            <ListItem style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <Grid
                  container
                  className="serviceAddresses"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%"
                  }}
                >
                  <Grid item xs={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start"
                      }}
                      onClick={onDelegateBar}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        Delegated Properties
                      </Typography>
                      <div style={{ marginLeft: 2 }}>
                        {!showDelegatedProperties ? (
                          <DownCircleTwoTone />
                        ) : (
                          <UpCircleTwoTone />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  {showDelegatedProperties && (
                    <Grid item style={{ width: "100%", alignSelf: "center" }}>
                      {delegateProperties.length > 0 ? (
                        delegateProperties.map((delegateProperty, i) => (
                          <DelegateTable
                            // handleEdit={handleEdit}
                            delegateProperties={delegateProperties}
                            closeAddProperty={closeAddProperty}
                            delegateProperty={delegateProperty}
                            onDeactivate={() => {
                              setCurrentProperty(i);
                              setDeactivePropertyModal(true);
                            }}
                          />
                        ))
                      ) : (
                        <Typography>
                          You do not have any delegated property.
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </div>
            </ListItem>
          </MainCard>
        </div>
      ) : (
        <>
          {/* {console.log(
            vendorDetails,
            "VENDJOIGJISUHGIYSGDIYGSDIGIYSGHIUSGHIUSDHGIU"
          )} */}
          <VendorProfile
            fetchVendorData={fetchVendorData}
            vendorDetails={vendorDetails}
            employeeDetails={employeeDetails}
          />
        </>
      )}

      {/* <Modal
        destroyOnClose={true}
        width="700px"
        title="Edit Service Address"
        open={modalIsOpen}
        onCancel={onClose}
        footer={false}
      >
        {modalIsOpen && (
          <EditPropertyForm
            onClose={onClose}
            fetchData={fetchProperties}
            unitNumber={properties[currentProperty].unit_number}
            address={properties[currentProperty].street}
            city={properties[currentProperty].city}
            province={properties[currentProperty].province}
            postalCode={properties[currentProperty].postal_code}
            propertyType={properties[currentProperty].property_type}
            totalUnits={properties[currentProperty].total_units}
            PropertyId={properties[currentProperty].property_id}
          />
        )}
      </Modal> */}

      <Modal
        destroyOnClose={true}
        width="700px"
        title="Add Service Address"
        open={displayAddProperty}
        onCancel={closeAddProperty}
        footer={false}
      >
        {displayAddProperty && (
          <AddProperty fetchData={fetchProperties} onClose={closeAddProperty} />
        )}
      </Modal>
      <Modal
        destroyOnClose={true}
        width="400px"
        title="Add Delegate"
        open={displayAddDelegate}
        onCancel={() => setDisplayAddDelegate(false)}
        footer={false}
      >
        {displayAddDelegate && (
          <AddDelegate
            fetchProperties={fetchProperties}
            fetchOwnerDelegateProperties={fetchOwnerDelegateProperties}
            propertyList={propertyList}
            displayDelegateSuccess={displayDelegateSuccess}
            onClose={() => setDisplayAddDelegate(false)}
          />
        )}
      </Modal>
      {delegateAlert && (
        <Alert
          style={{
            zIndex: 10000,
            position: "absolute",
            top: "2rem",
            left: "calc(50vw - 8rem)",
            width: "16rem"
          }}
          severity="success"
        >
          You have assigned delegate to your property successfully.
        </Alert>
      )}

      <Modal
        destroyOnClose={true}
        width="600px"
        title="User Details"
        open={editModal}
        onCancel={closeEditModalUser}
        footer={false}
      >
        {editModal && (
          <EditUserProfile
            setUserFirstName={setUserFirstName}
            setUserLastName={setUserLastName}
            setUserEmail={setUserEmail}
            setUserPhoneNum={setUserPhoneNum}
            setUserStreet={setUserStreet}
            setUserCity={setUserCity}
            setUserProvince={setUserProvince}
            setUserPostal={setUserPostal}
            fetchVendorData={fetchVendorData}
            fetchClientData={fetchClientData}
            fetchEmployeeData={fetchEmployeeData}
            fetchProperties={fetchProperties}
            propertyList={propertyList}
            vendorDetails={vendorDetails}
            clientDetails={clientDetails}
            employeeDetails={employeeDetails}
            user={user}
            closeUpdate={closeEditModalUser}
          />
        )}
      </Modal>

      <Modal
        destroyOnClose={true}
        width="400px"
        title="Change Profile Picture"
        open={changeImageModal}
        onCancel={closeChangeProfilePic}
        footer={false}
      >
        {changeImageModal ? (
          <ChangeProfilePicture
            user={user}
            closeModal={closeChangeProfilePic}
          />
        ) : (
          ""
        )}
      </Modal>
      <Modal
        width="400px"
        title="Delete Property"
        open={deactivePropertyModal}
        onCancel={() => setDeactivePropertyModal(false)}
        footer={null}
      >
        Are you sure you want to deactivate this address?
        <div style={{ textAlign: "right", marginTop: "1rem" }}>
          <Button
            color="error"
            variant="contained"
            onClick={deleteProperty}
            style={{
              marginRight: "0.5rem",
              height: "40px",
              lineHeight: "40px"
            }}
          >
            Deactivate
          </Button>
          <Button
            size="sm"
            variant="outlined"
            color="secondary"
            onClick={() => setDeactivePropertyModal(false)}
            style={{ height: "40px", lineHeight: "40px" }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal
        width="400px"
        title="Remove Property From Delegation"
        open={deactiveDelegationModal}
        onCancel={() => setDeactiveDelegationModal(false)}
        footer={null}
      >
        Are you sure you want to remove this property from delegation?
        <div style={{ textAlign: "right", marginTop: "1rem" }}>
          <Button
            color="error"
            variant="contained"
            onClick={removeDelegation}
            style={{
              marginRight: "0.5rem",
              height: "40px",
              lineHeight: "40px"
            }}
          >
            Remove
          </Button>
          <Button
            size="sm"
            variant="outlined"
            color="secondary"
            onClick={() => setDeactiveDelegationModal(false)}
            style={{ height: "40px", lineHeight: "40px" }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UserProfile;
