// import React from "react";
// import { withStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import { Typography } from "@material-ui/core";
// import { MessageOutlined } from "@ant-design/icons";
// import { IconButton } from "@mui/material";
// import StarRating from "../Review/StarRating";

// const StyledTableCell = withStyles((theme) => ({
//   head: {
//     backgroundColor: "#017a46",
//     color: theme.palette.common.white
//   },
//   body: {
//     fontSize: 14
//   }
// }))(TableCell);

// const StyledTableRow = withStyles((theme) => ({
//   root: {
//     "&:nth-of-type(odd)": {
//       backgroundColor: theme.palette.action.hover
//     }
//   }
// }))(TableRow);

// export default function BidListingTable(props) {
//   console.log(props.mobileView);
//   const handleRowClick = (e, bidIndex) => {
//     props.setBidDetailsModal(true);
//     props.setCurrentBid(bidIndex);
//   };
//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column"
//       }}
//     >
//       <Typography variant="h6" gutterBottom>
//         Bid Listing
//       </Typography>
//       <TableContainer
//         style={{
//           display: "flex",
//           width: "100%",
//           marginTop: "20px",
//           flexDirection: "row",
//           marginBottom: 20,
//           overflowX: "auto"
//         }}
//       >
//         <Table aria-label="customized table">
//           <TableHead>
//             <TableRow>
//               <StyledTableCell
//                 style={{
//                   border: "1px solid black",
//                   width: 150
//                 }}
//               >
//                 Vendor
//               </StyledTableCell>
//               <StyledTableCell
//                 align="center"
//                 style={{
//                   border: "1px solid black",
//                   width: 350
//                 }}
//               >
//                 Work need to be done{" "}
//               </StyledTableCell>
//               <StyledTableCell
//                 align="center"
//                 style={{
//                   border: "1px solid black",
//                   width: 150
//                 }}
//               >
//                 Bid Amount
//               </StyledTableCell>
//               <StyledTableCell
//                 align="center"
//                 style={{
//                   border: "1px solid black",
//                   width: 250
//                 }}
//               >
//                 Vendor rating(overall)
//               </StyledTableCell>
//               <StyledTableCell align="right"></StyledTableCell>
//             </TableRow>
//           </TableHead>
//           {props.bidDetails.map((bid, index) => (
//             <TableBody
//               onClick={(event) => handleRowClick(event, index)}
//               className="hover-row"
//             >
//               <StyledTableRow key={bid.vendor_name}>
//                 <StyledTableCell component="th" scope="row">
//                   {bid.vendor_legal_name}
//                 </StyledTableCell>
//                 <StyledTableCell align="left" wordWrap="break-word">
//                   {bid.work_to_be_done}
//                 </StyledTableCell>
//                 <StyledTableCell align="right">
//                   {bid.bid_amount}
//                 </StyledTableCell>
//                 <StyledTableCell align="right">
//                   <StarRating rating={bid.rating} />
//                 </StyledTableCell>
//                 <StyledTableCell align="right">
//                   <IconButton
//                     onClick={() => {
//                       props.newChat(
//                         bid.bid_vendor_id,
//                         bid.vendor_legal_name,
//                         bid.bid_id
//                       );
//                     }}
//                     size="sm"
//                     type="button"
//                     variant="contained"
//                     color="primary"
//                   >
//                     <MessageOutlined />
//                   </IconButton>
//                 </StyledTableCell>
//               </StyledTableRow>
//             </TableBody>
//           ))}
//         </Table>
//       </TableContainer>
//     </div>
//   );
// }
import React from "react";
import { styled } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import IconButton from "@mui/material/IconButton";
import StarRating from "../Review/StarRating";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#017a46",
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  }
}));

export default function BidListingTable(props) {
  const handleRowClick = (e, bidIndex) => {
    props.setBidDetailsModal(true);
    props.setCurrentBid(bidIndex);
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Bid Listing
      </Typography>

      <TableContainer
        style={{
          display: "flex",
          width: "100%",
          marginTop: "20px",
          flexDirection: "row",
          marginBottom: 20,
          // marginLeft: props.mobileView ? 10 : 10,

          overflowX: "auto"
        }}
      >
        <Table
          aria-label="customized table"
          sx={{ minWidth: props.mobileView ? 420 : 750 }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{ border: "1px solid black", width: "20%" }}
              >
                Vendor
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ border: "1px solid black", width: "40%" }}
              >
                Work need to be done
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ border: "1px solid black", width: "15%" }}
              >
                Bid Amount
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ border: "1px solid black", width: "30%" }}
              >
                Vendor rating
              </StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.bidDetails.map((bid, index) => (
              <StyledTableRow
                key={bid.vendor_name}
                onClick={(event) => handleRowClick(event, index)}
                className="hover-row"
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="left"
                  wordWrap="break-word"
                >
                  {bid.vendor_legal_name}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  wordWrap="break-word"
                  fontSize="12px"
                >
                  {bid.work_to_be_done}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {bid.bid_amount}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <StarRating rating={bid.rating} />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation(); // Stop event propagation
                      props.newChat(
                        bid.bid_vendor_id,
                        bid.vendor_legal_name,
                        bid.bid_id
                      );
                    }}
                    size="small"
                    type="button"
                    variant="contained"
                    color="primary"
                  >
                    <MessageOutlinedIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
