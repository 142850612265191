import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Modal } from "antd";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";

const PriceInput = (props) => {
  const [addClicked, setAddClicked] = useState(false);
  const [type, setType] = useState("Labour");
  const [labourType, setLabourType] = useState("Flat");
  const [amount, setAmount] = useState("");
  const [quantity, setQuantity] = useState("1");
  const [errors, setErrors] = useState({ quantity: false, amount: false });

  const checkForBadInput = () => {
    const newErrors = {
      quantity: quantity === "" || isNaN(quantity),
      amount: amount === "" || isNaN(amount),
    };
    setErrors(newErrors);
    return newErrors.quantity || newErrors.amount;
  };

  const handleSubmit = () => {
    if (checkForBadInput()) {
      setAddClicked(true);
      return;
    }

    const newPrice = {
      type: type === "Labour" ? labourType : type,
      amount: parseFloat(amount),
      quantity: parseInt(quantity),
    };

    if (newPrice.type === "Flat") {
      newPrice.quantity = 1;
    }

    setAmount("");
    setQuantity("1");
    setAddClicked(false);
    props.addPrice(newPrice);
  };

  return (
    <Grid container spacing={3}>
      <FormControl fullWidth sx={{ mt: 2, ml: 3 }}>
        <RadioGroup
          row={true}
          onChange={(e) => {
            setType(e.target.value);
            setAddClicked(false);
          }}
          value={type}
        >
          <FormControlLabel
            value="Labour"
            control={<Radio />}
            label="Labour Charge"
          />
          <FormControlLabel
            value="Materials"
            control={<Radio />}
            label="Material Charge"
          />
        </RadioGroup>
      </FormControl>

      {type === "Labour" && (
        <FormControl fullWidth sx={{ mt: 2, ml: 3 }}>
          <RadioGroup
            row={true}
            onChange={(e) => {
              setLabourType(e.target.value);
              setAddClicked(false);
            }}
            value={labourType}
          >
            <FormControlLabel
              value="Hourly"
              control={<Radio />}
              label="Hourly Rate?"
            />
            <FormControlLabel
              value="Flat"
              control={<Radio />}
              label="Flat Rate?"
            />
          </RadioGroup>
        </FormControl>
      )}

      <Grid item xs={12} md={12}>
        <Stack spacing={1}>
          <InputLabel>Price</InputLabel>
          <OutlinedInput
            required
            id="price"
            type="price"
            name="price"
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            placeholder="Price"
          />
          {console.log("errors", errors)}
          {(amount === "" || errors.amount) && addClicked && (
            <Typography style={{ color: "red" }}>
              *Price is required and must be a number.
            </Typography>
          )}
        </Stack>
      </Grid>
      {type === "Labour" && labourType === "Hourly" && (
        <Grid item xs={12} md={12}>
          <Stack spacing={1}>
            <InputLabel>Hours</InputLabel>
            <OutlinedInput
              required
              id="hours"
              type="hours"
              name="hours"
              onChange={(e) => setQuantity(e.target.value)}
              value={quantity}
              placeholder="Hours"
            />
            {(props.hours === "" || errors.quantity) && addClicked && (
              <Typography style={{ color: "red" }}>
                *Hours is required and must be a number.
              </Typography>
            )}
          </Stack>
        </Grid>
      )}

      <Button
        size="sm"
        variant="contained"
        color="primary"
        sx={{ ml: 2, float: "right" }}
        type="button"
        onClick={handleSubmit}
      >
        Add To Cost
      </Button>
    </Grid>
  );
};

const EditScopeForm = (props) => {
  const [priceList, setPriceList] = useState([]);
  const [notes, setNotes] = useState("");
  const [showCostInput, setShowCostInput] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    localStorage.removeItem(`editScopeFormData#${props.openJR.job_id}`);
    setSubmitting(true);

    const scopeFormData = {
      jobId: props.openJR.job_id,
      workToBeDone: props.openJR.job_description,
      notes: notes,
      priceList: priceList,
    };

    axios.post("/scope/changeScope", scopeFormData).then((res) => {
      props.setEditScopeModal(false);
      props.fetchData();
    });
  };

  /**
   * adds cost to the price list
   * @param {{type:string, amount:number, quantity:number}} newPrice
   */
  const addPriceLine = (newPrice) => {
    const newId =
      priceList.length === 0 ? 0 : priceList[priceList.length - 1].id;
    const newPriceLine = { ...newPrice, id: newId };
    setPriceList((currentList) => [...currentList, newPriceLine]);
    setShowCostInput(false);
  };

  const removePriceLine = (ID) => {
    const newTotal = priceList.filter((t) => {
      return t.id !== ID;
    });
    setPriceList(newTotal);
  };

  const calcTotalPrice = () =>
    priceList.reduce((accumulator, currentPrice) => {
      return accumulator + currentPrice.amount * currentPrice.quantity;
    }, 0);

  const saveFormDataToLocal = () => {
    const formDataToSave = {
      jobId: props.openJR.job_id,
      notes: notes,
      priceList,
      date: new Date(),
    };
    localStorage.setItem(
      `editScopeFormData#${props.openJR.job_id}`,
      JSON.stringify(formDataToSave)
    );
  };

  const getFormDataFromLocal = useCallback(() => {
    const savedFormData = localStorage.getItem(
      `editScopeFormData#${props.openJR.job_id}`
    );

    if (savedFormData) {
      const parsedFormData = JSON.parse(savedFormData);
      const saveDate = new Date(parsedFormData.date);
      saveDate.setDate(new Date(parsedFormData.date).getDate() + 5);
      if (saveDate < new Date()) {
        localStorage.removeItem(`editScopeFormData#${props.openJR.job_id}`);
      } else {
        setNotes(parsedFormData.notes);
        setPriceList(parsedFormData.priceList);
      }
    }
  }, [props.openJR.job_id]);

  useEffect(() => {
    getFormDataFromLocal();
  }, [getFormDataFromLocal]);

  return (
    <>
      <form style={{ display: "flex", flexDirection: "column" }}>
        <Grid container spacing={3} padding={0}>
          <Grid item md={12}>
            <Typography variant="h6" color="red">
              NOTE: This is not an estimate, this price will be used to generate
              an invoice.
            </Typography>
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <Typography variant="h5">Work to be done</Typography>
              <Typography variant="h6">
                {props.openJR.job_description}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={12} padding={0} margin={0}>
            <Stack padding={0} margin={0}>
              <InputLabel>
                <button
                  style={{
                    marginRight: "0.5rem",
                    width: "1em",
                    height: "1em",
                    lineHeight: "0.8em",
                    padding: "0",
                    borderRadius: "100%",
                    border: "none",
                    color: "white",
                    backgroundColor: "#1890ff",
                  }}
                  name="showCostInput"
                  type="button"
                  onClick={() => {
                    setShowCostInput(true);
                  }}
                >
                  +
                </button>
                {"Add Charges"}
              </InputLabel>
            </Stack>
          </Grid>

          {priceList.length > 0 && (
            <Grid xs={12} md={12} marginLeft="2em">
              <Grid container width="100%" height="fit-content">
                {priceList.map((priceItem, i) => {
                  priceItem.id = i;
                  let typeLabel;
                  if (priceItem.type === "Hourly") {
                    typeLabel = `Labour ${priceItem.quantity} hours at `;
                  } else if (priceItem.type === "Flat") {
                    typeLabel = "Labour flat rate";
                  } else {
                    typeLabel = "Materials at";
                  }
                  return (
                    <Grid
                      xs={12}
                      width="100%"
                      height="fit-content"
                      display={"flex"}
                      flexDirection={"row"}
                      padding={"0.25rem"}
                      gap={"2rem"}
                      alignItems={"center"}
                      justifyContent={"flex-end"}
                      key={priceItem.id}
                    >
                      <Grid xs={7}>
                        <p style={{ margin: "0" }}>
                          {typeLabel} ${priceItem.amount}
                        </p>
                      </Grid>
                      <Grid xs={4} textAlign="right" paddingRight={2}>
                        {`$${parseFloat(
                          priceItem.amount * priceItem.quantity
                        ).toFixed(2)}`}
                      </Grid>
                      <Grid xs={1}>
                        <IconButton
                          aria-label="delete"
                          size="medium"
                          style={{
                            justifySelf: "flex-end",
                          }}
                          onClick={() => removePriceLine(priceItem.id)}
                        >
                          <CloseOutlined
                            style={{
                              color: "#FF0000",
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })}

                <Grid
                  xs={12}
                  width="100%"
                  height="fit-content"
                  display={"flex"}
                  flexDirection={"row"}
                  padding={"0.25rem"}
                  gap={"2rem"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                >
                  <Grid xs={7}>&nbsp;</Grid>
                  <Grid
                    xs={4}
                    borderTop={"solid black 2px"}
                    textAlign="right"
                    paddingRight={2}
                  >
                    ${parseFloat(calcTotalPrice()).toFixed(2)}
                  </Grid>
                  <Grid xs={1}>&nbsp;</Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <InputLabel>Reason For price change</InputLabel>
              <OutlinedInput
                required
                id="notes"
                name="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder={"Price Change..."}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={12}>
            {submitting && (
              <Typography component="h4" color={"#7c7c7c"}>
                submitting...
              </Typography>
            )}
            <AnimateButton>
              <Button
                disabled={submitting}
                size="sm"
                type="button"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ ml: 2, float: "right" }}
              >
                Submit
              </Button>
            </AnimateButton>
            <AnimateButton>
              <Button
                size="sm"
                type="button"
                variant="contained"
                color="primary"
                sx={{ ml: 2, float: "right" }}
                onClick={saveFormDataToLocal}
              >
                Save
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>

      <Modal
        destroyOnClose={true}
        width="350px"
        title="Add to cost"
        open={showCostInput}
        onCancel={() => setShowCostInput(false)}
        footer={false}
      >
        {showCostInput && <PriceInput addPrice={addPriceLine} />}
      </Modal>
    </>
  );
};

export default EditScopeForm;
