// assets
import { FilePdfOutlined } from "@ant-design/icons";

// icons
const icons = {
  FilePdfOutlined,
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = {
  id: "document",
  title: "Document",
  type: "group",
  children: [
    {
      id: "terms",
      title: "Terms And Conditions",
      type: "item",
      url: "/dashboard/TermsAndCondition2022",
      icon: icons.FilePdfOutlined,
      external: false,
      target: false,
      breadcrumbs: false,
    },
    {
      id: "privacy",
      title: "Privacy Policy",
      type: "item",
      url: "/dashboard/PrivacyPolicy2022",
      icon: icons.FilePdfOutlined,
      external: false,
      target: false,
      breadcrumbs: false,
    },
  ],
};

export default support;
