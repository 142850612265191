import React, { useState, useEffect } from "react";
import classes from "./Checkout.module.css";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

import {
  Step,
  StepLabel,
  Button,
  Paper,
  Typography,
  Stepper,
} from "@mui/material";

import PaymentForm from "./PaymentForm";

import ReviewPayment from "./ReviewPayment";

const steps = ["Payment details", "Review your payment"];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <PaymentForm />;
    case 1:
      return <ReviewPayment />;
    default:
      throw new Error("Unknown step");
  }
}

const Checkout = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    axios.get("/payment/config").then((res) => {
      let publishableKey = res.data.publishableKey;
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    axios
      .post("/payment/checkout", {
        total: 1000,
      })
      .then((res) => {
        let { clientSecret } = res.data.clientSecret;
        setClientSecret(clientSecret);
      });
  }, []);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Payment Details
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for your payment.
                </Typography>
                <Typography variant="subtitle1">
                  Your payment number is #2001539. We have emailed your payment
                  confirmation.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}

                  {activeStep === steps.length - 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Pay
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </main>
    </div>
  );
};

export default Checkout;
