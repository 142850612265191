import { useCallback, useEffect, useState } from "react";
import { Modal, Typography } from "antd";
import styles from "./Employees.module.css";
import EmployeeTile from "./EmployeeTile";
import EmployeeForm from "./EmployeeForm.js";
import axios from "axios";
import MainCard from "../../../Layout/components/MainCard.js";
import { Button, Grid, List, ListItem, Stack, Alert } from "@mui/material";
import Avatar from "antd/lib/avatar/avatar";
import { useUserState } from "../../../Context/UserContext";
import EditUserProfile from "../../EditForms/EditUserProfile";
import ChangePassword from "../../EditForms/ChangePassword";
import ChangeProfilePicture from "../../EditForms/ChangeProfilePicture";

import EditVendorProfile from "../../EditForms/EditVendorProfile";
import SetEmployeeAvailability from "../../commonComponents/JRComponents/Availability/SetEmployeeAvailability";

import PropTypes from "prop-types";
import EditCompanyProfile from "./EditCompanyProfile";
import AdminEditProfile from "./AdminEditProfile";
import EditProfile from "./EditProfile";
import DeactivateEmployeeModal from "./DeactivateEmployeeModal";

import EditBankingInfo from "./EditBankingInfo.js";
import LoadScreen from "../../../Components/LoadScreen.js";
import {
  CameraTwoTone,
  PlusCircleTwoTone,
  EditTwoTone,
} from "@ant-design/icons";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";

const VendorProfile = (props) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [currentEmployee, setCurrentEmployee] = useState(-1);
  const [search, setSearch] = useState("");
  const [filterValue, setFilterValue] = useState("1");
  const [employees, setEmployees] = useState([]);
  const [currentEmployeeData, setCurrentEmployeeData] = useState([]);

  const [lastDay, setLastDay] = useState();

  // Modals
  const [updateEmployeeModal, setUpdateEmployeeModal] = useState(false);
  const [employeeFormModal, setEmployeeFormModal] = useState(false);
  const [deactivateEmployeeModal, setDeactivateEmployeeModal] = useState(false);
  const [vendorProfileEditModal, setVendorProfileEditModal] = useState(false);
  const [adminProfileEditModal, setAdminProfileEditModal] = useState(false);
  const [profileEditModal, setProfileEditModal] = useState(false);
  const [editVendorModal, setEditVendorModal] = useState(false);
  const [editEmployeeModal, setEditEmployeeModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [changeImageModal, setChangeImageModal] = useState(false);
  const [deactivationReason, setDeactivationReason] = useState("");
  const [vendorProfile, setVendorProfile] = useState(true);
  const [editBanking, setEditBanking] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { user } = useUserState();

  const handleCloseEditEmployeeModel = () => {
    fetchEmployeeData();
    setEditEmployeeModal(false);
  };

  const editUser = () => {
    setEditModal(true);
  };

  const editModalUser = () => {
    setProfileEditModal(true);
  };
  const closeEditModalUser = () => {
    setEditModal(false);
  };

  const changePassword = () => {
    setChangePasswordModal(true);
  };
  const closeChangePasswordModal = () => {
    setChangePasswordModal(false);
  };

  const changeProfilePic = () => {
    setChangeImageModal(true);
  };
  const closeChangeProfilePic = () => {
    setChangeImageModal(false);
  };

  const searchIcon = require("../../../images/search.png");

  const fetchEmployeeData = useCallback(() => {
    axios
      .post("/employee/getEmployees", {
        VendorId: user.company_id,
      })
      .then(({ data }) => {
        console.log(data);
        const employees = data.employees;
        setEmployeeList(employees);
        const employeeDetails = data.employees;
        setEmployees(employeeDetails);
      });
  }, [user.company_id]);

  const fetchCurrentEmployeeData = useCallback(() => {
    axios.post("/employee/getCurrentEmployeeData").then((res) => {
      const employeeDetails = res.data.currentEmployeeData;

      // Parse the availability property
      if (employeeDetails.availability) {
        employeeDetails.availability = JSON.parse(employeeDetails.availability);
      }

      setCurrentEmployeeData(employeeDetails);
    });
  }, []);

  useEffect(() => {
    const fetchAdminData = () => {
      fetchCurrentEmployeeData();
      fetchEmployeeData();
    };
    if (user.empRole !== ("Admin" && "Manager")) {
      if (
        currentEmployeeData.role === "Admin" ||
        currentEmployeeData.role === "Manager"
      ) {
        fetchAdminData();
      } else {
        fetchCurrentEmployeeData();
      }
    } else {
      fetchEmployeeData();
    }
  }, [
    fetchEmployeeData,
    currentEmployeeData.role,
    props.vendorDetails,
    user.empRole,
    fetchCurrentEmployeeData,
  ]);

  useEffect(() => {
    const searchEmployees = (employees) => {
      let rowList = employees;
      for (let searchTerm of search.toLowerCase().split(" ")) {
        rowList = rowList.filter((employee, i) => {
          let employeeString = `${employee.first_name} ${employee.last_name} ${employee.role}`;
          return employeeString.toLocaleLowerCase().includes(searchTerm);
        });
      }
      if (filterValue === "1") {
        rowList = rowList.filter((employee) => employee.is_active === 1);
      } else if (filterValue === "0") {
        rowList = rowList.filter(
          (employee) => employee.is_active === 0 || employee.is_active === 1
        );
      }
      return rowList;
    };
    setEmployeeList(searchEmployees(employees));
  }, [search, employees, filterValue]);

  const confirmDeactivation = () => {
    axios.post("/employee/deactivateEmployee", {
      EmployeeId: employeeList[currentEmployee].employee_id,
      deactivationReason: deactivationReason,
      lastDay: lastDay,
      currentDate: new Date().toUTCString(),
    });
    fetchEmployeeData();
    setDeactivateEmployeeModal(false);
  };

  // const handleSwitchChange = () => {
  //   if (filterValue === "1") {
  //     setFilterValue("0");
  //   } else {
  //     setFilterValue("1");
  //   }
  // };

  const spacedList = (string) => {
    const serviceList =
      props.vendorDetails.vendorDetails?.vendor_services.split(",");
    if (typeof serviceList !== "undefined") {
      const formattedServiceList = serviceList.join(", ");
      return formattedServiceList;
    } else {
      return "No Services";
    }
  };

  const hideAccount = (accountNum) => {
    let lastFour =
      props.vendorDetails.bankInfo.bank_account_number === null
        ? "NAN"
        : props.vendorDetails.bankInfo.bank_account_number.slice(-4);
    return `***${lastFour}`;
  };

  return (
    <>
      {props.vendorDetails !== "" ? (
        <MainCard
          title={vendorProfile ? "Company Details" : "User Details"}
          style={{
            width: "75vw",
            height: "fit-content",
            minWidth: "75vw",
            maxWidth: "75vw",
            backgroundColor: "#fff",
            marginLeft: 50,
          }}
        >
          {vendorProfile &&
            user.vendorMode &&
            user.empRole === ("Admin" || "Manager") && (
              <List>
                <Grid
                  item
                  xs={4}
                  style={{
                    marginBottom: "0.5em",
                    marginLeft: 50,
                    alignContent: "center",
                  }}
                >
                  {props.vendorDetails?.vendorDetails.vendor_profile_pic ===
                    "avatar_default.png" ||
                  props.vendorDetails?.vendorDetails.vendor_profile_pic ===
                    null ? (
                    <Avatar size={145} style={{ marginLeft: "25px" }}>
                      {props.vendorDetails.vendorDetails.vendor_legal_name[0]}
                    </Avatar>
                  ) : (
                    <Avatar
                      size={145}
                      style={{ marginLeft: "25px" }}
                      src={user.vendor_profile_pic_url}
                    />
                  )}
                  <CameraTwoTone
                    className="icon-size"
                    onClick={changeProfilePic}
                    theme="outlined"
                  />

                  <Stack
                    direction={"row"}
                    spacing={1}
                    style={{
                      fontWeight: "bold",
                      marginTop: 5,
                      justifyContent: "center",
                    }}
                  >
                    <Typography>
                      {props.vendorDetails.vendorDetails.vendor_legal_name}
                    </Typography>
                    <EditTwoTone
                      style={{
                        height: "5%",
                        width: "5%",
                        marginTop: 2.5,
                      }}
                      onClick={(e) => {
                        setEditVendorModal(true);
                      }}
                    />
                  </Stack>
                  {console.log(currentEmployeeData)}
                  <SetEmployeeAvailability
                    fetchCurrentEmployeeData={fetchCurrentEmployeeData}
                    currentEmployee={currentEmployeeData}
                    availabilityArray={currentEmployeeData.availability}
                    adminOrManager={
                      currentEmployeeData.role === "Admin"
                        ? true
                        : currentEmployeeData.role === "Manager"
                        ? true
                        : false
                    }
                  />
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <HomeRepairServiceIcon
                      style={{
                        height: "5%",
                        width: "5%",
                        marginTop: 2.5,
                      }}
                    />
                    <Typography>
                      {spacedList(
                        props.vendorDetails.vendorDetails.vendor_services
                      )}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <EmailOutlinedIcon
                      style={{
                        height: "5%",
                        width: "5%",
                        marginTop: 2.5,
                      }}
                    />
                    <Typography>
                      {props.vendorDetails.vendorDetails.company_email}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <PhoneOutlinedIcon
                      style={{
                        height: "5%",
                        width: "5%",
                        marginTop: 2.5,
                      }}
                    />
                    <Typography>
                      {props.vendorDetails.vendorDetails.phone_number}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <HomeOutlinedIcon
                      style={{
                        height: "5%",
                        width: "5%",
                        marginTop: 2.5,
                      }}
                    />
                    <Typography>
                      {props.vendorDetails.vendorDetails.street}
                      <Typography>
                        {props.vendorDetails.vendorDetails.city},{" "}
                        {props.vendorDetails.vendorDetails.province}{" "}
                        {props.vendorDetails.vendorDetails.postal_code}
                      </Typography>
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <AccountBalanceIcon
                      style={{
                        height: "5%",
                        width: "5%",
                        marginTop: 2.5,
                      }}
                    />
                    <Typography>
                      {`${hideAccount(
                        props.vendorDetails.bankInfo.bank_account_number
                      )}/${props.vendorDetails.bankInfo.bank_transit_code}-${
                        props.vendorDetails.bankInfo.bank_institution_code
                      }`}
                    </Typography>
                    <EditTwoTone
                      style={{
                        height: "5%",
                        width: "5%",
                        marginTop: 2.5,
                      }}
                      onClick={(e) => {
                        setEditBanking(true);
                      }}
                    />
                  </Stack>
                </Grid>

                <ListItem>
                  <div>
                    <Grid
                      container
                      className={styles.employeeList}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Grid item>
                        <Grid
                          item
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <Stack>Employees</Stack>

                          <PlusCircleTwoTone
                            id="addEmployee"
                            onClick={() => {
                              setEmployeeFormModal(true);
                            }}
                            alt="addEmployee"
                          />

                          <div
                            style={{
                              height: "1.3rem",
                              alignItems: "center",
                              display: "flex",
                              border: "1px solid #dddddd",
                              fontSize: "0.7rem",
                              padding: "0",
                              outline: "none",
                            }}
                          >
                            <input
                              style={{ border: "none" }}
                              placeholder="Search..."
                              type="text"
                              onChange={({ target }) => setSearch(target.value)}
                            />
                            <img
                              style={{
                                height: "100%",
                                borderLeft: "2px solid #dddddd",
                                padding: "2px",
                              }}
                              src={searchIcon}
                              alt=""
                              className="searchIcon"
                            />
                          </div>

                          {/* <div
                            style={{
                              height: "1.3rem",
                              alignItems: "center",
                              display: "flex",
                              fontSize: "0.7rem",
                              padding: "0",
                              outline: "none"
                            }}
                          >
                            <InputLabel htmlFor="employeeSwitch">
                              Show Inactive Employees
                            </InputLabel>
                            <Switch
                              id="employeeSwitch"
                              color="primary"
                              onChange={() => {
                                handleSwitchChange();
                              }}
                            />
                          </div> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </ListItem>

                <div className={styles.employeeList}>
                  {employeeList.map((employee, i) => {
                    return (
                      <span key={`employee_tile_${employee.employee_id}`}>
                        <EmployeeTile
                          employee={employee}
                          onEdit={() => {
                            setCurrentEmployee(i);
                            setUpdateEmployeeModal(true);
                          }}
                          onDeactivate={() => {
                            setCurrentEmployee(i);
                            setDeactivateEmployeeModal(true);
                          }}
                        />
                      </span>
                    );
                  })}
                </div>
              </List>
            )}

          {!vendorProfile &&
            user.vendorMode &&
            user.empRole === ("Admin" || "Manager") && (
              <List>
                <Grid
                  item
                  xs={4}
                  style={{ textAlign: "center", marginBottom: "0.5em" }}
                  justifyContent={"center"}
                >
                  {user.profile_pic === "avatar_default.png" ||
                  user.profile_pic === null ? (
                    <Avatar size={145}>{user.first_name[0]}</Avatar>
                  ) : (
                    <Avatar size={145} src={user.profile_pic} />
                  )}
                  <h2 style={{ margin: "0" }}>
                    {user.first_name} {user.last_name}
                  </h2>
                  <Stack
                    direction={"column"}
                    textAlign={"left"}
                    width={"fit-content"}
                    margin={"0 auto"}
                  >
                    <p style={{ margin: "0" }}>{user.email}</p>
                    <p style={{ margin: "0" }}>{user.phone_number}</p>
                    <p style={{ margin: "0" }}>{user.street}</p>
                    <p style={{ margin: "0" }}>
                      {user.city}, {user.province} {user.postal_code}
                    </p>
                    {/* <PaymentMethodSelector /> */}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={6}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    direction={"row"}
                    textAlign={"center"}
                    justifyContent={"center"}
                    width={"100%"}
                    maxWidth={"522px"}
                    spacing={5}
                  >
                    <Stack justifyContent={"center"} alignItems={"center"}>
                      <Button
                        size="small"
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={editModalUser}
                      >
                        Edit Profile
                      </Button>
                    </Stack>

                    <Stack justifyContent={"center"} alignItems={"center"}>
                      <SetEmployeeAvailability
                        fetchCurrentEmployeeData={fetchCurrentEmployeeData}
                        currentEmployee={currentEmployeeData}
                        availabilityArray={currentEmployeeData.availability}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </List>
            )}

          {user.vendorMode && user.empRole !== ("Admin" || "Manager") && (
            <div className={styles.main}>
              <div className={styles.logoContainer}>
                {user.profile_pic === "avatar_default.png" ? (
                  <Avatar size={145}>{user.first_name[1]}</Avatar>
                ) : (
                  <Avatar size={145} src={user.profile_pic} />
                )}
                <h2 style={{ margin: "0" }}>
                  {user.first_name} {user.last_name} ({currentEmployeeData.role}
                  )
                </h2>
                <Stack
                  direction={"column"}
                  textAlign={"left"}
                  width={"fit-content"}
                  margin={"0 auto"}
                >
                  {console.log(currentEmployeeData)}
                  <SetEmployeeAvailability
                    fetchCurrentEmployeeData={fetchCurrentEmployeeData}
                    currentEmployee={currentEmployeeData}
                    availabilityArray={currentEmployeeData.availability}
                  />
                  <p style={{ margin: "0" }}>{user.email}</p>
                  <p style={{ margin: "0" }}>{user.phone_number}</p>
                  <p style={{ margin: "0" }}>{user.street}</p>
                  <p style={{ margin: "0" }}>
                    {user.city}, {user.province} {user.postal_code}
                  </p>
                </Stack>
                <Grid item xs={4} md={6} style={{ textAlign: "center" }}>
                  <Button
                    size="small"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      setProfileEditModal(true);
                    }}
                  >
                    Edit Profile
                  </Button>
                </Grid>
              </div>
            </div>
          )}
        </MainCard>
      ) : (
        <LoadScreen />
      )}

      {/* =====================Modals======================= */}
      <Modal
        destroyOnClose={true}
        width="400px"
        title="Edit Company Profile"
        open={vendorProfileEditModal}
        onCancel={() => setVendorProfileEditModal(false)}
        footer={false}
      >
        <EditCompanyProfile
          changeProfilePic={changeProfilePic}
          fetchVendorData={props.fetchVendorData}
          vendorDetails={props.vendorDetails}
          setEditBanking={setEditBanking}
          setVendorProfileEditModal={setVendorProfileEditModal}
          vendorProfileEditModal={vendorProfileEditModal}
          setEditVendorModal={setEditVendorModal}
        />
      </Modal>

      <Modal
        destroyOnClose={true}
        width="400px"
        title="Edit Banking Info"
        open={editBanking}
        onCancel={() => setEditBanking(false)}
        footer={false}
      >
        <EditBankingInfo
          fetchVendorData={props.fetchVendorData}
          vendorDetails={props.vendorDetails}
          clientDetails={props.clientDetails}
          employeeDetails={props.employeeDetails}
          user={props.vendorDetails}
          setEditBanking={setEditBanking}
        />
      </Modal>

      <Modal
        destroyOnClose={true}
        width="400px"
        title="Edit Profile"
        open={adminProfileEditModal}
        onCancel={() => setAdminProfileEditModal(false)}
        footer={false}
      >
        <AdminEditProfile
          changeProfilePic={changeProfilePic}
          setAdminProfileEditModal={setAdminProfileEditModal}
          editModalUser={editModalUser}
          changePassword={changePassword}
        />
      </Modal>

      <Modal
        destroyOnClose={true}
        width="400px"
        title="Edit Profile"
        open={profileEditModal}
        onCancel={() => setProfileEditModal(false)}
        footer={false}
      >
        <EditProfile
          editModalUser={editUser}
          changeProfilePic={changeProfilePic}
          changePassword={changePassword}
          setProfileEditModal={setProfileEditModal}
        />
      </Modal>

      <Modal
        destroyOnClose={true}
        width="600px"
        title="Personal Details"
        open={editEmployeeModal}
        onCancel={() => setEditEmployeeModal(false)}
        footer={false}
      >
        <EmployeeForm
          fetchData={fetchCurrentEmployeeData}
          onClose={handleCloseEditEmployeeModel}
          isNew={false}
          employeeID={currentEmployeeData.employee_id}
          firstName={currentEmployeeData.first_name}
          lastName={currentEmployeeData.last_name}
          email={currentEmployeeData.email}
          role={currentEmployeeData.role}
          phoneNumber={currentEmployeeData.phone_number}
          dob={currentEmployeeData.dob}
          gender={currentEmployeeData.gender}
          streetAddress={currentEmployeeData.street}
          city={currentEmployeeData.city}
          province={currentEmployeeData.province}
          postalCode={currentEmployeeData.postal_code}
          employeeId={currentEmployeeData.employee_id}
        />
      </Modal>

      <Modal
        destroyOnClose={true}
        width="600px"
        title="User Details"
        open={editModal}
        onCancel={closeEditModalUser}
        footer={false}
      >
        {props.vendorDetails !== false && (
          <EditUserProfile
            userType={"Employee"}
            propertyList={props.vendorDetails.propertyDetails}
            vendorDetails={props.vendorDetails}
            clientDetails={props.clientDetails}
            employeeDetails={props.employeeDetails}
            user={user}
            closeUpdate={closeEditModalUser}
          />
        )}
      </Modal>

      <Modal
        destroyOnClose={true}
        width="600px"
        title="Company Details"
        open={editVendorModal}
        onCancel={() => {
          setEditVendorModal(false);
        }}
        footer={false}
      >
        <EditVendorProfile
          fetchVendorData={props.fetchVendorData}
          vendorDetails={props.vendorDetails}
          clientDetails={props.clientDetails}
          employeeDetails={props.employeeDetails}
          user={props.vendorDetails}
          setEditVendorModal={setEditVendorModal}
          setShowSuccessModal={setShowSuccessModal}
        />
      </Modal>

      <Modal
        destroyOnClose={true}
        width="600px"
        title="Change Password"
        open={changePasswordModal}
        onCancel={closeChangePasswordModal}
        footer={false}
      >
        <ChangePassword user={user} closeModal={closeChangePasswordModal} />
      </Modal>

      <Modal
        destroyOnClose={true}
        width="400px"
        title="Change Profile Picture"
        open={changeImageModal}
        onCancel={closeChangeProfilePic}
        footer={false}
      >
        <ChangeProfilePicture user={user} closeModal={closeChangeProfilePic} />
      </Modal>

      <Modal
        destroyOnClose={true}
        width="700px"
        title="Deactivate Employee"
        open={deactivateEmployeeModal}
        onCancel={() => setDeactivateEmployeeModal(false)}
        footer={false}
      >
        <DeactivateEmployeeModal
          setLastDay={setLastDay}
          lastDay={lastDay}
          deactivationReason={deactivationReason}
          setDeactivationReason={setDeactivationReason}
          employeeList={employeeList}
          confirmDeactivation={confirmDeactivation}
          setDeactivateEmployeeModal={setDeactivateEmployeeModal}
          currentEmployee={currentEmployee}
        />
      </Modal>

      <Modal
        destroyOnClose={true}
        width="700px"
        title="Add Employee"
        open={employeeFormModal}
        onCancel={() => setEmployeeFormModal(false)}
        footer={false}
      >
        <EmployeeForm
          fetchData={fetchEmployeeData}
          onClose={() => setEmployeeFormModal(false)}
          isNew={true}
        />
      </Modal>

      <Modal
        destroyOnClose={true}
        width="700px"
        title="Update Employee"
        open={updateEmployeeModal}
        onCancel={() => setUpdateEmployeeModal(false)}
        footer={false}
      >
        {updateEmployeeModal && (
          <>
            {console.log("employee", employeeList[currentEmployee])}
            <EmployeeForm
              fetchData={fetchEmployeeData}
              onClose={() => setUpdateEmployeeModal(false)}
              isNew={false}
              employeeID={employeeList[currentEmployee].employee_id}
              firstName={employeeList[currentEmployee].first_name}
              lastName={employeeList[currentEmployee].last_name}
              email={employeeList[currentEmployee].email}
              role={employeeList[currentEmployee].role}
              phoneNumber={employeeList[currentEmployee].phone_number}
              dob={employeeList[currentEmployee].dob}
              gender={employeeList[currentEmployee].gender}
              streetAddress={employeeList[currentEmployee].street}
              city={employeeList[currentEmployee].city}
              province={employeeList[currentEmployee].province}
              postalCode={employeeList[currentEmployee].postal_code}
              employeeUserId={employeeList[currentEmployee].employee_user_id}
            />
          </>
        )}
      </Modal>
      {showSuccessModal && (
        <Alert
          style={{
            zIndex: 10000,
            position: "absolute",
            top: "2rem",
            left: "calc(50vw - 8rem)",
            width: "16rem",
          }}
          severity="success"
        >
          Updated successfully.
        </Alert>
      )}
    </>
  );
};

VendorProfile.defaultProps = {
  vendorDetails: {},
  clientDetails: {},
  currentConversation: false,
  employeeDetails: false,
};

VendorProfile.propTypes = {
  vendorDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  currentConversation: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  employeeDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default VendorProfile;
