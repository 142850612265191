import { Button, Grid, Stack, Typography, Checkbox } from "@mui/material";
import { useState } from "react";
import Styles from "./style.module.css";
import { Modal } from "antd";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import TermsAndConditions from "../../PDF/TermsViewer";
import PrivatePolicy from "../../PDF/PrivacyViewer";
import LoadingSpinner from "../../../Layout/components/LoadingSpinner";
import axios from "axios";

const TermsAgreement = ({ onAgree, userData }) => {
  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);

  const [termsIsRead, setTermsIsRead] = useState(false);
  const [policyIsRead, setPolicyIsRead] = useState(false);

  const [hasAgreed, setHasAgreed] = useState(false);

  const [showCheckboxError, setShowCheckboxError] = useState(false);
  const [showContinueError, setShowContinueError] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const closeTermsAndConditions = () => {
    setIsOpenTerms(false);
    setTermsIsRead(true);
  };
  const openTermsAndConditions = () => setIsOpenTerms(true);
  const closePrivatePolicy = () => {
    setIsOpenPolicy(false);
    setPolicyIsRead(true);
  };
  const openPrivatePolicy = () => setIsOpenPolicy(true);

  const handleAgreeToTerms = (e) => {
    if (termsIsRead && policyIsRead) {
      setShowCheckboxError(false);
      setHasAgreed(e.target.checked);
    } else {
      setShowCheckboxError(true);
    }
  };

  const handleContinue = () => {
    if (hasAgreed) {
      setSubmitting(true);
      setShowContinueError(false);
      axios
        .post("/auth/agree", { userId: userData.user.id })
        .then((response) => {
          console.log(response.data.ok);
          if (response.data.ok) {
            onAgree();
          }
          setSubmitting(false);
        });
    } else {
      setShowContinueError(true);
    }
  };

  return (
    <LoadingSpinner isActive={submitting}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
            sx={{ mb: { xs: -0.5, sm: 0.5 } }}
          >
            <Typography variant="h5">
              User must agree to the{" "}
              <span className={Styles.textBtn} onClick={openTermsAndConditions}>
                terms and conditions
              </span>{" "}
              and{" "}
              <span className={Styles.textBtn} onClick={openPrivatePolicy}>
                privacy policy
              </span>{" "}
              before you can log in
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack style={{ marginBottom: "1em" }}>
            <Stack direction={"row"}>
              <Checkbox checked={hasAgreed} onClick={handleAgreeToTerms} />I
              have read and agree to the terms and conditions as well as the
              privacy policy
            </Stack>
            <Typography style={{ color: "red", fontStyle: "italic" }}>
              {showCheckboxError ? "*You must read both before agreeing" : null}
            </Typography>
          </Stack>
          <Stack>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#01552f" }}
                onClick={handleContinue}
              >
                Continue
              </Button>
            </AnimateButton>
            <Typography style={{ color: "red", fontStyle: "italic" }}>
              {showContinueError ? "*You must agree before continuing" : null}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Modal
        title="Terms And Conditions"
        width="700px"
        open={isOpenTerms}
        onCancel={closeTermsAndConditions}
        footer={[
          <Button key="submit" type="primary" onClick={closeTermsAndConditions}>
            Okay
          </Button>,
        ]}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(80vh - 200px)" }}
      >
        {isOpenTerms && (
          <TermsAndConditions
            signIn={true}
            openPolicy={() => {
              closeTermsAndConditions();
              openPrivatePolicy();
            }}
          />
        )}
      </Modal>
      <Modal
        title="Privacy Policy"
        width="700px"
        open={isOpenPolicy}
        onCancel={closePrivatePolicy}
        footer={[
          <Button key="submit" type="primary" onClick={closePrivatePolicy}>
            Okay
          </Button>,
        ]}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(80vh - 200px)" }}
      >
        {isOpenPolicy && <PrivatePolicy signIn={true} />}
      </Modal>
    </LoadingSpinner>
  );
};

export default TermsAgreement;
