import CalendarPage from "../CalendarPage/CalendarPage";

const date_today = new Date();
const day_today = parseInt(date_today.getDate());
const dummyJRs = [
  {
    job_id: 1,
    location: [48.9497381, -57.9421698],
    job_request_time: new Date(2023, 1, day_today - 10, 8, 30),
    job_due: new Date(2023, 1, day_today - 8, 8, 30),
    job_category: "Access & Security",
    job_title: "Access Problem",
    address: "24 Lower Cove Road",
    workStatus: "Requested",
    nextAction: "",
    vendorName: "Corner Brook Security",
    clientName: "Barry Mcintosh",
    job_description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vendorAddress: "12 Rupert Street",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A2H 7J2",
    clientPostalCode: "A9J 6G4",
    job_status: 1,
    is_done: true,
  },
  {
    job_id: 2,
    location: [48.9523316, -57.9460401],
    job_request_time: new Date(2023, 1, day_today - 10, 9, 30),
    job_due: new Date(2023, 1, day_today - 6, 8, 30),
    job_category: "Access & Security",
    job_title: "Access Problem",
    address: "15 Judge Street",
    workStatus: "Scheduled",
    nextAction: "Assess problem",
    vendorName: "Corner Brook Security",
    clientName: "Victor Crowley",
    job_description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vendorAddress: "12 Rupert Street",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A9K 4F7",
    clientPostalCode: "A0L 2D0",
    job_status: 2,
    is_done: true,
  },
  {
    job_id: 3,
    location: [48.9531178, -57.930483],
    job_request_time: new Date(2023, 1, day_today - 9, 1, 10, 30),
    job_due: new Date(2023, 1, day_today - 5, 8, 30),
    job_category: "Appliance Repair",
    job_title: "Broken Microwave",
    address: "55 Caplin Avenue",
    workStatus: "Problem Assessment",
    nextAction: "Fix Microwave",
    vendorName: "Corner Brook Appliance Repair",
    clientName: "Rebecca Smith",
    longDesc:
      "Microwave just doesn't heat up the food anymore. No matter how long i have food in there it stays cold.",
    vendorAddress: "34 Carnation Road",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A7M 3D9",
    clientPostalCode: "A1P 7S3",
    job_status: 4,
    is_done: true,
  },
  {
    job_id: 4,
    location: [48.9343638, -57.9475252],
    job_request_time: new Date(2023, 1, day_today - 9, 8, 30),
    job_due: new Date(2023, 1, day_today - 4, 8, 30),
    job_category: "Brick Layer",
    job_title: "Need a wall built around garden",
    address: "97b Cadbury Drive",
    workStatus: "Problem Solving",
    nextAction: "Complete Work",
    vendorName: "Corner Brook Brick Laying",
    clientName: "John Doe",
    job_description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vendorAddress: "78 Lincoln Avenue",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A0K 2X0",
    clientPostalCode: "A8F 6A3",
    job_status: 3,
    is_done: true,
  },
  {
    job_id: 5,
    location: [48.9114971, -57.9023401],
    job_request_time: new Date(2023, 1, day_today - 8, 10, 30),
    job_due: new Date(2023, 1, day_today - 3, 8, 30),
    job_category: "Building Supplies",
    job_title: "Need some 2x4 to build a shed",
    address: "254 Junction Crescent",
    workStatus: "Completed",
    nextAction: "",
    vendorName: "Corner Brook Building Supplies",
    clientName: "Jane Doe",
    job_description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vendorAddress: "43 Gardener Street",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A6K 1L1",
    clientPostalCode: "A8V 4B2",
    job_status: 2,
    is_done: true,
  },
  {
    job_id: 6,
    location: [48.904323, -57.9047251],
    job_request_time: new Date(2023, 1, day_today - 8, 13, 30),
    job_due: new Date(2023, 1, day_today - 2, 8, 30),
    job_category: "Building Supplies",
    job_title: "Need some screws",
    address: "84 Circular Road",
    workStatus: "Requested",
    nextAction: "",
    vendorName: "Corner Brook Building Supplies",
    clientName: "Jacob Demmer",
    job_description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vendorAddress: "43 Gardener Street",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A6K 1L1",
    clientPostalCode: "A8V 4B2",
    job_status: 4,
    is_done: true,
  },
  {
    job_id: 7,
    location: [48.9552822, -58.0052082],
    job_request_time: new Date(2023, 1, day_today - 7, 15, 30),
    job_due: new Date(2023, 1, day_today - 1, 8, 30),
    job_category: "Cabling & Data",
    job_title: "Replace cabling",
    address: "9 Cooks Street",
    workStatus: "Scheduled",
    nextAction: "Assess what cabling needs to be replaced",
    vendorName: "Corner Brook Cabling Centre",
    clientName: "Brayden Burden",
    job_description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vendorAddress: "21 Lumbridge Road",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A3D 2I6",
    clientPostalCode: "A1Q 3D3",
    job_status: 1,
    is_done: false,
  },
  {
    job_id: 8,
    location: [48.9636794, -57.9163097],
    job_request_time: new Date(2023, 1, day_today - 7, 14, 30),
    job_due: new Date(2023, 1, day_today, 8, 30),
    job_category: "& Data",
    job_title: "Data storage",
    address: "76 Lockes Road",
    workStatus: "Problem Solving",
    nextAction: "Complete Work",
    vendorName: "Corner Brook Cabling Centre",
    clientName: "Landon Ryan",
    job_description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vendorAddress: "21 Lumbridge Road",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A3D 2I6",
    clientPostalCode: "A1Q 3D3",
    job_status: 1,
    is_done: false,
  },
  {
    job_id: 9,
    location: [48.9497381, -57.9421698],
    job_request_time: new Date(2023, 1, day_today - 1, 8, 30),
    job_due: new Date(2023, 1, day_today + 1, 8, 30),
    job_category: "Access & Security",
    job_title: "Access Problem",
    address: "24 Lower Cove Road",
    workStatus: "Requested",
    nextAction: "",
    vendorName: "Corner Brook Security",
    clientName: "Barry Mcintosh",
    job_description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vendorAddress: "12 Rupert Street",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A2H 7J2",
    clientPostalCode: "A9J 6G4",
    job_status: 1,
    is_done: true,
  },
  {
    job_id: 10,
    location: [48.9523316, -57.9460401],
    job_request_time: new Date(2023, 1, day_today - 6, 9, 30),
    job_due: new Date(2023, 1, day_today, 8, 30),
    job_category: "Access & Security",
    job_title: "Access Problem",
    address: "15 Judge Street",
    workStatus: "Scheduled",
    nextAction: "Assess problem",
    vendorName: "Corner Brook Security",
    clientName: "Victor Crowley",
    job_description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vendorAddress: "12 Rupert Street",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A9K 4F7",
    clientPostalCode: "A0L 2D0",
    job_status: 2,
    is_done: false,
  },
  {
    job_id: 13,
    location: [48.9531178, -57.930483],
    job_request_time: new Date(2023, 1, day_today - 6, 10, 30),
    job_due: new Date(2023, 1, day_today + 3, 8, 30),
    job_category: "Appliance Repair",
    job_title: "Broken Microwave",
    address: "55 Caplin Avenue",
    workStatus: "Problem Assessment",
    nextAction: "Fix Microwave",
    vendorName: "Corner Brook Appliance Repair",
    clientName: "Rebecca Smith",
    job_description:
      "Microwave just doesn't heat up the food anymore. No matter how long i have food in there it stays cold.",
    vendorAddress: "34 Carnation Road",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A7M 3D9",
    clientPostalCode: "A1P 7S3",
    job_status: 4,
    is_done: false,
  },
  {
    job_id: 14,
    location: [48.9343638, -57.9475252],
    job_request_time: new Date(2023, 1, day_today - 5, 8, 30),
    job_due: new Date(2023, 1, day_today + 4, 8, 30),
    job_category: "Brick Layer",
    job_title: "Need a wall built around garden",
    address: "97b Cadbury Drive",
    workStatus: "Problem Solving",
    nextAction: "Complete Work",
    vendorName: "Corner Brook Brick Laying",
    clientName: "John Doe",
    job_description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vendorAddress: "78 Lincoln Avenue",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A0K 2X0",
    clientPostalCode: "A8F 6A3",
    job_status: 3,
    is_done: true,
  },
  {
    job_id: 15,
    location: [48.9114971, -57.9023401],
    job_request_time: new Date(2023, 1, day_today - 5, 10, 30),
    job_due: new Date(2023, 1, day_today + 5, 8, 30),
    job_category: "Building Supplies",
    job_title: "Need some 2x4 to build a shed",
    address: "254 Junction Crescent",
    workStatus: "Completed",
    nextAction: "",
    vendorName: "Corner Brook Building Supplies",
    clientName: "Jane Doe",
    job_description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vendorAddress: "43 Gardener Street",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A6K 1L1",
    clientPostalCode: "A8V 4B2",
    job_status: 2,
    is_done: false,
  },
  {
    job_id: 16,
    location: [48.904323, -57.9047251],
    job_request_time: new Date(2023, 1, day_today - 4, 13, 30),
    job_due: new Date(2023, 1, day_today + 6, 8, 30),
    job_category: "Building Supplies",
    job_title: "Need some screws",
    address: "84 Circular Road",
    workStatus: "Requested",
    nextAction: "",
    vendorName: "Corner Brook Building Supplies",
    clientName: "Jacob Demmer",
    job_description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vendorAddress: "43 Gardener Street",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A6K 1L1",
    clientPostalCode: "A8V 4B2",
    job_status: 4,
    is_done: false,
  },
  {
    job_id: 17,
    location: [48.9603922, -58.0083882],
    job_request_time: new Date(2023, 1, day_today - 4, 15, 30),
    job_due: new Date(2023, 1, day_today + 8, 8, 30),
    job_category: "Cabling & Data",
    job_title: "Replace cabling",
    address: "9 Cooks Street",
    workStatus: "Scheduled",
    nextAction: "Assess what cabling needs to be replaced",
    vendorName: "Corner Brook Cabling Centre",
    clientName: "Brayden Burden",
    job_description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vendorAddress: "21 Lumbridge Road",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A3D 2I6",
    clientPostalCode: "A1Q 3D3",
    job_status: 1,
    is_done: false,
  },
  {
    job_id: 18,
    location: [48.9636794, -57.9163097],
    job_request_time: new Date(2023, 1, day_today - 3, 14, 30),
    job_due: new Date(2023, 1, day_today + 10, 8, 30),
    job_category: "Cabling & Data",
    job_title: "Data storage",
    address: "76 Lockes Road",
    workStatus: "Problem Solving",
    nextAction: "Complete Work",
    vendorName: "Corner Brook Cabling Centre",
    clientName: "Landon Ryan",
    job_description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vendorAddress: "21 Lumbridge Road",
    city: "Corner Brook",
    province: "Newfoundland",
    vendorPostalCode: "A3D 2I6",
    clientPostalCode: "A1Q 3D3",
    job_status: 1,
    is_done: false,
  },
];

const CalendarOrTaskManager = () => {
  return <CalendarPage JRs={dummyJRs} />;
};

export default CalendarOrTaskManager;
