import { useMediaQuery, Button } from "@mui/material";
import "./propertyTable.css";
import { MinusOutlined } from "@ant-design/icons";

const DelegateList = (props) => {
  const mobileView = useMediaQuery("(max-width: 600px)");
  const getTableRows = (property) => {
    let rowList = [];

    !mobileView
      ? rowList.push(
          <tr className="onHover" key={`property_${property.property_id}`}>
            <td style={{ margin: "0", padding: "1rem", whiteSpace: "nowrap" }}>
              <span style={{ display: "block" }}>
                {property.delegate_to_name}
              </span>
              <span style={{ display: "block" }}>
                {property.delegate_to_email}
              </span>
            </td>

            <td style={{ margin: "0", padding: "1rem" }}>
              {property.delegated_property}
            </td>
            <td style={{ margin: "0", padding: "1rem" }}>
              ${property.spending_limit}
            </td>
            <td style={{ margin: "0", padding: "1rem" }}>
              {property.is_active ? (
                <td className="propertyActions">
                  <Button
                    size="small"
                    variant="contained"
                    className="deactivateDelegation"
                    onClick={(e) => {
                      props.onDeactivate();
                      e.stopPropagation();
                    }}
                  >
                    <MinusOutlined />{" "}
                  </Button>
                </td>
              ) : (
                ""
              )}
            </td>
          </tr>
        )
      : rowList.push(
          <tr className="onHover" key={`property_${property.property_id}`}>
            <td style={{ margin: "0", padding: "1rem", whiteSpace: "nowrap" }}>
              <span style={{ display: "block" }}>
                {property.delegate_to_name}
              </span>
              <span style={{ display: "block" }}>
                {property.delegate_to_email}
              </span>
            </td>

            <td style={{ margin: "0", padding: "1rem" }}>
              {property.delegated_property}
            </td>
            <td style={{ margin: "0", padding: "1rem" }}>
              ${property.spending_limit}
            </td>
            <td style={{ margin: "0", padding: "1rem" }}>
              {property.is_active ? (
                <td className="propertyActions">
                  <Button
                    size="small"
                    variant="contained"
                    className="deactivateDelegation"
                    onClick={(e) => {
                      props.onDeactivate();
                      e.stopPropagation();
                    }}
                  >
                    <MinusOutlined />{" "}
                  </Button>
                </td>
              ) : (
                ""
              )}
            </td>
          </tr>
        );

    return rowList;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%"
        }}
      >
        <table className="propertyTable">
          <tbody className="propertyTbody">
            {getTableRows(props.delegateProperty)}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DelegateList;
