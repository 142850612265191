import { VendorTile } from "./VendorTile";
import styles from "../../../MarketPage/MarketComponents/JobDisplay.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Card, InputLabel, Stack, Switch, useMediaQuery } from "@mui/material";

const VendorJR = (props) => {
  const [notifications, setNotifications] = useState([]);

  const getNotifications = () => {
    axios.get("/notifications/getJobs").then(({ data }) => {
      setNotifications(data.notifications);
    });
  };

  useEffect(() => {
    getNotifications();
    const notificationTimer = setInterval(getNotifications, 60000);
    return clearInterval(notificationTimer);
  }, []);

  const handleSwitchChange = () => {
    if (props.filterValue === "1") {
      props.setFilterValue("0");
    } else {
      props.setFilterValue("1");
    }
  };

  const mobileView = useMediaQuery("(max-width: 600px)");

  return (
    <div className={styles.jobDisplay}>
      {/* <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
        <InputLabel style={{ cursor: "pointer" }} htmlFor="directlyAssigned">
          Directly Assigned
        </InputLabel>
        <Switch
          id="directlyAssigned"
          color="primary"
          onChange={() => {
            handleSwitchChange();
            // console.log(typeof props.setMapFocus);
          }}
        />
      </Stack> */}

      <div
        className={styles.jobDisplayList}
        style={{ height: mobileView ? "65%" : "65%" }}
      >
        <div>
          <div className={styles.employeeList}>
            {props.currentJRs.length > 0 ? (
              props.currentJRs?.map((jrData, i) => {
                return (
                  <span key={`${jrData.job_id}`}>
                    <VendorTile
                      chargePercentages={props.chargePercentages}
                      setMapFocus={props.setMapFocus}
                      fetchJrData={props.fetchJrData}
                      jrDetails={props.acceptedJrs}
                      jrData={jrData}
                      showSubmitForm={props.showSubmitForm}
                      setShowSubmitForm={props.setShowSubmitForm}
                      openJR={props.openJR}
                      setOpenJR={props.setOpenJR}
                      notification={notifications.includes(jrData.job_id)}
                      setAgreementOpen={props.setAgreementOpen}
                    />
                  </span>
                );
              })
            ) : (
              <h2>
                <em>You have no any job request done.</em>
              </h2>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorJR;
