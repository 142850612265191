import { VendorTile } from "../VendorPhases/VendorTile";
import styles from "./Employees.module.css";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useMediaQuery } from "@mui/material";

const EmployeeJR = (props) => {
  const [currentJRs, setCurrentJRs] = useState(props.currentJRs);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    setCurrentJRs(props.currentJRs);
  }, [props.currentJRs]);

  const getNotifications = useCallback(() => {
    axios.get("/notifications/getJobs").then(({ data }) => {
      setNotifications(data.notifications);
    });
  }, []);

  useEffect(() => {
    getNotifications();
    const notificationTimer = setInterval(getNotifications, 60000);
    return clearInterval(notificationTimer);
  }, [getNotifications]);

  const mobileView = useMediaQuery("(max-width: 600px)");

  return (
    <div
      className={styles.jobDisplayList}
      style={{ height: mobileView ? "70%" : "67%", overflowY: "scroll" }}
    >
      <div>
        <div className={styles.employeeList}>
          {currentJRs.length > 0 ? (
            currentJRs?.map((jrData) => {
              return (
                <span key={`${jrData.job_id}`}>
                  <VendorTile
                    chargePercentages={props.chargePercentages}
                    fetchJrData={props.fetchJrData}
                    jrDetails={props.acceptedJrs}
                    jrData={jrData}
                    showSubmitForm={props.showSubmitForm}
                    setShowSubmitForm={props.setShowSubmitForm}
                    openJR={props.openJR}
                    setOpenJR={props.setOpenJR}
                    notification={notifications.includes(jrData.job_id)}
                  />
                </span>
              );
            })
          ) : (
            <h2>
              <em>You have no JRs</em>
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeJR;
