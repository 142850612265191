import { useState, useEffect, useRef } from "react";
import styles from "./TopBar.module.css";
import MultiRangeSlider from "multi-range-slider-react";
import "./slider.css";
import { getMonth, jrCatagories } from "../../commonComponents/util";
import { useUserState } from "../../../Context/UserContext";
import {
  Tabs,
  TextField,
  Tab,
  Box,
  Grid,
  Typography,
  Button,
  MenuItem,
  Select,
  Stack,
  useMediaQuery,
  Popover
} from "@mui/material";

import ListIcon from "@mui/icons-material/List";
import axios from "axios";

import { useJRContext } from "../../../Context/JobRequestContext";
import { GlobalOutlined } from "@ant-design/icons";

const todaysDate = new Date();
todaysDate.setHours(0);
todaysDate.setMinutes(0);

const EmployeeDropdown = (props) => {
  const [open, setOpen] = useState(false);
  const [allChecked, setAllChecked] = useState(true);
  const toggleAll = (checked) => {
    const ckBoxes = document.querySelectorAll(".dropdownCkBox");
    for (let ckBox of ckBoxes) {
      ckBox.checked = checked;
    }
    let newList = props.mapEmployees;
    for (let i in newList) {
      newList[i].visible = checked;
    }
    props.setMapEmployees(newList);
    props.refreshMap();
  };
  const toggleEmployees = (id, isVisible) => {
    let newList = props.mapEmployees;
    newList[id].visible = isVisible;
    props.setMapEmployees(newList);
    props.refreshMap();
  };
  const employees = props.mapEmployees;

  const createList = () => {
    return employees.map((employee, index) => {
      const profile = employee.profile_pic.src;
      const id = `emp_${employee.employee_id}_ckBox`;
      const name = `${employee.first_name} ${employee.last_name}`;

      return (
        <MenuItem
          value=""
          key={id}
          style={{ height: "3rem", width: "100%" }}
          onClick={(e) => e.stopPropagation()}
        >
          <input
            type="checkbox"
            className={`${styles.dropdownCkBox} dropdownCkBox`}
            name={id}
            id={id}
            defaultChecked={employee.visible}
            onChange={(e) => {
              e.stopPropagation();
              const ckBoxes = document.querySelectorAll(".dropdownCkBox");
              let allAreChecked = true;
              for (let ckBox of ckBoxes) {
                if (!ckBox.checked) {
                  allAreChecked = false;
                  break;
                }
              }
              setAllChecked(allAreChecked);
              toggleEmployees(index, e.target.checked);
            }}
          />
          <label htmlFor={id} style={{ width: "100%" }}>
            <img src={profile} alt="" style={{ width: "2rem" }} />
            &nbsp;{name}
          </label>
        </MenuItem>
      );
    });
  };

  const mobileView = useMediaQuery("(max-width: 600px)");
  return (
    <Select
      size="small"
      labelId="employees-select-label"
      id="employees-select"
      open={open}
      style={{
        fontSize: "0.75rem",
        backgroundColor: "#01552f",
        color: "white",
        width: mobileView ? "6rem" : "fit-content",
        height: "2rem"
      }}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      onChange={(e) => {
        e.stopPropagation();
      }}
      value={"Employees"}
    >
      <MenuItem value="Employees" hidden>
        Employees
      </MenuItem>
      <MenuItem value="" onClick={(e) => e.stopPropagation()}>
        <input
          type="checkbox"
          className={styles.dropdownCkBox}
          name="All"
          id="AllEmployeesCheckbox"
          onChange={(e) => {
            e.stopPropagation();
            const newAllChecked = !allChecked;
            setAllChecked(newAllChecked);
            toggleAll(newAllChecked);
          }}
          checked={allChecked}
        />
        <label style={{ width: "100%" }} htmlFor="AllEmployeesCheckbox">
          &nbsp;All
        </label>
        <div className={styles.brDiv}></div>
      </MenuItem>
      {createList()}
    </Select>
  );
};

const TopBar = (props) => {
  const { user } = useUserState();
  const { setOpenJR } = useJRContext();
  const [filterCategories, setFilterCategories] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [radius, setRadius] = useState(user.workingRadius);
  const [showRadiusBlock, setShowRadiusBlock] = useState(false);
  const [initialRadius, setInitialRadius] = useState(radius);
  const [sliderStart, setSliderStart] = useState(
    Math.round(Math.abs(props.startDate - todaysDate) / (1000 * 60 * 60 * 24))
  );
  const [currentTab, setCurrentTab] = useState(0);
  const [sliderEnd, setSliderEnd] = useState(
    Math.round(Math.abs(props.endDate - todaysDate) / (1000 * 60 * 60 * 24))
  );

  const sliderRef = useRef();

  const { setEndDate, setStartDate } = props;
  useEffect(() => {
    if (
      typeof sliderRef.current === "undefined" ||
      sliderRef.current === null
    ) {
      return;
    }
    sliderRef.current.oncontextmenu = (e) => {
      e.preventDefault();
      return false;
    };
  }, []);

  useEffect(() => {
    let date = new Date();
    date.setDate(todaysDate.getDate() + sliderStart);
    date.setHours(0);
    date.setMinutes(0);
    setStartDate(date);
  }, [sliderStart, setStartDate]);

  useEffect(() => {
    let date = new Date();
    date.setDate(todaysDate.getDate() + sliderEnd);
    date.setHours(23);
    date.setMinutes(59);
    setEndDate(date);
  }, [sliderEnd, setEndDate]);

  // get filter catagories
  useEffect(() => {
    setFilterCategories(populateFilterList(jrCatagories));
  }, []);

  const populateFilterList = (filterList) => {
    const options = [];
    for (let option in filterList) {
      options.push(
        <MenuItem key={option} value={filterList[option]}>
          {filterList[option]}
        </MenuItem>
      );
    }
    return options;
  };

  const handleRadiusSubmit = (event) => {
    event.preventDefault();
    axios
      .post("/jobRequest/updateWorkingRadius", { Radius: radius })
      .then((res) => {
        // console.log(res.data);
        if (res.data.ok) window.location.reload();
      });
  };

  const mobileView = useMediaQuery("(max-width: 600px)");
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    event.preventDefault();
    if (newValue === 0) {
      props.setCurrentJRs([]);
      props.setIsMarket(true);
      setOpenJR(null);
    } else {
      props.setCurrentJRs([]);
      props.setIsMarket(false);
      setOpenJR(null);
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowRadiusBlock(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowRadiusBlock(false);
  };

  return (
    <div
      className={styles.topBar}
      style={{
        marginBottom:
          mobileView &&
          (props.isMarket === true && props.mapOpen === false
            ? "0rem"
            : "4.25rem"),
        alignContent: "center"
      }}
    >
      {user.vendorMode && user.empRole === ("Admin" || "Manager") && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "fixed",

            top: mobileView ? 15 : 50,
            left: mobileView ? 5 : 20,
            width: "100%",
            zIndex: 100,
            marginTop: 5,
            marginBottom: 5
          }}
        >
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab className={styles.tabElement} label="Job Requests" value={0} />

            <Tab className={styles.tabElement} label="Active Jobs" value={1} />

            {mobileView ? (
              <Grid container justifyContent="flex-end">
                <Tab
                  className={styles.tabElement}
                  icon={
                    props.isMobile && props.mapOpen ? (
                      <ListIcon className={styles.icon} />
                    ) : (
                      <GlobalOutlined className={styles.icon} />
                    )
                  }
                  onClick={
                    props.isMobile && props.mapOpen ? props.toJR : props.toMap
                  }
                />
              </Grid>
            ) : (
              ""
            )}
          </Tabs>
          {!mobileView ? (
            <>
              <Typography>Working radius(in Kms)</Typography>

              <input
                type="number"
                value={radius}
                onChange={(e) => setRadius(e.target.value)}
                onKeyDown={(e) => {
                  // Prevent the user from typing a period (.)
                  if (e.key === ".") {
                    e.preventDefault();
                  }
                }}
                style={{
                  width: "80px",
                  height: "30px ",
                  marginLeft: "5px",
                  lineHeight: "30px", // Match the height of the input box
                  textAlign: "center" // Optionally, center the text horizontally
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ width: "80px", height: "30px", marginLeft: "10px" }}
                onClick={(e) => handleRadiusSubmit(e)}
                disabled={radius === initialRadius}
              >
                Update
              </Button>
            </>
          ) : (
            <>
              <Typography onClick={handleClick}>Radius(in Kms)</Typography>
              {showRadiusBlock && (
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                >
                  <Box p={2}>
                    <input
                      type="number"
                      value={radius}
                      onChange={(e) => setRadius(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === ".") {
                          e.preventDefault();
                        }
                      }}
                      style={{
                        width: "80px",
                        height: "30px",
                        marginLeft: "5px",
                        lineHeight: "30px",
                        textAlign: "center"
                      }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{
                        width: "80px",
                        height: "30px",
                        marginLeft: "10px"
                      }}
                      onClick={(e) => handleRadiusSubmit(e)}
                      disabled={radius === initialRadius}
                    >
                      Update
                    </Button>
                  </Box>
                </Popover>
              )}
            </>
          )}
        </Box>
      )}

      {!props.isMarket && !mobileView && (
        <Stack
          direction="row"
          alignItems="center"
          className={styles.dateSlider}
        >
          <Typography
            backgroundColor="#01552f"
            color="#FFFFFF"
            height="min-content"
            padding="2px 5px"
            borderRadius="15px"
            fontSize={mobileView ? "0.9rem !important" : "1rem"}
            textAlign={"center"}
          >
            {getMonth(props.startDate.getMonth())} {props.startDate.getDate()}
          </Typography>
          <div style={{ margin: "0 5px" }}>
            <MultiRangeSlider
              min="-10"
              max="31"
              step="1"
              minValue={sliderStart}
              maxValue={sliderEnd}
              onInput={(e) => {
                setSliderStart(e.minValue);
                setSliderEnd(e.maxValue);
              }}
              ref={sliderRef}
              style={{ width: "5rem" }}
            />
          </div>
          <Typography
            backgroundColor="#01552f"
            color="#FFFFFF"
            height="min-content"
            padding="2px 5px"
            borderRadius="15px"
            fontSize={mobileView ? "0.9rem  !important" : "1rem"}
            textAlign={"center"}
          >
            {getMonth(props.endDate.getMonth())} {props.endDate.getDate()}
          </Typography>
        </Stack>
      )}
      {/* {!props.isMarket && props.mapOpen && (
        <>
          {user.empRole === ("Admin" || "Manager") && (
            <EmployeeDropdown
              mapEmployees={props.mapEmployees}
              setMapEmployees={props.setMapEmployees}
              refreshMap={props.refreshMap}
            />
          )}
        </>
      )} */}
      {/* {props.isMobile && !props.mapOpen && (
        <Button
          color="primary"
          variant="contained"
          onClick={props.toMap}
          style={{
            maxWidth: "2rem",
            minWidth: "2rem",
            maxHeight: "2rem",
            minHeight: "2rem"
          }}
        >
          {mobileView ? <CompassOutlined style={{ color: "white" }} /> : "Map"}
        </Button>
      )} */}
    </div>
  );
};

export default TopBar;
