import { Grid, Button, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useUserState } from "../Context/UserContext";

// Utility function to generate a list of dates from a given start date
const generateDates = (startDate, numDays) => {
  const dates = [];
  const today = new Date(startDate);

  for (let i = 0; i < numDays; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
    });
    dates.push(formattedDate);
  }

  return dates;
};

const AvailabilitySelector = (props) => {
  const { user } = useUserState();
  console.log("START OF TEST");
  console.log(user, "user");
  console.log(user.is_employee, "is_employee");
  console.log(typeof props.jrData, "typeof jrData");
  console.log(
    props.availabilityArray !== false,
    "availability array !== false"
  );
  console.log(props.availabilityArray === null, "availability array === null");
  console.log("END OF TEST");

  console.log(
    !user.vendorMode
      ? typeof props.jrData !== "undefined"
        ? JSON.parse(props.jrData.client_availability) === null
          ? "[]"
          : "JSON.parse(props.jrData.client_availability)"
        : props.availabilityArray !== false
        ? "props.availabilityArray1"
        : "[]"
      : props.availabilityArray === null
      ? "[]"
      : "props.availabilityArray2"
  );
  const [availabilityList, setAvailabilityList] = useState(
    !user.vendorMode
      ? typeof props.jrData !== "undefined"
        ? JSON.parse(props.jrData.client_availability) === null
          ? []
          : JSON.parse(props.jrData.client_availability)
        : props.availabilityArray !== false
        ? props.availabilityArray
        : []
      : props.availabilityArray === null
      ? []
      : props.availabilityArray
  );
  const [employeeAvailability, setEmployeeAvailability] = useState(
    props.overlap ? props.employeeAvailability : []
  );

  const [selectedTime, setSelectedTime] = useState([]);
  const [isClickable, setIsClickable] = useState(props.editable ? true : false);
  const [dates, setDates] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const daysPerWeek = 7;

  useEffect(() => {
    const startDate = new Date();
    const initialDates = generateDates(startDate, 30);
    setDates(initialDates);
  }, []);

  const addAvailability = (date, time) => {
    const block = `${date} ${time}`;

    if (props.overlap) {
      if (
        availabilityList?.includes(block) &&
        employeeAvailability?.includes(block)
      ) {
        // Toggle block in selectedTime state
        if (!selectedTime?.includes(block) && selectedTime.length === 0) {
          setSelectedTime((prevList) => [...prevList, block]);
        } else {
          setSelectedTime((prevList) =>
            prevList.filter((item) => item !== block)
          );
        }
      }
    } else {
      if (!availabilityList?.includes(block)) {
        // Add the block if it's not already in the list
        if (isClickable) {
          setAvailabilityList((prevList) => [...prevList, block]);
        } else {
          console.log("Unclickable");
        }
      } else {
        if (isClickable) {
          setAvailabilityList((prevList) =>
            prevList.filter((item) => item !== block)
          );
        } else {
          console.log("Unclickable");
        }
      }
    }
  };

  const handleNextWeek = () => {
    if (startIndex + daysPerWeek >= dates.length) {
      // Generate more dates if we are reaching the end
      const newStartDate = new Date(dates[dates.length - 1]);
      newStartDate.setDate(newStartDate.getDate() + 1);
      const moreDates = generateDates(newStartDate, 30);
      setDates((prevDates) => [...prevDates, ...moreDates]);
    }
    setStartIndex((prevIndex) => prevIndex + daysPerWeek);
  };

  const handlePreviousWeek = () => {
    setStartIndex((prevIndex) => Math.max(prevIndex - daysPerWeek, 0));
  };

  const displayedDates = dates.slice(startIndex, startIndex + daysPerWeek);

  return (
    <>
      <Grid container>
        <Grid container item xs={1.5} direction="column">
          <Grid
            item
            style={{
              minWidth: "100%",
              maxWidth: "100%",
              border: "1px solid black",
              minHeight: "5rem",
              maxHeight: "5rem",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Availability
            </p>
          </Grid>
          {["Morning", "Afternoon", "Evening"].map((time, index) => (
            <Tooltip
              enterTouchDelay={0}
              title={`${time}\n${
                time === "Morning"
                  ? "(8am-12pm)"
                  : time === "Afternoon"
                  ? "(12pm-5pm)"
                  : "(5pm-10pm)"
              }`}
              arrow
            >
              <Grid
                item
                key={time}
                style={{
                  minWidth: "100%",
                  maxWidth: "100%",
                  border: "1px solid black",
                  minHeight: "5rem",
                  maxHeight: "5rem",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "default",
                }}
              >
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {time}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {time === "Morning"
                      ? "(8am-12pm)"
                      : time === "Afternoon"
                      ? "(12pm-5pm)"
                      : "(5pm-10pm)"}
                  </p>
                </div>
              </Grid>
            </Tooltip>
          ))}
        </Grid>
        {displayedDates.map((date, dateIndex) => (
          <Grid container item xs={1.5} direction="column" key={date}>
            <Grid
              item
              style={{
                border: "1px solid black",
                minHeight: "5rem",
                maxHeight: "5rem",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ margin: 0 }}>{date}</p>
            </Grid>
            {["Morning", "Afternoon", "Evening"].map((time) => (
              <Grid
                onClick={() => addAvailability(date, time)}
                item
                key={`${date}-${time}`}
                style={{
                  border: "1px solid black",
                  minHeight: "5rem",
                  maxHeight: "5rem",
                  cursor:
                    isClickable ||
                    (availabilityList?.includes(`${date} ${time}`) &&
                      employeeAvailability?.includes(`${date} ${time}`) &&
                      selectedTime.length === 0)
                      ? "pointer"
                      : "default",
                  backgroundColor: selectedTime?.includes(`${date} ${time}`)
                    ? "#ffa500" // Color for selected time
                    : availabilityList?.includes(`${date} ${time}`) &&
                      employeeAvailability?.includes(`${date} ${time}`)
                    ? "#B0E1B0" // Color when both are available
                    : availabilityList?.includes(`${date} ${time}`)
                    ? "#ffe561" // Color when only availabilityList includes the block
                    : employeeAvailability?.includes(`${date} ${time}`)
                    ? "#61ddff" // Color when only employeeAvailability includes the block
                    : "#ebebeb", // Default color
                }}
              ></Grid>
            ))}
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "1rem" }}>
        <Grid item xs={4}>
          <Button onClick={handlePreviousWeek} disabled={startIndex === 0}>
            Previous Week
          </Button>
        </Grid>
        {!props.overlap ? (
          !props.viewing ? (
            !props.editable ? (
              !isClickable ? (
                <Grid item xs={4} textAlign={"center"}>
                  <Button
                    variant="contained"
                    onClick={() => setIsClickable(true)}
                    disabled={isClickable}
                  >
                    Edit
                  </Button>
                </Grid>
              ) : (
                <>
                  <Grid item xs={2} textAlign={"center"}>
                    <Button onClick={() => setIsClickable(false)}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={2} textAlign={"center"}>
                    <Button onClick={() => props.onSubmit(availabilityList)}>
                      Submit
                    </Button>
                  </Grid>
                </>
              )
            ) : (
              <Grid item xs={4} textAlign={"center"}>
                <Button onClick={() => props.onSubmit(availabilityList)}>
                  Submit
                </Button>
              </Grid>
            )
          ) : (
            <Grid item xs={2} textAlign={"center"}></Grid>
          )
        ) : (
          <Grid item xs={4} textAlign={"center"}>
            <Button onClick={() => props.onSubmit(selectedTime)}>Submit</Button>
          </Grid>
        )}

        <Grid item xs={4} style={{ textAlign: "right" }}>
          <Button onClick={handleNextWeek}>Next Week</Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AvailabilitySelector;
