import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  RadioGroup,
  Radio,
  Typography,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import {
  strengthColor,
  strengthIndicator,
} from "../../../Layout/utils/password-strength";

import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import axios from "axios";
import LoadingSpinner from "../../../Layout/components/LoadingSpinner";

const AuthRegister = () => {
  let navigate = useNavigate();
  const [level, setLevel] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [spinnerActive, setSpinnerActive] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  const onSubmit = (values) => {
    setSpinnerActive(true);
    const { email, password } = values;
    axios.post("/auth/userExists", { email: email }).then((response) => {
      console.log(response);
      setSpinnerActive(false);
      const userExists = response.data.exists;
      try {
        if (userExists) {
          setErrorMessage("User with this email already exists");
          return navigate("/signup");
        } else {
          return navigate("/signup/clientDetails", {
            state: {
              email: email,
              password: password,
              isClient: 1,
            },
          });
        }
      } catch (err) {
        console.error(err);
      }
    });
  };

  return (
    <LoadingSpinner isActive={spinnerActive}>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-signup">Email Address*</InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="email-login"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="email"
                    inputProps={{}}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText error id="helper-text-email-signup">
                      {errors.email}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password-signup">Password</InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="password-signup"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      changePassword(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? (
                            <EyeOutlined />
                          ) : (
                            <EyeInvisibleOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="******"
                    inputProps={{}}
                  />
                  {touched.password && errors.password && (
                    <FormHelperText error id="helper-text-password-signup">
                      {errors.password}
                    </FormHelperText>
                  )}
                </Stack>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Box
                        sx={{
                          bgcolor: level?.color,
                          width: 85,
                          height: 8,
                          borderRadius: "7px",
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" fontSize="0.75rem">
                        {level?.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "#01552f" }}
                  >
                    Sign Up
                  </Button>
                </AnimateButton>
                <FormHelperText error id="helper-text-firstname-signup">
                  {errorMessage}
                </FormHelperText>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </LoadingSpinner>
  );
};

export default AuthRegister;
