// material-ui
import {
  Box,
  Button,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project import
import Profile from "./Profile";
import MobileSection from "./MobileSection";
import "./styles.css";

import "../../../../Pages/commonComponents/CSS/styles.css";

import { useEffect, useState } from "react";
import { Modal, message } from "antd";
import FeedbackForm from "../../../../Pages/commonComponents/FeedbackForm";
import { useUserDispatch, useUserState } from "../../../../Context/UserContext";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import AnimateButton from "../../../components/@extended/AnimateButton";

import TermsAndConditions from "../../../../Pages/PDF/TermsViewer";
import PrivacyPolicy from "../../../../Pages/PDF/PrivacyViewer";
// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery("(max-width: 900px)");
  const [feedbackModal, setFeedbackModal] = useState(false);
  const dispatch = useUserDispatch();
  const { user } = useUserState();
  const [vendorDescisionModal, setVendorDescisionModal] = useState(false);
  const [companyIntake, setCompanyIntake] = useState(false);
  const [values, setValues] = useState();
  const [showNextPage, setShowNextPage] = useState(false);

  const navigate = useNavigate();
  let location = useLocation();
  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);
  const [activeCreateAccount, setActiveCreateAccount] = useState(false);
  const [termsIsRead, setTermsIsRead] = useState(false);
  const [policyIsRead, setPolicyIsRead] = useState(false);
  const [termsReadError, setTermsReadError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);
  const [clientID, setClientID] = useState();
  const [locationNotFound, setLocationNotFound] = useState(false);
  const [companyEmail, setCompanyEmail] = useState();
  const [companyLegalName, setCompanyLegalName] = useState();
  const [companyDbaName, setCompanyDbaName] = useState();
  const [businessNumber, setBusinessNumber] = useState();
  const [taxId, setTaxId] = useState();
  const [selectedServices, setSelectedServices] = useState([]);
  const [institutionCode, setInstitutionCode] = useState("");
  const [transitCode, setTransitCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const openTermsAndConditions = () => {
    setIsOpenTerms(true);
    setTermsIsRead(true);
  };
  const closeTermsAndConditions = () => {
    setIsOpenTerms(false);
  };

  const openPrivatePolicy = () => {
    setPolicyIsRead(true);
    setIsOpenPolicy(true);
  };
  const closePrivatePolicy = () => {
    setIsOpenPolicy(false);
  };

  useEffect(() => {
    if (!user.vendorMode && window.location.href.includes("bidding")) {
      navigate("/dashboard/clientjobrequest");
    }
  }, [navigate, user.vendorMode]);

  const handleVendorMode = () => {
    console.log(user);
    if (user.is_vendor === 1) {
      dispatch({ state: user, type: "swapMode" });

      navigate(
        !user.vendorMode
          ? user.empRole === "Admin" || user.empRole === "Manager"
            ? "/dashboard/vendorMode/bidding"
            : "/dashboard/employeejobrequest"
          : "/dashboard/clientjobrequest"
      );
      // if (window.location.href.includes("bidding")) {
      //   navigate("/dashboard/clientjobrequest");
      // } else if (window.location.href.includes("events")) {
      //   navigate("/dashboard/eventCalendar");
      // } else if (window.location.href.includes("clientjobrequest")) {
      //   navigate("/dashboard/vendorMode/bidding");
      // } else if (window.location.href.includes("eventcalendar")) {
      //   navigate("/dashboard/vendorMode/events");
      // } else if (window.location.href.includes("employeejobrequest")) {
      //   navigate("/dashboard/clientjobrequest");
      // }

      sessionStorage.clear();
    } else {
      setVendorDescisionModal(true);
    }
  };

  const onSubmit = async () => {
    if (values === "Yes") {
      setCompanyIntake(true);
    } else {
      setVendorDescisionModal(false);
    }
  };

  const onSubmitCompany = async (values) => {
    try {
      await axios
        .post("/vendorMode/addCompany", {
          email: companyEmail,
          CompanyLegalName: companyLegalName,
          CompanyDbaName: companyDbaName,
          BusinessNumber: businessNumber,
          HSTRegistrationNumber: taxId,
          Services: `${selectedServices}`,
          InstitutionCode: institutionCode,
          TransitCode: transitCode,
          AccountNumber: accountNumber,
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.ok) {
            //email & password correct
            setLocationNotFound(false);
            setClientSecret(res.data.clientSecret);
            setClientID(res.data.clientID);
            setSubmitted(true);
            setCompanyIntake(false);
            setVendorDescisionModal(false);
            message.success(
              "Your account is created. Please verify your email."
            );
            dispatch({ state: user, type: "swapMode" });
            // handleSwitchChange();
            // axios.post("/vendorMode/changeVendorMode", {
            //   vendorMode: user.vendorMode,
            //   user: user,
            // });
            navigate(
              !user.vendorMode
                ? user.empRole === "Admin" || "Manager"
                  ? "/dashboard/vendorMode/bidding"
                  : "/dashboard/employeejobrequest"
                : "/dashboard/clientjobrequest"
            );
            sessionStorage.clear();
          } else if (res.data.notFound) {
            setLocationNotFound(true);
          }
        });
    } catch (err) {
      console.error(err.message);
    }
  };
  const services = [
    "Access & Security",
    "Appliance Repair",
    "Brick Layer",
    "Building Supplies",
    "Cabling and Data",
    "Carpenter",
    "Electrical",
    "Environmental",
    "Fire Protection",
    "Flooring",
    "Gas Fitter",
    "General Repair",
    "Glass Wholesale",
    "Heating",
    "Home Inspector",
    "Hot Tub Service",
    "HVAC",
    "Landscaping",
    "Lock Smith",
    "Miscellaneaous Supply",
    "Oil Burner Mechanic",
    "Paint Supply",
    "Pest Control",
    "Plaster & Paint Service",
    "Plumbing Supply",
    "Propane Service",
    "Property Management",
    "Property Restoration",
    "Refrigeration & AC",
    "Roofing",
    "Sheet Metal Worker",
    "Snow Clearing",
    "Tile Setter",
    "Ventilation",
    "Waste Management",
    "Windows & Doors",
    "Other",
  ];

  return (
    <>
      {!matchesXs && (
        <div
          style={{
            width: "100%",
          }}
        ></div>
      )}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}
      {!matchesXs && (
        <Stack direction="row">
          <InputLabel
            style={{ cursor: "pointer", marginTop: "0.5rem", color: "#fff" }}
            htmlFor="vendorMode"
          >
            {user.vendorMode
              ? matchesXs
                ? "Client"
                : "Client Mode"
              : matchesXs
              ? "Vendor"
              : "Vendor Mode"}
          </InputLabel>
          <Switch
            id="vendorMode"
            color="hover"
            checked={user.vendorMode}
            onChange={() => {
              handleVendorMode();
            }}
          />
        </Stack>
      )}

      {matchesXs && <MobileSection handleVendorMode={handleVendorMode} />}
      <Profile matchesXs={matchesXs} />

      <Stack
        padding={"2rem 0.5rem"}
        style={{
          position: "fixed",
          right: "0px",
          bottom: "300px",
          backgroundColor: "rgba(1, 82, 47, 0.7)",
          color: "white",
          fontSize: "1rem",
          border: "1px solid black",
          cursor: "pointer",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          borderRight: "none",

          textOrientation: "upright",
          writingMode: "vertical-lr",
        }}
        onClick={() => setFeedbackModal(!feedbackModal)}
      >
        Feedback
      </Stack>

      <Modal
        destroyOnClose={true}
        width="700px"
        title="Feedback"
        open={feedbackModal}
        onCancel={() => {
          setFeedbackModal(false);
        }}
        footer={false}
      >
        <FeedbackForm setFeedbackModal={setFeedbackModal} />
      </Modal>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Vendor Decision"
        open={vendorDescisionModal}
        onCancel={() => {
          setVendorDescisionModal(false);
        }}
        footer={false}
      >
        <Grid container spacing={3} justifyContent={"center"}>
          <Grid item xs={12}>
            <Typography variant="h3">Providing Services</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="p">
              Do you wish to provide services to clients?
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign={"center"}>
            <FormControl fullWidth sx={{ mt: 2, ml: 3 }}>
              <RadioGroup
                defaultValue=""
                name="customized-radios"
                row={true}
                value={values}
                onChange={(e) => {
                  setValues(e.target.value);
                  setShowNextPage(true);
                }}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio />}
                  disabled={values === "Yes"}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio />}
                  disabled={values === "No"}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <AnimateButton>
              <Button
                disableElevation
                disabled={!showNextPage}
                fullWidth
                size="large"
                variant="contained"
                onClick={onSubmit}
              >
                Continue
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Company Details"
        open={companyIntake}
        onCancel={() => {
          setCompanyIntake(false);
        }}
        footer={false}
      >
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="companyLegalName-signup">
                  Company Legal Name*
                </InputLabel>
                <OutlinedInput
                  required
                  id="companyLegalName-signup"
                  type="companyLegalName"
                  value={companyLegalName}
                  name="companyLegalName"
                  onChange={(e) => {
                    setCompanyLegalName(e.target.value);
                  }}
                  placeholder="Legal Name"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="companyDbaName-signup">
                  Company DBA Name*
                </InputLabel>
                <OutlinedInput
                  required
                  id="companyDbaName-signup"
                  type="companyDbaName"
                  value={companyDbaName}
                  name="companyDbaName"
                  onChange={(e) => setCompanyDbaName(e.target.value)}
                  placeholder="DBA Name"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="businessNumber-signup">
                  Business Number
                </InputLabel>
                <OutlinedInput
                  required
                  id="businessNumber-signup"
                  type="businessNumber"
                  value={businessNumber}
                  name="businessNumber"
                  onChange={(e) => setBusinessNumber(e.target.value)}
                  placeholder="Business Number"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="taxID-signup">
                  HST Registration #
                </InputLabel>
                <OutlinedInput
                  required
                  id="taxID-signup"
                  type="taxID"
                  value={taxId}
                  name="taxID"
                  onChange={(e) => setTaxId(e.target.value)}
                  placeholder="Tax ID"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <Stack>
                  <InputLabel>Provided Service</InputLabel>
                  <Select
                    required
                    id="service"
                    name="service"
                    value={selectedServices}
                    onChange={(e) => setSelectedServices(e.target.value)}
                    multiple
                  >
                    {services.map((service, i) => (
                      <MenuItem value={service} key={`${service}_service`}>
                        {service}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>Email</InputLabel>
                <OutlinedInput
                  required
                  name="companyEmail"
                  id="companyEmail"
                  type="email"
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                  placeholder="Company Email"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>Bank institution code</InputLabel>
                <OutlinedInput
                  required
                  name="institutionCode"
                  id="institutionCode"
                  type="text"
                  value={institutionCode}
                  onChange={(e) => setInstitutionCode(e.target.value)}
                  placeholder="Institution Code"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>Branch transit code</InputLabel>
                <OutlinedInput
                  required
                  name="transitCode"
                  id="transitCode"
                  type="text"
                  value={transitCode}
                  onChange={(e) => setTransitCode(e.target.value)}
                  placeholder="Branch transit code"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>Account number</InputLabel>
                <OutlinedInput
                  required
                  name="accountNumber"
                  id="accountNumber"
                  type="text"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  placeholder="Account number"
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">
                <Checkbox
                  checked={activeCreateAccount}
                  onChange={(e) => {
                    if (termsIsRead && policyIsRead) {
                      setActiveCreateAccount(e.target.checked);
                      setTermsReadError(false);
                    } else {
                      setTermsReadError(true);
                    }
                  }}
                />
                &nbsp; I have read and agreed to &nbsp;
                <Link
                  color={"#188bff"}
                  variant="subtitle2"
                  onClick={() => openTermsAndConditions()}
                >
                  Terms of Service
                </Link>
                &nbsp; and &nbsp;
                <Link
                  color={"#188bff"}
                  variant="subtitle2"
                  onClick={() => openPrivatePolicy()}
                >
                  Privacy Policy
                </Link>
              </Typography>
              <Typography color={"#ff0000"}>
                {termsReadError &&
                  "*You must read the terms and conditions & privacy policy"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={!activeCreateAccount}
                  fullWidth
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmitCompany(values);
                  }}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Add Company
                </Button>
              </AnimateButton>
            </Grid>
          </Grid>
        </form>

        {/* Open Terms and Conditions */}
      </Modal>
      <Modal
        title="Terms And Conditions"
        width="700px"
        open={isOpenTerms}
        onCancel={closeTermsAndConditions}
        footer={[
          <Button key="submit" type="primary" onClick={closeTermsAndConditions}>
            Okay
          </Button>,
        ]}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(80vh - 200px)" }}
      >
        {isOpenTerms && (
          <TermsAndConditions
            signIn={true}
            openPolicy={() => {
              closeTermsAndConditions();
              openPrivatePolicy();
            }}
          />
        )}
      </Modal>

      {/* Open Private Policy */}
      <Modal
        title="Privacy Policy"
        width="700px"
        open={isOpenPolicy}
        onCancel={closePrivatePolicy}
        footer={[
          <Button key="submit" type="primary" onClick={closePrivatePolicy}>
            Okay
          </Button>,
        ]}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(80vh - 200px)" }}
      >
        {isOpenPolicy && <PrivacyPolicy signIn={true} />}
      </Modal>
    </>
  );
};

export default HeaderContent;
