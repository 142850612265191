import React, { memo } from "react";
import PropTypes from "prop-types";
import styles from "../style.module.css";

const formatNumber = (value) => {
  const input = value.replace(/\D/g, "").substring(1, 11); // First 11 digits of input only
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    return `+1 (${areaCode}) ${middle}-${last}`;
  } else if (input.length > 3) {
    return `+1 (${areaCode}) ${middle}`;
  } else if (input.length > 0) {
    return `+1 (${areaCode})`;
  } else {
    return "+1";
  }
};

const PhoneInput = memo(({ required, value, name, id, onChange, onBlur }) => {
  if (typeof value === "undefined" || value === "") value = "+1";
  else value = formatNumber(value);
  return (
    <input
      required={required}
      placeholder="888-888-8888"
      type="tel"
      className={styles.inputDefaults}
      name={name}
      id={id}
      value={value}
      onChange={(e) => {
        e.target.value = formatNumber(e.target.value);
        onChange(e);
      }}
      onBlur={onBlur}
    />
  );
});

PhoneInput.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

PhoneInput.defaultProps = {
  required: false,
  value: "",
  name: "",
  id: null,
  onChange: () => {},
  onBlur: () => {},
};
export default PhoneInput;
