import { VendorTile } from "../../commonComponents/JRComponents/VendorPhases/VendorTile";
import { ClientTile } from "../../commonComponents/JRComponents/ClientPhases/ClientTile";
import { useUserState } from "../../../Context/UserContext";
import { useCallback, useEffect, useState } from "react";
import useOnce from "../../../hooks/useOnce";
import axios from "axios";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const JobDisplay = (props) => {
  const { user } = useUserState();
  const [jrList, setJrList] = useState([]);

  const [chargePercentages, setChargePercentages] = useState({
    clientPercentage: 0,
    vendorPercentage: 0
  });

  const fetchChargeData = useCallback(() => {
    axios.get("/finance/getChargePercentages").then((res) => {
      setChargePercentages(res.data);
    });
  }, []);

  const getJRs = useCallback(() => {
    axios
      .post("/communication/getConversationJR", {
        ConversationId: props.chatId
      })
      .then((response) => {
        console.log(response.data.jrList);
        setJrList(response.data.jrList);
      });
  }, [props.chatId]);

  useEffect(() => {
    if (props.chatId !== null) getJRs();
    else {
      setJrList([]);
    }
    fetchChargeData();
  }, [fetchChargeData, getJRs, props.chatId]);

  const NoJR = () => {
    return (
      <div className="noSelectedImg text-center">
        <img
          src={require("../../../images/emptyThread.png")}
          alt="emptyThread"
        />
        <div className="g-text-lg">
          There is no Job request linked to the conversation.
        </div>
      </div>
    );
  };

  const mobileView = useMediaQuery("(max-width: 600px)");

  return (
    <div className={`listContainer`} style={{ paddingTop: "2rem" }}>
      {mobileView && (
        <div style={{ height: "4rem" }}>
          <Link
            className="threadListing activethread"
            to={`/dashboard/chat/${props.chatId}`}
            onClick={() => {
              props.setJobView(false);
            }}
          >
            <img
              src={require("../../../icons/backArrow.png")}
              className="backbtnComms mr-2"
              style={{ cursor: "pointer" }}
              alt="backIcon.png"
            />
          </Link>
        </div>
      )}

      {jrList.length > 0 ? (
        <div className={`listScrollContainer`}>
          {jrList.map((jr, jrIndex) => {
            return !user.vendorMode ? (
              <ClientTile
                chargePercentages={chargePercentages}
                fromChat={true}
                jrData={jr}

                // openJR={openJR}
                // setOpenJR={setOpenJR}
                // key={jr.job_id}
              />
            ) : (
              <VendorTile
                chargePercentages={chargePercentages}
                fromChat={true}
                jrData={jr}
                // openJR={openJR}
                // setOpenJR={setOpenJR}
                // key={jr.job_id}
              />
            );
          })}{" "}
        </div>
      ) : (
        <NoJR />
      )}
    </div>
  );
};

export default JobDisplay;
