import "./searchbar.css";
import { InputGroup, FormControl } from "react-bootstrap";
import { SearchOutlined } from "@ant-design/icons";

function Searchbar({ value, onChange, className }) {
  return (
    <div>
      <InputGroup size="sm" className={className}>
        <FormControl
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          value={value}
          onChange={onChange}
          className="noHighlight sbh32"
        />
        <InputGroup.Text id="inputGroup-sizing-sm">
          <SearchOutlined />
        </InputGroup.Text>
      </InputGroup>
    </div>
  );
}

export default Searchbar;
