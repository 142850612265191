import styles from "./agreementFormat.module.css";
import { getMonth } from "../util.js";
const AgreementDocument = ({
  vendorLegalName,

  clientName,
  agreementDate,
  provinceName,
  city,
  workDescription,
  price,
  startDate,
  endDate
}) => {
  return (
    <div className={styles.agreement}>
      <span className={styles.center}>
        <h5>
          <b>GENERAL SERVICES AGREEMENT</b>
        </h5>
        <p>
          <b>This Agreement</b> made at {city}, in the Province of{" "}
          {provinceName}, this {agreementDate.getDate()} day of{" "}
          {getMonth(agreementDate.getMonth())}, {agreementDate.getFullYear()}{" "}
          (the “<b>Effective Date</b>”).
        </p>
      </span>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Between: &nbsp;</th>
            <td>
              <p>
                <b>{clientName}</b>, a body corporate incorporated pursuant to
                the laws of the Province of {provinceName} <b>OR</b> an
                individual resident in the Province of {provinceName}
              </p>
              <p>
                (hereinafter called the "<b>Client</b>")
              </p>
            </td>
          </tr>
          <tr>
            <th>and: </th>
            <td>
              <p>
                <b>{vendorLegalName}</b>, a body corporate incorporated pursuant
                to the laws of the Province of {provinceName} <b>OR</b> an
                individual resident in the Province of {provinceName}{" "}
                {/* <b>{vendorLegalName}</b> */}
              </p>
              <p>
                (hereinafter called the "<b>Contractor</b>")
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <b>WHEREAS:</b>
      </p>
      <div className={styles.listItem}>
        <p>A.</p>
        <p>
          The Client requires, and the Contractor has agreed to provide, the
          services described in <b>Schedule ‘A’ - Scope of Work.</b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>B.</p>
        <p>
          In exchange for performance of the Services, the Client has agreed to
          pay the Contractor the price(s) set out{" "}
          <b>Schedule ‘B’ – Compensation.</b>
        </p>
      </div>
      <p>
        <b>IN CONSIDERATION</b> of the rents and the covenants and agreements
        hereinafter contained (the receipt and sufficiency of which is hereby
        acknowledged) the parties agree as follows:
      </p>
      <div className={styles.listItem}>
        <p>
          <b>1.</b>
        </p>
        <p>
          <b>Definitions</b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>1.1</p>
        <div>
          <p>In this Agreement:</p>
          <div className={styles.listItem}>
            <p>(a)</p>
            <p>
              <b>“Agreement</b>” means this Agreement and all Schedules attached
              hereto.
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(b)</p>
            <p>
              <b>“Change</b>” means an addition, reduction, or other change to
              the Scope of Work and/or Compensation.
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(c)</p>
            <p>
              <b>“Claim</b>” means, without limitation, all losses, damages,
              costs, disbursements, penalties, fines, claims, demands, actions,
              causes of action, proceedings, lawsuits, liens, encumbrances,
              statutory obligations, liabilities, judgments, settlements,
              decrees, assessments, arbitration awards, adjudications,
              litigation expenses, unpaid taxes of any kind, costs of
              investigations and fees (including legal fees on a
              solicitor-client basis), together with any interest applicable
              thereto, whether ascertained or not ascertained, liquid or
              illiquid, contingent or non-contingent.
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(d)</p>
            <p>
              <b>“Compensation</b>” means the pricing terms and conditions
              outlined in
              <b>Schedule ‘B’ – Compensation.</b>
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(e)</p>
            <p>
              “<b>Confidential Information</b>” means all information, including
              personal information, and documentation acquired directly or
              indirectly, from any source, in writing, orally, or through
              observation, and includes all documents, financial data, trade
              secrets, assets, operations, activities, prospects, technical
              information, processes, drawings, plans, sketches, software,
              photographs, development plans, or other documents prepared by or
              received from the Client or any of its affiliates,
              representatives, or agents, and any other commercial, financial or
              technical information related to the Agreement or the Services,
              but it does not include information that (i) at the time of
              disclosure was in the public domain, or (ii) following its
              disclosure hereunder, was acquired by the Contractor without any
              confidentiality obligation.
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(f)</p>
            <p>
              <b>“Defect</b>” has its meaning set out in section 6.1.
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(g)</p>
            <p>
              “<b>Gross Negligence</b>” means any act or failure to act which
              was in serious reckless disregard to avoidable, foreseeable and
              harmful consequences that such Person knew, or should have known,
              such act or failure to act would have on the property, rights,
              safety, health, or life of another Person.
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(h)</p>
            <p>
              “<b>Laws</b>” means all applicable common law and federal, state,
              provincial, territorial, municipal, local and foreign statutes,
              codes, ordinances, decrees, rules, regulations, by-laws, judicial
              or arbitral or administrative or ministerial or departmental or
              regulatory judgments, orders, decisions, rulings or awards,
              policies and guidelines having the force of law and “<b>Law</b>”
              means any of the foregoing;
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(i)</p>
            <p>
              <b>“Party</b>” means either the Client or the Contractor and “
              <b>Parties</b>” means both the Client and the Contractor.
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(j)</p>
            <p>
              “<b>Person</b>” means any individual, sole proprietorship,
              partnership, firm, entity, unincorporated association,
              unincorporated syndicate, unincorporated organization, trust, body
              corporate, governmental authority, and where the context requires,
              any of the foregoing when they are acting as trustee.
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(k)</p>
            <p>
              “<b>Scope of Work</b>” means the work set out in{" "}
              <b>Schedule ‘A’ – Scope of Work.</b>
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(l)</p>
            <p>
              “<b>Services</b>” means, without limitation, all actions, labour,
              equipment, materials, efforts, quality control, quality assurance,
              inspection, training, supervision, procurement, transportation,
              administration, products, and services required to complete the
              Scope of Work.
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(m)</p>
            <p>
              “<b>Term</b>” has its meaning set out in section 2.
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(n)</p>
            <p>
              “<b>Warranty Charges</b>” has its meaning sect out in section 7.2.
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(o)</p>
            <p>
              “<b>Willful Misconduct</b>” means any act or failure to act which
              was intended to harm the property, rights, safety, health or life
              of another Person.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>2. </b>
        </p>
        <div>
          <p>
            <b>Term</b>
          </p>
          <p>
            This Agreement will start on{" "}
            <b>
              {getMonth(startDate.getMonth())} {startDate.getDate()},{" "}
              {startDate.getFullYear()}
            </b>{" "}
            and end{" "}
            <b>
              {getMonth(endDate.getMonth())} {endDate.getDate()},{" "}
              {endDate.getFullYear()}
            </b>{" "}
            (the “<b>Term</b>”) unless terminated earlier in accordance with the
            terms herein.
          </p>
        </div>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>3. </b>
        </p>
        <div>
          <p>
            <b>Pricing and Payment </b>
          </p>
          <p>
            The Contractor shall be paid in accordance with the Compensation
            listed in <b>Schedule ‘B’ – Compensation.</b>
          </p>
        </div>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>4.</b>
        </p>
        <p>
          <b>Standard of Care</b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>4.1</p>
        <p>
          The Contractor shall provide the Services in a skillful and
          workmanlike manner, which is consistent with the level of expertise,
          care, skill and diligence demonstrated by experienced and reputable
          services providers performing services of a similar nature.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>4.2</p>
        <p>
          The Contractor shall at all times act diligently in the performance of
          the Services under this Agreement. The Contractor shall comply with
          all Laws applicable to the Services.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>4.3</p>
        <p>
          The Contractor agrees that any information given or representation
          made to the Client and its representatives in connection with the
          Services is, to the best of its knowledge, accurate, current and is
          not misleading or deceptive in any respect, and as at the date of the
          Agreement, no conflict of interest exists and it will ensure none will
          arise in the performance of the Services.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>5.</b>
        </p>
        <div>
          <p>
            <b>Assignment of manufacturers’ warranties</b>
          </p>
          <p>
            The Client shall benefit from all legal and manufacturer’s
            warranties available in connection with the goods, equipment,
            materials, machinery, tools and consumables incorporated or used in
            the Services and the Contractor shall ensure for the assignment of
            all such warranties to the Client on the same terms in which these
            warranties have been provided to the Contractor. The Contractor
            shall cooperate with the Client in the enforcement of the assignment
            and the warranties against the manufacturers.
          </p>
        </div>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>6.</b>
        </p>
        <p>
          <b>Defects and Charges</b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>6.1</p>
        <p>
          Upon the Client’s request, the Contractor shall expeditiously
          re-perform, at the Contractor’s own expense (and at no additional cost
          to the Client) any parts of the Services which are in breach of
          section 4 (herein “<b>Defects</b>”) that are directly attributable to
          the Contractor until its correction.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>6.2</p>
        <div>
          <p>
            Subject to section 6.3, the Client may charge to the Contractor the
            additional costs incurred by the Client over and above the pricing
            set out in the Compensation to:
          </p>
          <div className={styles.listItem}>
            <p>(a)</p>
            <p>correct all Defects;</p>
          </div>
          <div className={styles.listItem}>
            <p>(b)</p>
            <p>remedy delays caused by the Contractor; and</p>
          </div>
          <div className={styles.listItem}>
            <p>(c)</p>
            <p>
              reconstruct and/or repair any structure, building, equipment etc.
              that was adversely affected by the Defects,
            </p>
          </div>
          <p>
            (the “<b>Warranty Charges</b>”).
          </p>
        </div>
      </div>
      <div className={styles.listItem}>
        <p>6.3</p>
        <p>
          Prior to applying Warranty Charges for the correction of Defects, the
          Contractor shall be given a reasonable opportunity to re-perform its
          Services pursuant to section 6.1.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>6.4</p>
        <p>
          The Client may set-off Warranty Charges against any sums owed to the
          Contractor. For this purpose, the Client may withhold any funds
          payable to the Contractor while the Warranty Charges are still
          outstanding.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>6.5</p>
        <p>
          The Contractor shall continue performing the Services irrespective of
          any disputes related to Warranty Charges.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>6.6</p>
        <p>
          The Contractor shall correct any Defects during the Term and for a
          period of 90 days after the expiration of the Term or termination of
          the Agreement. Acceptance of the Services by the Client shall not
          relieve the Contractor from its obligation to correct Defective
          Services.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>7. </b>
        </p>
        <div>
          <p>
            <b>Title to the Services</b>
          </p>
          <p>
            The materials and products used for performance of the Services
            shall become the property of the Client when and to the extent
            payments are made for same by the Client.
          </p>
        </div>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>8. </b>
        </p>
        <p>
          <b>Changes to the Services </b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>8.1</p>
        <p>
          At any time, the Client or the Contractor may propose a Change. The
          proposing party shall submit the request for Change in writing to the
          other party detailing any adjustments required to the Agreement,
          including the Schedules.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>8.2</p>
        <p>
          If both parties mutually agree to the proposed Change, the parties
          shall agree in writing to the details of the Change and such Change
          will be deemed effective the date the written agreement detailing the
          Change is signed.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>9.</b>
        </p>
        <p>
          <b>Insurance Requirement of the Contractor </b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>9.1</p>
        <p>
          Prior to commencing the Services and at all times throughout the Term,
          the Contractor shall, at is sole expense, maintain all insurance that
          a reasonably prudent contractor engaging in the Services would
          maintain.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>9.2</p>
        <p>
          The Contractor shall provide the Client with a copy of such insurance
          policies from time to time as requested by the Client.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>10.</b>
        </p>
        <p>
          <b>Confidential Information </b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>10.1</p>
        <p>
          Except as otherwise permitted by this Agreement, any Confidential
          Information which the Contracting Party now has or which may come into
          its possession in the course of this Agreement, including any
          information which is marked “<b>CONFIDENTIAL</b>”, will be kept
          confidential and will not, without the Client’s prior written consent,
          be disclosed by the Contractor in any manner whatsoever, in whole or
          in part, and will not be used for any purpose other than to provide
          Services to the Client. The Contractor may reveal or permit access to
          the Confidential Information only to its employees, consultants and
          subcontractors who need to know the Confidential Information, who must
          be advised of the confidential nature of the Confidential Information,
          who are directed by the Contractor to hold the Confidential
          Information in confidence and who agree to be bound by and to act in
          accordance with the terms and conditions of this Agreement. The
          Contractor shall take all necessary precautions or measures to prevent
          improper access to, or use or disclosure of, the Confidential
          Information by its employees, consultants and subcontractors and shall
          be jointly and severally responsible for any breach of this Agreement
          by any of its employees, consultants and subcontractors.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>10.2</p>
        <p>
          The Contractor warrants that the Contractor has made no use or
          disclosure of any Confidential Information prior to the date of the
          Contractor’s signing of this Agreement, except as permitted by this
          Agreement.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>10.3</p>
        <p>
          If the Contractor becomes legally compelled or is required by any
          governmental authority having appropriate jurisdiction to disclose any
          of the Confidential Information, the Contractor shall, if permitted
          under Law, promptly provide the Client with notice (including copies
          of the governmental authority's requirements) so that the Client may
          seek a protective order or other appropriate remedy and/or waive
          compliance with the provisions of this Agreement. The Contractor shall
          cooperate with the Client to obtain a protective order or other
          remedy. If a protective order or other remedy is not available, then
          the Contractor shall only disclose that portion of the Confidential
          Information which is legally required to be disclosed and use all
          reasonable efforts to obtain undertakings that the Confidential
          Information so disclosed will be treated in confidence by the
          recipients. The Contractor shall provide the Client, in advance of any
          disclosure to such governmental authority, the Confidential
          Information intended to be disclosed.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>10.4</p>
        <p>
          Upon termination or expiration of this Agreement, the Contractor shall
          promptly, and in any event, within ten (10) days after such request
          from the Client, return or destroy all copies of the Confidential
          Information and other material if it contains any Confidential
          Information, and delete all Confidential Information from all computer
          systems and databases. Any Confidential Information that is not
          returned or destroyed by the Contractor remains subject to the
          confidentiality obligations under this Agreement.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>11.</b>
        </p>
        <div>
          <p>
            <b>Health and Safety </b>
          </p>
          <p>
            The Contractor shall perform the Services in a safe manner and at
            all times shall comply with applicable occupational health and
            safety Laws, and any health and safety policies, rules or directions
            provided by the Client.
          </p>
        </div>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>12.</b>
        </p>
        <div>
          <p>
            <b>Indemnity from Third Party Claims</b>
          </p>
          <p>
            The Contractor shall at all times indemnify, defend, and save the
            Client and the Client’s affiliates, directors, officers, employees
            and agents harmless against all liabilities resulting from claims by
            third parties against the Client, the Client’s affiliates,
            directors, officers, employees or agents to the extent that such
            claims have been caused by the negligence, Gross Negligence, Willful
            Misconduct, breach of contract and/or material violation of any Law
            by the Contractor.
          </p>
        </div>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>13.</b>
        </p>
        <p>
          <b>LIMITATION OF LIABILITY</b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>13.1</p>
        <p>
          The Contractor hereby waives any Claim in connection with this
          Agreement against the Client, its affiliates, directors, officers
          employees or agents.{" "}
        </p>
      </div>
      <div className={styles.listItem}>
        <p>13.2</p>
        <p>
          The liability of either party in connection with this Agreement is
          limited to a sum equal to 100% of the total Price contained in the
          Compensation.{" "}
        </p>
      </div>
      <div className={styles.listItem}>
        <p>13.3</p>
        <p>
          Neither party shall be liable to pay the other party for any indirect,
          consequential, aggravated or special damages.{" "}
        </p>
      </div>
      <div className={styles.listItem}>
        <p>13.4</p>
        <div>
          <p>
            Notwithstanding sections 13.1, 13.2, and 13.3, no limitation of
            liability shall apply to:
          </p>
          <div className={styles.listItem}>
            <p>(a)</p>
            <p>
              claims by the Client for contribution and/or indemnity against the
              Contractor in accordance with section 12; and
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(b)</p>
            <p>
              claims by the Client against the Contractor resulting from the
              Contractor’s Gross Negligence, Willful Misconduct, or breach of
              any applicable Law.
            </p>
          </div>
        </div>
      </div>
      {/* 14 */}
      <div className={styles.listItem}>
        <p>
          <b>14. </b>
        </p>
        <p>
          <b>Liens and Claims</b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>14.1</p>
        <p>
          Contractor shall defend, protect, release, indemnify and hold Client
          harmless from and against, and shall keep Client's property, the
          Services and the site the Services are performed, thereon free and
          clear of all liens, charges, claims, assessments, fines and levies
          suffered, created, or committed by Contractor.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>14.2</p>
        <p>
          Contractor shall bear the cost of vacating and discharging all liens.
          If Contractor either does not promptly satisfy or commence reasonable
          efforts to vacate and discharge such lien(s) (or, where permitted,
          fails to provide a bond in lieu thereof), Client shall have the right,
          at its option, after two (2) days prior written notification to
          Contractor, to pay or vacate and discharge such lien(s) and Contractor
          shall, within two (2) days of request by Client, reimburse Client for
          all reasonable out of pocket costs actually incurred by Client to
          vacate and discharge such lien(s), including administrative costs,
          bond premiums reasonable attorneys' fees and other expenses.
        </p>
      </div>
      {/* 15 */}
      <div className={styles.listItem}>
        <p>
          <b>15.</b>
        </p>
        <p>
          <b>Suspension of Services </b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>15.1</p>
        <p>
          The Client may suspend the Services at any time for any reason (or no
          reason at all) upon provision of notice to the Contractor, with such
          notice having immediate effect.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>15.2</p>
        <p>
          At the end of the suspension period, the parties may negotiate in good
          faith any equitable adjustments to the Scope of Work and/or the
          Compensation in order to account for the time the Services were
          suspended.
        </p>
      </div>
      {/* 16 */}
      <div className={styles.listItem}>
        <p>
          <b>16.</b>
        </p>
        <p>
          <b>Termination</b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>16.1</p>
        <p>
          <u>Termination without Cause.</u> The Client may terminate this
          Agreement without cause for any reason (or not reason at all) upon 15
          days written notice to the Contractor.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>16.2</p>
        <p>
          <u>Effect of Termination Without Cause</u>. Should the Client
          terminate this Agreement in accordance with section 16.1, the sole
          Claim of the Contractor against the Client shall be for all undisputed
          invoices for Services actually provided by the Contractor up to the
          effective date of termination.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>16.3</p>
        <div>
          <p>
            <u>Termination for Cause by Client</u>. The following events shall
            constitute a material breach of this Agreement by the Contractor and
            shall entitle the Client to terminate this Agreement upon notice,
            effective immediately:
          </p>
          <div className={styles.listItem}>
            <p>(a)</p>
            <p>Willful Misconduct of the Contractor;</p>
          </div>
          <div className={styles.listItem}>
            <p>(b)</p>
            <p>Gross Negligence of the Contractor;</p>
          </div>
          <div className={styles.listItem}>
            <p>(c)</p>
            <p>
              the Contractor failing to comply with health and safety Laws or
              Client policies or directions;
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(d)</p>
            <p>
              the Contractor going into receivership, insolvency or bankruptcy;
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(e)</p>
            <p>
              the Contractor assigning any right or obligation of this Agreement
              without the prior written consent of the Client; and
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(f)</p>
            <p>the Contractor breaching any obligation under this Agreement.</p>
          </div>
        </div>
      </div>
      <div className={styles.listItem}>
        <p>16.4</p>
        <p>
          <u>Termination for Cause by Contractor</u>. The Contractor may
          terminate this Agreement upon notice to the Client if the Client goes
          receivership, insolvency or bankruptcy, or the Client breaches an
          obligation under this Agreement.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>16.5</p>
        <div>
          <p>
            <u>Effect of Termination for Cause</u>. In the event this Agreement
            is terminated in accordance with section 16.3, in addition to any
            other legal remedy or recourse at Law available to the Client, the
            Client shall be entitled to:
          </p>
          <div className={styles.listItem}>
            <p>(a)</p>
            <p>
              withhold any monies otherwise due to the Contractor to cover the
              costs of any disputes arising from or in connection with the
              termination; and
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(b)</p>
            <p>
              complete the Services or have a third Person complete the Services
              and charge to the Contractor any additional costs in connection to
              the completion of the Services that are over and above the Price
              set out in the Compensation.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.listItem}>
        <p>16.6</p>
        <p>
          Termination with cause by either Party shall not prejudice the rights
          that either Party may have under this Agreement and under Law.
        </p>
      </div>
      {/* 17 */}
      <div className={styles.listItem}>
        <p>
          <b>17.</b>
        </p>
        <p>
          <b>Independent Contractor Status </b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>17.1</p>
        <p>
          The Contractor is and shall remain an independent contractor and not
          an employee of the Client. The Contractor shall retain independent
          professional status throughout this Agreement and will use its own
          discretion in performing the tasks assigned.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>17.2</p>
        <p>
          This Agreement does not constitute the Parties as partners, joint
          venturers or agents of each other and no Party may so represent itself
          in this manner.
        </p>
      </div>
      {/* 18 */}
      <div className={styles.listItem}>
        <p>
          <b>18.</b>
        </p>
        <div>
          <p>
            <b>Assignment </b>
          </p>
          <p>
            Neither Party may assign this Agreement or subcontract its
            obligations under this Agreement without the express consent of the
            other Party. However, upon notice to the Contractor, the Client may
            assign this Agreement to any affiliated Client or Person without the
            prior express consent of the Contractor. This Agreement enures to
            the benefit of the Parties and it is binding upon the Parties'
            heirs, executors, successors and assigns.
          </p>
        </div>
      </div>
      {/* 19 */}
      <div className={styles.listItem}>
        <p>
          <b>19.</b>
        </p>
        <p>
          <b>Notices</b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>19.1</p>
        <p>
          All notices in connection with this Agreement shall be in writing.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>19.2</p>
        <p>
          Notices may be transmitted to e-mail or courier. Each party shall
          designate, from time to time, a representative for the purposes of
          providing notice to one another.
        </p>
      </div>
      {/* 20 */}
      <div className={styles.listItem}>
        <p>
          <b>20.</b>
        </p>
        <p>
          <b>Other Provisions </b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>20.1</p>
        <p>
          The parties shall use reasonable efforts to meet any time requirements
          in the performance of the parties’ respective.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>20.2</p>
        <p>
          Failure or delay in exercising any right, power or privilege in
          respect of this Agreement will not be presumed to operate as a waiver,
          and a single or partial exercise of any right, power or privilege will
          not be presumed to preclude any subsequent or further exercise, of
          that right, power or privilege or the exercise of any other right,
          power or privilege.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>20.3</p>
        <p>
          The parties shall, with reasonable diligence, do all such things and
          provide all such reasonable assurances as may be required to
          consummate the transactions contemplated by this Agreement, and each
          party shall provide such further documents or instruments required by
          any other party as may be reasonably necessary or desirable to effect
          the purpose of this Agreement and carry out its provisions, whether
          before or after the date of this Agreement.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>20.4</p>
        <p>
          All currency references are to Canadian Dollars unless otherwise
          specified.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>20.5</p>
        <p>
          Unless the context otherwise requires, words importing the singular
          include the plural and vice versa and words importing one gender
          include all genders. If, in any jurisdiction, any provision of the
          Agreement or its application to any Party or circumstance is
          restricted, prohibited or unenforceable, the provision is, as to that
          jurisdiction, ineffective only to the extent of the restriction,
          prohibition or unenforceability without invalidating the remaining
          provisions of the Agreement and without affecting the validity or
          enforceability of such provision in any other jurisdiction, and
          without affecting its application to other Parties or circumstances.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>20.6</p>
        <p>
          Sections 5, 6, 7, 10, 12, 13, 16.5, 16.6, and 20.6 shall survive
          termination or expiration of this Agreement.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>20.7</p>
        <p>
          This Agreement shall be governed by the laws of the Province of{" "}
          {provinceName}.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>20.8</p>
        <p>
          This Agreement may be executed in two or more counterparts, each of
          which together shall be deemed an original, but all of which together
          shall constitute one and the same instrument. In the event that the
          signature is delivered by fax transmission or by email delivery of a
          scanned data file (Adobe PDF or otherwise), such signature shall
          create a valid and binding obligation on the party executing the
          Agreement with the same force and effect as if the fax or scanned data
          file (Adobe PDF or otherwise) were an original thereof.
        </p>
      </div>
      <span className={styles.center}>
        <p>
          <b>Execution Page to Follow</b>
        </p>
      </span>
      <br />
      <br />
      <table className={styles.execution}>
        <tbody>
          <tr>
            <td>By the Client:</td>
            {/* <td></td> */}
            <td>
              <div>
                <p>
                  <b>{clientName}</b> {/* client Legal Name */}
                </p>
              </div>
              {/* <div> */}
              {/* <p> */}
              {/* <b>{client}</b> client Name */}
              {/* </p> */}
              {/* </div> */}
            </td>
          </tr>
          <tr>
            <td>By the Contractor:</td>
            {/* <td></td> */}
            <td>
              <div>
                <p>
                  <b>{vendorLegalName}</b> {/* vendor Legal Name */}
                </p>
              </div>
              {/* <div> */}
              {/* <p> */}
              {/* <b>{vendor}</b> vendor Name */}
              {/* </p> */}
              {/* </div> */}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <span>
        <p>
          <b>SCHEDULE ‘A’ – SCOPE OF WORK</b>
        </p>
      </span>
      {/* Insert Scope */}
      <p>{workDescription}</p>
      {/* insert space */}
      <br />
      <br />
      <div className={styles.listItem}>
        <p>
          <b>1.</b>
        </p>
        <div>
          <p>
            <b>Price</b>
          </p>
          <p>${price}</p>
          <p>
            (the “<b>Price</b>”)
          </p>
        </div>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>2. </b>
        </p>
        <p>
          <b>Invoicing </b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>2.1</p>
        <p>
          All invoices must be sent to the Client within sixty (30) days after
          the provision of the Services to which the invoice makes reference,
          otherwise payment of the invoices may be significantly delayed, and
          delayed payment by the Client under these circumstances shall not be
          considered a breach of the Client’s obligations hereunder.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>2.2</p>
        <p>
          The Contractor also waives its right to the payment of any invoices
          that have been received for the first time by the Client more than six
          months after the provision of the Services to which such invoice
          refers.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>2.3</p>
        <p>
          Each invoice shall be submitted with proper backup documentation to
          evidence to the satisfaction of the Client that all Services that are
          the subject of the invoice have been performed. The date of the
          proforma invoice shall never precede the date that the invoice and all
          backup documentation was submitted.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>2.4</p>
        <p>
          All invoices shall include such information as required for the Client
          to claim available input tax credits for GST/HST.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>3.</b>
        </p>
        <p>
          <b> Taxes</b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>3.1</p>
        <p>
          “<b>Taxes</b>” means any and all taxes, levies, imposts, duties,
          customs duties, stamp duties, fees, withholdings, assessments,
          deductions or charges whatsoever, imposed, assessed, levied or
          collected by any governmental authority, together with interest, fines
          and penalties including occupational, excise, unemployment, ownership,
          sales, gross receipts, income taxes, payroll taxes, employer
          contributions (both statutory and otherwise) and workers' compensation
          payments and contributions but does not include value-added tax.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>3.2</p>
        <p>
          Other than GST/HST, QST or PST applicable, the Price is inclusive of
          all Taxes for which the Contractor is responsible under applicable
          laws. In addition, the Client shall pay the GST/HST, QST or PST, as
          applicable, to the Contractor provided that the Contractor is
          registered for GST/HST, QST or PST remittance.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>3.3</p>
        <p>
          The Contractor shall indemnify, save and hold harmless the Client
          against any assessment by tax authorities in respect of GST/HST, QST
          or PST relative to the Services under this Agreement which was not
          timely remitted by the Contractor to the appropriate tax authorities.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>3.4</p>
        <p>
          In addition, if the Contractor is a not resident or becomes a
          non-resident of Canada during the Term of the Agreement, the
          Contractor shall ensure that each invoice issued to the Client
          identifies that portion of the Services performed in Canada and the
          value of that portion, in which event the Client is authorized to
          withhold from any monies payable to the Contractor in respect of that
          invoice, such amounts as the Client may be obliged to withhold under
          applicable tax legislation in respect of the Services performed in
          Canada, and to remit the same to the applicable tax authorities in
          Canada. The Contractor shall be responsible for filing the appropriate
          paperwork with the tax authorities for the applicable refunds or
          reimbursements, if applicable, as the case may be.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>4.</b>
        </p>
        <p>
          <b>Representations of the Contracting Party upon each Invoice</b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>4.1</p>
        <div>
          <p>
            By submitting an invoice the Contractor represents and warrants to
            the Client (and the Contractor acknowledges that it is a condition
            precedent to payment) that:
          </p>
          <div className={styles.listItem}>
            <p>(a)</p>
            <p>
              the Contractor has completed the portions of the Services that are
              the subject of the invoice;
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(b)</p>
            <p>
              the Contractor is not aware of any Defects in the Services that
              are the subject of the invoice;
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(c)</p>
            <p>
              all remuneration and amounts due and payable by the Contractor to
              any of its employees, subcontractors, agents, and suppliers have
              been paid, except for any applicable holdback amounts required by
              Law; and
            </p>
          </div>
          <div className={styles.listItem}>
            <p>(d)</p>
            <p>
              the Contractor is not aware of any Claim against the Client not
              previously made aware to the Client.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.listItem}>
        <p>
          <b>5.</b>
        </p>
        <p>
          <b>Holdback</b>
        </p>
      </div>
      <div className={styles.listItem}>
        <p>5.1</p>
        <p>
          The Client shall make all holdbacks on the Price as required by Law.
        </p>
      </div>
      <div className={styles.listItem}>
        <p>6.</p>
        <p>Set-Off</p>
      </div>
      <div className={styles.listItem}>
        <p>6.1</p>
        <p>
          In the event the Contractor is in any way indebted to the Client under
          this Agreement or for any other reason, the Client shall have the
          right to set-off such debt against the Price.
        </p>
      </div>
    </div>
  );
};

export default AgreementDocument;
