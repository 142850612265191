import { useState } from "react";
import { Button } from "antd";
import { useEffect } from "react";
import AgreementDocument from "./AgreementDocument";
import axios from "axios";

const Agreement = (props) => {
  const [price, setPrice] = useState();
  const footer =
    props.hasAgreed === 1 || props.vendorName !== "VendorName" ? (
      ""
    ) : (
      <Button
        key="submit"
        type="primary"
        onClick={() => {
          props.onClose();
        }}
      >
        I Agree
      </Button>
    );

  useEffect(() => {
    fetchInvoiceData();
  }, []);

  const fetchInvoiceData = () => {
    axios.post("/scope/getScope", { jobId: props.jobId }).then((res) => {
      // console.log(res.data.scopeDetails);
      const scopeDetails = res.data.scopeDetails;
      setPrice(scopeDetails[0].scope_amount);
    });
  };

  // console.log(price);
  return (
    <>
      <AgreementDocument
        vendorLegalName={props.vendorLegalName}
        clientName={props.clientName}
        agreementDate={new Date(props.agreementDate)}
        provinceName={props.provinceName}
        city={props.city}
        workDescription={props.workDescription}
        price={price}
        startDate={new Date(props.startDate)}
        endDate={new Date(props.endDate)}
      />
      {footer}
    </>
  );
};

Agreement.defaultProps = {
  hasAgreed: 0,
  agreementDate: new Date(),

  startDate: new Date(),
  endDate: new Date()
};

export default Agreement;
