import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";
import { useUserState } from "../../../../Context/UserContext";
import { DatePicker, Modal } from "antd";
import { Button, Grid, InputLabel, OutlinedInput, Stack } from "@mui/material";

const EditAction = (props) => {
  const { user } = useUserState();
  const [attachments, setAttachments] = useState([]);
  const [actionId, setActionId] = useState("");
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    axios.post("/action/getActionData", { JobId: props.jobID }).then((res) => {
      let actionData = res.data.actionData[0];
      setActionId(actionData.action_id);
      setDescription(actionData.action_description);
      setStartTime(new Date(actionData.start_time));
      setStartDate(new Date(actionData.start_date));
      setEndTime(new Date(actionData.end_time));
    });
  }, [props.jobID]);

  const importFile = () => {
    let newArray;
    if (attachments !== undefined) {
      newArray = [...attachments];
    } else {
      newArray = [];
    }
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = () => {
      newArray.push(...input.files);
      setAttachments(2);
      setAttachments(newArray);
    };
    input.click();
  };

  const removeAttachment = (id) => {
    const newArray = [...attachments];
    newArray.splice(id, 1);
    if (newArray.length === 0) {
      setAttachments("");
    } else {
      setAttachments(0);
      setAttachments(newArray);
    }
  };
  const handleUpdate = () => {
    const formObject = {
      startDate: new Date(startDate).toISOString(),
      actionDesc: description,
      startTime: new Date(startTime).toISOString(),
      endTime: new Date(endTime).toISOString(),
      actionId: actionId,
      JobId: props.jobID,
      activityDesc: `Action is updated.`,
      userName: `${user.first_name} ${user.last_name}`,
    };

    axios.post("/action/updateActionEntry", formObject).then((response) => {
      if (response.data.ok) {
        if (attachments.length > 0) {
          let form = new FormData();
          for (const element of attachments) {
            form.append("file", element);
          }
          form.append("action_id", actionId);
          form.append("job_id", props.jobID);

          axios.post("/action/addActionImages", form).then((res) => {
            props.setShowEditForm(false);
            props.fetchData();
          });
        } else {
          props.setShowEditForm(false);
          props.fetchData();
        }
      }
    });
  };

  const createAttachmentDisplay = (attachments) => {
    const attachmentList = [];
    for (let i in attachments) {
      attachmentList.push(
        <div
          onClick={() => removeAttachment(i)}
          key={attachments[i].name + attachments[i].size}
          className="attachment">
          {attachments[i].type.includes("image") ? (
            <img
              className="testIMG"
              src={URL.createObjectURL(attachments[i])}
              alt=""
            />
          ) : (
            attachments[i].name
          )}
        </div>
      );
    }
    return (
      <>
        <div>
          <p>Attachments: </p>
        </div>
        {attachmentList}
      </>
    );
  };
  return (
    <Modal
      destroyOnClose={true}
      width="700px"
      title={"Update Action Entry"}
      open={props.showEditForm}
      onCancel={() => {
        props.setShowEditForm(false);
      }}
      footer={false}>
      <form>
        <Grid container spacing={1}>
          <>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>Start Time</InputLabel>
                <DatePicker
                  required
                  showTime={{
                    format: "HH:mm a",
                  }}
                  format="YYYY-MM-DD HH:mm"
                  label="Start Date"
                  value={moment(startTime)}
                  onChange={(e, dateString) => {
                    setStartDate(dateString);
                    setStartTime(dateString);
                  }}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>End Time</InputLabel>
                <DatePicker
                  required
                  showTime={{
                    format: "HH:mm a",
                  }}
                  format="YYYY-MM-DD HH:mm"
                  label="End Date"
                  value={moment(endTime)}
                  onChange={(e, dateString) => {
                    setEndTime(dateString);
                  }}
                />
              </Stack>
            </Grid>
          </>

          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <InputLabel>Action Description</InputLabel>
              <OutlinedInput
                required
                id="ActionDesc"
                name="actionDesc"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Action Description"
                multiline
                rows={4}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel>Pictures/Documents</InputLabel>
              <Button
                size="sm"
                type="button"
                variant="contained"
                color="primary"
                onClick={importFile}
                id="file"
                name="file">
                Choose File
              </Button>

              {attachments !== undefined && attachments.length !== 0 && (
                <div className="attachments">
                  {createAttachmentDisplay(attachments)}
                </div>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <AnimateButton>
                <Button
                  size="sm"
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleUpdate();
                  }}
                  sx={{ ml: 2, float: "right" }}>
                  Update
                </Button>

                <Button
                  size="sm"
                  type="button"
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    props.setShowEditForm(false);
                  }}
                  sx={{ ml: 2, float: "right" }}>
                  Cancel
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default EditAction;
