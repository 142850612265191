import { useCallback, useEffect, useState } from "react";
import styles from "./MarketPage.module.css";
import axios from "axios";
import JobDisplay from "./MarketComponents/JobDisplay";
import Container from "../commonComponents/Container";
import MapDisplay from "./MarketComponents/MapDisplay";
import "../commonComponents/CSS/styles.css";
import SubmitBidForm from "./MarketComponents/SubmitBidForm";
import Agreement from "../commonComponents/Agreement";
import { Modal } from "antd";
import { Alert } from "@mui/material";
import { useUserState } from "../../Context/UserContext";
import ClientJR from "../commonComponents/JRComponents/ClientPhases/ClientJR";
import VendorJR from "../commonComponents/JRComponents/VendorPhases/VendorJR";
import EmployeeJR from "../commonComponents/JRComponents/EmployeeJR/index.js";
import { useJRContext } from "../../Context/JobRequestContext";
import backupProfileImage from "../../images/personPlaceholder.jpg";
import MainCard from "../../Layout/components/MainCard";
import LoadScreen from "../../Components/LoadScreen.js";

const date_today = new Date();
date_today.setHours(0);

// dummy data for employees
const locations = [
  [48.9429391, -57.9491593],
  [48.93203393563653, -57.93544518919865],
  [48.94729828328596, -57.96652065000176],
  [48.96239055792801, -57.90910188306521],
  [48.931301502204995, -57.97927583706526],
  [48.9499391, -57.9451593],
  [48.93903393563653, -57.94044518919865],
  [48.94029828328596, -57.96052065000176],
  [48.96939055792801, -57.90010188306521],
  [48.93930150220499, -57.97027583706526],
];

const getGeolocation = (setMapFocus) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        let geoLocation = [coords.latitude, coords.longitude];
        setMapFocus(geoLocation);
      },
      (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            console.error("User denied the request for Geolocation.");
            break;
          case error.POSITION_UNAVAILABLE:
            console.error("Location information is unavailable.");
            break;
          case error.TIMEOUT:
            console.error("The request to get user location timed out.");
            break;
          case error.UNKNOWN_ERROR:
          default:
            console.error("An unknown error occurred.");
            break;
        }
      }
    );
  } else {
    console.error("Error: Geolocation is not supported by this browser.");
  }
};

const MarketPage = (props) => {
  const { user } = useUserState();
  const [showSubmitForm, setShowSubmitForm] = useState(false);
  const [isMobile, setIsMobile] = useState();
  const [jrOpen, setJROpen] = useState(false);
  const [mapOpen, setMapOpen] = useState(true);
  const [agreementOpen, setAgreementOpen] = useState(false);
  const [mapFocus, setMapFocus] = useState([48.9523, -57.946]);
  useEffect(() => getGeolocation(setMapFocus), []);
  const [mapZoom, setMapZoom] = useState(13);
  const [mapRefresh, setMapRefresh] = useState();
  const refreshMap = () => setMapRefresh(!mapRefresh);
  const [filterCategory, setFilterCategory] = useState("All");
  const [directOrMarket, setDirectOrMarket] = useState("1");
  const [isMarket, setIsMarket] = useState(props.isMarket);
  const [currentEmployee, setCurrentEmployee] = useState(-1);

  const [mapEmployees, setMapEmployees] = useState([]);
  const [JRList, setJRList] = useState([]);
  const [currentJRs, setCurrentJRs] = useState([{}]);

  const [submitAlert, setSubmitAlert] = useState(false);
  const [chargePercentages, setChargePercentages] = useState({
    clientPercentage: 0,
    vendorPercentage: 0,
  });
  const [JRsLoaded, setJRsLoaded] = useState();

  const currentDate = new Date();
  const [startDate, setStartDate] = useState(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      1
    )
  );
  const [endDate, setEndDate] = useState(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      parseInt(currentDate.getDate()) + 7,
      0
    )
  );
  const [currentIcon, setCurrentIcon] = useState({
    closePopup: () => {
      return;
    },
  });

  const { openJR, setOpenJR } = useJRContext();

  const changeMapEmployees = (newList) => {
    setMapEmployees(newList);
    // force map rerender
    if (mapZoom === 13) {
      setMapZoom(13.4);
    } else {
      setMapZoom(13);
    }
  };

  const toMap = () => {
    setMapOpen(true);
    setJROpen(false);
  };

  const toJR = () => {
    setMapOpen(false);
    setJROpen(true);
  };

  useEffect(() => {
    setIsMarket(props.isMarket);
  }, [props.isMarket]);

  const fetchEmployees = useCallback(() => {
    console.log(user);
    axios.post("/employee/getEmployees").then((res) => {
      console.log(res.data);

      if (res.data.message === "forbidden") {
        console.log("forbidden");
        return;
      }
      setMapEmployees(
        res.data.employees.map((employee, i) => {
          employee.visible = true;
          if (employee.lat == null) {
            if (i < 10) {
              employee.lat = locations[i][0];
              employee.lon = locations[i][1];
            } else {
              const index = () => Math.floor(Math.random() * 10);
              employee.lat = locations[index()][0];
              employee.lon = locations[index()][1];
            }
          }
          const image = new Image();
          image.crossOrigin = "anonymous";
          if (employee.profile_pic === "avatar_default.png") {
            employee.profile_pic = backupProfileImage;
          }
          image.src = employee.profile_pic;
          employee.profile_pic = image;

          return employee;
        })
      );
    });
  }, [user.vendorMode]);

  const fetchData = useCallback((showAlert = false) => {
    // setOpenJR(null);
    axios.get("/jobRequest/getMarketJRdetails").then((res) => {
      let jobDetails = res.data.jobDetails.map((JR) => {
        JR.job_due = new Date(JR.job_due);
        return JR;
      });
      if (showAlert) {
        setSubmitAlert(true);
        setTimeout(() => setSubmitAlert(false), 4000);
      }
      setJRList(jobDetails);
    });
  }, []);

  const fetchAcceptedData = () => {
    axios.post("/jobRequest/getAcceptedJRdetails").then((res) => {
      let jobDetails = res.data.jobDetails.map((JR) => {
        JR.job_due = new Date(JR.job_due);
        return JR;
      });
      setJRList(jobDetails);
    });
  };

  const fetchJRData = useCallback(() => {
    isMarket === false ? fetchAcceptedData() : fetchData();
  }, [fetchData, isMarket]);

  const fetchJRsData = useCallback(() => {
    fetchAcceptedData();
    fetchData();
  }, [fetchData, isMarket]);

  const fetchChargeData = useCallback(() => {
    axios.get("/finance/getChargePercentages").then((res) => {
      setChargePercentages(res.data);
    });
  }, []);

  useEffect(() => {
    fetchEmployees();
    fetchJRData();
    fetchChargeData();
  }, [fetchChargeData, fetchEmployees, fetchJRData]);

  // useEffect(() => setOpenJR(null), [isMarket, setOpenJR]);

  useEffect(() => {
    if (openJR !== null) {
      return setCurrentJRs([openJR]);
    }
    let jrList = [];
    for (let jr of JRList) {
      if (!(filterCategory === "All" || jr.job_category === filterCategory)) {
        continue;
      }
      if (
        isMarket ||
        jr.job_due <= date_today ||
        (jr.job_due >= startDate && jr.job_due <= endDate)
      ) {
        jrList.push(jr);
      }
    }
    if (user.vendorMode && user.empRole === ("Admin" || "Manager")) {
      if (directOrMarket === "1") {
        jrList = jrList.filter((JR) => JR.send_market === 1);
      } else if (directOrMarket === "0") {
        jrList = jrList.filter((JR) => JR.send_market === 0);
      }
    }
    console.log(jrList);
    setCurrentJRs(jrList);
    setJRsLoaded(jrList[0]?.assigned_vendor_id);
  }, [
    JRList,
    startDate,
    endDate,
    filterCategory,
    isMarket,
    directOrMarket,
    user.vendorMode,
    user.empRole,
    setOpenJR,
    openJR,
    fetchJRData,
  ]);

  useEffect(() => {
    if (isMobile) {
      setJROpen(true);
      setMapOpen(false);
    } else {
      setMapOpen(true);
      setJROpen(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (openJR !== null) {
      currentIcon.closePopup();
    }
  }, [openJR, currentIcon]);

  // useEffect(() => {
  //   setOpenJR(null);
  // }, [filterCategory, startDate, endDate, setOpenJR]);

  console.log(openJR);
  return (
    <>
      <MainCard
        market={true}
        marketBar={{
          user: user,
          filterCategory: filterCategory,
          startDate: startDate,
          endDate: endDate,
          mapEmployees: mapEmployees,
          mapOpen: mapOpen,
          toJR: toJR,
          toMap: toMap,
          setStartDate: setStartDate,
          setEndDate: setEndDate,
          setFilterCategory: setFilterCategory,
          setMapEmployees: setMapEmployees,
          refreshMap: refreshMap,
          setIsMarket: setIsMarket,
          setCurrentJRs: setCurrentJRs,
        }}
        isMobile={isMobile}
        isMarket={isMarket}
        title={isMarket ? "Market" : "Job Requests"}
        style={{
          backgroundColor: "#fff",
          height: "95%",
        }}
      >
        {(isMarket && (JRsLoaded === null || JRsLoaded === undefined)) ||
        (!isMarket && JRsLoaded !== null) ? (
          <Container setIsMobile={setIsMobile}>
            <div className={styles.mainPage}>
              <div className={styles.split}>
                {jrOpen && (
                  <>
                    {isMarket ? (
                      <JobDisplay
                        setJRsLoaded={setJRsLoaded}
                        chargePercentages={chargePercentages}
                        fetchJrData={fetchData}
                        isMobile={isMobile}
                        isMarket={isMarket}
                        currentJRs={currentJRs}
                        showSubmitForm={showSubmitForm}
                        setShowSubmitForm={setShowSubmitForm}
                        setMapFocus={setMapFocus}
                        setAgreementOpen={setAgreementOpen}
                      />
                    ) : (
                      <>
                        {!user.vendorMode ? (
                          <ClientJR chargePercentages={chargePercentages} />
                        ) : user.vendorMode &&
                          user.empRole !== ("Admin" || "Manager") ? (
                          <EmployeeJR
                            setJRsLoaded={setJRsLoaded}
                            chargePercentages={chargePercentages}
                            filterValue={directOrMarket}
                            setFilterValue={setDirectOrMarket}
                            acceptedJrs={fetchAcceptedData}
                            currentJRs={currentJRs}
                            showSubmitForm={showSubmitForm}
                            setShowSubmitForm={setShowSubmitForm}
                            fetchJrData={fetchData}
                          />
                        ) : (
                          user.vendorMode && (
                            <VendorJR
                              setJRsLoaded={setJRsLoaded}
                              chargePercentages={chargePercentages}
                              setMapFocus={setMapFocus}
                              filterValue={directOrMarket}
                              setFilterValue={setDirectOrMarket}
                              acceptedJrs={fetchAcceptedData}
                              currentJRs={currentJRs}
                              showSubmitForm={showSubmitForm}
                              setShowSubmitForm={setShowSubmitForm}
                              fetchJrData={fetchJRData}
                              setAgreementOpen={setAgreementOpen}
                            />
                          )
                        )}
                      </>
                    )}
                  </>
                )}

                {mapOpen && (
                  <MapDisplay
                    currentIcon={currentIcon}
                    mapEmployees={mapEmployees}
                    mapRefresh={mapRefresh}
                    currentEmployee={currentEmployee}
                    isMobile={isMobile}
                    focus={mapFocus}
                    zoom={mapZoom}
                    isMarket={isMarket}
                    currentJRs={currentJRs}
                    setCurrentIcon={setCurrentIcon}
                    setMapEmployees={changeMapEmployees}
                    setCurrentEmployee={setCurrentEmployee}
                    setFocus={setMapFocus}
                    toJR={toJR}
                  />
                )}
              </div>
            </div>
          </Container>
        ) : (
          <LoadScreen />
        )}
      </MainCard>

      {submitAlert && (
        <Alert
          style={{
            zIndex: 10000,
            position: "absolute",
            top: "2rem",
            left: "calc(50vw - 8rem)",
            width: "16rem",
          }}
          severity="success"
        >
          Your bid has been submitted
        </Alert>
      )}
      {/* <Modal
        destroyOnClose={true}
        width="700px"
        title="Submitting Bid"
        open={showSubmitForm}
        onCancel={() => setShowSubmitForm(false)}
        footer={false}
      >
        
        {showSubmitForm && (
          <SubmitBidForm
            chargePercentages={chargePercentages}
            openJR={openJR}
            currentJRs={currentJRs}
            fetchJrData={fetchData}
            id={openJR.job_id}
            clientID={openJR.client_id}
            onClose={() => setShowSubmitForm(false)}
          />
        )}
      </Modal> */}
      <Modal
        destroyOnClose={true}
        width="1000px"
        title="Service Agreement"
        open={agreementOpen}
        onCancel={() => setAgreementOpen(false)}
        footer={false}
      >
        {agreementOpen && !isMarket && (
          <Agreement
            hasAgreed={1}
            vendorLegalName={openJR.vendor_legal_name}
            clientName={openJR.clientName}
            workDescription={openJR.job_description}
            provinceName={openJR.province_name}
            jobId={openJR.job_id}
            closeAgreement={() => setAgreementOpen(false)}
          />
        )}
      </Modal>
    </>
  );
};

MarketPage.defaultProps = {
  isMarket: false,
};

export default MarketPage;
