import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useUserState } from "../../../../Context/UserContext";
import { DatePicker, Image, Modal, TimePicker } from "antd";

import { Button, Grid, InputLabel, OutlinedInput, Stack } from "@mui/material";
import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";

const EditTroubleshooting = (props) => {
  const { user } = useUserState();
  const [file, setFile] = useState("");

  const [troubleshootImage, setTroubleshootImage] = useState([]);

  const [troubleshootID, setTroubleshootID] = useState();

  const [attachments, setAttachments] = useState([]);
  const [startDate, setStartDate] = useState();
  const [jobTime, setJobTime] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    axios
      .post("/troubleshoot/getTroubleshootingData", { JobId: props.jobID })
      .then((res) => {
        let troubleshootData = res.data.troubleshootData[0];
        setTroubleshootID(troubleshootData.troubleshoot_id);
        setDescription(troubleshootData.troubleshoot_description);
        setJobTime(troubleshootData.start_time);
        setStartDate(troubleshootData.start_date);
        setTroubleshootImage(res.data.images);
      });
  }, []);

  const importFile = () => {
    let newArray;
    if (attachments !== undefined) {
      newArray = [...attachments];
    } else {
      newArray = [];
    }
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = () => {
      newArray.push(...input.files);
      setAttachments(2);
      setAttachments(newArray);
    };
    input.click();
  };
  const removeAttachment = (id) => {
    const newArray = [...attachments];
    newArray.splice(id, 1);
    if (newArray.length === 0) {
      setAttachments("");
    } else {
      setAttachments(0);
      setAttachments(newArray);
      setFile("");
      setFile(newArray);
    }
  };

  const handleUpdate = () => {
    const formObject = {
      startDate: startDate,
      startTime: jobTime,
      description: description,
      troubleshootId: troubleshootID,
      JobId: props.jobID,
      activityDesc: `Troubleshoot entry is updated."`,
      userName: `${user.first_name} ${user.last_name}`,
    };

    axios
      .post("/troubleshoot/updateTroubleshootEntry", formObject)
      .then((response) => {
        if (response.data.ok) {
          if (attachments.length > 0) {
            let form = new FormData();

            for (const element of attachments) {
              form.append("file", element);
            }
            form.append("troubleshoot_id", troubleshootID);
            form.append("job_id", props.jobID);

            axios
              .post("/troubleshoot/addTroubleshootingImages", form)
              .then((res) => {
                props.setShowEditForm(false);
                props.fetchData();
                // props.fetchTroubleshootData();
                // props.fetchActivityData();
              });
          } else {
            props.setShowEditForm(false);
            props.fetchData();
            // props.fetchTroubleshootData();
            // props.fetchActivityData();
          }
        }
      });
  };
  const createAttachmentDisplay = (attachments) => {
    const attachmentList = [];
    for (let i in attachments) {
      attachmentList.push(
        <div
          onClick={() => removeAttachment(i)}
          key={attachments[i].name + attachments[i].size}
          className="attachment"
        >
          {attachments[i].type.includes("image") ? (
            <img
              className="testIMG"
              src={URL.createObjectURL(attachments[i])}
              alt=""
            />
          ) : (
            attachments[i].name
          )}
        </div>
      );
    }
    return (
      <>
        <div>
          <p>Attachments: </p>
        </div>
        {attachmentList}
      </>
    );
  };

  return (
    <Modal
      destroyOnClose={true}
      width="700px"
      title={"Update Troubleshooting Entry"}
      open={props.showEditForm}
      onCancel={() => {
        props.setShowEditForm(false);
      }}
      footer={false}
    >
      <form>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel>Start Time</InputLabel>
              <DatePicker
                required
                value={moment(jobTime)}
                label="Start Time"
                name="startTime"
                format={"YYYY-MM-DD HH:mm"}
                onChange={(e, dateString) => {
                  setStartDate(dateString);
                  setJobTime(dateString);
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <InputLabel>Description</InputLabel>
              <OutlinedInput
                required
                id="troubleshootingDescription"
                name="troubleshootingDescription"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                placeholder="Troubleshooting Description"
                multiline
                rows={4}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel>Pictures/Documents</InputLabel>
              <Button
                size="sm"
                type="button"
                variant="contained"
                color="primary"
                onClick={importFile}
                id="file"
                name="file"
              >
                Choose File
              </Button>

              {attachments !== undefined && attachments.length !== 0 && (
                <div className="attachments">
                  {createAttachmentDisplay(attachments)}
                </div>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <AnimateButton>
                <Button
                  size="sm"
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleUpdate();
                  }}
                  sx={{ ml: 2, float: "right" }}
                >
                  Update
                </Button>

                <Button
                  size="sm"
                  type="button"
                  variant="outlined"
                  color="secondary"
                  onClick={() => props.setShowEditForm(false)}
                  sx={{ ml: 2, float: "right" }}
                >
                  Cancel
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default EditTroubleshooting;
