import { useEffect, useRef } from "react";

// only fires once
const useOnce = (callback) => {
  const shouldRun = useRef(true);
  useEffect(() => {
    if (shouldRun.current) {
      shouldRun.current = false;
      callback();
    }
  }, [callback]);
  return shouldRun;
};

export default useOnce;
