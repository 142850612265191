const spinner = require("../../icons/loader.gif");

const LoadingSpinner = ({ isActive, children }) => {
  return (
    <>
      {children}
      {isActive && (
        <div
          style={{
            position: "absolute",
            zIndex: 5,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.3)", // don't set to a solid color
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img alt={""} src={spinner} width={64} height={64} />
        </div>
      )}
    </>
  );
};

LoadingSpinner.defaultProps = {
  isActive: true,
  children: <></>,
};

export default LoadingSpinner;
