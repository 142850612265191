import * as React from "react";

import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MainFeaturedPost from "./MainFeaturedPost";
import "./landingPage.css";

const mainFeaturedPost = {
  title: "FixDen",
  description: "Find the right tradesperson to fix your problem."
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      light: "#77c7a2",
      dark: "#017a46",
      contrastText: "#fff"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      }
    }
  }
});

export default function MainPage() {
  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          padding: "0",
          width: "100%",
          height: "3450px",
          display: "flex",
          justifyContent: "center", // Center horizontally
          borderRadius: "10px"
        }}
      >
        <MainFeaturedPost post={mainFeaturedPost} />
      </Container>
    </ThemeProvider>
  );
}
