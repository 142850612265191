import axios from "axios";

import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  FormHelperText,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";

import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import AuthWrapper from "./AuthWrapper";
import { useNavigate } from "react-router";

const ForgotPassword = (_props) => {
  const navigate = useNavigate();

  const onSubmit = (values) => {
    const { email } = values;

    axios
      .post("/auth/resetPasswordLink", {
        email: email,
        host: window.location.host,
      })
      .then((_res) => {
        navigate("/resetLinkSent");
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  return (
    <AuthWrapper>
      <Formik
        initialValues={{
          email: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
        })}
        onSubmit={onSubmit}>
        {({
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-signup">
                    Enter the email associated with your account*
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="email-login"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="email"
                    inputProps={{}}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText error id="helper-text-email-signup">
                      {errors.email}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary">
                    Reset Password
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </AuthWrapper>
  );
};

export default ForgotPassword;
