import { Link } from 'react-router-dom';

import { Result } from 'antd';

import { Button } from '@mui/material';

import AuthWrapper from './AuthWrapper';

const PasswordResetSuccess = () => {
	return (
		<>
			<AuthWrapper>
				<Result
					className='flex-60 padding0'
					status='success'
					title={<h4>Your password is reset successfully. </h4>}
					extra={[
						<Button type='primary'>
							<Link to='/login'>Login</Link>
						</Button>
					]}
				/>
			</AuthWrapper>
		</>
	);
};

export default PasswordResetSuccess;
