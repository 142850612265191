import React, { useState, useEffect } from "react";
import { useUserState } from "../../../../Context/UserContext";
import axios from "axios";

import { Button, Grid, InputLabel, Stack, useMediaQuery } from "@mui/material";

import { Modal } from "antd";

function AddImageModal(props) {
  const { user } = useUserState();
  const mobileView = useMediaQuery("(max-width: 600px)");

  const [attachments, setAttachments] = useState([]);
  const [property, setProperty] = useState(0);

  const [properties, setProperties] = useState([]);
  useEffect(() => {
    axios.get("/property/getPropertyDetails").then((res) => {
      console.log(res.data.property);
      setProperties(res.data.property);
      res.data.property.forEach((prop, index) => {
        if (user.street === prop.street && user.city === prop.city) {
          setProperty(index);
        }
      });
    });
  }, []);
  console.log(props.jrData);
  const onSubmit = () => {
    if (attachments.length > 0) {
      let form = new FormData();
      for (let attachment of attachments) {
        form.append("file", attachment);
      }
      form.append("job_id", props.jrData.job_id);
      axios.post("/jobRequest/addImages", form).then((res) => {
        if (res.data.ok) {
          props.setImageSubmitAlert(true);
          props.onClose();
          props.fetchJrData();
          props.fetchData();
        }
      });
    }
  };

  function importFile() {
    let newArray;
    if (attachments !== undefined) {
      newArray = [...attachments];
    } else {
      newArray = [];
    }
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = () => {
      newArray.push(...input.files);
      setAttachments(2);
      setAttachments(newArray);
    };
    input.click();
  }

  const removeAttachment = (id) => {
    const newArray = [...attachments];
    newArray.splice(id, 1);
    if (newArray.length === 0) {
      setAttachments("");
    } else {
      setAttachments(newArray);
    }
  };

  const createAttachmentDisplay = (attachments, jrImages) => {
    console.log(attachments);
    const attachmentList = [];
    for (let i in attachments) {
      attachmentList.push(
        <div
          onClick={() => removeAttachment(i)}
          key={attachments[i].name + attachments[i].size}
          className="attachment"
        >
          {attachments[i].type.includes("image") ? (
            <img
              style={{ width: 40, height: 40 }}
              className="testIMG"
              src={URL.createObjectURL(attachments[i])}
              alt=""
            />
          ) : (
            attachments[i].name
          )}
        </div>
      );
    }

    return <>{attachmentList}</>;
  };

  return (
    <Modal
      destroyOnClose={true}
      width="400px"
      title="Add Images"
      open={props.showImageUploadModal}
      onCancel={props.onClose}
      footer={[
        <div style={{ height: 50 }}>
          <Button
            size="sm"
            type="button"
            variant="contained"
            color="primary"
            onClick={() => {
              props.onClose();
            }}
            sx={{ ml: 2, float: mobileView ? "none" : "right" }}
          >
            Cancel
          </Button>

          <Button
            size="sm"
            type="button"
            variant="contained"
            color="primary"
            onClick={() => {
              onSubmit();
            }}
            sx={{ ml: 2, float: mobileView ? "none" : "right" }}
          >
            Upload
          </Button>
        </div>
      ]}
    >
      <Grid item xs={12}>
        <Stack>
          <InputLabel>
            Please upload any pictures or documents or videos
          </InputLabel>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              size="sm"
              type="button"
              variant="contained"
              color="primary"
              onClick={importFile}
              id="document"
              name="document"
            >
              Choose File
            </Button>

            {attachments !== undefined && attachments.length !== 0 && (
              <div className="attachments" style={{ marginLeft: 10 }}>
                {createAttachmentDisplay(attachments)}
              </div>
            )}
          </div>
        </Stack>
      </Grid>
    </Modal>
  );
}

export default AddImageModal;
