import { useCallback, useEffect } from "react";
import { useUserState } from "../Context/UserContext";
import axios from "axios";

const LocationTracker = ({ children }) => {
  const userState = useUserState();
  const getGeolocation = useCallback(() => {
    if (userState == null) return;
    const { user } = userState;
    if (typeof user === "undefined") return;
    if (!user.vendorMode) return;
    if (
      typeof user === "undefined" ||
      (typeof user?.empRole === "string" &&
        ["Admin", "Manager", null].includes(user.empRole))
    )
      return;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          const data = {
            lat: coords.latitude,
            lon: coords.longitude,
          };
          axios.post("/employee/setLocation", data);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.error("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.error("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              console.error("The request to get user location timed out.");
              break;
            case error.UNKNOWN_ERROR:
            default:
              console.error("An unknown error occurred.");
              break;
          }
        }
      );
    } else {
      console.error("Error: Geolocation is not supported by this browser.");
    }
  }, [userState]);

  useEffect(() => {
    getGeolocation();
  }, [getGeolocation]);
  return <>{children}</>;
};

export default LocationTracker;
