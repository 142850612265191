// material-ui
import {
  Box,
  InputLabel,
  Stack,
  Switch,
  Typography,
  useMediaQuery
} from "@mui/material";

// project import
import NavGroup from "./NavGroup";
import {
  DashboardOutlined,
  MessageOutlined,
  DollarCircleOutlined,
  ToolOutlined,
  GroupOutlined,
  UserOutlined,
  TeamOutlined,
  HomeOutlined
} from "@ant-design/icons";
import adminMenu from "../../../../menu-items/admin";
import { useUserState } from "../../../../../Context/UserContext";
import Notification from "../../../../../Pages/InboxPage/InboxComponents/Notification";
import { useEffect, useState } from "react";
import axios from "axios";

// icons
const icons = {
  DashboardOutlined,
  MessageOutlined,
  DollarCircleOutlined,
  ToolOutlined,
  GroupOutlined,
  UserOutlined,
  TeamOutlined,
  HomeOutlined,
  Notification
};

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = (props) => {
  const { user } = useUserState();
  const [unreadMsg, setUnreadMsg] = useState([]);
  const matchesS = useMediaQuery("(min-width: 1050px)");
  const matchesXs = useMediaQuery("(min-width: 950px)");

  useEffect(() => {
    axios
      .post("/communication/getUnreadMessage", {
        // receiverId: receipientId
      })
      .then((res) => {
        setUnreadMsg(res.data.unreadMsgList.length);
      });
  }, []);

  const employeeJobRequest = {
    id: "employeeJobRequest",
    title: "Job Requests",
    type: "item",
    url: "/dashboard/employeejobrequest",
    icon: icons.ToolOutlined,
    breadcrumbs: false
  };

  const clientJobRequest = {
    id: "clientJobRequest",
    title: "Job Requests",
    type: "item",
    url: "/dashboard/clientjobrequest",
    icon: icons.ToolOutlined,
    breadcrumbs: false
  };

  const eventCalendar = {
    id: "eventCalendar",
    title: "Calendar",
    type: "item",
    url: "/dashboard/eventcalendar",
    icon: icons.GroupOutlined,
    breadcrumbs: false
  };
  const vendorEvents = {
    id: "eventCalendar",
    title: "Events",
    type: "item",
    url: "/dashboard/vendorMode/events",
    icon: icons.GroupOutlined,
    breadcrumbs: false
  };

  const bidding = {
    id: "bidding",
    title: "Market",
    type: "item",
    url: "/dashboard/vendorMode/bidding",
    icon: icons.DashboardOutlined,
    breadcrumbs: false
  };

  const finance = {
    id: "finance",
    title: "Finance",
    type: "item",
    url: "/dashboard/finance",
    icon: icons.DollarCircleOutlined,
    breadcrumbs: false
  };
  const vendorFinance = {
    id: "finance",
    title: "Finance",
    type: "item",
    url: "/dashboard/vendorMode/finance",
    icon: icons.DollarCircleOutlined,
    breadcrumbs: false
  };

  const chat = {
    id: "chat",
    title: "Chat",
    type: "item",
    url: "/dashboard/chat",
    icon: icons.MessageOutlined,
    breadcrumbs: false,
    unreadMessages: unreadMsg
  };
  // const vendorChat = {
  //   id: "chat",
  //   title: "Chat1",
  //   type: "item",
  //   url: "/dashboard/vendorMode/chat",
  //   icon: icons.MessageOutlined,
  //   breadcrumbs: false,
  //   unreadMessages: unreadMsg
  // };
  // const employeeChat = {
  //   id: "chat",
  //   title: "Chat",
  //   type: "item",
  //   url: "/dashboard/vendorMode/employee/chat",
  //   icon: icons.MessageOutlined,
  //   breadcrumbs: false,
  //   unreadMessages: unreadMsg
  // };

  let dashboardChildren;
  if (!user.vendorMode) {
    dashboardChildren = [clientJobRequest, eventCalendar, finance, chat];
  } else if (user.empRole === ("Admin" || "Manager")) {
    dashboardChildren = [bidding, vendorEvents, vendorFinance, chat];
  } else {
    dashboardChildren = [employeeJobRequest, vendorEvents, chat];
  }

  const dashboard = {
    id: "group-dashboard",
    title: "Dashboard",
    type: "group",
    children: dashboardChildren
  };

  const menuItems = {
    items: user.is_admin === 1 ? [adminMenu] : [dashboard]
  };

  const navGroups = menuItems.items.map((item) => {
    if (item.type === "group") {
      return (
        <NavGroup
          handleVendorMode={props.handleVendorMode}
          setOpen={props.setOpen}
          key={item.id}
          item={item}
        />
      );
    } else {
      return (
        <Typography key={item.id} variant="h6" color="error" align="center">
          Fix - Navigation Group
        </Typography>
      );
    }
  });

  return (
    <Box
      sx={{
        pt: 2,
        marginLeft: matchesS ? "25%" : matchesXs ? "12%" : ""
      }}
    >
      {navGroups}
      {/* <Stack direction="row">
        <InputLabel
          style={{ cursor: "pointer", marginTop: "0.5rem", color: "#fcf2e7" }}
          htmlFor="vendorMode"
        >
          {user.vendorMode
            ? matchesXs
              ? "Client"
              : "Client Mode"
            : matchesXs
            ? "Vendor"
            : "Vendor Mode"}
        </InputLabel>
        <Switch
          id="vendorMode"
          color="hover"
          checked={user.vendorMode}
          onChange={() => {
            props.handleVendorMode();
          }}
        />
      </Stack> */}
    </Box>
  );
};

export default Navigation;
