import { useState } from "react";
import LoadingSpinner from "../../../Layout/components/LoadingSpinner";
import { Button, Grid, InputLabel, OutlinedInput, Stack } from "@mui/material";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import axios from "axios";

const EditBankingInfo = (props) => {
  console.log(props.user);
  const vendorDetails = props.user.bankInfo;
  const infoIV = props.user.vendorDetails.bank_info_iv;
  const [institutionCode, setInstitutionCode] = useState(
    vendorDetails.bank_institution_code
  );
  const [transitCode, setTransitCode] = useState(
    vendorDetails.bank_transit_code
  );
  const [accountNumber, setAccountNumber] = useState(
    vendorDetails.bank_account_number
  );

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = () => {
    setSubmitting(true);
    const submitData = {
      accountNumber: accountNumber,
      transitCode: transitCode,
      institutionCode: institutionCode,
      info_iv: infoIV,
    };

    axios
      .post("/updateBanking", submitData)
      .then((res) => {
        if (res.data.ok) {
          props.setEditBanking(false);
        }
      })
      .catch((err) => {
        console.error(err.message);
      })
      .finally(() => {
        setSubmitting(false);
        props.fetchVendorData();
      });
  };

  return (
    <LoadingSpinner isActive={submitting}>
      <Grid item xs={12} md={6}>
        <Stack spacing={1}>
          <InputLabel>Bank institution code</InputLabel>
          <OutlinedInput
            required
            name="institutionCode"
            id="institutionCode"
            type="text"
            value={institutionCode}
            onChange={(e) => setInstitutionCode(e.target.value)}
            placeholder="Institution Code"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack spacing={1}>
          <InputLabel>Branch transit code</InputLabel>
          <OutlinedInput
            required
            name="transitCode"
            id="transitCode"
            type="text"
            value={transitCode}
            onChange={(e) => setTransitCode(e.target.value)}
            placeholder="Branch transit code"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack spacing={1}>
          <InputLabel>Account number</InputLabel>
          <OutlinedInput
            required
            name="accountNumber"
            id="accountNumber"
            type="text"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            placeholder="Account number"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} marginTop={"2rem"}>
        <AnimateButton>
          <Stack direction={"row"} justifyContent={"right"} spacing={2}>
            <Button
              disableElevation
              size="sm"
              variant="outlined"
              color="secondary"
              onClick={() => props.setEditBanking(false)}
            >
              Cancel
            </Button>
            <Button
              disableElevation
              size="sm"
              variant="contained"
              color="primary"
              onClick={() => onSubmit()}
            >
              {props.vendorDetails.bankInfo.bank_account_number === null
                ? "Add"
                : "Update"}
            </Button>
          </Stack>
        </AnimateButton>
      </Grid>
    </LoadingSpinner>
  );
};

export default EditBankingInfo;
