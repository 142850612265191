import { useState } from "react";
import { InputLabel, Input, Grid, Button } from "@mui/material";
import axios from "axios";
import { useUserDispatch, useUserState } from "../../Context/UserContext";

const ChangeProfilePicture = (props) => {
  const dispatch = useUserDispatch();
  const { user } = useUserState();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUploadButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileInputChange = (event) => {
    const input = event.target.files;
    setSelectedFile(input[0]);

    if (input && input[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        document
          .getElementById("previewAva")
          .setAttribute("src", e.target.result);
      };
      reader.readAsDataURL(input[0]); // convert to base64 string
    }
  };

  const readURL = (input) => {
    setSelectedFile(input[0]);

    if (input && input[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        document
          .getElementById("previewAva")
          .setAttribute("src", e.target.result);
      };
      reader.readAsDataURL(input[0]); // convert to base64 string
    }
  };
  const updateProfileImage = (newImage) => {
    user.vendorMode
      ? dispatch({
          state: {
            user: { ...user, vendor_profile_pic_url: newImage }
          },
          type: "initialize"
        })
      : dispatch({
          state: {
            user: { ...user, profile_pic_url: newImage }
          },
          type: "initialize"
        });
  };

  const updateImageOnSubmit = (file) => {
    var form = new FormData();
    form.append("file", file);

    axios
      .post(
        user.vendorMode ? "/updateVendorProfileImg" : "/updateProfileImage",
        form
      )
      .then((response) => {
        updateProfileImage(response.data.newImage);
        props.closeModal();
      });
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <div
            className="ant-avatar previewimg "
            style={{ width: "145px", height: "145px", marginLeft: "100px" }}
          >
            <img
              alt="profile"
              src={
                !user.vendorMode
                  ? props.user.profile_pic_url
                    ? props.user.profile_pic_url
                    : require("../../images/profile.png")
                  : user.vendor_profile_pic_url
                  ? user.vendor_profile_pic_url
                  : require("../../images/profile.png")
              }
              id="previewAva"
            />
          </div>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <div>
            <Button
              size="small"
              type="button"
              variant="contained"
              color="primary"
              onClick={handleUploadButtonClick}
            >
              Upload
            </Button>
            <input
              id="fileInput"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileInputChange}
            />
          </div>

          <Input
            style={{ display: "none" }}
            id="myfile"
            type="file"
            name="myfile"
            accept="image/gif, image/jpeg, image/jpg, image/png"
            onChange={(e) => readURL(e.target.files)}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            size="sm"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={props.closeModal}
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedFile}
            size="sm"
            variant="contained"
            color="primary"
            sx={{ mt: 2, float: "right" }}
            onClick={() => {
              updateImageOnSubmit(selectedFile);
            }}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ChangeProfilePicture;
