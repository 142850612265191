import { StyleSheet } from "@react-pdf/renderer";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useRef } from "react";
import PDFToolBar from "./PDFToolBar";
import { DownloadOutlined } from "@ant-design/icons";
import generatePDF from "react-to-pdf";

const PrivacyViewer = (props) => {
  const targetRef = useRef();
  const mobileView = useMediaQuery("(max-width: 600px");

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    body: {
      padding: !mobileView
        ? props.signIn
          ? "0"
          : "35px 7.5rem 65px 7.5rem"
        : props.signIn
        ? "0 !important"
        : "3rem 0 0 0",
      paddingHorizontal: 35,
      backgroundColor: "white",
    },
    container: {
      margin: !mobileView
        ? props.signIn
          ? "0"
          : "1rem 7.5rem 25rem 7.5rem"
        : props.signIn
        ? "0"
        : "0.8rem 0 0 0",
      justifyContent: "center",
      padding: props.signIn ? "0 !important" : "0 0.15rem 0.15rem 0.15rem",
      backgroundColor: "white",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 14,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    text: {
      margin: 12,
      fontSize: 18,
      textAlign: "justify",
      fontFamily: "Times-Roman",
      marginbottom: 2,
    },
    title: {
      fontSize: 22,
      textAlign: "center",
      fontFamily: "Times-Roman",
      fontWeight: 800,
      marginbottom: 0.05,
    },
    heading: {
      margin: 12,
      fontSize: 20,
      textAlign: "justify",
      fontFamily: "Times-Roman",
      fontWeight: 600,
      marginbottom: 2,
    },
  });

  return (
    <Grid
      container
      width={"fit-content"}
      justifyContent={"center"}
      style={styles.container}
    >
      <Grid item xs={12} textAlign={"right"}>
        <Button
          variant="text"
          style={{
            minWidth: "1.5rem",
            minHeight: "1.5rem",
            maxWidth: "1.5rem",
            maxHeight: "1.5rem",
            // border: "2px solid blue",
          }}
          onClick={() =>
            generatePDF(targetRef, { filename: "privacypolicy.pdf" })
          }
        >
          <DownloadOutlined />
        </Button>
      </Grid>
      {/* <Grid item xs={12} position={"fixed"} minWidth={"100%"}>
        <PDFToolBar
          targetRef={targetRef}
          filename={"privacypolicy.pdf"}
          targetElement={"privacypolicy"}
        />
      </Grid> */}
      <div id="privacypolicy" ref={targetRef} style={styles.body}>
        <Typography style={styles.title}>
          STEADY INNOVATION CORPORATION
        </Typography>
        <Typography style={styles.title}>PRIVACY POLICY</Typography>
        <Typography style={styles.title}>(the “Privacy Policy”)</Typography>
        <Typography style={styles.text}>
          Steady Innovation Corporation (o/a Steady Innovation, FixDen and other
          brands) (“FixDen”, “we”, “us”, or “our”) takes the security of your
          personal information very seriously. We understand and respect your
          right to privacy and value the trust you place in us. This Privacy
          Policy describes FixDen’s privacy practices in relation to how we
          collect, use, store, disclose and protect your personal information,
          as well as your choices regarding use, access and correction of your
          personal information.
        </Typography>
        <Typography style={styles.heading}>
          1. Scope of this Privacy Policy
        </Typography>
        <Typography style={styles.text}>
          This Privacy Policy applies to information we may collect from
          visitors through our websites which link to this Privacy
          Policy(“Visitors ”) or from users or purchasers of our services and
          apps, including the FixDen platform and applications(“Clients”). (Any
          reference to “Users”, “you” or “your” in this Privacy Policy refers to
          both Visitors and Clients, as the case may be.)
        </Typography>
        <Typography style={styles.text}>
          FixDen may change all or part of this Privacy Policy from time to
          time. We will notify you when this occurs.An up-to-date version of our
          Privacy Policy will be posted on{" "}
          <a href="https://www.FixDen.com">www.FixDen.com</a> and can be found
          under the “Settings” section of our apps. We will always seek your
          express consent to our collection, use and disclosure of your personal
          information, unless subsequent operations are sufficiently similar to
          ones already consented to. You may withdraw your consent at any time.
        </Typography>
        <Typography style={styles.heading}>
          2. Our Collection and Use of Information
        </Typography>
        <Typography style={styles.text}>
          This Privacy Policy distinguishes between “personal information” and
          “non-personal information”. Personal information is information that
          can be used to identify an individual person.Non-personal information
          means data in a form that does not, on its own or in combination with
          other data sets, permit direct association with any specific
          individual. The types of information we collect and the purposes for
          collection are set out below.
        </Typography>
        <Typography style={styles.text}>
          Personal Information – When you contact us, order or download one of
          our products,or register or use our services, we collect personal
          information. This personal information may include your name,
          occupation, shipping and residential address, email address, phone
          number, payment information, contact preferences, credit card
          information, and, if you provide it to us, your age and gender.
        </Typography>
        <Typography style={styles.text}>
          We use personal information to help us create, develop, operate,
          deliver, and improve our products, services, content and advertising,
          and for loss prevention and anti-fraud purposes. Some of the specific
          types of purposes for which we may use your personal information are
          as follows:
        </Typography>
        <Typography style={styles.text}>
          (a) When expressing an interest in obtaining additional information
          about our products and services, downloading our apps or software
          updates, purchasing a product or contacting us, FixDen may collect and
          use personal information, such as your contact information and billing
          information, to assist us in performing the requested services (i.e.
          to respond to your requests and to process payments).
        </Typography>
        <Typography style={styles.text}>
          (b) We may use your personal information, including date of birth, to
          assist with verifying your identity when you contact us.
        </Typography>
        <Typography style={styles.text}>
          (c) From time to time, we may use your personal information to inform
          you of our products and of software and firmware updates, to send you
          notices about products and services that you have purchased, to advise
          you of changes to our terms, conditions, and policies, and to advise
          you of enhancements to our products. Because this information is
          important to your interaction with FixDen, you may not opt out of
          receiving these communications.
        </Typography>
        <Typography style={styles.text}>
          (d) We may contact you to survey you with respect to your use and
          views on our products or to send you promotional material. If you do
          not wish to receive such communications, you may opt out anytime by
          updating your account settings or by emailing{" "}
          {/* TODO: Use real email */}
          <a href="mailto: info@FixDen.com">info@FixDen.com</a>
        </Typography>
        <Typography style={styles.text}>
          Opting Out of Direct Marketing - Where personal information is
          processed for direct marketing purposes, you have the right to object
          at any time to the processing of your personal informationand your
          personal information will no longer be processed for such purposes.
        </Typography>
        <Typography style={styles.text}>
          Online Navigational Information – Our websites, emailsand
          advertisements may use "cookies" and other similar technologies to
          collect online navigational information, such as information about
          your web browser, your Internet Protocol (IP) address, your Internet
          Service Provider, your operating system, referring and exit web sites
          and applications, date/time stamps, clickstream data and the actions
          you take on our websites. Cookies and similar technologies may also be
          placed by third-party analytic services, such as Google analytics, or
          third-party marketing services.
        </Typography>
        <Typography style={styles.text}>
          In some of our email or other electronic communications, we may use a
          "click-through URL" linked to content on our website. When the email
          reader clicks one of these URLs, they pass through a separate web
          server before arriving at the destination page on our website. We
          track this click-through data to help us determine interest in
          particular topics and measure the effectiveness of our customer
          communications. If you prefer not to be tracked in this way, you
          should not click text or graphic links in our electronic
          communication.
        </Typography>
        <Typography style={styles.text}>
          Online Navigational Information is aggregated and used to help us
          understand and analyze trends, to understand and analyze the
          effectiveness of our marketing, to administer the website and apps, to
          learn about user behavior on the site, to gather demographic
          information about our User base as a whole, and to improve our
          products and services to better help you.
        </Typography>
        <Typography style={styles.text}>
          Aggregated data with respect to online navigational information is
          considered non-personal information for the purposes of this Privacy
          Policy. If we do combine non-personal information with personal
          information, the combined information will be treated as personal
          information for as long as it remains combined. To the extent that IP
          addresses or similar identifiers are considered personal information,
          we also treat these identifiers as personal information for the
          purposes of this Privacy Policy.
        </Typography>
        <Typography style={styles.heading}>
          {" "}
          3. Disclosure of Personal Information to Others
        </Typography>
        <Typography style={styles.text}>
          Third Party Providers – With your express consent (and only with your
          express consent), we may provide your personal information to other
          users, including property managers, property owners, tenants, vendors
          and home repair and renovation contractors, to the extent necessary to
          facilitate the provision of the service requested by you, including,
          but not limited to facilitating home repair and/or renovation services
          between property owners, property managers, tenants and contractors,
          or the entering into of a services agreement.
        </Typography>
        <Typography style={styles.text}>
          Business Partners and Solution Providers - With your express consent
          (and only with your express consent), we may provide your personal
          information to trusted business partners and solution providers that
          work with us to provide you with additional or enhanced products and
          services.
        </Typography>
        <Typography style={styles.text}>
          Service Providers - We may contract with third party service providers
          to assist us in performing business-related functions such as
          information processing, conducting security and background checks,
          fulfilling customer orders, delivering products to you, managing and
          enhancing customer data, providing customer service, assessing your
          interest in our products and services, and conducting customer
          research or satisfaction surveys. To the extent a service provider has
          access to your personal information for such a purpose, we will ensure
          that they are obligated to use your personal information only for such
          purpose and that they are obligated to protect your information to
          standards at least as protective as those set out in this Privacy
          Policy.
        </Typography>
        <Typography style={styles.text}>
          Third Party Offers - We may present you with offers from trusted third
          parties to provide an offer or solution to you that may enhance your
          interaction with the property (or properties) you own, manage, live in
          or service. If we present you with such offers or solutions, it will
          be on an “opt-in” basis only.
        </Typography>
        <Typography style={styles.text}>
          Legal Process – It may be necessary by law, litigation or other legal
          process for us to disclose your personal information. Our policy is to
          notify you of any suchlegal process seeking access to your personal
          information unless we are prohibited by law from doing so or when the
          request is an emergency.We may also disclose information about you if
          we determine that disclosure is reasonably necessary to enforce our
          terms and conditions or protect our operations or users.
        </Typography>
        <Typography style={styles.text}>
          <em>Business Transitions</em> – If we are involved in a
          reorganization, merger, or the actual or prospective sale of our
          business or company (or a unit or division) we may disclose personal
          information to the relevant third party as part of the transaction. In
          that event, we will ensure that your personal information remains
          protected in accordance with the principles set out in this Privacy
          Policy.
        </Typography>
        <Typography style={styles.text}>
          <em>International transfer of information collected</em> – FixDen
          primarily stores data about Users in Canada. To facilitate FixDen’s
          global operations, we may transfer and access such information from
          around the world, including from other countries in which FixDen has
          operations. This Privacy Policy shall apply even if FixDen transfers
          personal information about Users to other countries.
        </Typography>
        <Typography style={styles.heading}>
          4. Anonymized and Aggregated Data
        </Typography>{" "}
        <Typography style={styles.text}>
          We may anonymize and aggregate the data we collect across many
          usersand may provide this anonymized, aggregated data to third
          parties. Such data will not identify you or be able to be
          de-anonymized such that it couldidentify you personally.
        </Typography>
        <Typography style={styles.heading}>
          5. Protection of Personal Information
        </Typography>
        <Typography style={styles.text}>
          We take the security of your personal information very seriously. We
          use technical, administrative, and physical controls to maintain the
          security of your data. While we use industry-recognized security
          protocols to protect personal information, we cannot guarantee the
          safety of personal information from hacking or theft. Where a data
          breach occurs, we will notify affected individuals within 72 hours.
          Further, we will not transfer your personal information to any country
          that has privacy practices of a lesser standard than those set out in
          this Privacy Policy. If you have a security-related concern, please
          contact us at <a href="mailto: info@FixDen.com">info@FixDen.com</a>
        </Typography>
        <Typography></Typography>
        <Typography style={styles.heading}>
          6. Retention of Personal Information and Right to Be Forgotten
        </Typography>
        <Typography style={styles.text}>
          You have the right to be forgotten. Upon your request and without
          undue delay, we will erase your personal information from our records,
          other than the personal information that we are required or permitted
          to retain by law, including any necessary information (including your
          contact information) to facilitate the investigation or resolution of
          a complaint, dispute or conflict arising from your use of the FixDen
          services. Upon your request, we will erase your personal information
          without undue delay.
        </Typography>
        <Typography style={styles.text}>
          Subject always to your ability to request we erase your personal
          information as described above, we will retain your personal
          information for the period consistent with the purposes outlined in
          this Privacy Policy.
        </Typography>
        <Typography style={styles.heading}>
          7. Access to your Personal Information
        </Typography>
        <Typography style={styles.text}>
          Upon reasonable request, we will provide you with access to your
          personal information that we retain for any purpose including
          requesting that we correct the data if it is inaccurate or delete the
          data if we are not required to retain it by law or for legitimate
          business purposes. We may decline to process requests that are
          frivolous, that jeopardize the privacy of others, are impractical, or
          for which access is not otherwise required by local law. Access,
          correction, or deletion requests can be made THROUGH
          <a href="mailto: info@FixDen.com">info@FixDen.com</a>.
        </Typography>
        <Typography style={styles.heading}>
          8. Third Party Sites and Services
        </Typography>
        <Typography style={styles.text}>
          Our websites, products, applications, and services may contain links
          to third-party websites, products, and services. Our products and
          services may also offer products or services from third parties. The
          information collected by third parties, including location data or
          contact details, is governed by their privacy policies. FixDen is not
          responsible for these third-party websites, products or services and
          encourages you to review the privacy policies of such third party web
          sites, products and services to understand their information
          practices.
        </Typography>
        <Typography style={styles.heading}>9. Privacy Questions</Typography>
        <Typography style={styles.text}>
          If you have any questions or concerns about our Privacy Policy or
          associated practices, please contact our Data Protection Officer at
          <a href="mailto: info@FixDen.com">info@FixDen.com</a>. You can also
          always contact us by phone at our customer supportnumber.
        </Typography>
        <Typography
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </div>
    </Grid>
  );
};

export default PrivacyViewer;
